import React, { useEffect, useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Typography,
  CircularProgress,
  TablePagination
} from '@material-ui/core';
import { receiptService } from '../../../_services/receipt.service';
import datehelper from "../../../_helpers/datehelper";
import { virtualAccountService } from "../../../_services/virtualaccount.service";

function OnlinePayments({ cust }) {
  console.log('collection tab is', cust);
  const [paymentData, setPaymentData] = useState([]);
  const [spinner, setSpinner] = useState(true);


  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  async function fetchData() {
    try {
      setSpinner(true);
      const response = await virtualAccountService.getOnlinePaymentsOfTheLoan({
        application_id: cust.application_id,
      });
      console.log('response is', response);
      setPaymentData(response || []);
    } catch (err) {
      console.log('Error fetching payment data:', err);
    } finally {
      setSpinner(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Typography variant="h5" gutterBottom style={{ marginBottom: '20px', color: '#2949B9' }}>
        Online Payments
      </Typography>
      <TableContainer component={Paper}  >
        <Table   >
          <TableHead style={{ backgroundColor: '#525155' }}>
            <TableRow >
              <TableCell style={{textAlign:'center' , color:'white'}}>#</TableCell>
              <TableCell style={{textAlign:'center', color:'white' }}>Date</TableCell>
              <TableCell style={{textAlign:'center', color:'white' }}>Loan Number</TableCell>
              <TableCell style={{textAlign:'center', color:'white' }}>Payment</TableCell>
              <TableCell style={{textAlign:'center', color:'white' }}>Remitter Name</TableCell>
              <TableCell style={{textAlign:'center', color:'white' }}>Emp Name</TableCell>
              <TableCell style={{textAlign:'center', color:'white' }}>UTR</TableCell>
              <TableCell style={{textAlign:'center', color:'white' }}>Receipt Number</TableCell>
              <TableCell style={{textAlign:'center' , color:'white'}}>Invoice Number</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {spinner ? (
              <TableRow>
                <TableCell colSpan={9} style={{ textAlign: 'center', padding: '20px' }}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : paymentData.length > 0 ? (
              paymentData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((payment, index) => (
                <TableRow key={payment?._id?.$oid || index}>
                  <TableCell style={{textAlign:'center'}}>{index + 1}</TableCell>
                  <TableCell style={{textAlign:'center'}}>
                    {payment.updated &&
                      datehelper.displayDate(new Date(payment.updated))}
                  </TableCell>
                  <TableCell style={{textAlign:'center'}}>{payment.vaccountnum}</TableCell>
                  <TableCell style={{textAlign:'center'}}>₹{payment.txn_amt}</TableCell>
                  <TableCell style={{textAlign:'center'}}>{payment.remitter_name}</TableCell>
                  <TableCell style={{textAlign:'center'}}>
                    {payment?.receiptdata?.length > 0 &&
                      payment.receiptdata[0]?.emp_name}
                  </TableCell>
                  <TableCell style={{textAlign:'center'}}>{payment.utr_num}</TableCell>
                  <TableCell style={{textAlign:'center'}}>{payment?.receiptdata?.[0]?.receipt_no}</TableCell>
                  <TableCell style={{textAlign:'center'}}>{payment?.receiptdata?.[0]?.invoice_no}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} style={{ textAlign: 'center', padding: '20px' }}>
                  No cash payments available.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {paymentData ? (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={paymentData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : (
        <div />
      )}
    </div>
  );
}

export default OnlinePayments;
