import React, { useState } from 'react'
import { Paper,Button } from '@material-ui/core'
import AlreadyUplaodDocuments from './AlreadyUplaodDocuments'
import NestedDropdownWithUpload from "./NestedDropdownWithUpload"
import UploadDocuments from './UploadedDocuments'
import {employeeService} from '../../../../../_services'
import roleChecker from '../../../../../_helpers/rolechecker'
function AllDocuments(props){
     const cust = props.cust
     const user = employeeService.userValue;
   return (
    <div>
      <div>
           <Paper style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '10px', padding: '10px', paddingBottom: '10px', fontSize:'xx-large'  }}>
               All Documents of the loan
           </Paper>
         {!["Collection","Closed"].includes(cust.stage) && !roleChecker.isAuditorAccess(user.role)  && <NestedDropdownWithUpload cust={cust}/>}
          <UploadDocuments cust={cust} />
           <AlreadyUplaodDocuments cust={cust}/>
      </div>
    </div>
  )
}

export default AllDocuments