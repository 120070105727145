import React, { useEffect, useState } from 'react'
import { useReactToPrint } from "react-to-print";
import { loanService } from '../../../_services';
import './sanctionAnnexureunattesteddeep.css'
import datehelper from "../../../_helpers/datehelper";
import capitalfinance from "../../../assets/capitalfinance.png";
import logo from "../../../assets/kaabil.png"
const UnattestedDeedOfHypothecation = (props) => {

    const data = props.data
    const branches = props.branches


    // const MoeRef = React.useRef();


 
    // const handleSubmissionPrint = useReactToPrint({
    //     content: () => MoeRef.current,
    //     pageStyle: "print",
    // });

    const one = ["", "One ", "Two ", "Three ", "Four ", "Five ", "Six ", "Seven ", "Eight ", "Nine ", "Ten ", "Eleven ", "Twelve ", "Thirteen ", "Fourteen ", "Fifteen ",
        "Sixteen ", "Seventeen ", "Eighteen ", "Nineteen "];

    var ten = ["", "", "Twenty ", "Thirty ", "Forty ", "Fifty ", "Sixty ", "Seventy ", "Eighty ", "Ninety "];

    function numToWords(n, s) {
        let str = "";
        // if n is more than 19, divide it
        if (n > 19) {
            str += ten[parseInt(n / 10)] + one[n % 10];
        }
        else {
            str += one[n];
        }
        if (n != 0) {
            str += s;
        }

        return str;
    }

    function convertToWords(n) {
        // stores word representation of given number n
        let out = "";

        // handles digits at ten millions and hundred
        // millions places (if any)
        out += numToWords(parseInt(n / 10000000),
            "Crore ");

        // handles digits at hundred thousands and one
        // millions places (if any)
        out += numToWords(parseInt((n / 100000) % 100),
            "Lakh ");

        // handles digits at thousands and tens thousands
        // places (if any)
        out += numToWords(parseInt((n / 1000) % 100),
            "Thousand ");

        // handles digit at hundreds places (if any)
        out += numToWords(parseInt((n / 100) % 10),
            "Hundred ");

        if (n > 100 && n % 100 > 0) {
            out += "and ";
        }

        // handles digits at ones and tens places (if any)
        out += numToWords(parseInt(n % 100), "");

        return out;
    }

    const headerDiv = () => (
        <div>
          <img src={logo} style={{ height: "100px" }} alt="KFPL Logo" />
          
            <img
              src={capitalfinance}
              style={{
                height: "100px",
                position: "absolute",
                top: "2",
                right: "0",
                marginRight: "3rem",
              }}
              alt="Capital Finance Logo"
            />
          
          
        </div>
      );
    return (
        <>
            {/* <div style={{ textAlign: "center" }}>
                <button onClick={handleSubmissionPrint}>Print Letter</button>
            </div> */}

            <div
                style={{
                    width: "290mm",
                    height:'1650mm',
                    backgroundColor: "white",
                    padding: 40,
                    position: "relative",
                    fontSize: "20px"
                }}
            >{headerDiv()}<br/><br/>
                <span style={{ marginLeft: "40%" }}><b>SCHEDULE</b></span><br />
                <span style={{ marginLeft: "30%" }}><b>(Unattested Deed of Hypothecation)</b></span><br /><br />
                <table style={{ width: "90%", marginLeft: "5%", marginTop: "20px", fontSize: "20px" }}>
                    <tr style={{ border: "1px solid" }}>
                        <td  colspan="1" style={{width:'10px', textAlign: "center", border: "1px solid",verticalAlign:'top' }}><b>S. NO.</b></td>
                        <td  colspan="1" style={{ textAlign: "center", border: "1px solid" ,verticalAlign:'top'}}><b>Particulars</b></td>
                        <td colspan="4"  style={{ textAlign: "center", border: "1px solid",verticalAlign:'top' }}><b>TERMS</b></td>
                    </tr>
                    <tr style={{ border: "1px solid" }}>
                    <td style={{
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"start"
                        }}>
                           1.
                        </td>
                        <td style={{ border: "1px solid",verticalAlign:'top' }} >
                           <b>Facility</b>
                        </td>
                        <td style={{ border: "1px solid" }}> 
                      <b>  Rs. {data && data.loan && data.loan.sanction_amount}/- (Rupees {convertToWords(data && data.loan && data.loan.sanction_amount)} only)</b>
                        </td>
                    </tr>
                    <tr style={{ border: "1px solid" }}>
                    <td style={{
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"start"
                        }}>
                           2.
                        </td>
                        <td style={{ border: "1px solid",verticalAlign:'top' }} >
                        <b>Facility 
Agreement</b>
                        </td>
                        <td style={{ border: "1px solid" }}> 
                       <b> dated {datehelper.displayDate(new Date(data.loan?.agreement_stamp_date))} executed by and between the Borrowers and the 
Lenders.</b>
                        </td>
                    </tr>
                  
                    <tr style={{ border: "1px solid" }}>
                    <td style={{
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"start"
                        }}>
                           3.
                        </td>
                        <td style={{ border: "1px solid",verticalAlign:'top' }} >
                       <b> Execution</b>
                        </td>
                        <td style={{ border: "1px solid" }}> 
                        <b>Date of execution : {datehelper.displayDate(new Date(data.loan?.agreement_stamp_date))}
Place of execution:__________________________________________________
</b>  </td>
                    </tr>
                    
                    <tr style={{ border: "1px solid" }}>
                        <td style={{
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"start"
                        }}>
                           4.
                        </td>
                        <td style={{ border: "1px solid", width: "30%",verticalAlign:'top' }}><b>Borrowers:</b></td>
                        <td>
                            <tr><b>1) Name: {data && data.applicant && data.applicant.general_information && data.applicant.general_information.name}</b></tr>
                            <tr><b>Residence/registered office/corporate office address:: {data && data.applicant && data.applicant.general_information && data.applicant.general_information.address}</b></tr><br /><br />
                            {data && data.co_applicants && data.co_applicants.length > 0 && data.co_applicants.map((e, idx) => {
                                return <>
                                    <tr><b>{idx+2}) Name: {e.general_information.name}</b></tr>
                                    <tr><b>Residence/registered office/corporate office address:: {e.general_information.address}</b></tr><br /><br />
                                </>
                            })}
                        </td>
                    </tr>
                
                    <tr style={{ border: "1px solid" }}>
                        <td style={{
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"start"
                        }}>
                           5.
                        </td>
                        <td style={{ border: "1px solid", width: "30%" ,verticalAlign:'top'}}><b>Hypothecators:</b></td>
                        <td>
                            <tr><b>1) Name: __________________________________________________________<br/>___________________________________________________________________</b></tr>
                            <tr><b>Residence/registered office/corporate office address:: ____________________<br/>___________________________________________________________________<br/>___________________________________________________________________</b></tr>
                            <tr><b> PAN/CIN No.: _______________________________________________________</b></tr>
                            <tr><b>Email Id: __________________________________________________________</b></tr>
                            <br /><br />
                            <tr><b>2) Name:__________________________________________________________<br/>___________________________________________________________________</b></tr>
                            <tr><b>Residence/registered office/corporate office address:: ____________________<br/>___________________________________________________________________<br/>___________________________________________________________________</b></tr>
                            <tr><b> PAN/CIN No.: _______________________________________________________</b></tr>
                            <tr><b>Email Id: __________________________________________________________</b></tr>
                            <br /><br />
                            <tr><b>3) Name:__________________________________________________________<br/>___________________________________________________________________</b></tr>
                            <tr><b>Residence/registered office/corporate office address:: ____________________<br/>___________________________________________________________________<br/>___________________________________________________________________</b></tr>
                            <tr><b> PAN/CIN No.: ______________________________________________________</b></tr>
                            <tr><b>Email Id: __________________________________________________________</b></tr>
                            <br /><br />
                        </td>
                    </tr>
                    <tr style={{ border: "1px solid" }}>
                    <td style={{
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"start"
                        }}>
                           6.
                        </td>
                        <td style={{ border: "1px solid",verticalAlign:'top' }} >
                       <b> Priority/ 
ranking</b>
                        </td>
                        <td style={{ border: "1px solid" }}> 
                      <b>  a first ranking exclusive charge over Hypothecated Properties which will rank pari 
passu between the Lenders to the extent of their respective share in the Loan.</b>

                        </td>
                    </tr>
 
                    <tr style={{ border: "1px solid" }}>
                    <td style={{
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"start"
                        }}>
                           7.
                        </td>
                        <td style={{ border: "1px solid",verticalAlign:'top' }} >
                       <b> Details of 
Encumbrances</b>
                        </td>
                        <td style={{ border: "1px solid" }}> 
                         ___________________________________________________________________<br/> ___________________________________________________________________<br/> ___________________________________________________________________<br/> ___________________________________________________________________

                        </td>
                    </tr>
                    <tr style={{ border: "1px solid" }}>
                    <td style={{
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"start"
                        }}>
                           8.
                        </td>
                        <td style={{ border: "1px solid",verticalAlign:'top' }} >
                      <b>  Details of 
litigations, 
judgments, 
unsatisfied 
claims etc. 
against the 
Hypothecators 
(existing as on 
date)</b>
                        </td>
                        <td style={{ border: "1px solid",display:'flex',border:'none' }}> 
                         ___________________________________________________________________<br/> ___________________________________________________________________<br/> ___________________________________________________________________<br/> ___________________________________________________________________

                        </td>
                    </tr>
                    <tr style={{ border: "1px solid" }}>
                    <td style={{
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"start"
                        }}>
                          9.
                        </td>
                        <td style={{ border: "1px solid" ,verticalAlign:'top'}} >
                      <b>  Timeline for 
perfection of 
charge</b>
                        </td>
                        <td style={{ border: "1px solid",verticalAlign:'top' }}> 
                       <b> As per provision of the applicable Laws.
</b>

                        </td>
                    </tr>
                    <tr style={{ border: "1px solid" }}>
                    <td style={{
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"start"
                        }}>
                          10.
                        </td>
                        <td style={{ border: "1px solid",verticalAlign:'top' }} >
                       <b> Additional 
Convents</b>
                        </td>
                        <td style={{ border: "1px solid" }}> 
                        <p style={{display:'flex',margin:0}}>I. <p><b>Negative Covenants:</b></p></p>
                     <p style={{marginLeft:'2.1rem'}}>

Each of the Hypothecators hereby undertake and covenant with the Lenders, in 
respect of itself, that until the full and final settlement of the Outstanding Amounts, 
it shall not do or allow to be done or cause to be done, directly and/or indirectly, 
whether through itself or through any other Person, including but not limited to its 
respective promoters, directors, any act, deed or thing (nor take or allow to be 
taken any corporate action, including passing a resolution) in relation to any of the 
following without obtaining the prior written consent of the Lenders:
                     </p>
 
<p style={{display:'flex'}}>
 (a) <p>  incur any Financial Indebtedness;</p> </p>
 
<p style={{display:'flex'}}>
 (b) <p>  effect or cause any change in its name:</p> </p>
 
<p style={{display:'flex'}}>
 (c) <p> effect any changes in its shareholding or capital structure or management or 
board or effect any change, whether directly or indirectly, in its legal or 
beneficial ownership or control or management or shareholding or board or 
modify/ amend any shareholders' agreement or pass any resolution or take 
any corporate action in relation thereto</p> </p>
 
<p style={{display:'flex'}}>
 (d) <p>  effect any changes in its shareholding or capital structure or management or 
board or effect any change, whether directly or indirectly, in its legal or 
beneficial ownership or control or management or shareholding or board or 
modify/ amend any shareholders' agreement or pass any resolution or take 
any corporate action in relation thereto</p> </p>
 
<p style={{display:'flex'}}>
 (e) <p>  purchase, redeem, buy-back or reduce its share capital or otherwise making 
any payment in respect thereof (except as required by applicable Law);
</p> </p>
 
<p style={{display:'flex'}}>
 (f) <p> authorise, create or issue any shares and/or other securities, including rights 
issue of any class or series of shares, buy-back, split, issuance of convertible 
debt instruments, bonus shares, debt restructuring involving conversions into 
equity (except as otherwise provided herein);
</p> </p>
 
<p style={{display:'flex'}}>
 (g) <p>  make any amendments in its constitutional documents and/or make any 
changes to its board of directors;
</p> </p>

 
<p style={{display:'flex'}}>
 (h) <p>  undertake guarantee obligations on behalf of any other Person;</p> </p>
 
<p style={{display:'flex'}}>
 (i) <p> create or permit to subsist any Encumbrance or sell, assign, dispose/ transfer 
the Securities (or any part thereof) whether by way divestment, sale, lease, 
license or exchange or pledge or in any other way, over the Securities (or 
any part thereof), other than the Encumbrance created over the Securities in 
favour of the Lenders, in terms of the Transaction Documents;
</p> </p>
 
<p style={{display:'flex'}}>
 (j) <p> enter into any compromise or arrangement or settlement with any of its 
secured creditors;</p> </p>
 
<p style={{display:'flex'}}>
 (k) <p> liquidate, or dissolve their affairs, become insolvent or liquidate or dissolve 
their respective affairs;</p> </p>
 
<p style={{display:'flex'}}>
 (l) <p> take any action which may result in restricting in any manner, the ability of 
the Lenders to enforce any of the Securities under the Transaction 
Documents;</p> </p>
 
<p style={{display:'flex'}}>
 (m) <p>  change its business or start a new business, in any manner whatsoever,
</p> </p>
 
<p style={{display:'flex'}}>
 (n) <p> approach the Board (as defined in the Insolvency Code) for is voluntary 
liquidation or winding up under the Insolvency Code;</p> </p>
 
<p style={{display:'flex'}}>
 (o) <p>  entering into any related party transaction in relation to the Securities;
</p> </p>
 
<p style={{display:'flex'}}>
 (p) <p>  change its statutory auditors</p> </p>
 
<p style={{display:'flex'}}>
 (q) <p>  pay any commission to the directors, managers or any other person for 
furnishing guarantees,
</p> </p>
 
<p style={{display:'flex'}}>
 (r) <p>  give/ provide/issue counter guarantees or indemnities to any other 
Person/entity whatsoever otherwise than in terms of this Deed; and / or</p> </p>
 
<p style={{display:'flex'}}>
 (s) <p> advancing any monies to the Promoters or any of their group entities.
</p> </p>


                        </td>
                    </tr>
                    <tr style={{ border: "1px solid" }}>
                    <td style={{
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"start"
                        }}>
                           11.
                        </td>
                        <td style={{ border: "1px solid",verticalAlign:'top' }} >
                       <b> Jurisdiction</b>
                        </td>
                        <td style={{ border: "1px solid" }}> 
                       <b> __________________India</b>


                        </td>
                    </tr>
                    <tr style={{ border: "1px solid" }}>
                    <td style={{
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"start"
                        }}>
                           12.
                        </td>
                        <td style={{ border: "1px solid" ,verticalAlign:'top'}} >
                       <b> Particulars for 
serving notices</b>
                        </td>
                        <td style={{ border: "1px solid" }}> 
                       <b>(i) In case of the Lender 1:</b>
                          <table style={{ width: "80%", marginLeft: "3%", marginTop: "10px",marginBottom:'10px', fontSize: "20px" }}>
                            <tr style={{ border: "1px solid" }}>
                                <td style={{ border: "1px solid" }}>
                                <b>Address :</b>
                                </td>
                                <td style={{ border: "1px solid" }}>
                               <b> Kaabil Finance Private Limited, 328C, Kusum 
vihar, Ramnagaria Road, Jagatpura, Jaipur-302025</b>
                                </td>
                            </tr>
                            <tr style={{ border: "1px solid" }}>
                                <td colSpan={1} style={{ border: "1px solid" }}>
                                <b> Telephone:</b>

                                </td>
                                <td style={{ border: "1px solid" }}>
                                
                                </td>
                            </tr>
                            <tr style={{ border: "1px solid" }}>
                                <td style={{ border: "1px solid" }}>
                               <b> Email :</b>

                                </td>
                                <td style={{ border: "1px solid" }}>
                                 
                                </td>
                            </tr>
                          </table>
                          <b>(ii) In case of the Lender 2:
</b>
                          <table style={{ width: "80%", marginLeft: "3%", marginTop: "10px",marginBottom:'10px', fontSize: "20px" }}>
                            <tr style={{ border: "1px solid" }}>
                                <td style={{ border: "1px solid" }}>
                                <b>Address :</b>
                                </td>
                                <td style={{ border: "1px solid" }}>
                                <b>Capital India Finance Limited Level-20, Birla 
Aurora, Dr. Annie Besant Road, Worli, Mumbai-400030</b>
                                </td>
                            </tr>
                            <tr style={{ border: "1px solid" }}>
                                <td colSpan={1} style={{ border: "1px solid" }}>
                                <b> Telephone:</b>

                                </td>
                                <td style={{ border: "1px solid" }}>
                                
                                </td>
                            </tr>
                            <tr style={{ border: "1px solid" }}>
                                <td style={{ border: "1px solid" }}>
                                <b> Email :</b>

                                </td>
                                <td style={{ border: "1px solid" }}>
                                 
                                </td>
                            </tr>
                          </table>
                          <b>(iii) In case of the Hypothecator 1:</b>
                          <table style={{ width: "80%", marginLeft: "3%", marginTop: "10px",marginBottom:'10px', fontSize: "20px" }}>
                            <tr style={{ border: "1px solid" }}>
                                <td  >
                                <b>Address :</b>
                                </td>
                                <td >
                               
                                </td>
                            </tr>
                            <tr style={{ border: "1px solid" }}>
                                <td   >
                                <b> Telephone:</b>

                                </td>
                                <td  >
                                
                                </td>
                            </tr>
                            <tr style={{ border: "1px solid" }}>
                                <td  >
                                <b> Email :</b>

                                </td>
                                <td  >
                                 
                                </td>
                            </tr>
                          </table>
                          <b>(iv) In case of the Hypothecator 2:</b>
                          <table style={{ width: "80%", marginLeft: "3%", marginTop: "10px",marginBottom:'10px', fontSize: "20px" }}>
                            <tr style={{ border: "1px solid" }}>
                                <td  >
                                <b>Address :</b>
                                </td>
                                <td  >
                                 
                                </td>
                            </tr>
                            <tr style={{ border: "1px solid" }}>
                                <td    >
                               <b> Telephone:</b>

                                </td>
                                <td  >
                                
                                </td>
                            </tr>
                            <tr style={{ border: "1px solid" }}>
                                <td  >
                                <b> Email :</b>

                                </td>
                                <td  >
                                 
                                </td>
                            </tr>
                          </table>
                        </td>
                    </tr>
                </table>

                 
            </div>
            <div
                style={{
                    width: "290mm",
                    height: "400mm",
                    backgroundColor: "white",
                    padding: 40,
                    position: "relative",
                    fontSize: "20px"
                }}
            >
            <div style={{height:'250mm'}}>
 <span style={{ marginLeft: "40%" }}><b>SCHEDULE 2</b></span><br />
                <span style={{ marginLeft: "30%" }}><b>(Description of Hypothecated Properties)</b></span><br /><br />
_________________________________________________________________________________________
__________________________________________________________________________________________
__________________________________________________________________________________________
__________________________________________________________________________________________
__________________________________________________________________________________________
__________________________________________________________________________________________
__________________________________________________________________________________________
</div>
 
<div  >
            <b>IN WITNESS WHEREOF</b> the Hypothecators and the lender have caused this Deed to be executed on the day, 
month and year first hereinabove writeen.
<br/>
<b>
SIGNED AND DELIVERED by within named</b>
<br/>
<b>“Hypothecators”.</b>
<br/><br/>
<div style={{display:"flex", justifyContent:"space-between"}}>    
                    <span span style={{width:"300px"}}>{data && data.applicant && data.applicant.general_information && data.applicant.general_information.name}</span><br />
                    <span span style={{width:"200px"}}>________________________</span><br /><br/>
                 </div>   
                    {data && data.co_applicants && data.co_applicants.length > 0 && data.co_applicants.map((e, idx) => {
                        return <div style={{display:"flex", justifyContent:"space-between"}}>
                            <span span style={{width:"300px"}}>{e.general_information.name}</span><br />
                            <span span style={{width:"200px"}}>________________________</span><br /><br/>
                        </div>
                    })}
            </div>

            </div>
            <br/>  <br/>  <br/>
           
        </>
    )
}

export default UnattestedDeedOfHypothecation