import React, { useEffect, useState } from 'react'
import { useReactToPrint } from "react-to-print";
import { loanService } from '../../../_services';
import datehelper from "../../../_helpers/datehelper";
import capitalfinance from "../../../assets/capitalfinance.png";
import logo from "../../../assets/kaabil.png"

const MemorandumMortgage = (props) => {

    const data = props.data
    const branches = props.branches



    const one = ["", "One ", "Two ", "Three ", "Four ", "Five ", "Six ", "Seven ", "Eight ", "Nine ", "Ten ", "Eleven ", "Twelve ", "Thirteen ", "Fourteen ", "Fifteen ",
        "Sixteen ", "Seventeen ", "Eighteen ", "Nineteen "];

    var ten = ["", "", "Twenty ", "Thirty ", "Forty ", "Fifty ", "Sixty ", "Seventy ", "Eighty ", "Ninety "];

    function numToWords(n, s) {
        let str = "";
        // if n is more than 19, divide it
        if (n > 19) {
            str += ten[parseInt(n / 10)] + one[n % 10];
        }
        else {
            str += one[n];
        }
        if (n != 0) {
            str += s;
        }

        return str;
    }

    function convertToWords(n) {
        // stores word representation of given number n
        let out = "";

        // handles digits at ten millions and hundred
        // millions places (if any)
        out += numToWords(parseInt(n / 10000000),
            "Crore ");

        // handles digits at hundred thousands and one
        // millions places (if any)
        out += numToWords(parseInt((n / 100000) % 100),
            "Lakh ");

        // handles digits at thousands and tens thousands
        // places (if any)
        out += numToWords(parseInt((n / 1000) % 100),
            "Thousand ");

        // handles digit at hundreds places (if any)
        out += numToWords(parseInt((n / 100) % 10),
            "Hundred ");

        if (n > 100 && n % 100 > 0) {
            out += "and ";
        }

        // handles digits at ones and tens places (if any)
        out += numToWords(parseInt(n % 100), "");

        return out;
    }

    const headerDiv = () => (
        <div>
          <img src={logo} style={{ height: "100px" }} alt="KFPL Logo" />
          
            <img
              src={capitalfinance}
              style={{
                height: "100px",
                position: "absolute",
                top: "2",
                right: "0",
                marginRight: "3rem",
              }}
              alt="Capital Finance Logo"
            />
          
          
        </div>
      );
    return (
        <>
            <div
                style={{
                    width: "290mm",
                    height: "410mm",
                    backgroundColor: "white",
                    padding: 40,
                    position: "relative",
                    fontSize: "20px"
                }}
            >{headerDiv()}<br/><br/>
                <span style={{ marginLeft: "40%" }}><b>SCHEDULE</b></span> <br />
                <span style={{ marginLeft: "30%" }}><b>MEMORANDUM RECORDING CREATION OF MORTGAGE</b></span> <br /><br />
                <table style={{ width: "90%", marginLeft: "5%", marginTop: "20px", fontSize: "20px" }}>
                    <tr style={{ border: "1px solid" }}>
                        <td colspan="2" style={{ textAlign: "center", border: "1px solid" }}>Details</td>
                    </tr>
                    <tr style={{ border: "1px solid" }}>
                        <td style={{ border: "1px solid", width: "30%" }}>Date Of Execution</td>
                        <td style={{ border: "1px solid" }}><b>{datehelper.displayDate(new Date(data.loan?.agreement_stamp_date))} </b> </td>
                    </tr>
                    <tr style={{ border: "1px solid" }}>
                        <td style={{ border: "1px solid", width: "30%" }}>Place Of Execution</td>
                        <td style={{ border: "1px solid" }}>{branches.branch_city}</td>
                    </tr>
                    <tr style={{ border: "1px solid" }}>
                    <td style={{
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"start"
                        }}>
                            Details of Mortgagor/s:
                        </td>
                        <td style={{border:"1px solid"}}>
                            <tr>Name: _______________________________</tr>
                            <tr>Address: __________________________________________</tr><br /><br />
                            <tr>Name: _______________________________</tr>
                            <tr>Address: __________________________________________</tr><br /><br />
                            <tr>Name: _______________________________</tr>
                            <tr>Address: __________________________________________</tr><br /><br />

                        </td>
                    </tr>
                    <tr style={{ border: "1px solid" }}>
                    <td style={{
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"start"
                        }}>
                            Details of Borrower/s:
                        </td>
                        <td style={{border:"1px solid"}}>
                            <tr>Name: {data && data.applicant && data.applicant.general_information && data.applicant.general_information.name}</tr>
                            <tr>Address: {data && data.applicant && data.applicant.general_information && data.applicant.general_information.address}</tr><br/><br/>
                            {data && data.co_applicants && data.co_applicants.length > 0 && data.co_applicants.map((e, idx) => {
                                return <>
                                    <tr>Name: {e.general_information.name}</tr>
                                    <tr>Address: {e.general_information.address}</tr><br/><br/>
                                </>
                            })}
                        </td>
                    </tr>
                    <tr style={{ border: "1px solid" }}>
                        <td style={{ border: "1px solid" }}>
                            Details of the Loan
                        </td>
                        <td>Rs.{data && data.loan && data.loan.sanction_amount}/- &nbsp;(Rupees {convertToWords(data && data.loan && data.loan.sanction_amount)}only)</td>
                    </tr>

                    <tr style={{ border: "1px solid" }}>
                        <td style={{ border: "1px solid" }}>
                            Loan Agreement
                        </td>
                        <td>Loan agreement dated <b>{datehelper.displayDate(new Date(data.loan?.agreement_stamp_date))} </b> , entered by and between the Borrower/s and the Lenders.</td>
                    </tr>
                </table>
            </div>
            <div
                style={{
                    width: "290mm",
                    height: "410mm",
                    backgroundColor: "white",
                    padding: 40,
                    position: "relative",
                    fontSize: "20px"
                }}
            >{headerDiv()}<br/><br/>
                <table style={{ width: "90%", marginLeft: "5%", marginTop: "20px", fontSize: "20px" }}>
                    <tr style={{ border: "1px solid" }}>
                    <td style={{
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"start"
                        }}>
                            Scheduled Properties
                        </td>
                        <td style={{ border: "1px solid" }}>{data && data.property_details && data.property_details.address ? data.property_details.address : <p>________________________________________________________ <br />________________________________________________________</p>} together with all rights (including but not limited to all development rights, rights
                            to use common areas and facilities etc.), titles, benefits, claims and interests of
                            the Mortgagor/s in relation to the Scheduled Properties and all furniture,
                            fixtures, fittings and things attached thereto.</td>
                    </tr>

                    <tr style={{ border: "1px solid" }}>
                        <td style={{ border: "1px solid" }}>
                            Title Deeds
                        </td>
                        <td>{data && data.property_details && data.property_details.type}</td>
                    </tr>

                    <tr style={{ border: "1px solid" }}>
                        <td style={{ border: "1px solid" }}>
                            Date of deposit of Title Deeds
                        </td>
                        <td>&nbsp;</td>
                    </tr>
                    <tr style={{ border: "1px solid" }}>
                    <td style={{
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"start"
                        }}>
                            Details of Depositor/s
                        </td>
                        <td style={{border:"1px solid"}}>
                            <tr>Name: _______________________________</tr>
                            <tr>Address: __________________________________________</tr><br /><br />
                            <tr>Name: _______________________________</tr>
                            <tr>Address: __________________________________________</tr><br /><br />
                            <tr>Name: _______________________________</tr>
                            <tr>Address: __________________________________________</tr><br /><br />
                        </td>
                    </tr>

                    <tr style={{ border: "1px solid" }}>
                        <td style={{ border: "1px solid" }}>
                            Name of the representative of the Lender
                        </td>
                        <td>&nbsp;</td>
                    </tr>

                    <tr style={{ border: "1px solid" }}>
                        <td style={{ border: "1px solid" }}>
                            Place of deposit of Title Deeds
                        </td>
                        <td>&nbsp;</td>
                    </tr>

                    <tr style={{ border: "1px solid" }}>
                        <td style={{ border: "1px solid" }}>
                            Details of Encumbrances
                        </td>
                        <td>&nbsp;</td>
                    </tr>
                </table><br /><br />
                <span>Executed by the Lender Representative at<br />
                    the Place and on the Date mentioned hereinabove.<br /><br />
                    (Signature of Lender Representative)<br/>
                    Name: Mr. ____________________________________<br/>
                </span><br/>
            </div>
                
                <div
                style={{
                    width: "290mm",
                    height: "410mm",
                    backgroundColor: "white",
                    padding: 40,
                    position: "relative",
                    fontSize: "20px"
                }}
            >{headerDiv()}<br/><br/>
                <span>The Depositor/s have signed these presents pursuant as below:</span><br/><br/>
                   <div style={{display:"flex", justifyContent:"space-between"}}>
                    <span style={{width:"300px"}}>Name of the Borrower/s</span>
                    <span style={{width:"300px"}}>Signature/ Thumb Impression</span>
                </div><br/>
                <div style={{display:"flex", justifyContent:"space-between"}}>    
                    <span span style={{width:"300px"}}>{data && data.applicant && data.applicant.general_information && data.applicant.general_information.name}</span><br />
                    <span span style={{width:"200px"}}>________________________</span><br /><br/>
                 </div>   
                    {data && data.co_applicants && data.co_applicants.length > 0 && data.co_applicants.map((e, idx) => {
                        return <div style={{display:"flex", justifyContent:"space-between"}}>
                            <span span style={{width:"300px"}}>{e.general_information.name}</span><br />
                            <span span style={{width:"200px"}}>________________________</span><br /><br/>
                        </div>
                    })}
                </div>
        </>
    )
}

export default MemorandumMortgage