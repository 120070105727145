import React from "react";
import datehelper from "../../../../_helpers/datehelper";
import roleChecker from "../../../../_helpers/rolechecker";
import { employeeService } from "../../../../_services";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import displayhelper from "../../../../_helpers/displayhelper";
import AddEscrowDetails from "./AddEscrowDetails.jsx";

const EscrowDetailsList = (props) => {
  const customer = props.customer;
  const user = employeeService.userValue;

  // Check if customer data is valid
  if (!customer || !customer.escrow_details || !Array.isArray(customer.escrow_details)) {
    return <div>No escrow details available.</div>;
  }

  return (
    <div>
      <h2 style={{ color: "wheat", backgroundColor: "black", textAlign: "center"  }}>
        Escrow Payment Details
      </h2>
      <table  >
        <thead>
          <tr style={{ border: "1px solid",backgroundColor: "#4B5C6B" ,color: "white" }}>
            <th style={{ border: "1px solid gray" }}> </th>
            <th style={{ border: "1px solid gray" }}>From Bank </th>
            <th style={{ border: "1px solid gray" }}>To Bank </th>
            <th style={{ border: "1px solid gray" }}>Payment Amount</th>
            <th style={{ border: "1px solid gray" }}>Payment Date</th>
            <th style={{ border: "1px solid gray" }}>Automated Payment</th>
            <th style={{ border: "1px solid gray" }}>UTR Number</th>
            <th style={{ border: "1px solid gray" }}>Payment Type</th>
            <th style={{ border: "1px solid gray" }}>Status</th>
            <th style={{ border: "1px solid gray" }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {customer.escrow_details.map((e, idx) => {
            // Validate each escrow detail
            const kaabilToEscrow = e.kaabilToEscrow;
            const escrowToCustomer = e.escrowToCustomer;

            return (
              <React.Fragment key={idx}>
                {/* KaabilToEscrow Row */}
                <tr style={{ border: "1px solid gray",backgroundColor: "#ffe89d" }}>
                  <td style={{ border: "1px solid gray", textAlign: "center" }}>KaabilToEscrow</td>
                  <td style={{ border: "1px solid gray", textAlign: "center" }}>
                    <p>{kaabilToEscrow?.kaabil_bank_name}</p>
                    <p>{kaabilToEscrow?.kaabil_account_number}</p>
                  </td>
                  <td style={{ border: "1px solid gray", textAlign: "center" }}>
                    <p>{kaabilToEscrow?.escrow_bank_name}</p>
                    <p>{kaabilToEscrow?.escrow_bank_account}</p>
                  </td>
                  <td style={{ border: "1px solid gray", textAlign: "center" }}>
                    {kaabilToEscrow?.escrow_amount && displayhelper.displayNumberAccounting(kaabilToEscrow.escrow_amount)}
                  </td>
                  <td style={{ border: "1px solid gray", textAlign: "center" }}>
                    {datehelper.displayDate(new Date(kaabilToEscrow.disbursed_date))}
                  </td>
                  <td style={{ border: "1px solid gray", textAlign: "center" }}>
                    {kaabilToEscrow.automated_payments && kaabilToEscrow.automated_payments.bene_addition && kaabilToEscrow.automated_payments.bene_addition.status !== 'SUCCESS' && (
                      <>Beneficiary Addition - Status: {kaabilToEscrow.automated_payments.bene_addition.status}</>
                    )}
                    {kaabilToEscrow.automated_payments && kaabilToEscrow.automated_payments.payment_transfer && (
                      <>Payment Transfer - Status: {kaabilToEscrow.automated_payments.payment_transfer.status} Request id: {kaabilToEscrow.automated_payments.payment_transfer.request_id}</>
                    )}
                  </td>
                  <td style={{ border: "1px solid gray", textAlign: "center" }}>
                    {kaabilToEscrow.disbursed_utr_number}
                  </td>
                  <td style={{ border: "1px solid gray", textAlign: "center" }}>{kaabilToEscrow.payment_type || 'N/A'}</td>
                  <td style={{ border: "1px solid", textAlign: "center" }}>
                    {kaabilToEscrow.disbursed_utr_number && kaabilToEscrow.disbursed_utr_number.length > 5 ? (
                      <>Paid<VerifiedUserIcon /></>
                    ) : (
                      <span style={{ color: "red" }}>pending<AddEscrowDetails idx={idx} account_data={kaabilToEscrow} customer={customer} type="kaabilToEscrow" isEdit={true} button={"Add UTR"} /></span>
                    )}
                  </td>
                  <td>
                    {!escrowToCustomer && kaabilToEscrow.disbursed_utr_number && kaabilToEscrow.disbursed_utr_number.length > 5 && (
                      <AddEscrowDetails idx={idx} account_data={kaabilToEscrow} customer={customer} type="escrowToCustomer" isEdit={false} button={"Customer Payments"} />
                    )}
                  </td>
                </tr>

                {/* EscrowToCustomer Row */}
                {escrowToCustomer && (
                  <tr style={{ border: "1px solid gray",backgroundColor: "#9793d6" }}>
                    <td style={{ border: "1px solid", textAlign: "center" }}>EscrowToCustomer</td>
                    <td style={{ border: "1px solid gray", textAlign: "center" }}>
                      <p>{kaabilToEscrow?.escrow_bank_name}</p>
                      <p>{kaabilToEscrow?.escrow_bank_account}</p>
                    </td>
                    <td style={{ border: "1px solid gray", textAlign: "center" }}>
                      <p>{escrowToCustomer.disbursed_bank_name}</p>
                      <p>{escrowToCustomer.disbursed_account_number}</p>
                    </td>
                    <td style={{ border: "1px solid gray", textAlign: "center" }}>
                      {escrowToCustomer.disbursed_amount && displayhelper.displayNumberAccounting(escrowToCustomer.disbursed_amount)}
                    </td>
                    <td style={{ border: "1px solid gray", textAlign: "center" }}>
                      {datehelper.displayDate(new Date(escrowToCustomer.disbursed_date))}
                    </td>
                    <td style={{ border: "1px solid gray", textAlign: "center" }}>
                      {escrowToCustomer.automated_payments && escrowToCustomer.automated_payments.bene_addition && escrowToCustomer.automated_payments.bene_addition.status !== 'SUCCESS' && (
                        <>Beneficiary Addition - Status: {escrowToCustomer.automated_payments.bene_addition.status}</>
                      )}
                      {escrowToCustomer.automated_payments && escrowToCustomer.automated_payments.payment_transfer && (
                        <>Payment Transfer - Status: {escrowToCustomer.automated_payments.payment_transfer.status} Request id: {escrowToCustomer.automated_payments.payment_transfer.request_id}</>
                      )}
                    </td>
                    <td style={{ border: "1px solid gray", textAlign: "center" }}>{escrowToCustomer.disbursed_utr_number}</td>
                    <td style={{ border: "1px solid gray", textAlign: "center" }}>{escrowToCustomer.payment_type}</td>
                    <td style={{ border: "1px solid gray", textAlign: "center" }}>
                      {escrowToCustomer.disbursed_utr_number && escrowToCustomer.disbursed_utr_number.length > 5 ? (
                        <>Paid<VerifiedUserIcon /></>
                      ) : (
                        <span style={{ color: "red" }}>pending<AddEscrowDetails idx={idx} account_data={e} customer={customer} type="escrowToCustomer" isEdit={true} button={"Add UTR"} /></span>
                      )}
                    </td>
                    <td></td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default EscrowDetailsList;