import React, { useState, useEffect } from 'react';
// import './style.css';
import { safeCustodyService } from '../../../_services/safeCustodyService'; // Ensure this service has the required methods
import Safenewfile from './safenewfile';
import SafeMyRequest from './safemyrequest';
import swal from 'sweetalert';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import { employeeService, loanService } from "../../../_services";
function SafeDashboard() {
  const [searchLoanNumber, setSearchLoanNumber] = useState('');
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [allRequests, setAllRequests] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedFileNo, setSelectedFileNo] = useState(null);
  const [searchResult, setSearchResult] = useState(null);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filesCount, setFilesCount] = useState(0);
  const [availableFilesCount, setAvailableFilesCount] = useState(0);
  const [response, setResponse] = React.useState({})
  const [mobile_number, setMobile_number] = React.useState("")
  const [data_id, setData_id] = React.useState("")
  const [file_type, setFile_type] = React.useState("")
  const [rqs_by, setReqs_by] = React.useState("")
  const user = JSON.parse(localStorage.getItem('user'));
  const [showDropdown, setShowDropdown] = useState(false);
  const [month, setMonth] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState("All");
  const [selectedType, setSelectedType] = useState("All");
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [year, setYear] = useState('');
    // Options for months and years
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const years = ['17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30'];
  
  // console.log('user', user);
  const empId = user?.emp_id;
  const Role = user?.role;
  // const empName = user?.first_name + ' ' + user?.last_name;

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    height: 200,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    // overflow: 'scroll',
    // paddingBottom: '30px',
  };
  useEffect(() => {
    safeCustodyService
      .getallfiles()
      .then((response) => {
        // console.log("getallfilesdata",response.data)
        if (response && response.data && Array.isArray(response.data)) {
          setFiles(response.data);
          setFilesCount(response.data.length);
        } else {
          setError('Invalid data format received.');
        }
        setLoading(false);
      })
      .catch((error) => {
        setError('Error fetching files.');
        setLoading(false);
      });


      safeCustodyService
      .getAvailableFilesCount()
      .then((response) => {
        // console.log("file counnttttt", response);
        if (response && response.availableFilesCount) {
          setAvailableFilesCount(response.availableFilesCount);
        } else {
          setError('Error fetching available files count.');
        }
      })
      .catch((error) => {
        setError('Error fetching available files count.');
      });
  }, []);

  useEffect(() => {
    safeCustodyService
      .getAllRequests()
      .then((response) => {
        // console.log(response,"response")
        if (response && response.success === true && Array.isArray(response.data)) {
          const flattenedData = response.data.flatMap((item) =>
            item.request_type.map((request) => ({
              file_no: item.file_no,
              date: item.date,
              almirah_no: item.almirah_no,
              requested_by: item.requested_by,
              requested_to: item.requested_to || 'N/A',
              request_date: item.requested_date,
              id: item._id,
              type: request.type,
              status: request.status,
              otp_verified:request.otp_verified,
              otp: request.otp || 'N/A',
              emp_mob_no:request.emp_mob_no,
              emp_id: request.emp_id, // Add emp_id from response for filtering
              loan_date: request.Date, // Add loan_date from response
              sr_no: item.sr_no,
              emp_name:item.emp_name, // Add sr_no from response
              // empName: empName, // Add empName from response 
            }))
          );
          setData(flattenedData);
        } else {
          setError('Invalid data format received.');
        }
        setLoading(false);
      })
      .catch((error) => {
        setError('Error fetching file requests.');
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    // Filter the data based on the role and emp_id
    let filtered = (Role === 'Admin' || [201131, 22058, 20962].includes(user.emp_id)) 
        ? data 
        : data.filter((item) => item.requested_by === empId);

    // Reverse the filtered data to show the latest request first
    setFilteredData(filtered.reverse());
}, [data, empId, Role, user.emp_id]);

  // Toggle dropdown visibility
  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };
  // Get unique options for dropdowns
  const uniqueEmployees = [...new Set(data.map((item) => item.emp_name))];
  const uniqueTypes = [...new Set(data.map((item) => item.type))];
  const uniqueStatuses = [...new Set(data.map((item) => item.status))];


  // Filtered data based on dropdown selections

  // Handle the submit of month and year
  const handleSubmit = async () => {
    if (month && year) {
      // Prepare the payload for sending
      const payload = {
        month,
        year
      };

      try {
        // Call the service to send the payload to the backend
        const response = await safeCustodyService.searchByMonthYear(payload);

        // Check if response contains the first_sr_no, and show it in swal alert
        if (response && response.first_sr_no) {
          swal({
            title: 'Search Results',
            text: `Last Serial Number of ${payload.month}-${payload.year} is: ${response.first_sr_no}`,
            icon: 'success',
            button: 'OK',
          }).then(() => {
            // Reset the form state after the swal button is clicked
            setMonth('');
            setYear('');
            setShowDropdown(false); // Hide the dropdown after successful search
          });
        } else {
          swal({
            title: 'No Record Found',
            text: 'No matching record found for the selected month and year.',
            icon: 'info',
            button: 'OK',
          }).then(() => {
            // Reset the form state after the swal button is clicked
            setMonth('');
            setYear('');
            setShowDropdown(false); // Hide the dropdown after no record is found
          });
        }
      } catch (error) {
        // Show error alert with the error message
        swal({
          title: 'Error',
          text: `Something went wrong: ${error.message || 'Unknown error'}`,
          icon: 'error',
          button: 'OK',
        }).then(() => {
          // Reset the form state after the swal button is clicked
          setMonth('');
          setYear('');
          setShowDropdown(false); // Hide the dropdown after an error occurs
        });
      }
    } else {
      swal({
        title: 'Missing Fields',
        text: 'Please select both month and year!',
        icon: 'warning',
        button: 'OK',
      }).then(() => {
        // Reset the form state after the swal button is clicked
        setMonth('');
        setYear('');
        setShowDropdown(false); // Hide the dropdown if fields are missing
      });
    }
  };
// console.log(data,"ak")
  const handleSearch = () => {
    if (!searchLoanNumber) {
      swal('Input Required', 'Please enter a file number.', 'warning');
      return;
    }

    setLoading(true);
    safeCustodyService
      .searchDataController({ file_no: searchLoanNumber })
      .then((response) => {
        if (response && response.data) {
          const { file_data, latest_request_data } = response.data;

          let status = 'Available';
          if (
            latest_request_data &&
            latest_request_data.request_type.some((request) => request.status === 'Pending')
          ) {
            status = 'No-Available';
          }
          // console.log(file_data,"dfsfsdfsdfd")
          if (file_data) {
            swal({
              title: 'File Found!',
              content: createCustomResultHTML(file_data, latest_request_data, status,file_data.sr_no, file_data.comments),
              icon: 'success',
            });
            setSearchResult(file_data);
          } else {
            swal('Not Found', 'No file found with the provided file number.', 'error');
          }
        } else {
          swal('Error', 'Invalid or empty response from the server.', 'error');
        }
      })
      .catch((error) => {
        swal('Error', 'Error occurred while fetching file data.', 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createCustomResultHTML = (file, latestRequest, status) => {
    const wrapper = document.createElement('div');
    wrapper.style.textAlign = 'left';
    wrapper.innerHTML = `
      <p><strong>Serial Number:</strong> ${file.sr_no}</p>
      <p><strong>File Number:</strong> ${file.file_no}</p>
      <p><strong>Almirah No:</strong> ${file.almirah_no}</p>
      <p><strong>Branch Name:</strong> ${file.branch_name}</p>
      <p><strong>Applicant Name:</strong> ${file.applicant_name}</p>
      <p><strong>Loan Created Date:</strong> ${file.date || 'N/A'}</p>
      <p><strong>Status:</strong> ${status}</p>
      <p><strong>Comment:</strong> ${file.comments || 'N/A'}</p>
      ${latestRequest && latestRequest.request_type.length > 0 ? 
        `<p><strong>Request Types:</strong></p>
        <ul>
          ${latestRequest.request_type.map((request) => 
            `<li>
              <strong>Type:</strong> ${request.type} <br>
              <strong>Status:</strong> ${request.status} <br>
              <strong>Requested Date:</strong> ${request.created_date}
            </li>`
          ).join('')}
        </ul>` : ''}
    `;
    return wrapper;
  };


  // console.log(data,"dddddddddddddd")
  const requestData = {
    _id:data_id,
    request_type:file_type,
    otp: "Success",
    requested_by: rqs_by,
    emp_mob_no: mobile_number,

  }
  // console.log(requestData,"fileee")
  const [verify, setVerify] = React.useState(false);
  const verifyOtp = () => {
    // console.log(value, "ssssssssssss");
    // console.log(value.otp, "ssssssotp");
  
    if (value.otp == response) {
      // console.log(requestData, "requestDatarequestData");
      safeCustodyService.updateOtpAndStatus(requestData)
        .then(() => {
          swal("Otp verified", "The OTP has been successfully verified!", "success")
            .then(() => {
              window.location.reload(); // Reload the page after clicking "OK"
            });
        })
        .catch((error) => {
          console.error("Error updating OTP and status:", error);
          swal("Error", "There was an error verifying the OTP. Please try again.", "error");
        });
    } else {
      swal("Incorrect Otp", "The OTP you entered is incorrect. Please try again.", "error");
    }
  };
  
  const otp = Math.floor(1000 + Math.random() * 9000);

  const sendOtp = () => {
    // console.log(mobile_number, otp, "Sending OTP...");
    loanService.sendOtp({ contact: mobile_number, otp: otp })
      .then((data) => {
        // console.log(data, "OTP response");
        setResponse(data.otp);
      })
      .catch((err) => {
        console.error("Error sending OTP:", err);
      });
    setVerify(true);
    setSent(true);
  };
  const [sent, setSent] = React.useState(false)
  const [open, setOpen] = React.useState(false);
  // const handleOpen = (mobile_numbers) => 
    const handleOpen = (mobile_numbers, id, type, req_by) => {
      setOpen(true);
      // console.log(type, "File type logic check");
    
      // Override mobile number for "File Check-Out"
      // const fixedMobileNumber = "7568696231";
      const isFileCheckOut = type === "File Check-Out";
    
      setMobile_number(isFileCheckOut ? '' : mobile_numbers); // Use fixed number if File Check-Out
      setData_id(id);
      setFile_type(type);
      setReqs_by(req_by);
    
      // Save the fixed number for "File Check-Out" in the database
      if (isFileCheckOut) {
        safeCustodyService.updateRequestWithMobileNumber(id, mobile_numbers)
          .then(() => {
            // console.log("Fixed mobile number saved for File Check-Out");
          })
          .catch((error) => {
            console.error("Error saving fixed mobile number:", error);
          });
      }
    };
// console.log(mobile_number,"mobile_number")
 
  const handleClose = () => setOpen(false);

  const [value, setValue] = React.useState({})

  const handleChange = (e) => {
    value[e.target.name] = e.target.value
    setValue(value)
  }

  const handleMobileNumberChange = (e) => {
    setMobile_number(e.target.value);
  };


  // Add these state variables
const [currentPage, setCurrentPage] = useState(0);
const entriesPerPage = 10;

// Calculate the data to be displayed on the current page
// const offset = currentPage * entriesPerPage;
// const currentPageData = filteredData
//   .filter(item => {
//     const oneDayInMillis = 24 * 60 * 60 * 1000;
//     const requestDate = new Date(item.request_date);
//     const currentDate = new Date();
//     const isOlderThanOneDay = (currentDate - requestDate) > oneDayInMillis;
//     return (item.type === 'File Check-In' || item.type === 'File Check-Out') && !(item.status === 'Complete' && isOlderThanOneDay);
//   })
//   .slice(offset, offset + entriesPerPage);

// Handle page change
const handlePageClick = ({ selected }) => {
  setCurrentPage(selected);
};
  // Filtered data based on dropdown selections
  const filteredRequests = data.filter((item) => {
    const employeeMatch =
      selectedEmployee === "All" || item.emp_name === selectedEmployee;
    const typeMatch = selectedType === "All" || item.type === selectedType;
    const statusMatch = selectedStatus === "All" || item.status === selectedStatus;
    return employeeMatch && typeMatch && statusMatch;
  });
  return (
    <div>
      <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', backgroundColor: '#f9f9f9' }}>
        <h2 style={{ textAlign: 'center' }}>Safe Custody Dashboard</h2>

        <div style={{ display: 'flex', gap: '20px', justifyContent: 'space-between', marginTop: '40px', marginBottom: '10px' }}>

        {(Role === 'Admin' || ([201131,22058,20962].includes( user.emp_id )))&&   
      <>
          <div style={{ flex: 1, padding: '20px', border: '1px solid #4CAF50', borderRadius: '10px', backgroundColor: '#e8f5e9', textAlign: 'center' }}>
          


            <h3>Total Files</h3>
            {loading ? (
                <p>Loading...</p>
              ) : (
                filesCount > 0 && (
                  <h2 style={{ color: 'green' }}>
                    {filesCount}
                  </h2>
                )
              )}

            <Safenewfile />
          </div>
            </>
          }
          <div style={{ flex: 1, padding: '20px', border: '1px solid #4CAF50', borderRadius: '10px', backgroundColor: '#e3f2fd', textAlign: 'center' }}>
          {(Role === 'Admin' || ([201131,22058,20962].includes( user.emp_id )))&&  
      <>
            <h3>Available Files</h3>
            {loading ? (
                <p>Loading...</p>
                ) : (
                  <h2 style={{ color: 'blue' }}>{availableFilesCount}</h2>
              )}
            {/* {loading ? <p>Loading...</p> : <h2>{availableFilesCount}</h2>} */}

            </>
        }
            <SafeMyRequest />
          </div>
          {(Role === 'Admin' || ([201131,22058,20962].includes( user.emp_id )))&&  
      <>
          <div style={{ flex: 1, padding: '20px', border: '1px solid #FF5722', borderRadius: '10px', backgroundColor: '#ffebee', textAlign: 'center' }}>

            <h3>Pending Files</h3>

              {filesCount - availableFilesCount > 0 && (
                <h2 style={{ color: 'red' }}>
                  {filesCount - availableFilesCount}
                </h2>
              )}
            {/* <h2>{filesCount - availableFilesCount}</h2> */}

          </div>
          </>
        }
        </div>
      </div>

      {(Role === 'Admin' || ([201131,22058,20962].includes( user.emp_id )))&&   
      <>


      <div style={{ marginBottom: '50px', textAlign: 'center',color: 'black' }}>
        <label>
          <b>Search Loan Number:</b>
          <input
            type="text"
            value={searchLoanNumber}
            onChange={(e) => setSearchLoanNumber(e.target.value)}
            style={{ marginLeft: '10px', padding: '5px', color: 'black'  }}
          />
        </label>
        <button
          onClick={handleSearch}
          style={{
            marginLeft: '10px',
            padding: '5px 10px',
            backgroundColor: '#4CAF50',
            color: 'black' ,
            // color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          Search
        </button>
      </div>
      </>
        }

    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
    {(Role === 'Admin' || ([201131,22058,20962].includes( user.emp_id )))&&  
      <>
      <button
        onClick={handleDropdownToggle}
        style={{
          backgroundColor: '#007bff',
          color: 'white',
          padding: '10px 20px',
          borderRadius: '5px',
          border: 'none',
          cursor: 'pointer',
          fontSize: '16px',
          transition: 'background-color 0.3s ease',
        }}
        onMouseOver={(e) => e.target.style.backgroundColor = '#0056b3'}
        onMouseOut={(e) => e.target.style.backgroundColor = '#007bff'}
      >
        Select Month and Year
      </button>
      </>
        }
      {showDropdown && (
        <div style={{ marginTop: '20px' }}>
          <select
            onChange={(e) => setMonth(e.target.value)}
            value={month}
            style={{
              padding: '10px',
              margin: '5px',
              borderRadius: '5px',
              fontSize: '16px',
              width: '150px',
              border: '1px solid #ccc',
            }}
          >
            <option value="">Select Month</option>
            {months.map((m, index) => (
              <option key={index} value={m}>{m}</option>
            ))}
          </select>

          <select
            onChange={(e) => setYear(e.target.value)}
            value={year}
            style={{
              padding: '10px',
              margin: '5px',
              borderRadius: '5px',
              fontSize: '16px',
              width: '150px',
              border: '1px solid #ccc',
            }}
          >
            <option value="">Select Year</option>
            {years.map((y, index) => (
              <option key={index} value={y}>{y}</option>
            ))}
          </select>

          <button
            onClick={handleSubmit}
            style={{
              backgroundColor: '#28a745',
              color: 'white',
              padding: '10px 20px',
              borderRadius: '5px',
              border: 'none',
              cursor: 'pointer',
              fontSize: '16px',
              marginLeft: '10px',
              transition: 'background-color 0.3s ease',
            }}
            onMouseOver={(e) => e.target.style.backgroundColor = '#218838'}
            onMouseOut={(e) => e.target.style.backgroundColor = '#28a745'}
          >
            Search
          </button>
        </div>
      )}
    </div>

    <div>
      <h2 style={{ textAlign: "center", marginBottom: "20px", color: "black" }}>
        File Request Details
      </h2>

      {/* Filters Section */}
      <div style={{ marginBottom: "20px", display: "flex", gap: "20px" }}>
        {/* Employee Name Dropdown */}
        <select
          value={selectedEmployee}
          onChange={(e) => setSelectedEmployee(e.target.value)}
          style={{ padding: "10px", borderRadius: "5px" }}
        >
          <option value="All">All Employees</option>
          {uniqueEmployees.map((name, index) => (
            <option key={index} value={name}>
              {name}
            </option>
          ))}
        </select>

        {/* Type Dropdown */}
        <select
          value={selectedType}
          onChange={(e) => setSelectedType(e.target.value)}
          style={{ padding: "10px", borderRadius: "5px" }}
        >
          <option value="All">All Types</option>
          {uniqueTypes.map((type, index) => (
            <option key={index} value={type}>
              {type}
            </option>
          ))}
        </select>

        {/* Status Dropdown */}
        <select
          value={selectedStatus}
          onChange={(e) => setSelectedStatus(e.target.value)}
          style={{ padding: "10px", borderRadius: "5px" }}
        >
          <option value="All">All Statuses</option>
          {uniqueStatuses.map((status, index) => (
            <option key={index} value={status}>
              {status}
            </option>
          ))}
        </select>
      </div>

      {/* Table Section */}
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          backgroundColor: "#fff",
          color: "black",
        }}
      >
        <thead>
          <tr
            style={{
              backgroundColor: "#696969",
              color: "white",
              textAlign: "left",
            }}
          >
            <th style={{ padding: "10px", border: "1px solid #ddd" }}>S no</th>
            <th style={{ padding: "10px", border: "1px solid #ddd" }}>File No</th>
            {(Role === "Admin" || [201131, 22058, 20962].includes(user.emp_id)) && (
              <>
                <th style={{ padding: "10px", border: "1px solid #ddd" }}>
                  Loan Date
                </th>
                <th style={{ padding: "10px", border: "1px solid #ddd" }}>
                  Almirah No
                </th>
                <th style={{ padding: "10px", border: "1px solid #ddd" }}>
                  Serial No
                </th>
              </>
            )}
            <th style={{ padding: "10px", border: "1px solid #ddd" }}>
              Employee Name
            </th>
            <th style={{ padding: "10px", border: "1px solid #ddd" }}>
              Request By
            </th>
            <th style={{ padding: "10px", border: "1px solid #ddd" }}>
              Request Date
            </th>
            <th style={{ padding: "10px", border: "1px solid #ddd" }}>Type</th>
            <th style={{ padding: "10px", border: "1px solid #ddd" }}>Status</th>
            <th style={{ padding: "10px", border: "1px solid #ddd" }}>Otp</th>
          </tr>
        </thead>
        <tbody>
          {filteredRequests.map((item, index) => (
            <tr key={index} style={{ borderBottom: "1px solid #ddd" }}>
              <td
                style={{
                  padding: "10px",
                  border: "1px solid #ddd",
                  backgroundColor: "#FAF9F6",
                  color: "black",
                }}
              >
                {index + 1}
              </td>
              <td
                style={{
                  padding: "10px",
                  border: "1px solid #ddd",
                  backgroundColor: "#FAF9F6",
                  color: "black",
                }}
              >
                {item.file_no}
              </td>
              {(Role === "Admin" ||
                [201131, 22058, 20962].includes(user.emp_id)) && (
                <>
                  <td
                    style={{
                      padding: "10px",
                      border: "1px solid #ddd",
                      backgroundColor: "#FAF9F6",
                      color: "black",
                    }}
                  >
                    {item.date}
                  </td>
                  <td
                    style={{
                      padding: "10px",
                      border: "1px solid #ddd",
                      backgroundColor: "#FAF9F6",
                      color: "black",
                    }}
                  >
                    {item.almirah_no}
                  </td>
                  <td
                    style={{
                      padding: "10px",
                      border: "1px solid #ddd",
                      backgroundColor: "#FAF9F6",
                      color: "black",
                    }}
                  >
                    {item.sr_no}
                  </td>
                </>
              )}
              <td
                style={{
                  padding: "10px",
                  border: "1px solid #ddd",
                  backgroundColor: "#FAF9F6",
                  color: "black",
                }}
              >
                {item.emp_name}
              </td>
              <td
                style={{
                  padding: "10px",
                  border: "1px solid #ddd",
                  backgroundColor: "#FAF9F6",
                  color: "black",
                }}
              >
                {item.requested_by}
              </td>
              <td
                style={{
                  padding: "10px",
                  border: "1px solid #ddd",
                  backgroundColor: "#FAF9F6",
                  color: "black",
                }}
              >
                {new Date(item.request_date).toLocaleString()}
              </td>
              <td
                style={{
                  padding: "10px",
                  border: "1px solid #ddd",
                  backgroundColor:
                    item.type === "File Check-In"
                      ? "#f2d9ff"
                      : item.type === "File Check-Out"
                      ? "#ffd9f2"
                      : "",
                }}
              >
                {item.type}
              </td>
              <td
                style={{
                  padding: "10px",
                  border: "1px solid #ddd",
                  backgroundColor:
                    item.status === "Complete"
                      ? "#80e27e"
                      : item.status === "Pending"
                      ? "#ffcc66"
                      : "",
                }}
              >
                {item.status}
              </td>
              <td
                style={{
                  padding: "10px",
                  border: "1px solid #ddd",
                  backgroundColor: "#FAF9F6",
                }}
              >
                {item.otp_verified ? (
                  "Verified"
                ) : (
                  <button
                    onClick={() =>
                      handleOpen(item.emp_mob_no, item.id, item.type, item.requested_by)
                    }
                    style={{
                      padding: "5px 10px",
                      backgroundColor: "#FF5722",
                      color: "white",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                  >
                    verify otp
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Count Section */}
      <div style={{ marginTop: "20px", textAlign: "center" }}>
        <h4>Total Records: {filteredRequests.length}</h4>
      </div>
    </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {/* <input type='number' disabled value={mobile_number} />
            {sent === true ? <button className="btn btn-primary" style={{ marginLeft: "10px" }}>Resend Otp</button> : <button className="btn btn-primary" style={{ marginLeft: "10px", }} onClick={sendOtp} >Send Otp</button>}<br /><br />
            {verify === true ? <div><input type='number' name='otp' onChange={handleChange} placeholder="Enter the Otp" /> <button className="btn btn-primary" onClick={verifyOtp} style={{ marginLeft: "10px", }}>Verify Otp</button></div> : null} */}
          <div className="otp-container">
            <div style={{display:"flex",justifyContent:"space-between"}}>
            <div>
            <input
        type="number"
        minLength={10}
        disabled={file_type === 'File Check-In'}
        value={mobile_number}
        onChange={handleMobileNumberChange}
        className="otp-input"
      />
            </div>
         
<div 

>
  {sent === true ? (
    <button 
      className="btn btn-primary resend-button" 
      style={{
        backgroundColor: "#007BFF", 
        border: "none", 
        color: "#fff", 
        // padding: "10px 20px", 
        fontSize: "16px", 
        borderRadius: "5px", 
        cursor: "pointer", 
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)"
      }}
    >
      Resend Otp
    </button>
  ) : (
   
    <button 
      className="btn btn-primary send-button" 
      onClick={sendOtp} 
      style={{
        backgroundColor: "#28A745", 
        border: "none", 
        color: "#fff", 
        padding: "10px 20px", 
        fontSize: "16px", 
        borderRadius: "5px", 
        cursor: "pointer", 
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)"
      }}
    >
      Send Otp
    </button>
  )}
</div>
</div>
<br />
{verify === true && (
  <div 
    className="otp-verification" 
    style={{display:"flex",justifyContent:"space-between"}}
  >
    
    <input 
      type="number" 
      name="otp" 
      onChange={handleChange} 
      minLength={4}
      placeholder="Enter the Otp" 
      className="otp-input" 
      style={{
        width: "250px", 
        padding: "10px", 
        fontSize: "16px", 
        border: "1px solid #ced4da", 
        borderRadius: "5px", 
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
      }}
    />
    <button 
      className="btn btn-primary verify-button" 
      onClick={verifyOtp} 
      style={{
        backgroundColor: "#17A2B8", 
        border: "none", 
        color: "#fff", 
        padding: "10px 20px", 
        fontSize: "16px", 
        borderRadius: "5px", 
        cursor: "pointer", 
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)"
      }}
    >
      Verify Otp
    </button>
  </div>
)}

</div>

          </Typography>
        </Box>
      </Modal>
      
    </div>
  );
}

export default SafeDashboard;
