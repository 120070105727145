import React, { useState, useEffect } from "react";
import { loanService,employeeService } from "../../_services";
import "chart.js/auto";
import { Chart } from "react-google-charts";
import { Modal } from "@material-ui/core";
import { useLocation } from 'react-router-dom';
import datehelper from "../../_helpers/datehelper";
import {Typography, Box,Card, CardContent} from "@material-ui/core";
const Dashboard = () => {
  const [filters, setFilters] = useState({ date: "month" });
  const [agentloanDetails, setAgentLoanDetails] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showAllCases, setShowAllCases] = useState(false);
  const [showDayCallingList, setShowDayCallingList] = React.useState(false);
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCustomerType, setSelectedCustomerType] = useState("Applicant");
  const [enteredMobileNumber, setEnteredMobileNumber] = useState("");
  const [empId, setEmpId] = useState("");
  const [applicationNumber, setApplicationNumber] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [comment, setComment] = useState('');
  const [nextfollowupdate, setNextFollowUpDate] = useState('');
  const [callStatus, setCallStatus] = useState('');
  const [commentOption, setCommentOption] = useState('');
  const [customComment, setCustomComment] = useState('');
  const [isCommentVisible, setIsCommentVisible] = useState(false);
  const [isCommentSaved, setIsCommentSaved] = useState(false);
  const [callId, setCallId] = useState(""); // New state variable for _id
  const [callMode, setcallMode] = useState(""); // New state variable for _id
  const [callinguserAnalyticsData, setCallingUserAnalyticsData] = useState([]);
  const [userAnalytics, setUserAnalytics] = useState(null);
  const [WeeklyUserCallingAnalyticsData, setWeeklyUserCallingAnalyticsData] = useState([]);
  const [chartUserData, setUserChartData] = useState([
    ["Day", "Calls Made", "Successful Calls", "Call Duration"],
  ]);
  const [isRecodingModalOpen, setIsRecodingModalOpen] = useState(false);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [recordings, setRecordings] = useState([]);
  const [comments, setallComments] = useState([]);
  const [selectedLoan, setSelectedLoan] = useState(null);

  // Function to handle the Play Recording button click
  const handlePlayRecordingClick = (loan) => {
  if (loan?.requestData?.length > 0) {
    // Extract all recording URLs for the selected loan
    const allRecordings = loan.requestData
      .map((item) => item.response_data?.recording_url)
      .filter(Boolean); // Remove undefined/null values

    setRecordings(allRecordings); // Set recordings for the modal
    setSelectedLoan(loan); // Track the currently selected loan
    setIsRecodingModalOpen(true); // Open the modal
      }
    };
  const handleCommentClick = (loan) => {
  if (loan?.feedbackData?.length > 0) {
    // Extract all recording URLs for the selected loan
    const allComments = loan.feedbackData
      .map((item) => item.feedbackData?.remarks)
      .filter(Boolean); // Remove undefined/null values

    setallComments(allComments); // Set recordings for the modal
    setSelectedLoan(loan); // Track the currently selected loan
    setIsCommentModalOpen(true); // Open the modal
      }
    };

    // Function to close the modal
    const closeRecodingModal = () => {
      setIsRecodingModalOpen(false);
      setRecordings([]);
      setSelectedLoan(null);
    };
    const closeCommentModal = () => {
      setIsCommentModalOpen(false);
      setallComments([]);
      setSelectedLoan(null);
    };
  console.log('location', location);
  // Access rowData from location.state
  const rowData = location.state?.agent;
  let employeeId = rowData?.emp_id;
  const first_name = rowData?.first_name;
  const middle_name = rowData?.middle_name;
  const last_name = rowData?.last_name;
  const full_name = `${first_name} ${middle_name} ${last_name}`.trim();
  const user=employeeService.userValue
  if(!employeeId) {
    employeeId=user.emp_id  }
  
  useEffect(() => {
    if (employeeId) {
      console.log('employeeId', employeeId);
      // Using async function for data fetching
      const fetchData = async () => {
        try {
          // Fetch agent assigned loans only if not fetched already
          const response = await loanService.getAgentAssignedLoans({ employeeId });
          const agentsData = Array.isArray(response) ? response : [];
          setAgentLoanDetails(agentsData);

          // Fetch calling user analytics data
          const analyticsResponse = await loanService.getCallingUserAnalyticsData({ employeeId });
          const analyticsData = Array.isArray(analyticsResponse) ? analyticsResponse : [];
          setCallingUserAnalyticsData(analyticsData);

        } catch (error) {
          alert('An error occurred while fetching the data.');
        }
      };
      const getWeeklyCallingUserAnalyticsData = async () => {
        try {
          const analyticsResponse = await loanService.getWeeklyCallingUserAnalyticsData({employeeId});
          const analyticsData = Array.isArray(analyticsResponse) ? analyticsResponse : [];
          setWeeklyUserCallingAnalyticsData(analyticsData);
        } catch (error) {
          alert('An error occurred while fetching the data.');
        }
      };
      getWeeklyCallingUserAnalyticsData();
      fetchData(); // Calling the fetch function

    }
  }, [employeeId]); 
  useEffect(() => {
    if (
      WeeklyUserCallingAnalyticsData.length > 0 &&
      WeeklyUserCallingAnalyticsData[0].display_data &&
      Array.isArray(WeeklyUserCallingAnalyticsData[0].display_data)
    ) {
      const data = WeeklyUserCallingAnalyticsData[0].display_data.map(item => [
        item.date || "N/A", // Default to "N/A" if date is missing
        item.total_make_call || 0, // Calls Made
        item.total_success_call || 0, // Successful Calls
        convertDurationToSeconds(item.total_call_duration || "0H:0M:0Sec"), // Call Duration in seconds
      ]);
      // Update the chart data
      setUserChartData(prevData => [...prevData.slice(0, 1), ...data]); // Keep headers, append new data
    }
  }, [WeeklyUserCallingAnalyticsData]);
  const convertDurationToSeconds = (duration) => {
    const parts = duration.match(/(\d+)H|(\d+)M|(\d+)Sec/g) || [];
    const [hours = 0, minutes = 0, seconds = 0] = parts.map(part =>
      parseInt(part.replace(/[^\d]/g, ""), 10) || 0
    );
    return hours+":"+ minutes +":"+ seconds;
  };
  const openModal = (loan) => {
    setApplicationNumber(loan.loan_number); // Example to pass loan application number
    setEmpId(loan.emp_id); // Example to set employee ID from loan details
    setCustomerName(loan.app_name); // Example to set employee ID from loan details
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const toggleShowAllCases = () => {
    setShowAllCases((prev) => !prev);
  };
  const toggleDayCallingList = () => setShowDayCallingList((prev) => !prev);
  const downloadExcel = () => {
    const tableHeaders = ["Date","Loan ID", "Agent Name Name", "call Status", "Follow Up Date","Comment"];
    const tableData = agentloanDetails?.map((loan) => [datehelper.displayDate(loan?.requestData[loan.requestData?.length-1]?.date),loan?.loan_number,full_name, loan?.requestData[loan.requestData?.length-1]?.status, loan?.feedbackData[loan.feedbackData?.length-1]?.next_follow_up_date,loan?.feedbackData[loan.feedbackData?.length-1]?.remark]);
    const csvContent =
      "data:text/csv;charset=utf-8," +
      [tableHeaders.join(","), ...tableData.map((row) => row.join(","))].join("\n");
  
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "day_calling_list.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleNext = () => {
    if (currentIndex < agentloanDetails.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleCustomerTypeChange = (e) => setSelectedCustomerType(e.target.value);
  const handleMobileNumberChange = (e) => setEnteredMobileNumber(e.target.value);
  const handleCommentChange = (e) => setComment(e.target.value);
  const handleFollowUpDatChange = (e) => setNextFollowUpDate(e.target.value);
  const handleCallStatusChange = (e) => setCallStatus(e.target.value);
  // Handler for selecting a comment option
  const handleCommentOptionChange = (e) => {
    setCommentOption(e.target.value);
    // Clear custom comment if a predefined option is selected
    if (e.target.value !== 'Other') {
      setCustomComment('');
    }
  };

  // Handler for custom comment input
  const handleCustomCommentChange = (e) => {
    setCustomComment(e.target.value);
  };
  console.log('agentloanDetails', agentloanDetails);
  // const handleStartCall = async () => {
  //   const data = {
  //     emp_id: empId,
  //     application_number: applicationNumber,
  //     mobile_number: enteredMobileNumber,
  //     customer_type: selectedCustomerType,
  //   };
  
  //   try {
  //     const response = await loanService.startClickTocallVoiceAPI(data);
  //     if (response && response.status === "success") {
  //       console.log("API response:", response);
  //       alert(response.message);
  //       closeModal();
  //     }else{
  //       alert(response.message);
  //     }
  //   } catch (error) {
  //     console.error("Error starting call:", error);
  //     alert("An error occurred while making the call.");
  //   }
  // };
  const handleStartCall = async () => {
    const data = {
      emp_id: empId,
      application_number: applicationNumber,
      mobile_number: enteredMobileNumber,
      customer_type: selectedCustomerType,
    };

    try {
      const response = await loanService.startClickTocallVoiceAPI(data);
    if (response && response.status === "success") {
          console.log("API response:", response);
          alert(response.message);
          setCallId(response._id); // Save the _id from the response
          setIsCommentVisible(true);
          setcallMode(response.mode)
        } else {
        alert(response.message);
      }
    } catch (error) {
      console.error("Error starting call:", error);
      alert("An error occurred while making the call.");
    }
  };
  const handleSaveComment = async () => {
    try {
        const data = {
          emp_id: empId, // Use the correct state variable name
          call_status: callStatus,
          next_follow_up_date: nextfollowupdate,
          remarks: comment,
          loan_number: applicationNumber,
          customer_name: customerName,
          ref_call_id: callId, // Include the callId
          mode:callMode,
        };
      console.log('data', data);
      const response = await loanService.createCustomerCareFeedbackForm(data);
      if (response) {
        console.log("Comment saved:", response);
        alert("Comment saved successfully.");
        setIsCommentSaved(true);
        closeModal();
      } else {
        alert(response.message);
      }
    } catch (error) {
      console.error("Error saving comment:", error);
      alert("An error occurred while saving the comment.");
    }
  };

  useEffect(() => {
    // Extract and parse display_data if available
    if (callinguserAnalyticsData && callinguserAnalyticsData.length > 0) {
      const data = callinguserAnalyticsData[0];  // Assuming we have at least one item
      const parsedDisplayData = JSON.parse(data.display_data);

      // Set the parsed data into state
      setUserAnalytics(parsedDisplayData);
    }
  }, [callinguserAnalyticsData])

  
  console.log('rowData', rowData);
  console.log('WeeklyUserCallingAnalyticsData', WeeklyUserCallingAnalyticsData);
  console.log('chartUserData', chartUserData);
  // const cardData = {
  //   totalCalls: 1500,
  //   successCalls: 1200,
  //   rejectedCalls: 300,
  //   totalTime: 4500, // in minutes
  //   avgCallDuration: 3, // in minutes
  // };

  // Mock loan data
  // const loanData = [
  //   {
  //     loanNumber: "LN001",
  //     dueEmiCount: 3,
  //     dueEmiAmount: 5000,
  //     bucketName: "30 Days Bucket",
  //     officerName: "John Doe",
  //     mobileNumber: "9876543210",
  //   },
  //   {
  //     loanNumber: "LN002",
  //     dueEmiCount: 2,
  //     dueEmiAmount: 3000,
  //     bucketName: "60 Days Bucket",
  //     officerName: "Jane Smith",
  //     mobileNumber: "8765432109",
  //   },
  // ];

  // const handleCallNow = (mobileNumber) => {
  //   console.log("Calling API with number:", mobileNumber);
  //   // Make API call here
  // };

  const data = {
    labels: ["Day 1", "Day 2", "Day 3", "Day 4", "Day 5"],
    datasets: [
      {
        label: "Calls",
        data: [100, 120, 150, 130, 160],
        borderColor: "#4CAF50",
        borderWidth: 2,
        fill: false,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
    },
  };

  const styles = {
    appContainer: {
      fontFamily: "Arial, sans-serif",
      margin: "20px",
      color: "#333",
    },
    cardContainer: {
      padding:"10px",
      display: "flex",
      gap: "20px",
      marginBottom: "20px",
    },
    card: {
      background: "#4caf50",
      color: "white",
      borderRadius: "8px",
      padding: "15px",
      textAlign: "center",
      flex: 1,
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    },
    chartContainer: {
      margin: "20px auto",
      padding: "10px",
      borderRadius: "8px",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
      maxWidth: "50%",
      backgroundColor: "#fff",
    },
    chartTitle: {
      fontSize: "18px",
      marginBottom: "10px",
      textAlign: "center",
      color: "#333",
    },
    lineChart: {
      padding: "10px",
      height: "300px", // Reduced height
    },
    filterButtons: {
      display: "flex",
      justifyContent: "center",
      gap: "10px",
      marginBottom: "10px",
    },
    filterButton: {
      padding: "5px 15px",
      fontSize: "14px",
      border: "1px solid #ccc",
      borderRadius: "5px",
      cursor: "pointer",
      backgroundColor: "#f9f9f9",
      transition: "background-color 0.2s ease",
    },
    filterButtonActive: {
      backgroundColor: "#007bff",
      color: "#fff",
      borderColor: "#007bff",
    },
    chartArea: {
      height: "calc(100% - 10px)", // Ensure chart fits within container
    
    },
    tableContainer: {
      marginTop: "20px",
    },
    loanTable: {
      width: "100%",
      borderCollapse: "collapse",
      marginTop: "20px",
    },
    loanTableTh: {
      background: "#4caf50",
      color: "white",
      padding: "8px",
      border: "1px solid #ddd",
      textAlign: "left",
    },
    loanTableTd: {
      padding: "8px",
      border: "1px solid #ddd",
      textAlign: "left",
    },
    callNowBtn: {
      background: "#ff5722",
      color: "white",
      border: "none",
      padding: "8px 12px",
      borderRadius: "4px",
      cursor: "pointer",
    },
    callNowBtnHover: {
      background: "#e64a19",
    },
    loanTableTd: {
      padding: "10px",
    },
    modalOverlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
    },
    modalContent: {
      backgroundColor: "#fff",
      padding: "20px",
      borderRadius: "8px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      width: "80%",
      maxWidth: "600px",
    },
    modalHeader: {
      fontSize: "18px",
      fontWeight: "bold",
      marginBottom: "15px",
    },
    table: {
      width: "100%",
      borderCollapse: "collapse",
      marginTop: "10px",
    },
    tableHeader: {
      textAlign: "left",
      padding: "8px",
      backgroundColor: "#f2f2f2",
      borderBottom: "1px solid #ddd",
    },
    tableCell: {
      padding: "8px",
      borderBottom: "1px solid #ddd",
    },
    
  };

  const modalStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.6)', // Slightly darker background
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000, // Ensures the modal stays on top
  };
  
  const modalContentStyle = {
    backgroundColor: '#fff',
    padding: '30px',
    borderRadius: '12px', // Smooth corners
    maxWidth: '600px', // Limit width for readability
    width: '100%',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
    animation: 'fadeIn 0.3s ease-out', // Animation for smooth modal appearance
  };
  
  const buttonStyle = {
    backgroundColor: '#4CAF50', // Green color for "Start Call"
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    fontSize: '16px',
    borderRadius: '5px',
    cursor: 'pointer',
    margin: '10px 0', // Add margin for spacing between buttons
    transition: 'background-color 0.3s',
  };
  
  const buttonHoverStyle = {
    backgroundColor: '#45a049', // Darker green for hover effect
  };
  
  const closeButtonStyle = {
    backgroundColor: '#f44336', // Red color for "Close" button
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    fontSize: '16px',
    borderRadius: '5px',
    cursor: 'pointer',
    margin: '10px 0',
    transition: 'background-color 0.3s',
  };
  
  const closeButtonHoverStyle = {
    backgroundColor: '#e53935', // Darker red for hover effect
  };
  
  const inputStyle = {
    width: '100%',
    padding: '10px',
    margin: '10px 0',
    border: '1px solid #ddd',
    borderRadius: '5px',
    fontSize: '16px',
  };
  
  const selectStyle = {
    width: '100%',
    padding: '10px',
    margin: '10px 0',
    border: '1px solid #ddd',
    borderRadius: '5px',
    fontSize: '16px',
  };
  
  
  return (
    <div style={styles.appContainer}>
      <h1 style={{ textAlign: 'center' }}>Calling Team Dashboard</h1>
  
      {/* Top Cards */}
      <div>

      <Box display="flex" justifyContent="space-around" mt={4}>
            {userAnalytics ? [
              { label: "Total Calls", value: userAnalytics.total_assign_cases },
              { label: "Total Made Calls", value: userAnalytics.total_make_calls },
              { label: "Total Success Call", value: userAnalytics.total_success_calls },
              { label: "Total Rejected Call", value: userAnalytics.total_reject_call },
              { label: "Total Time", value: userAnalytics.total_calls_duration },
              { label: "Avg Call Duration", value: userAnalytics.avg_call_duration },
            ].map((card, index) => (
              <Card key={index} style={{ minWidth: "15%", margin: "0 10px", backgroundColor: "#e3f2fd" }}>
                <CardContent>
                  <Typography variant="h6">{card.label}</Typography>
                  <Typography variant="h5" fontWeight="bold">{card.value}</Typography>
                </CardContent>
              </Card>
            )) : (
              <Typography variant="h6" color="textSecondary">Loading...</Typography>
            )}
          </Box>
        {/* {Object.entries(cardData).map(([key, value], index) => (
          <div style={styles.card} key={index}>
            <h3>
              {key
                .replace(/([A-Z])/g, " $1")
                .replace(/^./, (str) => str.toUpperCase())}
            </h3>
            <p>
              {key === "totalTime" || key === "avgCallDuration"
                ? `${value} mins`
                : value}
            </p>
          </div>
        ))} */}
      </div>
  
      {/* Loan Table */}
      {/* <div style={styles.tableContainer}>
        <h2>Assigned Loans</h2>
        <table style={styles.loanTable}>
          <thead>
            <tr>
              <th style={styles.loanTableTh}>Loan Number</th>
              <th style={styles.loanTableTh}>Branch</th>
              <th style={styles.loanTableTh}>Branch Manager</th>
              <th style={styles.loanTableTh}>Due EMI Amount</th>
              <th style={styles.loanTableTh}>EMI Amount</th>
              <th style={styles.loanTableTh}>Collection Officer Name</th>
              <th style={styles.loanTableTh}>Current Month Calling Count</th>
              <th style={styles.loanTableTh}>Today's Calling Count</th>
              <th style={styles.loanTableTh}>Calling Time</th>
              <th style={styles.loanTableTh}>Action</th>
              <th style={styles.loanTableTh}>Recording</th>
            </tr>
          </thead>
          <tbody>
            {agentloanDetails.map((loan, index) => (
              <tr key={index}>
                <td style={styles.loanTableTd}>{loan.loan_number}</td>
                <td style={styles.loanTableTd}>{loan.branch}</td>
                <td style={styles.loanTableTd}>{loan.bm}</td>
                <td style={styles.loanTableTd}>{loan.arrear_amount}</td>
                <td style={styles.loanTableTd}>{loan.emp_id}</td>
                <td style={styles.loanTableTd}>{loan.key_person}</td>
                <td style={styles.loanTableTd}>{loan.month_call_count}</td>
                <td style={styles.loanTableTd}>{loan.day_call_count}</td>
                <td style={styles.loanTableTd}>{loan.officerName}</td>
                <td style={styles.loanTableTd}>
                  <button
                    style={styles.callNowBtn}
                    onClick={() => openModal(loan)} // Pass the loan object here
                  >
                    Call Now
                  </button>
                </td>
                <td style={styles.loanTableTd}>{loan.officerName}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div> */}
      <div style={styles.tableContainer}>
      <button style={{ ...styles.callNowBtn, marginRight: "10px" }} onClick={toggleShowAllCases}>
        {showAllCases ? "Hide All Cases" : "Show All Cases"}
      </button>
      <button style={{...styles.callNowBtn,backgroundColor:'Green'}} onClick={toggleDayCallingList}>
        {showDayCallingList ? "Hide Day Calling List" : "View Day Calling List"}
      </button>
      {showDayCallingList && (
        <div style={{ marginTop: "20px" }}>
          <table style={styles.table}>
            <thead>
              <tr>
                <th style={styles.tableHeader}>Date</th>
                <th style={styles.tableHeader}>Loan ID</th>
                <th style={styles.tableHeader}>Agent Name</th>
                <th style={styles.tableHeader}>Call Status</th>
                <th style={styles.tableHeader}>Follow UP Date</th>
                <th style={styles.tableHeader}>Comment</th>
              </tr>
            </thead>
            <tbody>
              {/* {agentloanDetails.map((loan, index) => (
                <tr key={index}>
                <td style={styles.tableCell}>{datehelper.displayDate(new Date(loan?.requestData[loan.requestData?.length - 1]?.date))}</td>
                <td style={styles.tableCell}>{loan.loan_number}</td>
                <td style={styles.tableCell}>{full_name}</td>
                <td style={styles.tableCell}>{loan.requestData[loan.requestData?.length - 1]?.status}</td>
                <td style={styles.tableCell}>{loan.feedbackData[loan.feedbackData?.length - 1]?.next_follow_up_date}</td>
                <td style={styles.tableCell}>{loan.feedbackData[loan.feedbackData?.length - 1]?.remarks}</td>
              </tr>
              ))} */}
              {agentloanDetails
                    .filter(
                      (loan) =>
                        loan?.requestData?.length > 0 &&
                        loan.requestData[loan.requestData.length - 1]?.date
                    ) // Filter rows with a valid date
                    .map((loan, index) => (
                      <tr key={index}>
                        <td style={styles.tableCell}>
                          {datehelper.displayDate(
                            new Date(loan.requestData[loan.requestData.length - 1].date)
                          )}
                        </td> {/* Display date in readable format */}
                        <td style={styles.tableCell}>{loan.loan_number}</td>
                        <td style={styles.tableCell}>{full_name}</td>
                        <td style={styles.tableCell}>
                          {loan.requestData[loan.requestData.length - 1]?.status}
                        </td>
                        <td style={styles.tableCell}>
                          {loan.feedbackData[loan.feedbackData.length - 1]?.next_follow_up_date}
                        </td>
                        <td style={styles.tableCell}>
                          {loan.feedbackData[loan.feedbackData.length - 1]?.remarks}
                        </td>
                      </tr>
                    ))}

            </tbody>
          </table>
          <button
            style={{
              ...styles.callNowBtn,
              marginTop: "10px",
              backgroundColor: "#28a745",
            }}
            onClick={downloadExcel}
          >
            Download Excel
          </button>
        </div>
      )}

      <h2>Assigned Loans</h2>
      {showAllCases ? (
        <table style={styles.loanTable}>
          <thead>
            <tr>
            <th style={styles.loanTableTh}>Loan Number</th>
                  <th style={styles.loanTableTh}>Customer Name</th>
                  <th style={styles.loanTableTh}>Branch</th>
                  <th style={styles.loanTableTh}>Branch Manager</th>
                  <th style={styles.loanTableTh}>Due EMI Amount</th>
                  <th style={styles.loanTableTh}>EMI Amount</th>
                  <th style={styles.loanTableTh}>Current Month Calling Count</th>
                  <th style={styles.loanTableTh}>Today's Calling Count</th>
                  <th style={styles.loanTableTh}>FollowUp Date</th>
                  <th style={styles.loanTableTh}>Action</th>
                  <th style={styles.loanTableTh}>Comments</th>
                  <th style={styles.loanTableTh}>Recording</th>
            </tr>
          </thead>
          <tbody>
            {agentloanDetails.map((loan, index) => (
              <tr key={index}>
                <td style={styles.loanTableTd}>{loan.loan_number}</td>
                <td style={styles.loanTableTd}>{loan.app_name}</td>
                <td style={styles.loanTableTd}>{loan.branch}</td>
                <td style={styles.loanTableTd}>{loan.bm}</td>
                <td style={styles.loanTableTd}>{loan.arrear_amount}</td>
                <td style={styles.loanTableTd}>{loan.emi_amount}</td>
                <td style={styles.loanTableTd}>{loan.month_call_count}</td>
                <td style={styles.loanTableTd}>{loan.day_call_count}</td>
                <td style={styles.loanTableTd}>{loan.date}</td>
                <td style={styles.loanTableTd}>
                <button style={styles.callNowBtn} onClick={() => openModal(loan)}>
                    Call Now
                  </button>
                </td>
                <td style={styles.loanTableTd}>{loan.feedbackData[loan.feedbackData.length - 1]?.remarks}</td>
                <td style={styles.loanTableTd}>
                    {loan?.requestData?.length > 0 && loan.requestData[0]?.response_data?.recording_url ? (
                      <button
                        style={{
                          padding: "8px 12px",
                          backgroundColor: "#007bff",
                          color: "#fff",
                          border: "none",
                          borderRadius: "4px",
                          cursor: "pointer",
                        }}
                        onClick={() => handlePlayRecordingClick(loan)}
                      >
                        Play Recordings
                      </button>
                    ) : (
                      "No recording"
                    )}

                    {isRecodingModalOpen && selectedLoan === loan && (
                      <div style={styles.modalOverlay}>
                        <div style={styles.modalContent}>
                          <div style={styles.modalHeader}>Recordings</div>
                          {loan?.requestData?.length > 0 ? (
                            <table style={styles.table}>
                              <thead>
                                <tr>
                                  <th style={styles.tableHeader}>Date</th>
                                  <th style={styles.tableHeader}>Recording</th>
                                </tr>
                              </thead>
                              <tbody>
                                {loan.requestData.map((recording, index) => (
                                  <tr key={index}>
                                    <td style={styles.tableCell}>
                                      {loan?.date || "Unknown Date"}
                                    </td>
                                    <td style={styles.tableCell}>
                                      <button
                                        style={{
                                          padding: "8px 12px",
                                          backgroundColor: "#007bff",
                                          color: "#fff",
                                          border: "none",
                                          borderRadius: "4px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => window.open(recording.response_data?.recording_url, "_blank")}
                                      >
                                        Play Recording {index + 1}
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <div>No recordings available</div>
                          )}
                          <button
                            style={{
                              padding: "8px 12px",
                              backgroundColor: "#007bff",
                              color: "#fff",
                              border: "none",
                              borderRadius: "4px",
                              cursor: "pointer",
                              marginTop: "20px",
                            }}
                            onClick={closeRecodingModal}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    )}
                </td>


              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        agentloanDetails.length > 0 && (
          <>
            <table style={styles.loanTable}>
              <thead>
                <tr>
                  <th style={styles.loanTableTh}>Loan Number</th>
                  <th style={styles.loanTableTh}>Customer Name</th>
                  <th style={styles.loanTableTh}>Branch</th>
                  <th style={styles.loanTableTh}>Branch Manager</th>
                  <th style={styles.loanTableTh}>Due EMI Amount</th>
                  <th style={styles.loanTableTh}>EMI Amount</th>
                  <th style={styles.loanTableTh}>Current Month Calling Count</th>
                  <th style={styles.loanTableTh}>Today's Calling Count</th>
                  <th style={styles.loanTableTh}>FollowUp Date</th>
                  <th style={styles.loanTableTh}>Action</th>
                  <th style={styles.loanTableTh}>Comments</th>
                  <th style={styles.loanTableTh}>Recording</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={styles.loanTableTd}>{agentloanDetails[currentIndex]?.loan_number}</td>
                  <td style={styles.loanTableTd}>{agentloanDetails[currentIndex]?.app_name}</td>
                  <td style={styles.loanTableTd}>{agentloanDetails[currentIndex]?.branch}</td>
                  <td style={styles.loanTableTd}>{agentloanDetails[currentIndex]?.bm}</td>
                  <td style={styles.loanTableTd}>{agentloanDetails[currentIndex]?.arrear_amount}</td>
                  <td style={styles.loanTableTd}>{agentloanDetails[currentIndex]?.emp_id}</td>
                  <td style={styles.loanTableTd}>{agentloanDetails[currentIndex]?.month_call_count}</td>
                  <td style={styles.loanTableTd}>{agentloanDetails[currentIndex]?.day_call_count}</td>
                  <td style={styles.loanTableTd}>{agentloanDetails[currentIndex]?.feedbackData?.next_follow_up_date}</td>
                  
                  <td style={styles.loanTableTd}>
                    <button style={styles.callNowBtn} onClick={() => openModal(agentloanDetails[currentIndex])}>
                      Call Now
                    </button>
                  </td>
                  <td style={styles.loanTableTd}>
                  {agentloanDetails[currentIndex]?.feedbackData?.length > 0 
                    ? agentloanDetails[currentIndex].feedbackData[agentloanDetails[currentIndex].feedbackData.length - 1]?.remarks 
                    : "No remarks available"}
                </td>

                  {/* <td style={styles.loanTableTd}>
                    {agentloanDetails[currentIndex]?.requestData?.length > 0 ?  (
                      <button
                        style={{
                          padding: "8px 12px",
                          backgroundColor: "#007bff",
                          color: "#fff",
                          border: "none",
                          borderRadius: "4px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleCommentClick(agentloanDetails[currentIndex])}
                        // onClick={() => handleCommentClick()}
                      >
                        View All Comments
                      </button>
                    ) : (
                      "No Comments"
                    )}

                    {isCommentModalOpen && selectedLoan === agentloanDetails[currentIndex] && (
                      <div style={styles.modalOverlay}>
                        <div style={styles.modalContent}>
                          <div style={styles.modalHeader}>Recordings</div>
                          {agentloanDetails[currentIndex]?.requestData?.length > 0 ? (
                            <table style={styles.table}>
                              <thead>
                                <tr>
                                  <th style={styles.tableHeader}>Date</th>
                                  <th style={styles.tableHeader}>Comment</th>
                                </tr>
                              </thead>
                              <tbody>
                                {agentloanDetails[currentIndex]?.feedbackData.map((allComments, index) => (
                                  <tr key={index}>
                                    <td style={styles.tableCell}>
                                      {agentloanDetails[currentIndex]?.date || "Unknown Date"}
                                    </td>
                                    <td style={styles.tableCell}>                               
                                    {allComments}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <div>No Comment available</div>
                          )}
                          <button
                            style={{
                              padding: "8px 12px",
                              backgroundColor: "#007bff",
                              color: "#fff",
                              border: "none",
                              borderRadius: "4px",
                              cursor: "pointer",
                              marginTop: "20px",
                            }}
                            onClick={closeCommentModal}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    )}
                  </td> */}
                  

                  {/* <td style={styles.loanTableTd}>{agentloanDetails[currentIndex].feedbackData.remarks}</td> */}
                  <td style={styles.loanTableTd}>
                    {agentloanDetails[currentIndex]?.requestData?.length > 0 ? (
                      <button
                        style={{
                          padding: "8px 12px",
                          backgroundColor: "#007bff",
                          color: "#fff",
                          border: "none",
                          borderRadius: "4px",
                          cursor: "pointer",
                        }}
                        onClick={() => handlePlayRecordingClick(agentloanDetails[currentIndex])}
                      >
                        Play Recordings
                      </button>
                    ) : (
                      "No recording"
                    )}

                    {isRecodingModalOpen && selectedLoan === agentloanDetails[currentIndex] && (
                      <div style={styles.modalOverlay}>
                        <div style={styles.modalContent}>
                          <div style={styles.modalHeader}>Recordings</div>
                          {agentloanDetails[currentIndex]?.requestData?.length > 0 ? (
                            <table style={styles.table}>
                              <thead>
                                <tr>
                                  <th style={styles.tableHeader}>Date</th>
                                  <th style={styles.tableHeader}>Recording</th>
                                </tr>
                              </thead>
                              <tbody>
                                {agentloanDetails[currentIndex]?.requestData.map((recording, index) => (
                                  <tr key={index}>
                                    <td style={styles.tableCell}>
                                      {agentloanDetails[currentIndex]?.date || "Unknown Date"}
                                    </td>
                                    <td style={styles.tableCell}>
                                      <button
                                        style={{
                                          padding: "8px 12px",
                                          backgroundColor: "#007bff",
                                          color: "#fff",
                                          border: "none",
                                          borderRadius: "4px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => window.open(recording.response_data?.recording_url, "_blank")}
                                      >
                                        Play Recording {index + 1}
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <div>No recordings available</div>
                          )}
                          <button
                            style={{
                              padding: "8px 12px",
                              backgroundColor: "#007bff",
                              color: "#fff",
                              border: "none",
                              borderRadius: "4px",
                              cursor: "pointer",
                              marginTop: "20px",
                            }}
                            onClick={closeRecodingModal}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    )}
                  </td>


                  {/* <td style={styles.loanTableTd}>
                  {agentloanDetails[currentIndex]?.requestData?.length > 0 && agentloanDetails[currentIndex].requestData[0].response_data?.recording_url ? (
                    <button
                      style={{
                        padding: "8px 12px",
                        backgroundColor: "#007bff",
                        color: "#fff",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                      onClick={() => window.open(agentloanDetails[currentIndex].requestData[0].response_data?.recording_url, "_blank")}
                    >
                      Play Recording
                    </button>
                  ) : (
                    "No recording"
                  )}
                </td> */}
                </tr>
              </tbody>
            </table>
            <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
            <button style={styles.callNowBtn} onClick={handlePrevious} disabled={currentIndex === 0}>
              Previous
            </button>
            <button style={styles.callNowBtn} onClick={handleNext} disabled={currentIndex === agentloanDetails.length - 1}>
              Next
            </button>
          </div>
          </>
        )
      )}
    </div>
  
      {/* Line Chart */}
<div style={styles.chartContainer}>
  <h2 style={styles.chartTitle}>Calling Trends</h2>
  <div style={styles.lineChart}>
    {/* <div style={styles.filterButtons}>
      <button
        style={
          filters.date === "day"
            ? { ...styles.filterButton, ...styles.filterButtonActive }
            : styles.filterButton
        }
        onClick={() => setFilters({ date: "day" })}
      >
        Daily
      </button>
      <button
        style={
          filters.date === "week"
            ? { ...styles.filterButton, ...styles.filterButtonActive }
            : styles.filterButton
        }
        onClick={() => setFilters({ date: "week" })}
      >
        Weekly
      </button>
      <button
        style={
          filters.date === "month"
            ? { ...styles.filterButton, ...styles.filterButtonActive }
            : styles.filterButton
        }
        onClick={() => setFilters({ date: "month" })}
      >
        Monthly
      </button>
    </div> */}
    <div style={styles.chartArea}>
    <Chart
                chartType="Bar"
                data={chartUserData}
                width="100%"
                height="300px"
                options={{
                  hAxis: { title: "Days" },
                  vAxis: { title: "Calls Made" },
                  colors: ["#1976d2", "#ff7043", "#66bb6a"],
                  series: { 0: { lineDashStyle: [4, 4] } },
                }}
              />
      {/* <Line data={data} options={options} /> */}
    </div>
  </div>
</div>

  
      {/* Modal for Making a Call */}
      <Modal 
      open={isModalOpen}
      onClose={closeModal}
      style={modalStyle}
      BackdropProps={{
        onClick: (e) => e.stopPropagation(),
      }}
    >
      <div style={modalContentStyle} onClick={(e) => e.stopPropagation()}>
        <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Make a Call</h2>

        {/* Customer Type Dropdown */}
        <label style={{ fontSize: '18px', marginBottom: '10px' }}>
          Choose Customer:
          <select 
            value={selectedCustomerType} 
            onChange={handleCustomerTypeChange} 
            style={selectStyle}
          >
            <option value="Applicant">Applicant</option>
            <option value="CoApplicant">CoApplicant</option>
            <option value="Guarantor">Guarantor</option>
          </select>
        </label>

        {/* Enter Mobile Number */}
        <label style={{ fontSize: '18px', marginBottom: '10px' }}>
          Mobile Number:
          <input
            type="text"
            value={enteredMobileNumber}
            onChange={handleMobileNumberChange}
            placeholder="Enter mobile number"
            style={inputStyle}
          />
        </label>

        {/* Comment Input */}
        {isCommentVisible && (
          <>
            {/* <label style={{ fontSize: '18px', marginBottom: '10px' }}>
              Comment:
              <input
                type="text"
                value={comment}
                onChange={handleCommentChange}
                placeholder="Enter comment"
                style={inputStyle}
              />
            </label> */}
             <label style={{ fontSize: '18px', marginBottom: '10px', display: 'block' }}>
                Select Comment:
                <select
                  value={comment}
                  onChange={handleCommentChange}
                  style={{ ...inputStyle, marginBottom: '10px' }}
                >
                  <option value="">-- Select an Option --</option>
                  <option value="Emi Received">Emi Received</option>
                  <option value="Customer will Pay Today">Customer will Pay Today</option>
                  <option value="Customer will Pay Tomorrow">Customer will Pay Tomorrow</option>
                  <option value="Call Not Attend">Call Not Attend</option>
                  <option value="Other">Other</option>
                </select>
              </label>

              {comment === 'Other' && (
                <label style={{ fontSize: '18px', marginBottom: '10px', display: 'block' }}>
                  Custom Comment:
                  <input
                    type="text"
                    value={customComment}
                    onChange={handleCustomCommentChange}
                    placeholder="Enter custom comment"
                    style={inputStyle}
                  />
                </label>
              )}
            {/* <label style={{ fontSize: '18px', marginBottom: '10px' }}>
              Follow Up Date:
              <input
                type="date"
                value={nextfollowupdate}
                onChange={handleFollowUpDatChange}
                placeholder="Enter comment"
                style={inputStyle}
              />
            </label> */}
            <label style={{ fontSize: '18px', marginBottom: '10px', display: 'block' }}>
                Follow Up Date:
                <input
                  type="date"
                  value={nextfollowupdate}
                  onChange={handleFollowUpDatChange}
                  style={inputStyle}
                  min={new Date().toISOString().split('T')[0]} // Restrict past dates
                  onKeyDown={(e) => e.preventDefault()} // Prevent manual typing
                />
              </label>
            <label style={{ fontSize: "18px", marginBottom: "10px" }}>
                Call Status:
                <select
                  value={callStatus}
                  onChange={handleCallStatusChange}
                  style={{
                    ...inputStyle,
                  }}
                >
                  <option value="">Select Call Status</option>
                  <option value="Call Attend by Applicant">Call Attend by Applicant</option>
                  <option value="Call Attend by Co-Applicant">Call Attend by Co-Applicant</option>
                  <option value="Call Attend by Guarantor">Call Attend by Guarantor</option>
                  <option value="Call Not Attended by Applicant">Call Not Attended by Applicant</option>
                  <option value="Call Not Attended by Co-Applicant">Call Not Attended by Co-Applicant</option>
                  <option value="Call Not Attended by Guarantor">Call Not Attended by Guarantor</option>
                  <option value="Call Cut by Customer">Call Cut by Customer</option>
                  <option value="Network Issue">Network Issue</option>
                </select>
              </label>
            <button 
              style={buttonStyle} 
              onMouseEnter={(e) => e.target.style.backgroundColor = buttonHoverStyle.backgroundColor} 
              onMouseLeave={(e) => e.target.style.backgroundColor = buttonStyle.backgroundColor} 
              onClick={handleSaveComment}
            >
              Save Comment
            </button>
          </>
        )}

        {/* Start Call Button */}
        {!isCommentVisible && (
          <button 
            style={buttonStyle} 
            onMouseEnter={(e) => e.target.style.backgroundColor = buttonHoverStyle.backgroundColor} 
            onMouseLeave={(e) => e.target.style.backgroundColor = buttonStyle.backgroundColor} 
            onClick={handleStartCall}
          >
            Start Call
          </button>
        )}

        {/* Close Button */}
        {!isCommentVisible && (
          <button 
            style={closeButtonStyle} 
            onMouseEnter={(e) => e.target.style.backgroundColor = closeButtonHoverStyle.backgroundColor} 
            onMouseLeave={(e) => e.target.style.backgroundColor = closeButtonStyle.backgroundColor} 
            onClick={closeModal}
          >
            Close
          </button>
        )}
      </div>
    </Modal>

    </div>
  );
  
};


export default Dashboard;
