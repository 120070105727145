import { Role } from "./role"
import Product from "./product";

function productbyrole(role) {
    switch (role) {
        case Role.Admin:
            return [Product.GoldLoan, Product.SBL, Product.Accounting, Product.Collection, Product.HR, Product.Legal, Product.CustomerCare,Product.CoLending,Product.VendorPanel];
        
        case Role.Sales:
            return [ Product.SBL];
        case Role.CoLending:
            return [Product.CoLending];    
        case Role.Collections:
            return [  Product.Collection];
        case Role.HO:
            return [Product.GoldLoan, Product.SBL,Product.Collection ];
        case Role.HOC:
            return [Product.SBL ];
        case Role.HOO:
            return [Product.SBL ];
        case Role.HOCL:
            return [Product.Collection ];                  
        case Role.HOA:
            return [ Product.SBL ];
        case Role.HOCA:
            return [ Product.SBL ];
        case Role.HOOA:
            return [ Product.SBL ];
        case Role.HOSA:
            return [ Product.SBL ];
        case Role.HOCLA:
            return [ Product.Collection ];                
        case Role.GM:
            return [ Product.SBL, Product.Collection, Product.Accounting,  Product.Legal, Product.CustomerCare,Product.CoLending,Product.VendorPanel];
        case Role.CEO:
                return [Product.GoldLoan, Product.SBL, Product.Collection, Product.Accounting,  Product.Legal, Product.CustomerCare,Product.CoLending,Product.VendorPanel];    
        case Role.BM:
            return [Product.SBL];
        case Role.ABM:
            return [Product.SBL];
        case Role.IR:
            return [Product.Accounting];
        case Role.DOCA:
            return [Product.SBL];
        case Role.BCM:
            return [ Product.SBL];
        case Role.ASM:
            return [  Product.SBL];
        case Role.RSM:
            return [  Product.SBL];
        case Role.ZSM:
            return [Product.SBL];
        case Role.EAZSM:
            return [Product.SBL];
        case Role.KTR:
            return [Product.SBL];
        case Role.ACM:
            return [ Product.SBL];
        case Role.RCM:
            return [ Product.SBL];
        case Role.CSM:
            return [  Product.SBL];
        case Role.CCM:
            return [  Product.SBL];
        case Role.ZM:
            return [  Product.SBL];
        case Role.ZCM:
            return [ Product.SBL];
        case Role.ZCCM:
            return [ Product.SBL, Product.Collection];
        case Role.RCCM:
            return [ Product.SBL, Product.Collection];
        case Role.ACCM:
            return [ Product.SBL, Product.Collection];
        case Role.CCCM:
            return [ Product.SBL, Product.Collection];
        case Role.BCCM:
            return [ Product.SBL, Product.Collection];
        case Role.CC:
            return [Product.CustomerCare];
        case Role.AM:
            return [Product.GoldLoan, Product.SBL, Product.Accounting,Product.CoLending ];
        case Role.AC:
            return[Product.GoldLoan, Product.SBL, Product.Accounting,Product.CoLending ];
        case Role.LG:
            return [Product.Legal];
        case Role.CLM:
            return [ Product.SBL ];

        case Role.IT:
            return [Product.GoldLoan, Product.SBL, Product.Accounting, Product.HR, Product.Legal, Product.CustomerCare];

        case Role.HOGM:
            return [Product.GoldLoan ];
        case Role.HOGC:
            return [Product.GoldLoan ];
        case Role.GLDR:
            return [Product.GoldLoan];
        case Role.HR:
            return [Product.HR];
        case Role.CFO:
            return [Product.GoldLoan, Product.SBL, Product.Collection, Product.Accounting,Product.CoLending ,Product.VendorPanel];
        case Role.CP:
            return [  Product.SBL ];
        case Role.COL:
            return [  Product.CoLending ];
        case Role.COLA:
            return [  Product.CoLending ];
        case Role.COLM:
            return [Product.CoLending];
        case Role.SLG:
            return [  Product.SBL ];
        case Role.FH:
            return [ Product.Accounting, Product.SBL];
        case Role.BOP:
            return [Product.SBL];
        case Role.VPC:
            return [Product.SBL];
        case Role.VENDOR:
            return [Product.VendorPanel]
        case Role.AUDITOR:
            return [Product.SBL]
        default:
            return [Product.GoldLoan, Product.SBL, Product.Collection, Product.Accounting, Product.HR, Product.Legal, Product.CustomerCare,Product.CoLending];
    }
}





export default productbyrole 
