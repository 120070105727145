import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import CerSaiUpload from '../../Applications/CerSaiUpload';
import { TabContext } from '../TabBar';
import { loanService } from "../../../../_services";

const CersaiReport = () => {
  const TabContextValue = useContext(TabContext);
  const history = useHistory();
  // Wrap `cust` in `useMemo` to ensure its stability
  const cust = useMemo(() => TabContextValue?.cust || {}, [TabContextValue]);

  const [stageCollection, setStageCollection] = useState(false);
  const [isRequestHovered, setIsRequestHovered] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [apiMessage, setApiMessage] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isEditButtonDisabled, setIsEditButtonDisabled] = useState(true);
  const [isTryExceedingLimit, setIsTryExceedingLimit] = useState(false);

  // Extracting latest CAM letter details
  const application_id = cust.application_id;
  const camLetters = cust.cam_letters || [];
  const latestCamLetter = camLetters[camLetters.length - 1] || {};

  const owner_name = latestCamLetter.owner_name || '';
  const property_owner = latestCamLetter.property_owner || 'Yes';
  const coapplicants = latestCamLetter.coapplicants || [];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editErrorDetails, setErrorDetails] = useState([]); // Array of errors
  const [newValues, setNewValues] = useState([]); // Store new values for updating
  let IsApplicatispropertyOwner = true;
  let matchingCoapplicantName = '';

  const normalizedOwnerName = owner_name.toLowerCase().replace(/\s+/g, '') || '';
  if (property_owner === 'No') {
    const matchingCoapplicant = coapplicants.find(coapplicant => {
      const normalizedCoapplicantName = coapplicant?.name?.toLowerCase().replace(/\s+/g, '') || '';
      return normalizedCoapplicantName === normalizedOwnerName;
    });

    if (matchingCoapplicant) {
      IsApplicatispropertyOwner = false;
      matchingCoapplicantName = matchingCoapplicant.name;
    }
  }

  const formatErrorMessages = (errorDetails) => {
    if (!errorDetails || typeof errorDetails !== 'object') {
      return 'Unknown error occurred.';
    }

    // Extract all errors and format them
    return Object.entries(errorDetails)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n'); // Use newlines or `<br />` for HTML
  };
  // Prepare CERSAI data
  const cersaiData = {
    application_id: cust.application_id || '',
    applicant_name: cust.applicant?.general_information?.name || '',
    applicant_sonof: cust.applicant?.general_information?.sonof || '',
    gender: cust.applicant?.general_information?.gender || '',
    sanction_amount: cust.loan?.sanction_amount || '',
    disbursement_date: cust.loan?.disbursement_date || '',
    cersai_reports: cust.property_details?.cersai_reports || [],
    property_area: cust.property_details?.area || '',
    co_lending: cust.co_lending?.[cust.co_lending.length - 1]?.co_lender_name || '',
    co_lending_status: cust.co_lending?.[cust.co_lending.length - 1]?.co_lender_status || '',
    property_address: latestCamLetter.property_address || '',
    owner_name: owner_name,
    property_owner: property_owner,
    application_form: cust.application_form || '',
    valuationrculegaldata: cust.valuationrculegaldata || '',
    stage: cust.stage || '',
    IsApplicatispropertyOwner,
    matchingPropertyOwner: matchingCoapplicantName,
  };

  // Handle stage collection
  useEffect(() => {
    if (cust.stage === 'Collection') {
      setStageCollection(true);
    } else {
      setStageCollection(false);
    }
  }, [cust]);
  useEffect(() => {
    let isMounted = true; // Track if the component is mounted
  
    const getCersaiStatus = async () => {
      const application_id = cust.application_id;
  
      // If application_id is missing, show an error message
      if (!application_id) {
        if (isMounted) {
          setShowError(true);
          setErrorMessage("Error: Application ID is missing.");
        }
        return;
      }
  
      try {
        // Fetch the CERSAI status using the API
        const response = await loanService.getCersaiCreationStatus({ application_id });
        console.log('response:::', response);
  
        if (isMounted) {
          switch (response.status) {
            case "NotFound":
              setShowError(false);
              setIsButtonDisabled(false);
              setIsEditButtonDisabled(true);
              break;
            case "InProgress":
              setApiMessage(response.message || "Your request is in progress.");
              setShowError(false);
              setIsButtonDisabled(true);
              break;
            case "Failed":
              if (response?.error?.error_type === "Data") {
                // Enable Edit Data button when error is related to Data
                setIsEditButtonDisabled(false);
              } else if (response?.error?.error_type === "Network") {
                // Disable both buttons when error is Network related
                setIsButtonDisabled(true);
                setIsEditButtonDisabled(true);
              }
              setApiMessage(response.message || "Your request failed.");
              setShowError(true);
  
              // Handle try_count exceeding limit
              if (response.try_count >= 5) {
                setIsTryExceedingLimit(true);
              } else {
                setIsTryExceedingLimit(false);
              }
              if (
                cust && 
                cust.property_details && 
                cust.property_details.cersai_reports && 
                cust.property_details.cersai_reports.length > 0 && 
                cust.property_details.cersai_reports[0]?.document_url
              ) {
                setIsButtonDisabled(true);
                setIsEditButtonDisabled(true);
              }
  
              const errorDetails = response?.error?.error_detail || {};
              const formattedErrorMessage = formatErrorMessages(errorDetails);
              setErrorMessage(`\n${formattedErrorMessage}`);
              break;
            case "Success":
              setApiMessage(response.message || "Request was successful.");
              setShowError(false);
              setIsButtonDisabled(true);
              break;
            default:
              setApiMessage("Unexpected response. Please contact support.");
              setShowError(true);
              setErrorMessage("Error: Unknown response received.");
              setIsButtonDisabled(true);
          }
        }
      } catch (error) {
        if (isMounted) {
          setApiMessage("Error occurred. Please try again.");
          setShowError(true);
          setErrorMessage("Error: Unable to process your request. Please try again.");
          setIsButtonDisabled(true);
        }
      }
    };
  
    if (cust.application_id) {
      getCersaiStatus();
    }

    return () => {
      isMounted = false; // Cleanup function to mark the component as unmounted
    };
  }, [cust]);

  // Send request to create CERSAI
  const handleSendRequest = async () => {
    const application_id = cust.application_id;

    if (!application_id) {
      setShowError(true);
      setErrorMessage("Error: Application ID is missing.");
      return;
    }

    try {
      const response = await loanService.sendCersaiCreationRequest({ application_id });

      if (response?.status === "Success") {
        setApiMessage(response?.message);
        setShowError(false);
        setIsButtonDisabled(true);
      } else {
        setApiMessage("Please resubmit your request.");
        setShowError(true);
        setErrorMessage("Error: Unable to process your request. Please try again later.");
        setIsButtonDisabled(false);
      }
    } catch (error) {
      setApiMessage("Error occurred. Please try again.");
      setShowError(true);
      setErrorMessage("Error: Unable to process your request. Please try again.");
    }
  };
  // Modal close handler
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
const handleEditDataClick = () => {
  const parsedErrors = errorMessage
    .split('\n') // Split errorMessage into lines
    .filter((line) => line.includes(':')) // Filter valid key-value error lines
    .map((line, index) => {
      const [columnName, errorDetail] = line.split(':').map((str) => str.trim());
      return { id: index + 1, columnName, errorDetail, newValue: '' };
    });

  setErrorDetails(parsedErrors); // Populate error details in the modal
  setNewValues(parsedErrors); // Initialize new values for editing
  setIsModalOpen(true); // Open the modal
};


 // Handle the new value input for each row
const handleNewValueChange = (id, value) => {
  setNewValues(prevValues =>
    prevValues.some(val => val.id === id)
      ? prevValues.map(val => (val.id === id ? { ...val, newValue: value } : val))
      : [...prevValues, { id, newValue: value }]
  );
};

// Handle API call on form submit
const handleSubmit = async () => {
  try {
    // Construct the editdata object
    const editData = newValues.reduce((acc, { id, newValue }) => {
      const errorDetail = editErrorDetails.find(error => error.id === id);
      if (errorDetail) {
        acc[errorDetail.columnName] = newValue;
      }
      return acc;
    }, {});

    // API payload
    const payload = {
      application_id,
      editdata: editData,
    };

    // Call the API
    const response = await loanService.updateCersaiEditData(payload);

    // Handle success
    console.log('API response:', response);
    if (response.status === 'Success') {
      // Close the modal
      setIsModalOpen(false);
      // Show success message
      setApiMessage('Data updated successfully!');
      // Disable the Edit button
      setIsEditButtonDisabled(true);
    }
    alert('Data updated successfully!');
  } catch (error) {
    console.error('Error:', error);
  }
};


  // Button styles
  const buttonStyle = {
    backgroundColor: 'blue',
    border: 'none',
    color: 'white',
    padding: '15px 32px',
    fontSize: '16px',
    fontWeight: 'bold',
    borderRadius: '8px',
    cursor: 'pointer',
    marginRight: '10px',
  };

  const buttonHoverStyle = {
    backgroundColor: 'white',
    color: 'blue',
    border: '2px solid #4CAF50',
  };

  const disabledButtonStyle = {
    backgroundColor: '#ccc',
    color: '#666',
    cursor: 'not-allowed',
  };

  // Container styles
  const containerStyle = {
    backgroundColor: '#f0f0f0',
    padding: '20px',
    borderRadius: '10px',
    marginTop: '20px',
  };

  const errorBoxStyle = {
    backgroundColor: '#ffcccc',
    color: '#cc0000',
    padding: '15px',
    marginTop: '20px',
    borderRadius: '8px',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '14px',
  };

  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '20px',
  };
  // Add your modal and styles
const modalStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const modalContentStyle = {
  backgroundColor: 'white',
  padding: '20px',
  borderRadius: '8px',
  maxWidth: '80%',
  overflow: 'auto',
};

  return (
    <div style={containerStyle}>
      <div style={buttonContainerStyle}>
        {stageCollection ? (
          <>
            <button
              style={{
                ...buttonStyle,
                ...(isRequestHovered ? buttonHoverStyle : {}),
                ...(isButtonDisabled || !isTryExceedingLimit ? disabledButtonStyle : {}),
              }}
              onMouseEnter={() => setIsRequestHovered(true)}
              onMouseLeave={() => setIsRequestHovered(false)}
              onClick={() => !isButtonDisabled && isTryExceedingLimit && history.push('/home/cersai', { cersaiData })}
              disabled={isButtonDisabled || !isTryExceedingLimit} // Disable button if try_count < 5
            >
              SEARCH CERSAI
            </button>

            <button
              style={{
                ...buttonStyle,
                ...(isRequestHovered ? buttonHoverStyle : {}),
                ...(isButtonDisabled ? disabledButtonStyle : {}),
              }}
              onMouseEnter={() => setIsRequestHovered(true)}
              onMouseLeave={() => setIsRequestHovered(false)}
              onClick={handleSendRequest}
              disabled={isButtonDisabled}
            >
              Send Request to Create Cersai
            </button>
            <button
              style={{...buttonStyle,...(isEditButtonDisabled ? disabledButtonStyle : {})}}
              onClick={handleEditDataClick} // Open modal on click
              disabled={isEditButtonDisabled}
            >
              Edit Data
            </button>
          </>
        ) : (
          <>
            {/* <button style={disabledButtonStyle} disabled>
              SEARCH CERSAI
            </button> */}
            <button style={disabledButtonStyle} disabled>
              Send Request to Create CERSAI
            </button>
          </>
        )}
      </div>
      {apiMessage && <div><h4 style={{'color':'red'}}>Note:: {apiMessage}</h4></div>}
      <div>
      {showError && (
        <div style={errorBoxStyle}>
          <p>Error Details:</p>
          <pre>{errorMessage}</pre> {/* Preformatted to preserve newlines */}
        </div>
      )}
    </div>
      {/* Modal to show error details and allow editing */}
      {isModalOpen && (
        <div style={modalStyle}>
          <div style={modalContentStyle}>
            <h2>Edit Data</h2>
            <table>
              <thead>
                <tr>
                  <th>Column Name</th>
                  <th>Error Details</th>
                  <th>Edit</th>
                </tr>
              </thead>
              <tbody>
                {editErrorDetails.map((error, index) => (
                  <tr key={error.id}>
                    <td>{error.columnName}</td>
                    <td>{error.errorDetail}</td>
                    <td>
                      <input
                        type="text"
                        value={newValues.find(val => val.id === error.id)?.newValue || ''}
                        onChange={(e) => handleNewValueChange(error.id, e.target.value)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <br />
            <button style={buttonStyle} onClick={handleSubmit}>Submit</button>
            <button style={buttonStyle} onClick={handleCloseModal}>Close</button>
          </div>
        </div>
      )}
      <CerSaiUpload application_id={cust.application_id || ''} cust={cust} />
    </div>
  );
};

export default CersaiReport;
