import React, { useEffect, useState } from 'react'
import datehelper from "../../../_helpers/datehelper";
import vistaarLogo from "../../../assets/Vistaar.jpg"
import capitalfinance from "../../../assets/capitalfinance.png";
import logo from "../../../assets/kaabil.png";
const ChequeSubmissionForm = (props) => {

    const data = props.data
    const branches = props.branches
    const colender = props.colender
    // const ChequeSubmissionFormRef = React.useRef();




    // const handleSubmissionPrint = useReactToPrint({
    //     content: () => ChequeSubmissionFormRef.current,
    //     pageStyle: "print",
    // });
    const headerDiv = (colender) => (
        <div>
          <img src={logo} style={{ height: "100px" }} alt="KFPL Logo" />
          {colender.includes("CIFL") && (
            <img
              src={capitalfinance}
              style={{
                height: "100px",
                position: "absolute",
                top: "2",
                right: "0",
                marginRight: "3rem",
              }}
              alt="Capital Finance Logo"
            />
          )}
          {colender.includes("VistaarFinance") && (
            <img
              src={vistaarLogo}
              style={{
                height: "100px",
                position: "absolute",
                top: "2",
                right: "0",
                marginRight: "3rem",
              }}
              alt="Vistaar Finance Logo"
            />
          )}
        </div>
      );

      const formattedDate = colender.includes("CIFL")  ?datehelper.displayDate(new Date(data?.loan?.agreement_stamp_date)):datehelper.displayDate(new Date(data.created))
      const [day, month, Year] = formattedDate.split("-"); // Split the date by the '-' delimiter
      const getOrdinalSuffix = (day) => {
          if (day % 10 === 1 && day % 100 !== 11) return `${day}st`;
          if (day % 10 === 2 && day % 100 !== 12) return `${day}nd`;
          if (day % 10 === 3 && day % 100 !== 13) return `${day}rd`;
          return `${day}th`;
        };
        const dayWithSuffix = day ? getOrdinalSuffix(day) : "_______";
    return (
        <>

            <div
                style={{
                    width: "290mm",
                    height: "410mm",
                    backgroundColor: "white",
                    padding: 40,
                    position: "relative",
                    fontSize: "20px"
                }}
            >
                   {headerDiv(colender)}<br/><br/>
                <span style={{ marginLeft: "40%", fontSize: "20px" }}><b>CHEQUES SUBMISSION FORM</b></span><br /><br />

                <div style={{ fontSize: "20px", textAlign: "justify" }}>
                    <p>To</p>
                    <span>
                        Kaabil Finance Private Limited,<br />
                        Plot No. 1, Scheme No. 1, Income Tax Colony, Model Town, <br/>Jagatpura, Jaipur Rajasthan 302017, (“Lender 1”)
                    </span><br /><br />
                    {colender.includes("CIFL") ?
                   <span>
                   Capital India Finance Limited<br />
                   2nd Floor, DLF Centre, Sansad Marg<br />
                   New Delhi, Central Delhi – 110001, (“Lender 2”).<br /><br />
                   (Collectively “Lenders”)
               </span>:

                    <span>
                    Vistaar Financial Services Pvt Ltd <br />
                    Plot No. 59 & 60-23, 22nd Cross, 29th Main BTM Layout, <br />
                    2nd  Stage NA Bengaluru Bangalore KA 560076(“Lender 2”).<br /><br />
                        (Collectively “Lenders”)
                    </span>}<br /><br />

                    <span>
                        Ref: Loan of Rs. <b>{data && data.loan && data.loan.sanction_amount}/-</b> approved by the Lenders, vide the Sanction Letter dated <b>{colender.includes("CIFL")  ?datehelper.displayDate(new Date(data?.loan?.agreement_stamp_date)):datehelper.displayDate(new Date(data.created))} </b> 
                        (“Loan”)
                    </span><br /><br />

                    <span>Dear Sir / Madam,</span><br /><br />

                    <span>
                        With reference to the Loan, I/we, the Borrower/s, hereby submit the following cheques to you, drawn on
                        <b> KAABIL FINANCE PRIVATE LIMITED</b>, to facilitate timely and regular repayment of all the outstanding
                        amount in relation to the Loan.
                    </span><br /><br />

                    <table>
                        <tr style={{ border: "1px solid", textAlign: "center" }}>
                            <td style={{ border: "1px solid" }} colspan="2">Cheque Number</td>
                            <td style={{ border: "1px solid" }}>No. of cheques</td>
                            <td style={{ border: "1px solid" }} colspan="2">Dates</td>
                            <td style={{ border: "1px solid" }} rowspan="2">Name and address of the Bank</td>
                            <td style={{ border: "1px solid" }} rowspan="2">Amount (Rs.)</td>
                            <td style={{ border: "1px solid" }} rowspan="2">Purpose (PEMI / Installment / other)</td>
                        </tr>
                        <tr style={{ border: "1px solid", textAlign: "center" }}>
                            <td style={{ border: "1px solid", width: "8%" }}>From</td>
                            <td style={{ border: "1px solid", width: "8%" }}>To</td>
                            <td style={{ border: "1px solid" }}></td>
                            <td style={{ border: "1px solid", width: "8%" }}>From</td>
                            <td style={{ border: "1px solid", width: "8%" }}>To</td>
                        </tr>

                        <tr style={{ border: "1px solid" }}>
                            <td style={{ border: "1px solid" }}>&nbsp;</td>
                            <td style={{ border: "1px solid" }}></td>
                            <td style={{ border: "1px solid" }}></td>
                            <td style={{ border: "1px solid" }}></td>
                            <td style={{ border: "1px solid" }}></td>
                            <td style={{ border: "1px solid" }}></td>
                            <td style={{ border: "1px solid" }}></td>
                            <td style={{ border: "1px solid" }}></td>
                        </tr>

                        <tr style={{ border: "1px solid" }}>
                            <td style={{ border: "1px solid" }}>&nbsp;</td>
                            <td style={{ border: "1px solid" }}></td>
                            <td style={{ border: "1px solid" }}></td>
                            <td style={{ border: "1px solid" }}></td>
                            <td style={{ border: "1px solid" }}></td>
                            <td style={{ border: "1px solid" }}></td>
                            <td style={{ border: "1px solid" }}></td>
                            <td style={{ border: "1px solid" }}></td>
                        </tr>

                        <tr style={{ border: "1px solid" }}>
                            <td style={{ border: "1px solid" }}>&nbsp;</td>
                            <td style={{ border: "1px solid" }}></td>
                            <td style={{ border: "1px solid" }}></td>
                            <td style={{ border: "1px solid" }}></td>
                            <td style={{ border: "1px solid" }}></td>
                            <td style={{ border: "1px solid" }}></td>
                            <td style={{ border: "1px solid" }}></td>
                            <td style={{ border: "1px solid" }}></td>
                        </tr>

                        <tr style={{ border: "1px solid" }}>
                            <td style={{ border: "1px solid" }}>&nbsp;</td>
                            <td style={{ border: "1px solid" }}></td>
                            <td style={{ border: "1px solid" }}></td>
                            <td style={{ border: "1px solid" }}></td>
                            <td style={{ border: "1px solid" }}></td>
                            <td style={{ border: "1px solid" }}></td>
                            <td style={{ border: "1px solid" }}></td>
                            <td style={{ border: "1px solid" }}></td>
                        </tr>

                        <tr style={{ border: "1px solid" }}>
                            <td style={{ border: "1px solid" }}>&nbsp;</td>
                            <td style={{ border: "1px solid" }}></td>
                            <td style={{ border: "1px solid" }}></td>
                            <td style={{ border: "1px solid" }}></td>
                            <td style={{ border: "1px solid" }}></td>
                            <td style={{ border: "1px solid" }}></td>
                            <td style={{ border: "1px solid" }}></td>
                            <td style={{ border: "1px solid" }}></td>
                        </tr>

                    </table><br />

                    <span>
                        Executed at&nbsp; {branches.branch_city}, on the  {day ? <b>{dayWithSuffix}</b> : "_______"} day of {(month && Year)? <b>{month} - {Year} </b>:"__________________________"} by,
                    </span><br /><br />
                </div>
            </div>

            <div
                style={{
                    width: "290mm",
                    height: "410mm",
                    backgroundColor: "white",
                    padding: 40,
                    position: "relative",
                    fontSize: "20px"
                }}
            >  {headerDiv(colender)}<br/><br/><br/>
                <div style={{textAlign:"justify"}}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span style={{ width: "300px" }}>Name of the Borrower/s</span>
                        <span style={{ width: "300px" }}>Signature/ Thumb Impression</span>
                    </div><br />
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span span style={{ width: "300px" }}>{data && data.applicant && data.applicant.general_information && data.applicant.general_information.name}</span><br />
                        <span span style={{ width: "200px" }}>________________________</span><br /><br />
                    </div>
                    {data && data.co_applicants && data.co_applicants.length > 0 && data.co_applicants.map((e, idx) => {
                        return <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span span style={{ width: "300px" }}>{e.general_information.name}</span><br />
                            <span span style={{ width: "200px" }}>________________________</span><br /><br />
                        </div>
                    })}


                    <span>Received by:</span><br />

                    <span>For, Kaabil Finance Private Limited</span><br /><br />

                    <span>Name:</span><br />
                    <span>Employee No:</span><br />
                    <span>Designation:</span><br /><br />

                    <span>
                        <b>Terms / Instructions:</b><br />
                        <p>• Cheques should be crossed as “Account Payee only”.</p><br />
                        <p>• Cheques should be drawn in favour of “_______________________________________________” only.</p><br />
                        <p>• Please ensure that there should not be any cutting or correction on the cheque and amount in words
                            and figures are the same.</p><br />
                        <p>• Installment cheques to be dated as per the repayment schedule.</p><br />
                        <p>• Cheques shall be collected and retained by the Lender 1, for and on the behalf of the Lender 1 and
                            Lender 2.</p><br />
                        <p>• Upon closure of the Loan by the Borrower/s in full in the terms of loan agreement, the Cheques may
                            be destroyed by the Lender 1.</p>
                    </span>
                </div>
            </div>
        </>
    )
}

export default ChequeSubmissionForm