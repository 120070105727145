import React from 'react'
import { employeeService } from "../../../_services/employee.service";
import FieldVisitTab from './FieldVisitTab';
import CollectionTabBar from './CollectionTabBar';

function CollectionTab({cust}){
console.log('collection tab is',cust)
    return (
        <div>
        <CollectionTabBar cust={cust}/>
         
        </div>
        
    )
}

export default CollectionTab