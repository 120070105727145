import { fetchWrapper} from "../_helpers";

const apiUrl = process.env.REACT_APP_API_URL;

const baseUrl = `${apiUrl}/virtualaccounts`;

export const virtualAccountService = {
  getAll,
  getAllPayments,
  create,
  saveQRCode,
  getPaymentsByDate,
  getByLoanAccount,
  getBySearch, 
  getPaymentsByAccount,
  getOnlinePaymentsOfTheLoan,
  nachPaymentsDetailsUsingLoanReferenceNo
};

function getAll() {
  return fetchWrapper.get(baseUrl);
}

function getAllPayments() {
  return fetchWrapper.get(`${baseUrl}/payments`);
}

function getPaymentsByDate(params) {
  //console.log(params)
  return fetchWrapper.post(`${baseUrl}/getpayments`, params)
}
function getByLoanAccount(params) {
  //console.log(params)
  return fetchWrapper.post(`${baseUrl}/getbyloanaccount`, params)
}


function create(params) {
  return fetchWrapper.post(baseUrl, params)
}

function saveQRCode(params)
{
  return fetchWrapper.post(`${baseUrl}/saveqrcode`, params)
}

function getBySearch(params){
  return fetchWrapper.post(`${baseUrl}/getbysearch`, params)
}

function getPaymentsByAccount(params){
  return fetchWrapper.post(`${baseUrl}/getpaymentsbyaccount`, params)
}
function getOnlinePaymentsOfTheLoan(params){
  console.log(params)
  return fetchWrapper.post(`${baseUrl}/getonlinepaymentsoftheloan`, params)
}
function nachPaymentsDetailsUsingLoanReferenceNo(params){
  return fetchWrapper.post(`${baseUrl}/nachpaymentsdetailsusingloaneferenceNo`, params)
}