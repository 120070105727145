import React, { useEffect, useState } from 'react'
import { useReactToPrint } from "react-to-print";
import { loanService } from '../../../_services';
import datehelper from "../../../_helpers/datehelper";
import vistaarLogo from "../../../assets/Vistaar.jpg"
import capitalfinance from "../../../assets/capitalfinance.png";
import logo from "../../../assets/kaabil.png";

  
const Moe = (props) => {

    const data = props.data
    console.log(data,"datadatadata")
    const branches = props.branches
    const colender = props.colender
console.log(colender,"colendercolender")
    // const MoeRef = React.useRef();
    const headerDiv = (colender) => (
        <div>
          <img src={logo} style={{ height: "100px" }} alt="KFPL Logo" />
          {colender.includes("CIFL") && (
            <img
              src={capitalfinance}
              style={{
                height: "100px",
                position: "absolute",
                top: "2",
                right: "0",
                marginRight: "3rem",
              }}
              alt="Capital Finance Logo"
            />
          )}
          {colender.includes("VistaarFinance") && (
            <img
              src={vistaarLogo}
              style={{
                height: "100px",
                position: "absolute",
                top: "2",
                right: "0",
                marginRight: "3rem",
              }}
              alt="Vistaar Finance Logo"
            />
          )}
        </div>
      );

    // const handleSubmissionPrint = useReactToPrint({
    //     content: () => MoeRef.current,
    //     pageStyle: "print",
    // });

    const one = ["", "One ", "Two ", "Three ", "Four ", "Five ", "Six ", "Seven ", "Eight ", "Nine ", "Ten ", "Eleven ", "Twelve ", "Thirteen ", "Fourteen ", "Fifteen ",
        "Sixteen ", "Seventeen ", "Eighteen ", "Nineteen "];

    var ten = ["", "", "Twenty ", "Thirty ", "Forty ", "Fifty ", "Sixty ", "Seventy ", "Eighty ", "Ninety "];

    function numToWords(n, s) {
        let str = "";
        // if n is more than 19, divide it
        if (n > 19) {
            str += ten[parseInt(n / 10)] + one[n % 10];
        }
        else {
            str += one[n];
        }
        if (n != 0) {
            str += s;
        }

        return str;
    }

    function convertToWords(n) {
        // stores word representation of given number n
        let out = "";

        // handles digits at ten millions and hundred
        // millions places (if any)
        out += numToWords(parseInt(n / 10000000),
            "Crore ");

        // handles digits at hundred thousands and one
        // millions places (if any)
        out += numToWords(parseInt((n / 100000) % 100),
            "Lakh ");

        // handles digits at thousands and tens thousands
        // places (if any)
        out += numToWords(parseInt((n / 1000) % 100),
            "Thousand ");

        // handles digit at hundreds places (if any)
        out += numToWords(parseInt((n / 100) % 10),
            "Hundred ");

        if (n > 100 && n % 100 > 0) {
            out += "and ";
        }

        // handles digits at ones and tens places (if any)
        out += numToWords(parseInt(n % 100), "");

        return out;
    }

    const formattedDate = colender.includes("CIFL")  ?datehelper.displayDate(new Date(data?.loan?.agreement_stamp_date)):datehelper.displayDate(new Date(data.created))
    const [day, month, Year] = formattedDate.split("-"); // Split the date by the '-' delimiter
    const getOrdinalSuffix = (day) => {
        if (day % 10 === 1 && day % 100 !== 11) return `${day}st`;
        if (day % 10 === 2 && day % 100 !== 12) return `${day}nd`;
        if (day % 10 === 3 && day % 100 !== 13) return `${day}rd`;
        return `${day}th`;
      };
      const dayWithSuffix = day ? getOrdinalSuffix(day) : "_______";
      
    return (
        <>
            {/* <div style={{ textAlign: "center" }}>
                <button onClick={handleSubmissionPrint}>Print Letter</button>
            </div> */}

            <div
                style={{
                    width: "290mm",
                    height: "410mm",
                    backgroundColor: "white",
                    padding: 40,
                    position: "relative",
                    fontSize: "20px"
                }}
            >
                 {headerDiv(colender)}<br/><br/>
                <span style={{ marginLeft: "40%" }}><b>SCHEDULE</b></span><br />
                <span style={{ marginLeft: "30%" }}><b>(Memorandum for Equitable Mortgage)</b></span><br /><br />
                {/* <table style={{ width: "90%", marginLeft: "5%", marginTop: "20px", fontSize: "20px" }}>
                    <tr style={{ border: "1px solid" }}>
                        <td colspan="2" style={{ textAlign: "center", border: "1px solid" }}>Particulars</td>
                    </tr>
                    <tr style={{ border: "1px solid" }}>
                    <td style={{
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"start"
                        }}>
                            Scheduled Properties
                        </td>
                        <td style={{ border: "1px solid" }}>{data && data.property_details && data.property_details.address ? data.property_details.address : <p>________________________________________________________ <br />________________________________________________________</p>} together with all rights (including but not limited to all development rights, rights
                            to use common areas and facilities etc.), titles, benefits, claims and interests of
                            the Mortgagor/s in relation to the Scheduled Properties and all furniture,
                            fixtures, fittings and things attached thereto.</td>
                    </tr>
                    <tr style={{ border: "1px solid" }}>
                        <td style={{ border: "1px solid", width: "30%" }}>Mortgagor/s</td>
                        <td style={{ border: "1px solid" }}>Name:- {data && data.property_details && data.property_details.owner}</td>
                    </tr>

                    <tr style={{ border: "1px solid" }}>
                        <td style={{ border: "1px solid", width: "30%" }}>Borrower/s:</td>
                        <td>
                            <tr>Name: {data && data.applicant && data.applicant.general_information && data.applicant.general_information.name}</tr>
                            <tr>Address: {data && data.applicant && data.applicant.general_information && data.applicant.general_information.address}</tr><br /><br />
                            {data && data.co_applicants && data.co_applicants.length > 0 && data.co_applicants.map((e, idx) => {
                                return <>
                                    <tr>Name: {e.general_information.name}</tr>
                                    <tr>Address: {e.general_information.address}</tr><br /><br />
                                </>
                            })}
                        </td>
                    </tr>

                    <tr style={{ border: "1px solid" }}>
                        <td style={{ border: "1px solid", width: "30%" }}>Details of Loan</td>
                        <td style={{ border: "1px solid" }}>Term loan of Rs. {data && data.loan && data.loan.sanction_amount}/- (Rupees {convertToWords(data && data.loan && data.loan.sanction_amount)} only).</td>
                    </tr>

                    <tr style={{ border: "1px solid" }}>
                        <td style={{ border: "1px solid", width: "30%" }}>Loan Agreement</td>
                        <td style={{ border: "1px solid" }}> Loan Agreement dated _________________, entered by and between the
                            Borrower/s and the Lenders.</td>
                    </tr>

                    <tr style={{ border: "1px solid" }}>
                        <td style={{ border: "1px solid", width: "30%" }}>Title Deeds</td>
                        <td style={{ border: "1px solid" }}> {data && data.property_details && data.property_details.type}</td>
                    </tr>

                    <tr style={{ border: "1px solid" }}>
                        <td style={{ border: "1px solid", width: "30%" }}>Details of Encumbrances and charges</td>
                        <td style={{ border: "1px solid" }}> &nbsp;</td>
                    </tr>

                    <tr style={{ border: "1px solid" }}>
                        <td style={{ border: "1px solid", width: "30%" }}>Other declarations, covenants and undertaking of Mortgagor/s</td>
                        <td style={{ border: "1px solid" }}> &nbsp;</td>
                    </tr>
                </table><br /><br /> */}
                <table className="table table-bordered" style={{ width: "90%", marginLeft: "5%", marginTop: "20px", fontSize: "20px" }}>
  <thead>
    <tr className="text-center">
      <th colSpan="2">Particulars</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td className="font-weight-bold">Scheduled Properties</td>
      <td>
        {data && data.property_details && data.property_details.address 
          ? data.property_details.address 
          : <p>________________________________________________________ <br />________________________________________________________</p>}
        together with all rights (including but not limited to all development rights, rights to use common areas and facilities etc.), titles, benefits, claims and interests of the Mortgagor/s in relation to the Scheduled Properties and all furniture, fixtures, fittings and things attached thereto.
      </td>
    </tr>
    <tr>
      <td className="font-weight-bold">Mortgagor/s</td>
      <td>Name: {data && data.property_details && data.property_details.owner}</td>
    </tr>
    <tr>
      <td className="font-weight-bold">Borrower/s:</td>
      <td>
        <div>Name: {data && data.applicant && data.applicant.general_information && data.applicant.general_information.name}</div>
        <div>Address: {data && data.applicant && data.applicant.general_information && data.applicant.general_information.address}</div>
        <br />
        {data && data.co_applicants && data.co_applicants.length > 0 && data.co_applicants.map((e, idx) => (
          <div key={idx}>
            <div>Name: {e.general_information.name}</div>
            <div>Address: {e.general_information.address}</div>
            <br />
          </div>
        ))}
      </td>
    </tr>
    <tr>
      <td className="font-weight-bold">Details of Loan</td>
      <td>Term loan of <b>Rs. {data && data.loan && data.loan.sanction_amount}/- (Rupees {convertToWords(data && data.loan && data.loan.sanction_amount)} only).</b></td>
    </tr>
    <tr>
      <td className="font-weight-bold">Loan Agreement</td>
      <td>Loan Agreement dated <b> {colender.includes("CIFL")  ?datehelper.displayDate(new Date(data?.loan?.agreement_stamp_date)):datehelper.displayDate(new Date(data.created))}</b> , entered by and between the Borrower/s and the Lenders.</td>
    </tr>
    <tr>
      <td className="font-weight-bold">Title Deeds</td>
      <td>{data && data.property_details && data.property_details.type}</td>
    </tr>
    <tr>
      <td className="font-weight-bold">Details of Encumbrances and charges</td>
      <td>&nbsp;</td>
    </tr>
    <tr>
      <td className="font-weight-bold">Other declarations, covenants and undertaking of Mortgagor/s</td>
      <td>&nbsp;</td>
    </tr>
  </tbody>
</table>

            </div>
            <div
                style={{
                    width: "290mm",
                    height: "410mm",
                    backgroundColor: "white",
                    padding: 40,
                    position: "relative",
                    fontSize: "20px"
                }}
            >
 {headerDiv(colender)}<br/><br/><br/>
                <span >
                    AND I/we make the aforesaid declaration, solemnly and sincerely believing the same to be true and<br />
                    knowing fully well that on the faith thereof and relying thereon that the Lender has agreed to provide<br />
                    the Loan to the Borrower/s.
                </span><br /><br />

                <span>Solemnly declared at {branches.branch_city} on the  {day ? <b>{dayWithSuffix}</b> : "_______"} day of {(month && Year)? <b>{month} - {Year} </b>:"__________________________"} by:</span><br /><br /><br />

                <div style={{display:"flex", justifyContent:"space-between"}}>
                    <span style={{width:"300px"}}>Name of the Borrower/s</span>
                    <span style={{width:"300px"}}>Signature/ Thumb Impression</span>
                </div><br/>
                <div style={{display:"flex", justifyContent:"space-between"}}>    
                    <span span style={{width:"300px"}}>{data && data.applicant && data.applicant.general_information && data.applicant.general_information.name}</span><br />
                    <span span style={{width:"200px"}}>________________________</span><br /><br/>
                 </div>   
                    {data && data.co_applicants && data.co_applicants.length > 0 && data.co_applicants.map((e, idx) => {
                        return <div style={{display:"flex", justifyContent:"space-between"}}>
                            <span span style={{width:"300px"}}>{e.general_information.name}</span><br />
                            <span span style={{width:"200px"}}>________________________</span><br /><br/>
                        </div>
                    })}
            </div>
        </>
    )
}

export default Moe