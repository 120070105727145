import React, { useState } from 'react';
import { safeCustodyService } from '../../../_services/safeCustodyService'; // Correct service import
import { employeeService } from "../../../_services";
import { Modal } from 'react-bootstrap';
import { Button } from "@material-ui/core";
import swal from 'sweetalert';


const SafeMyRequest = ({ updateAlerts }) => {
  const [fileNo, setFileNo] = useState('');
  const [requestType, setRequestType] = useState('');
  const [message, setMessage] = useState('');
  const user = employeeService.userValue;
  const [showMainModal, setShowMainModal] = useState(false);
  const contact = user?.contact;

  const handleOpenMainModal = () => setShowMainModal(true);
  const handleCloseMainModal = () => setShowMainModal(false);

  const handleRequest = async (e) => {
    e.preventDefault();

    // Validate fileNo and requestType
    if (!fileNo || !requestType) {
      setMessage('Please enter file number and select request type.');
      return;
    }

    try {
      const requestData = {
        file_no: fileNo,
        request_type: requestType,
        requested_by: user.emp_id, // Ensure this is correct
        emp_contact: user.contact,
        emp_name: user?.first_name + ' ' + user?.last_name
      };
      // console.log(requestData,"requestData")
      const role = user.role
      // console.log(role,"role")
      const response = await safeCustodyService.requestFile(requestData);

      if (response && response.success === true) {
        swal('Success', 'File saved successfully!', 'success')
          .then(() => {
            // Reload the page after the user clicks "OK"
            window.location.reload();
          });
    
        // Clear the form fields and close the modal
        setFileNo('');
        setRequestType('');
        setShowMainModal(false);
      }else if (response && response.success === false && response.message) {
        swal('Error', response.message || 'Failed to save the file.', 'error');
        setFileNo('');
        setRequestType('');
        setShowMainModal(false);
      }else {
        swal('Error', 'Failed to save the file. Please try again.!', 'error');
        setFileNo('');
        setRequestType('');
        setShowMainModal(false);
      }
    } catch (error) {
      console.error('Error saving file:', error);
      swal('Error',error, 'error');
      setFileNo('');
        setRequestType('');
        setShowMainModal(false);
    
    }
  };

  return (
    <div>
     <Button
  variant="outlined"
  onClick={[201131, 22058, 20962].includes(user.emp_id) ? undefined : handleOpenMainModal}
  style={{
    backgroundColor: '#1565c0',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    padding: '10px 20px',
  }}
>
  Request File
</Button>

      {/* Request Modal */}
      <Modal
        show={showMainModal}
        onHide={handleCloseMainModal}
        style={{ marginTop: "3rem" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Request File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleRequest}
            style={{
              maxWidth: '400px',
              margin: '0 auto',
              padding: '20px',
              border: '1px solid #ddd',
              borderRadius: '8px',
              backgroundColor: '#ffffff',
            }}
          >
            <div style={{ marginBottom: '20px' }}>
              <label htmlFor="fileNo" style={{ display: 'block', color: '#333' }}>File Number:</label>
              <input
                type="text"
                id="fileNo"
                value={fileNo}
                onChange={(e) => setFileNo(e.target.value)}
                style={{
                  width: '100%',
                  padding: '10px',
                  marginTop: '5px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                }}
                required
              />
            </div>

            <div style={{ marginBottom: '20px' }}>
              <label htmlFor="requestType" style={{ display: 'block', color: '#333' }}>Request Type:</label>
              <select
                value={requestType}
                onChange={(e) => setRequestType(e.target.value)}
                style={{
                  width: '100%',
                  padding: '10px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                }}
                required
              >
                <option value="" disabled>Please Select Request Type</option>
                <option value="File Check-In">File Check-In</option>
                <option value="File Check-Out">File Check-Out</option>
              </select>
            </div>

            <button
              type="submit"
              style={{
                width: '100%',
                padding: '12px',
                backgroundColor: '#4CAF50',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              Submit Request
            </button>
          </form>

          {message && (
            <p
              style={{
                textAlign: 'center',
                marginTop: '20px',
                color: message.includes('success') ? '#4CAF50' : '#D32F2F',
              }}
            >
              {message}
            </p>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SafeMyRequest;
