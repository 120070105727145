import React from "react";
import { Route, Switch } from "react-router-dom";
import ColendingDashboard from "./ColendingDashboard";
import ColendingPaymentList from "./ColendingSearch";
import CommonColendingList from "./CommonColendingList";
import MyColendingTasksList from "./MyColendingTasks";
import ColenderAccessTokenList from "./ColenderAccessTokenList";
function ColendingIndex({ match }) {
  const { path } = match;

  return (
    <Switch>
      <Route path={`${path}/loans/:colender`} component={CommonColendingList} />
      <Route path={`${path}/payments`} component={ColendingPaymentList} />
      <Route path={`${path}/mytasks/:colender`} component={MyColendingTasksList} />
      <Route path={`${path}/dashboard`} component={ColendingDashboard} />   
      <Route path={`${path}/colenderlists`} component={ColenderAccessTokenList} />     
    </Switch>
  );
}

export { ColendingIndex };
