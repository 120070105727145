import { fetchWrapper } from "../_helpers/fetch-wrapper";
const apiUrl = process.env.REACT_APP_API_URL;
const baseUrl = `${apiUrl}/bulkuploaddata`;
export const bulkUploadService = {
    bulkUploadingDataSheet,
    rpsUploadingDataSheet,
    reconciliationUploadingDataSheet,
    insuranceUploadingDataSheet,
    collectionRemoval,
    callingTeamCaseAssignment
    
}


async function bulkUploadingDataSheet({link }){
    
    // console.log("params data is",{link,columnIndexes,upload_key})
    return fetchWrapper.post(`${baseUrl}/bulk_upload_files`, {link});
}
async function collectionRemoval(params){
    
    // console.log("params data is",{link,columnIndexes,upload_key})
    return fetchWrapper.post(`${baseUrl}/collectionremoval`, params);
}
async function rpsUploadingDataSheet({link }){
    
    // console.log("params data is",{link,columnIndexes,upload_key})
    return fetchWrapper.post(`${baseUrl}/bulk_update_rps`, {link});
}
async function reconciliationUploadingDataSheet(link){
    console.log(link,"linklinklinklinklinklink")
    return fetchWrapper.post(`${baseUrl}/bulk_nach_reconciliation`, link);
}
async function insuranceUploadingDataSheet({link }){
    
    // console.log("params data is",{link,columnIndexes,upload_key})
    return fetchWrapper.post(`${baseUrl}/sbl_insurance_bulk_upload`, {link});
}
async function callingTeamCaseAssignment({link }){
    
    // console.log("params data is",{link,columnIndexes,upload_key})
    return fetchWrapper.post(`${baseUrl}/calling_case_assignment`, {link});
}

