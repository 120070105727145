import React, { useEffect, useState } from 'react';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Typography, Box, CircularProgress ,TablePagination} from '@material-ui/core';
import { receiptService } from '../../../_services/receipt.service';
import datehelper from "../../../_helpers/datehelper";

function CashPayments({ cust }) {
  console.log('collection tab is', cust);
  const [receiptData, setReceiptData] = useState([]);
  const [spinner, setSpinner] = useState(true);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
  
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
  async function fetchData() {
    try {
      setSpinner(true);
      const response = await receiptService.getAllCashPaymentOfTheLoan({
        loan_account: cust.application_id,
      });
      console.log('response is', response);
      setReceiptData(response || []);
    } catch (err) {
      console.log('Error fetching payment data:', err);
    } finally {
      setSpinner(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [cust]); // Ensures fetchData is triggered when `cust` changes

  return (
    <div>
      <Typography variant="h5" gutterBottom style={{ marginBottom: '20px', color: '#2949B9' }}>
        Cash Payments
      </Typography>

      <TableContainer component={Paper} style={{ maxHeight: '500px' }}>
        <Table  >
          <TableHead style={{ backgroundColor: '#525155' }}>
            <TableRow  >
              <TableCell  style={{textAlign:'center' , color:'white'}}>#</TableCell>
              <TableCell  style={{textAlign:'center' , color:'white'}}>Date</TableCell>
              <TableCell  style={{textAlign:'center' , color:'white'}}>Loan Number</TableCell>
              <TableCell  style={{textAlign:'center' , color:'white'}}>Payment</TableCell>
              <TableCell  style={{textAlign:'center' , color:'white'}}>Customer Name</TableCell>
              <TableCell  style={{textAlign:'center' , color:'white'}}>Employee Name</TableCell>
              <TableCell  style={{textAlign:'center' , color:'white'}}>Receipt Number</TableCell>
              <TableCell  style={{textAlign:'center' , color:'white'}}>Invoice Number</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {spinner ? (
              <TableRow>
                <TableCell colSpan={8} style={{ textAlign: 'center', padding: '40px' }}>
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress />
                  </Box>
                </TableCell>
              </TableRow>
            ) : receiptData.length > 0 ? (
              receiptData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((receipt, index) => (
                <TableRow key={receipt._id}>
                  <TableCell style={{textAlign:'center'}}>{index + 1}</TableCell>
                  <TableCell style={{textAlign:'center'}}>{receipt.date && datehelper.displayDate(new Date(receipt.date))}</TableCell>
                  <TableCell style={{textAlign:'center'}}>{receipt.loan_no}</TableCell>
                  <TableCell style={{textAlign:'center'}}>₹{receipt.payment}</TableCell>
                  <TableCell style={{textAlign:'center'}}>{receipt.cust_name}</TableCell>
                  <TableCell style={{textAlign:'center'}}>{receipt.emp_name}</TableCell>
                  <TableCell style={{textAlign:'center'}}>{receipt.receipt_no}</TableCell>
                  <TableCell style={{textAlign:'center'}}>{receipt.invoice_no}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} style={{ textAlign: 'center' }}>
                  No cash payments available.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {receiptData ? (
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={receiptData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            ) : (
              <div />
            )}
    </div>
  );
}

export default CashPayments;
