import React, { useEffect } from "react";
import { Button, Modal, Typography, Box, MenuItem, Select, Checkbox, FormControlLabel } from "@material-ui/core";
import roleChecker from "../../_helpers/rolechecker";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { reportsService } from "../../_services/reports.service";
import { Input } from "antd";
import { employeeService } from "../../_services";
import { useParams } from 'react-router-dom';

const { Search } = Input;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  height: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'scroll',
};

export default function MyTasksList() {
  const user = employeeService.userValue;
  const [applicants, setApplicants] = React.useState([]);
  const [spinner, setSpinner] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [searchFilter, setSearchFilter] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [paymentData, setPaymentData] = React.useState(null);
  const [branchFilter, setBranchFilter] = React.useState("all");
  const [discontinued, setDiscontinued] = React.useState(false); // Initial state for discontinued
  const { id } = useParams();

  useEffect(() => {
    if (id === 'true') {
      setDiscontinued(true); // Set discontinued to true if id is 'true'
    } else {
      setDiscontinued(false); // Otherwise, set discontinued to false
    }
  }, [id]); // Run this effect when the `id` changes

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let branchId = '';
  React.useEffect(() => {
    let isMounted = true;

    const getBalanceInsufficientenachcount = () => {
      if (roleChecker.isASMLevelAccess(user.role) || ["ACM","ACCM","RSM","RCM","RCCM","ZCCM"].includes(user.role) || roleChecker.isZSMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isCollectionManager(user.role) || ["BM"].includes(user.role) || ["BCM"].includes(user.role)) {
        if (roleChecker.isASMLevelAccess(user.role) || ["ACM","ACCM"].includes(user.role)) {
          branchId = { area_id: user.branch_id }
        }
        else if (roleChecker.isZSMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role)|| ["ZCCM"].includes(user.role) ) {
          branchId = { zone_id: user.branch_id }
        }
        else if ( ["RSM","RCM","RCCM"].includes(user.role)) {
          branchId = { region_id: user.branch_id }
        } else if (roleChecker.isBMLevelAccess(user.role)  || roleChecker.isBCMLevelAccess(user.role)) {
          branchId = { branch_id: user.branch_id }
        }
        else {
          console.log('User role ---->>> ')
        }
        reportsService
          .getNotregisterdata({ role: user.role, enach_status: "Balance Insufficient", ...branchId })
          .then((response) => {
            setApplicants(response)
          })
          .catch((err) => {
            console.log(err);
          })
      }  else if ((["CEO","CFO","Admin","HOCL","PIVG"].includes(user.role)||roleChecker.isCollectionManager(user.role))){
        reportsService
          .getNotregisterdata({ enach_status: "Balance Insufficient" })
          .then((response) => {
            setApplicants(response);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      else {
        console.log("data not found")
      }
    };
    getBalanceInsufficientenachcount();

    return () => {
      isMounted = false;
    };
  }, [user]);

  // Filter applicants based on the search input
  const filteredApplicants = applicants?.filter((applicant) => {
    const matchesSearch = applicant?.application_id?.toLowerCase()?.includes(searchFilter?.toLowerCase());
    const matchesBranch = branchFilter === "all" || applicant?.branch_id === branchFilter;
    const matchesDiscontinued = discontinued ? applicant?.discontinued === true : true;
    return matchesSearch && matchesBranch && matchesDiscontinued;
  });

  const handleViewDetailsClick = async (referenceCode) => {
    try {
      const response = await reportsService.getenachcollectpayments({ reference_code: referenceCode });
      setPaymentData(response);
      setModalVisible(true);
    } catch (error) {
      console.error("Error fetching ENACH payments:", error);
    }
  };

  return (
    <div>
      <h1>Enach Balance Insufficient</h1>

      <div className="d-flex justify-content-between">
        <Search
          onChange={(e) => setSearchFilter(e.target.value)}
          placeholder="Search Only Loan No."
          enterButton
          style={{
            position: "sticky",
            top: "0",
            left: "0",
            width: "200px",
            marginTop: "2vh",
          }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={discontinued}
              onChange={(e) => setDiscontinued(e.target.checked)}
              color="primary"
            />
          }
          label="Discontinued Only"
        />
        <Select
          value={branchFilter}
          onChange={(e) => setBranchFilter(e.target.value)}
          displayEmpty
          style={{ width: "200px", marginLeft: "1rem" }}
        >
          <MenuItem value="all">Select All Branch</MenuItem>
          {[...new Set(applicants.map((applicant) => applicant?.branch_id))].map((branchId, idx) => {
            const branch = applicants.find((applicant) => applicant.branch_id === branchId);
            return (
              <MenuItem key={idx} value={branchId}>
                {branch?.branch_name} ({branchId})
              </MenuItem>
            );
          })}
        </Select>
      </div>
      <br />

      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th style={{ width: "12%" }}>Applicant Name</Th>
              <Th style={{ width: "12%" }}>Loan NO.</Th>
              <Th style={{ width: "12%" }}>Branch Name & ID</Th>
              <Th style={{ width: "12%" }}>RO</Th>
              <Th style={{ width: "12%" }}>Enach Comment</Th>
              <Th style={{ width: "10%" }}>Payment History</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredApplicants.length > 0 ? (
              filteredApplicants
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((applicant, idx) => (
                  <Tr key={idx}>
                    <Td>{applicant?.applicant_name}</Td>
                    <Td>{applicant?.application_id}</Td>
                    <Td>{applicant?.branch_name} ({applicant?.branch_id})</Td>
                    <Td>{applicant?.ro_name}</Td>
                    <Td>{applicant?.enach_comment}</Td>
                    <Td>
                      <Button
                        onClick={() => handleViewDetailsClick(applicant?.urmn_no)}
                        size="small"
                        color="primary"
                      >
                        View History
                      </Button>
                    </Td>
                  </Tr>
                ))
            ) : (
              <Tr>
                <Td colSpan="6" className="text-center">
                  {searchFilter ?  (
                                      <Typography variant="h6" color="textSecondary">
                                        Data not found for "{searchFilter}"
                                      </Typography>
                                    ) : spinner ? (
                    <span className="spinner-border spinner-border-lg align-center"></span>
                  ) : (
                    "No applicants found"
                  )}
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>

      {filteredApplicants.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredApplicants.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}

      <Modal
        open={modalVisible}
        onClose={() => setModalVisible(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            gutterBottom
          >
            Payment History
          </Typography>

          {paymentData && paymentData.length > 0 ? (
            <Table>
              <Thead>
                <Tr>
                  <Th><strong>S No.</strong></Th>
                  <Th><strong>Amount</strong></Th>
                  <Th><strong>Date</strong></Th>
                  <Th><strong>Status</strong></Th>
                  <Th><strong>Collection Comment</strong></Th>
                </Tr>
              </Thead>
              <Tbody>
                {paymentData.slice().reverse().map((item, index) => (
                  <Tr key={item._id}>
                    <Td>{index + 1}</Td>
                    <Td>{item.amount}</Td>
                    <Td>{new Date(item.collection_date).toLocaleDateString()}</Td>
                    <Td>
                      <span
                        style={{
                          color: item.collection_status === 'Success' ? 'green' : 'red',
                          fontWeight: 'bold',
                        }}
                      >
                        {item.collection_status}
                      </span>
                    </Td>
                    <Td>{item.collection_comment}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          ) : (
            <Typography variant="body1" color="text.secondary">
              No payment data available.
            </Typography>
          )}
        </Box>
      </Modal>
    </div>
  );
}
