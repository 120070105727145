import React, { useEffect, useState } from 'react'
import { useReactToPrint } from "react-to-print";
import { loanService } from '../../../_services';
import datehelper from "../../../_helpers/datehelper";
import capitalfinance from "../../../assets/capitalfinance.png";
import logo from "../../../assets/kaabil.png";
const Indemnity = (props) => {

    const data = props.data
    const branches = props.branches
    // const IndemnityRef = React.useRef();


    // const handleSubmissionPrint = useReactToPrint({
    //     content: () => IndemnityRef.current,
    //     pageStyle: "print",
    // });

    const one = ["", "One ", "Two ", "Three ", "Four ", "Five ", "Six ", "Seven ", "Eight ", "Nine ", "Ten ", "Eleven ", "Twelve ", "Thirteen ", "Fourteen ", "Fifteen ",
        "Sixteen ", "Seventeen ", "Eighteen ", "Nineteen "];

    var ten = ["", "", "Twenty ", "Thirty ", "Forty ", "Fifty ", "Sixty ", "Seventy ", "Eighty ", "Ninety "];

    function numToWords(n, s) {
        let str = "";
        // if n is more than 19, divide it
        if (n > 19) {
            str += ten[parseInt(n / 10)] + one[n % 10];
        }
        else {
            str += one[n];
        }
        if (n != 0) {
            str += s;
        }

        return str;
    }

    function convertToWords(n) {
        // stores word representation of given number n
        let out = "";

        // handles digits at ten millions and hundred
        // millions places (if any)
        out += numToWords(parseInt(n / 10000000),
            "Crore ");

        // handles digits at hundred thousands and one
        // millions places (if any)
        out += numToWords(parseInt((n / 100000) % 100),
            "Lakh ");

        // handles digits at thousands and tens thousands
        // places (if any)
        out += numToWords(parseInt((n / 1000) % 100),
            "Thousand ");

        // handles digit at hundreds places (if any)
        out += numToWords(parseInt((n / 100) % 10),
            "Hundred ");

        if (n > 100 && n % 100 > 0) {
            out += "and ";
        }

        // handles digits at ones and tens places (if any)
        out += numToWords(parseInt(n % 100), "");

        return out;
    }


    const headerDiv = () => (
        <div>
          <img src={logo} style={{ height: "100px" }} alt="KFPL Logo" />
          
            <img
              src={capitalfinance}
              style={{
                height: "100px",
                position: "absolute",
                top: "2",
                right: "0",
                marginRight: "3rem",
              }}
              alt="Capital Finance Logo"
            />
          
          
        </div>
      );

    return (
        <>
            {/* <div style={{ textAlign: "center" }}>
                <button onClick={handleSubmissionPrint}>Print Letter</button>
            </div> */}

            <div
                style={{
                    width: "290mm",
                    height: "410mm",
                    backgroundColor: "white",
                    padding: 40,
                    position: "relative",
                    fontSize: "20px"
                }}
            >{headerDiv()}<br/><br/>
                <span style={{ marginLeft: "40%" }}><b>INDEMNITY</b></span><br /><br />

                <div style={{ textAlign: "justify" }}>

                    <span>THIS INDEMNITY (“Indemnity”) is executed at {branches.branch_city} on this day _______ of _____________
                        20____, by- </span>
                    <div>
                        <span>{data && data.applicant && data.applicant.general_information && data.applicant.general_information.name}</span><br /><br />
                        {data && data.co_applicants && data.co_applicants.length > 0 && data.co_applicants.map((e, idx) => {
                            return <div>
                                <span>{e.general_information.name}</span><br /><br />
                            </div>
                        })}
                    </div>
                    (hereinafter referred to as the <b>"Borrower/s"</b> which expression shall, unless repugnant to or inconsistent with
                    the context thereof, mean and include the heirs, successors, executors, administrators, legal
                    representatives and assigns (as applicable), of each of the Borrower/s), in favor of;<br /><br />

                    <span>
                        1) <b>KAABIL FINANCE PRIVATE LIMITED,</b> a company within the meaning of the Companies Act 2013,
                        having corporate identity number U65929RJ2017PTC058138 and its registered office at Kaabil Towers, Plot No. 1, Scheme No. 1, Income Tax Colony, Model Town, Jagatpura, Jaipur Rajasthan 302017, (hereinafter referred to as <b>“Lender 1”</b>, which
                        expression shall, unless repugnant to the context or meaning thereof, be deemed to mean and include
                        its successors and assigns) and,</span><br />

                    <span>
                    2) <b>CAPITAL INDIA Finance LIMITED</b>, a company within the meaning of the Companies Act, 2013
                        and having its registered office at 2nd Floor, DLF Centre, Sansad Marg, New Delhi, Delhi, 110001
                        (hereinafter referred to as the <b>"Lender 2"</b> which expression shall, unless repugnant to or inconsistent
                        with the context thereof, mean and include its successors and permitted assigns).
                    </span><br /><br />

                    <span>(Lender 1 and Lender 2 shall hereinafter be collectively referred to as the Lenders and independently as
                        Lender.)</span><br /><br />

                    <span><b>Whereas:</b></span><br />

                    <span>The Borrower/s propose to avail or availed a term loan facility of <b>Rs. {data && data.loan && data.loan.sanction_amount}/-</b> (Rupees
                        {convertToWords(data && data.loan && data.loan.sanction_amount)}only)
                        (“Loan”) from the Lenders, on the terms and conditions of loan agreement dated <b>{datehelper.displayDate(new Date(data.loan?.agreement_stamp_date))}</b>
                        executed by and between the Borrower/s and the Lenders and other transaction documents executed in
                        relation to the Loan (hereinafter collectively referred to as <b>“Transaction Documents”</b>).</span><br /><br />

                    <span>
                        To secure the obligations of the Borrower/s in respect of the Loan, the Borrower/s have agreed to create a first
                        ranking pari passu charge , either by way of equitable mortgage or registered mortgage, over the right, title and
                        interest of the Borrower/s, in respect of the assets and properties, as described in the Schedule below
                        (hereinafter referred to as <b>“Scheduled Properties”</b>).<br />
                    </span><br />

                    <span>Subject to the terms and conditions of the Transaction Documents, and the creation and the perfection of the
                        mortgage on the Scheduled Properties, the Lenders have agreed to disburse the Loan.</span><br /><br />
                </div>
            </div>
            <div
                style={{
                    width: "290mm",
                    height: "410mm",
                    backgroundColor: "white",
                    padding: 40,
                    position: "relative",
                    fontSize: "20px"
                }}
            >{headerDiv()}<br/><br/>
                <div style={{ textAlign: "justify" }}>
                    <span>However, any one or more of the original title deeds or documents or any part thereof, pertaining to the
                        Scheduled Properties (as described in the Schedule below and hereinafter referred to as “Said Documents”)
                        are torn, misplaced, lost, mutilated, inadequately stamped, un-registered or not available for depositing with the
                        Lenders for creation and perfection of the mortgage, as required under the provisions of the Transaction
                        Documents and applicable laws. The Borrower/s have requested the Lenders, to accept Said Documents from
                        the Borrower/s, in order to create charge thereon and disburse the Loan.</span><br /><br />

                    <span>In consideration of the Lenders agreeing to disburse the Loan to the Borrower/s, the Borrower/s hereby, agree
                        and undertake to indemnify, and keep the Lenders and its officers and employees fully indemnified and
                        harmless, at all times to the fullest extent, against all claims, costs, expenses, charges, losses, liabilities etc.,
                        incurred or to be incurred to them, on account of or in connection with all or any of the following events:
                    </span><br /><br />

                    <span>1) Said Documents are lost or misplaced or mutilated or are in torn condition and/or are not available for
                        depositing with the Lender for creating the mortgage on the Scheduled Properties.</span><br />
                    <span>
                        2) Said Documents or any one or more of the Financing Documents are not duly stamped or registered as
                        per the applicable laws and/or any demand or claim is raised by any authority or court, for the payment
                        of deficit stamp duty with or without, any penalty or charges levied thereon, with respect to the Said
                        Documents and the Transaction Documents.<br />
                    </span>

                    <span style={{ marginLeft: "40%" }}><b>Schedule</b></span><br /><br />

                    <span>Part - 1: Details of the Schedule Properties</span><br /><br />
                    <span>{data && data.property_details && data.property_details.address}</span><br /><br />

                    <span>
                        Part – 2: Details of Said Documents
                    </span><br /><br />
                    <span>{"uyt"}</span><br /><br />

                    <span>IN WITNESS WHEREOF, the Borrower/s have executed this indemnity on the day, month and year, herein
                        above written.</span><br /><br />

                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span style={{ width: "300px" }}>Name of the Borrower/s</span>
                        <span style={{ width: "300px" }}>Signature/ Thumb Impression</span>
                    </div><br />
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span span style={{ width: "300px" }}>{data && data.applicant && data.applicant.general_information && data.applicant.general_information.name}</span><br />
                        <span span style={{ width: "200px" }}>________________________</span><br /><br />
                    </div>
                    {data && data.co_applicants && data.co_applicants.length > 0 && data.co_applicants.map((e, idx) => {
                        return <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span span style={{ width: "300px" }}>{e.general_information.name}</span><br />
                            <span span style={{ width: "200px" }}>________________________</span><br /><br />
                        </div>
                    })}<br />
                    <span>WITNESSES:</span><br />
                    <span>________________________</span><br />
                    <span>________________________</span>
                </div>
            </div>

        </>
    )
}

export default Indemnity