import React, { useState } from 'react';
import {
  CheckCircle as CheckCircle2
} from '@material-ui/icons';
import { safeCustodyService } from '../../../_services/safeCustodyService';
import { Modal, Button } from 'react-bootstrap';
import swal from 'sweetalert';
import { scanDocumentService } from '../../../_services/scanDocumentService';
function SafeNewFile() {
  const [fileNo, setFileNo] = useState('');
  const [almeraNo, setAlmeraNo] = useState(''); // Almera Number Dropdown value
  const [applicantName, setApplicantName] = useState('');
  const [branchName, setBranchName] = useState('');
  const [comment, setComments] = useState('');
  const [creationMonth, setCreationMonth] = useState(''); // For Month selection
  const [creationYear, setCreationYear] = useState(''); // For Year selection
  const [loanStage, setLoanStage] = useState(''); // Loan Stage Dropdown value
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [srNo, setSrNo] = useState('');
  const [isLoanVerified, setIsLoanVerified] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  // Generate months in 'MMM' format
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  // Generate years from 2000 to 2050
  const years = [];
for (let i = 2000; i <= 2050; i++) {
  years.push(i.toString().slice(-2)); // Push the last two digits of the year
}


  // Generate Almera Numbers A1 to A20
  const almeraNumbers = [];
  for (let i = 1; i <= 30; i++) {
    almeraNumbers.push(`KFPL${i}`);
  }

const handleVerifyLoan = async () => {
  if (fileNo.trim() === '') {
    swal({
      title: 'Input Required',
      text: 'Please enter a loan number.',
      icon: 'warning',
      button: 'OK',
    });
    return;
  }

  try {
    const requestData = { file_no: fileNo.trim() }; // Use trimmed `fileNo`
    console.log('Sending file_no:', requestData.file_no);

    // Replace with the correct backend URL
    const response = await scanDocumentService.searchLoan(requestData);

    if (response.error) {
      swal({
        title: 'Error',
        text: response.message || 'Loan number is incorrect.',
        icon: 'error',
        button: 'OK',
      });
      setIsLoanVerified(false);
      return;
    }

    if (response.data) {
      setIsLoanVerified(true);
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 3000);
      swal({
        title: 'Loan Verified',
        text: 'Loan number verified successfully.',
        icon: 'success',
        button: 'OK',
      });
    } else {
      setIsLoanVerified(false);
      swal({
        title: 'Verification Failed',
        text: 'Loan number not found.',
        icon: 'error',
        button: 'OK',
      });
    }
  } catch (error) {
    console.error('Error verifying loan number:', error);
    swal({
      title: 'Error',
      text: 'Unable to connect to the server. Please try again later.',
      icon: 'error',
      button: 'OK',
    });
  }
};

  // Loan Stage options
  const loanStages = ['Closed', 'Rejected', 'Collection', 'Disbursal'];

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!creationMonth || !creationYear) {
      swal('Error', 'Please select both month and year for Date of Creation.', 'error');
      return;
    }

    const creationDate = `${creationMonth}-${creationYear}`; // Combine Month and Year
    console.log('Creation Date:', creationDate);
    const newFileData = {
      sr_no: srNo.trim(),
      file_no: fileNo.trim(),
      date: creationDate, // Combined month and year (e.g., 'Jan-2024')
      loan_stage: loanStage, // Loan Stage
      applicant_name: applicantName.trim(),
      branch_name: branchName.trim(),
      almirah_no: almeraNo.trim(),
      comments:comment,
    };

    try {
      const response = await safeCustodyService.createFile(newFileData);

      if (response && response.success === true) {
        swal('Success', 'File saved successfully!', 'success');
        // Clear the form fields
        setSrNo('');
        setFileNo('');
        setAlmeraNo('');
        setApplicantName('');
        setBranchName('');
        setComments('');
        setCreationMonth('');
        setCreationYear('');
        setLoanStage('');
        setShowModal(false); // Close modal on success
      } else {
        swal('Error', response.message || 'Failed to save the file.', 'error');
      }
    } catch (error) {
      console.error('Error saving file:', error);
      swal('Error', 'An error occurred while saving the file.', 'error');
    }
  };

  return (
    <div style={{ padding: '0px 20px', fontFamily: 'Arial, sans-serif' }}>
      {/* Add Button */}
      <Button
        variant="outlined"
        onClick={() => setShowModal(true)} // Open modal
        style={{
          backgroundColor: '#4CAF50',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          padding: '10px 20px',
        }}
      >
        Add New File
      </Button>

      {/* Success or Failure Message */}
      {message && (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: message.type === 'success' ? '#e8f5e9' : '#ffebee',
            border: `2px solid ${message.type === 'success' ? '#4CAF50' : '#D32F2F'}`,
            color: message.type === 'success' ? '#4CAF50' : '#D32F2F',
            padding: '20px',
            borderRadius: '5px',
            zIndex: 9999,
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            fontSize: '16px',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          {message.text}
        </div>
      )}

      {/* Modal for Form Submission */}
      <Modal show={showModal} onHide={() => setShowModal(false)} style={{ marginTop: '50px' }}>
        <Modal.Header closeButton>
          <Modal.Title>Add New File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit} style={{ maxWidth: '500px', margin: '0 auto' }}>
            <div><label style={{ display: 'block', marginBottom: '5px' }}>File Number:</label></div>
            <div style={{ marginBottom: '15px', display: 'flex'}}>
              <input
                type="text"
                value={fileNo}
                onChange={(e) => setFileNo(e.target.value)}
                required
                style={{
                  width: '100%',
                  padding: '10px',
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                }}
              />
                <button
                  onClick={handleVerifyLoan}
                  style={{
                    padding: '8px 16px',
                    backgroundColor: '#2563eb',
                    color: 'white',
                    border: 'none',
                    borderRadius: '6px',
                  }}
                >
                  Verify
                </button>
            </div>

            {showSuccess && (
              <div
                style={{
                  padding: '12px',
                  backgroundColor: '#dcfce7',
                  borderRadius: '6px',
                  marginBottom: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px'
                }}
              >
                <CheckCircle2 style={{ color: '#166534', fontSize: 20 }} />
                <span style={{ color: '#166534' }}>Loan number verified successfully</span>
              </div>
            )}

        {isLoanVerified && (
                      <>
            {/* Loan Stage Dropdown */}
            <div style={{ marginBottom: '15px' }}>
              <label style={{ display: 'block', marginBottom: '5px' }}>Loan Stage:</label>
              <select
                value={loanStage}
                onChange={(e) => setLoanStage(e.target.value)}
                required
                style={{
                  width: '100%',
                  padding: '10px',
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                }}
              >
                <option value="">Select Loan Stage</option>
                {loanStages.map((stage, index) => (
                  <option key={index} value={stage}>
                    {stage}
                  </option>
                ))}
              </select>
            </div>

            <div style={{ marginBottom: '15px' }}>
              <label style={{ display: 'block', marginBottom: '5px' }}>Almera Number:</label>
              <select
                value={almeraNo}
                onChange={(e) => setAlmeraNo(e.target.value)}
                required
                style={{
                  width: '100%',
                  padding: '10px',
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                }}
              >
                <option value="">Select Almera Number</option>
                {almeraNumbers.map((number, index) => (
                  <option key={index} value={number}>
                    {number}
                  </option>
                ))}
              </select>
            </div>

            <div style={{ marginBottom: '15px' }}>
              <label style={{ display: 'block', marginBottom: '5px' }}>Serial Number:</label>
              <input
                type="text"
                value={srNo}
                onChange={(e) => setSrNo(e.target.value)}
                required
                style={{
                  width: '100%',
                  padding: '10px',
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                }}
              />
            </div>

            <div style={{ marginBottom: '15px' }}>
              <label style={{ display: 'block', marginBottom: '5px' }}>Applicant Name:</label>
              <input
                type="text"
                value={applicantName}
                onChange={(e) => setApplicantName(e.target.value)}
                required
                style={{
                  width: '100%',
                  padding: '10px',
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                }}
              />
            </div>

            <div style={{ marginBottom: '15px' }}>
              <label style={{ display: 'block', marginBottom: '5px' }}>Branch Name:</label>
              <input
                type="text"
                value={branchName}
                onChange={(e) => setBranchName(e.target.value)}
                required
                style={{
                  width: '100%',
                  padding: '10px',
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                }}
              />
            </div>

            {/* Month and Year Dropdowns */}
            <div style={{ marginBottom: '15px' }}>
              <label style={{ display: 'block', marginBottom: '5px' }}>File Creation Date:</label>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <select
                  value={creationMonth}
                  onChange={(e) => setCreationMonth(e.target.value)}
                  required
                  style={{
                    width: '48%',
                    padding: '10px',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                  }}
                >
                  <option value="">Select Month</option>
                  {months.map((month, index) => (
                    <option key={index} value={month}>
                      {month}
                    </option>
                  ))}
                </select>

                <select
                  value={creationYear}
                  onChange={(e) => setCreationYear(e.target.value)}
                  required
                  style={{
                    width: '48%',
                    padding: '10px',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                  }}
                >
                  <option value="">Select Year</option>
                  {years.map((year, index) => (
                    <option key={index} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div style={{ marginBottom: '15px' }}>
              <label style={{ display: 'block', marginBottom: '5px' }}>Comments:</label>
              <input
                type="text"
                value={comment}
                onChange={(e) => setComments(e.target.value)}
                style={{
                  width: '100%',
                  padding: '10px',
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                }}
              />
            </div>
            

            <button
              type="submit"
              style={{
                width: '100%',
                padding: '12px',
                backgroundColor: '#4CAF50',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              Save File
            </button>
            </>
            )}
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default SafeNewFile;
