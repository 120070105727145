import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Typography,
  Box,
  CircularProgress,
  TablePagination,
  Button,
  Grid,
  makeStyles
} from "@material-ui/core";

import LocationOnIcon from '@material-ui/icons/LocationOn';
import ImageIcon from '@material-ui/icons/Image';
import { employeeService } from "../../../_services";
import { fieldVisitService } from "../../../_services/fieldvisit.service";
import datehelper from "../../../_helpers/datehelper";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 500,
  },
  media: {
    width: 100,
    height: 100,
  },
  root: {
    display: "flex",
    flexGrow: 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    marginTop: "11%",
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}));

export default function FieldVisitTab({ cust }) {
  const classes = useStyles();
  const [fieldVisits, setFieldVisits] = React.useState([]);
  const [spinner, setSpinner] = React.useState(true);
  const user = employeeService.userValue;

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
    async function retrieveData() {
      setSpinner(true);
      fieldVisitService.search({ "search_data": { 'application_id': cust.application_id } })
        .then(res => {
          setFieldVisits(res?.reverse());
          console.log(res);
          setSpinner(false);
        })
        .catch((err) => {
          console.log(err);
          setSpinner(false);
        });
    }
    retrieveData();
  }, []);

  function getImageDiv(fmg) {
    return (
      <Grid key={fmg.uri} item>
        <Button
          component="a"
          target="_blank"
          href={fmg.uri}
          size="small"
          color="primary"
          style={{ fontSize: "12px", padding: "1rem" }}
        >
          <ImageIcon />
        </Button>
      </Grid>
    );
  }

  function getLocationDiv(fmg) {
    return (
      <Grid key={fmg.uri} item>
        <Button
          component="a"
          target="_blank"
          href={`https://maps.google.com/?q=${fmg.location.lat},${fmg.location.long}`}
          size="small"
          color="primary"
          style={{ fontSize: "12px", padding: "1rem" }}
        >
          <LocationOnIcon />
        </Button>
      </Grid>
    );
  }

  console.log("fieldvisits", fieldVisits);

  return (
    <Paper
       
    >
      <br />
      <TableContainer>
        <Table>
          <TableHead style={{ backgroundColor: '#525155' }}>
            <TableRow>
              <TableCell  style={{textAlign:'center' , color:'white'}}>Employee</TableCell>
              <TableCell  style={{textAlign:'center' , color:'white'}}>Branch</TableCell>
              <TableCell  style={{textAlign:'center' , color:'white'}}>Loan Number</TableCell>
              <TableCell  style={{textAlign:'center' , color:'white'}}>Visit Date</TableCell>
              <TableCell  style={{textAlign:'center' , color:'white'}}>Payment Collected</TableCell>
              <TableCell  style={{textAlign:'center' , color:'white'}}>Next Visit</TableCell>
              <TableCell  style={{textAlign:'center' , color:'white'}}>Visit Comments</TableCell>
              <TableCell  style={{textAlign:'center' , color:'white'}}>Property Visited?</TableCell>
              <TableCell  style={{textAlign:'center' , color:'white'}}>Selfie</TableCell>
              <TableCell  style={{textAlign:'center' , color:'white'}}>Visit Location</TableCell>
              <TableCell  style={{textAlign:'center' , color:'white'}}>Visit Proof</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {spinner ? (
              <TableRow>
                <TableCell colSpan="11" style={{ textAlign: "center", padding: "20px" }}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {fieldVisits.length > 0 ? (
                  fieldVisits.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((fvi, idx) => (
                    <TableRow key={fvi._id}>
                      <TableCell style={{ padding: "5px", textAlign: "center" }}>
                        {fvi?.employee?.length > 0 && (fvi.employee[0].first_name + " " + fvi.employee[0].last_name)}{" "}
                        {fvi.emp_id && (
                          <a
                            target="_blank"
                            href={`/home/collectiontrackings/${fvi.emp_id}/${new Date(fvi.visit_date).toISOString().split('T')[0]}`}
                          >
                            tracking
                          </a>
                        )}
                      </TableCell>
                      <TableCell style={{ padding: "5px", textAlign: "center" }}>
                        {fvi?.employee?.length > 0 && fvi.employee[0].branch_id}
                      </TableCell>
                      <TableCell style={{ padding: "5px", textAlign: "center" }}>{fvi?.application_id}</TableCell>
                      <TableCell style={{ padding: "5px", textAlign: "center" }}>
                        {datehelper.displayDateTime(new Date(fvi.visit_date))}
                      </TableCell>
                      <TableCell style={{ padding: "5px", textAlign: "center" }}>{fvi.payment_collect_status}</TableCell>
                      <TableCell style={{ padding: "5px", textAlign: "center" }}>
                        {fvi.payment_collect_status === "No" && (
                          <span>{datehelper.displayDate(new Date(fvi.followup_date))}</span>
                        )}
                      </TableCell>
                      <TableCell style={{ padding: "5px", textAlign: "center" }}>
                        <span style={{ display: "flex", justifyContent: "center" }}>{fvi.visit_comments}</span>
                        <span style={{ fontWeight: "bold" }}>{fvi.selected_comment}</span>
                      </TableCell>
                      <TableCell style={{ padding: "0px", textAlign: "center" }}>
                        {fvi.distance_visit === undefined
                          ? ""
                          : fvi.distance_visit === "NaN"
                          ? "Property Lat and long doesn't exist"
                          : fvi.distance_visit > 100 ? (
                              <p style={{ color: "red", fontWeight: "bold" }}>NO ({fvi.distance_visit}) m</p>
                            ) : (
                              <p style={{ color: "green", fontWeight: "bold" }}>Yes ({fvi.distance_visit}) m</p>
                            )}
                      </TableCell>
                      <TableCell style={{ padding: "0px", textAlign: "center" }}>
                        {fvi.visit_images &&
                          fvi.visit_images.map((fmg) =>
                            fmg.ref_id === "employee_selfie" ? getImageDiv(fmg) : null
                          )}
                      </TableCell>
                      <TableCell style={{ padding: "0px", textAlign: "center" }}>
                        {fvi.visit_images &&
                          fvi.visit_images.map((fmg) =>
                            fmg.ref_id === "visit_proof" ? getLocationDiv(fmg) : null
                          )}
                      </TableCell>
                      <TableCell style={{ padding: "0px", textAlign: "center" }}>
                        {fvi.visit_images &&
                          fvi.visit_images.map((fmg) =>
                            fmg.ref_id === "visit_proof" ? getImageDiv(fmg) : null
                          )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan="11" style={{ textAlign: "center", padding: "0px" }}>
                      No Field Visits
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {fieldVisits ? (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={fieldVisits.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : (
        <div />
      )}
    </Paper>
  );
}