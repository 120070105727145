import React, { useState, useEffect } from 'react';


// Import specific Material UI icons
import {
  CloudUpload as UploadIcon,
  Visibility as ViewIcon // Import the view icon
} from '@material-ui/icons';

// Import services
import { scanDocumentaccService } from '../../../_services/scanDocumentaccservice';
import { uploadFileToBucket } from '../../../_helpers/upload';
import datehelper from "../../../_helpers/datehelper"

import swal from 'sweetalert';

const ScanDocuments = () => {
  // State declarations with useState hook
  const [documents, setDocuments] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [comment, setComment] = useState('');
  const [spinner, setSpinner] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [documentType, setDocumentType] = useState('');
  const [description, setDescription] = useState('');
  const [documentUrls, setDocumentUrls] = useState('');
  const bucket = process.env.REACT_APP_s3docsBucket;
     // State for card counts
  const [totalDocumentsCount, setTotalDocumentsCount] = useState(0);
  const [todayDocumentsCount, setTodayDocumentsCount] = useState(0);
  const documentTypes = [
    'Accounts Document Repository',
    'Convense / travelling expense',
    'Petty cash',
    'Rent sheet',
    'Bank statement',
    'Compliance sheet',
    'Others'
  ];



 // Fetch document counts (total and today's count)
 const fetchDocumentCounts = async () => {
   try {
     const response = await scanDocumentaccService.getDocumentCounts(); // This new API gets the counts
     if (response.data) {
       setTotalDocumentsCount(response.data.totalDocuments); // Set total documents
       setTodayDocumentsCount(response.data.todayDocuments); // Set today's documents
     }
   } catch (error) {
     console.error('Error fetching document counts:', error);
   }
 };

 useEffect(() => {
  fetchDocumentCounts(); // Fetch document counts when component mounts
}, []);

// Fetch all documents and count logic
const fetchDocuments = async () => {
  try {
    const response = await scanDocumentaccService.getAllDocuments(); // Assuming this service gets all documents
    if (response.data) {
      setDocuments(response.data);
    }
  } catch (error) {
    console.error('Error fetching documents:', error);
  }
};

// Call fetchDocuments when component mounts
useEffect(() => {
  fetchDocuments();
}, []);
  // Search handling logic
  const handleSearch = async () => {
    if (searchQuery.trim() === '') {
      swal({
        title: 'Input Required',
        text: 'Please enter a search query.',
        icon: 'warning',
        button: 'OK',
      });
      return;
    }

    try {
      const response = await scanDocumentaccService.searchData(searchQuery); // Directly passing searchQuery

      if (!response.error) {
        if (response.data && response.data.length > 0) {
          // Documents found
          setDocuments(response.data);
          swal({
            title: 'Search Successful',
            text: 'The search query was processed successfully.',
            content: {
              element: 'div',
              attributes: {
                innerHTML: `
                  <div style="
                    padding: 16px; 
                    background-color: #f9f9f9; 
                    border: 1px solid #ddd; 
                    border-radius: 8px; 
                    max-height: 300px; 
                    overflow-y: auto;
                    font-family: Arial, sans-serif;
                  ">
                    ${response.data
                      .map(
                        (doc) => `
                      <div style="
                        margin-bottom: 16px; 
                        padding: 12px; 
                        border: 1px solid #ccc; 
                        border-radius: 4px; 
                        background-color: #ffffff;
                      ">
                        <p style="margin: 0; font-weight: bold; color: #333;">Comment:</p>
                        <p style="margin: 0; color: #555;">${doc.comment}</p>
                        <button
                          style="margin-top: 10px; background-color: #4caf50; color: white; border: none; border-radius: 4px; padding: 8px 12px; cursor: pointer;"
                          onclick="viewDocument(${doc._id}, '${doc.documentUrl}')"
                        >
                          View Document
                        </button>
                      </div>
                    `
                      )
                      .join('')}
                  </div>
                `,
              },
            },
            buttons: {
              confirm: {
                text: 'Close',
                value: true,
                visible: true,
                className: 'btn btn-primary',
                closeModal: true,
              },
            },
          });
        } else {
          // No documents found
          swal({
            title: 'No Results Found',
            text: 'No documents match the entered search query.',
            icon: 'info',
            button: 'OK',
          });
        }
      } else {
        swal({
          title: 'Error',
          text: 'An error occurred while processing your search request.',
          icon: 'error',
          button: 'OK',
        });
        console.error('Error searching documents:', response.message);
      }
    } catch (error) {
      swal({
        title: 'Error',
        text: 'An unexpected error occurred. Please try again later.',
        icon: 'error',
        button: 'OK',
      });
      console.error('Error searching documents:', error.message);
    }
  };
  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    setSelectedFiles(files);
  };

  const handleUpload = async () => {
    if (selectedFiles.length > 0) {
      setSpinner(true); // Show spinner
      setIsUploading(true); // Disable upload button
  
      try {
        const fileLinks = [];
  
        for (const file of selectedFiles) {
          const fileExtension = file.name.split('.').pop().toLowerCase();
          const filePath = `${bucket}/loandata/scandocumentsaccount/${Date.now()}.${fileExtension}`;
  
          const link = await uploadFileToBucket(bucket, file.type, filePath, file);
          fileLinks.push(link);
        }
  
        const payload = {
          type: documentType,
          comment: description,
          documentUrl: fileLinks.join(','), // Join all uploaded file URLs
        };
  
        const result = await scanDocumentaccService.upload(payload);
  
        if (result.error) {
          swal('Error', 'Failed to upload some documents. Please try again.', 'error');
        } else {
          setDocuments([...documents, result.data]);
          swal('Success', 'Documents uploaded successfully!', 'success');
          setShowUploadModal(false); // Close modal
          setSelectedFiles([]); // Clear selected files
        }
      } catch (error) {
        console.error('Error uploading documents:', error);
        swal('Error', 'Failed to upload documents. Please try again.', 'error');
      } finally {
        setSpinner(false); // Hide spinner
        setIsUploading(false); // Enable upload button
      }
    } else {
      swal('Error', 'No files selected. Please choose files to upload.', 'error');
    }
  };
  

  // Handle viewing a document by clicking the "View Document" button
  const viewDocument = async (documentId, documentUrl) => {
    try {
      swal({
        title: 'Document Details',
        content: {
          element: 'iframe',
          attributes: {
            src: documentUrl,
            style: 'width:100%; height:400px;',
          },
        },
        buttons: {
          download: {
            text: 'Download Document',
            value: 'download',
            visible: true,
            className: 'btn btn-primary',
            closeModal: true,
          },
          close: {
            text: 'Close',
            value: 'close',
            visible: true,
            className: 'btn btn-secondary',
            closeModal: true,
          },
        },
      }).then((value) => {
        if (value === 'download') {
          window.open(documentUrl, '_blank');
        }
      });
    } catch (error) {
      swal({
        title: 'Error',
        text: 'An unexpected error occurred. Please try again later.',
        icon: 'error',
        button: 'OK',
      });
      console.error('Error viewing document:', error.message);
    }
  };


  return (
    <div style={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
                <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '24px'
      }}>
        {/* Card for Total Documents Count */}
        <div style={{
          backgroundColor: '#d4f7d4', // Light green
          borderRadius: '8px',
          padding: '24px',
          width: '48%',
          textAlign: 'center',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
        }}>
          <h3>Total Documents</h3>
          <p style={{ fontSize: '36px', fontWeight: '600' }}>{totalDocumentsCount}</p>
        </div>

        {/* Card for Today's Documents Count */}
        <div style={{
          backgroundColor: '#e0f7fa', // Light blue
          borderRadius: '8px',
          padding: '24px',
          width: '48%',
          textAlign: 'center',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
        }}>
          <h3>Today's Documents</h3>
          <p style={{ fontSize: '36px', fontWeight: '600' }}>{todayDocumentsCount}</p>
        </div>
      </div>
      <div style={{ 
        backgroundColor: 'white', 
        borderRadius: '8px', 
        padding: '24px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
      }}>
        <div style={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          marginBottom: '24px'
        }}>
          <div>
            <h2 style={{ 
              margin: '0',
              fontSize: '24px',
              fontWeight: '600',
              color: '#333'
            }}>Scan Documents</h2>
            <p style={{ 
              margin: '8px 0 0',
              color: '#666',
              fontSize: '14px'
            }}>Manage and access your scanned documents</p>
          </div>
          <button
            onClick={() => setShowUploadModal(true)}
            style={{
              backgroundColor: '#2196f3',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              padding: '10px 20px',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              fontSize: '14px'
            }}
          >
            <UploadIcon style={{ marginRight: '8px' }} />
            Upload Documents
          </button>
        </div>

        <div style={{ 
          display: 'flex',
          marginBottom: '24px'
        }}>
          <input
            type="text"
            placeholder="Search Documents by name, number..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{
              flex: 1,
              padding: '10px',
              border: '1px solid #ddd',
              borderRadius: '4px',
              marginRight: '12px',
              fontSize: '14px'
            }}
          />
          <button
            onClick={handleSearch}
            style={{
              backgroundColor: '#2196f3',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              padding: '10px 24px',
              cursor: 'pointer'
            }}
          >
            Search
          </button>
        </div>

        {/* Document Table with "View" button */}
        <div>
          {documents.map(doc => (
            <div key={doc._id} style={{
              marginBottom: '16px',
              padding: '12px',
              border: '1px solid #ddd',
              borderRadius: '8px',
              backgroundColor: '#f9f9f9'
            }}>
              <p><strong>Document Name:</strong> {doc.type}</p>
              <p><strong>Comment:</strong> {doc.comment}</p>
              <p><strong>Date:</strong> {datehelper.displayDate(new Date(doc.created_date))}</p>
              
              <button
                onClick={() => viewDocument(doc._id, doc.documentUrl)}
                style={{
                  backgroundColor: '#4caf50',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  padding: '8px 16px',
                  cursor: 'pointer'
                }}
              >
                <ViewIcon style={{ marginRight: '8px' }} />
                View Document
              </button>
            </div>
          ))}
        </div>

        {showUploadModal && (
          <div style={{
            position: 'fixed',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000
          }}>
            <div style={{
              backgroundColor: 'white',
              borderRadius: '8px',
              padding: '24px',
              width: '500px',
              maxWidth: '90%'
            }}>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '20px'
              }}>
                <h3 style={{ margin: 0 }}>Upload Documents</h3>
                <button
                  onClick={() => setShowUploadModal(false)}
                  style={{
                    border: 'none',
                    background: 'none',
                    fontSize: '20px',
                    cursor: 'pointer'
                  }}
                >
                  ×
                  </button>
              </div>

              <div
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                style={{
                  border: '2px dashed #ddd',
                  borderRadius: '4px',
                  padding: '40px',
                  textAlign: 'center',
                  marginBottom: '20px',
                  position: 'relative'
                }}
              >
                <UploadIcon style={{ fontSize: '48px', color: '#666', marginBottom: '12px' }} />
                <div>Choose Files</div>
                <div style={{ fontSize: '12px', color: '#666', marginTop: '8px' }}>
                  or drag and drop
                </div>
                <div style={{ fontSize: '12px', color: '#666', marginTop: '4px' }}>
                  PDF
                </div>
                {selectedFiles.length > 0 && (
                    <p style={{ marginTop: '8px', fontSize: '14px', color: '#666' }}>
                      Selected: {selectedFiles.map(file => file.name).join(', ')}
                    </p>
                  )}
                <input
                  type="file"
                  multiple
                  onChange={handleFileSelect}
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: 0,
                    left: 0,
                    opacity: 0,
                    cursor: 'pointer'
                  }}
                />
              </div>

              <select
                value={documentType}
                onChange={(e) => setDocumentType(e.target.value)}
                style={{
                  width: '100%',
                  padding: '10px',
                  marginBottom: '12px',
                  border: '1px solid #ddd',
                  borderRadius: '4px'
                }}
              >
                <option value="">Select Document Type</option>
                {documentTypes.map((type) => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>

              <textarea
                placeholder="Add description (optional)"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                style={{
                  width: '100%',
                  padding: '10px',
                  marginBottom: '20px',
                  border: '1px solid #ddd',
                  borderRadius: '4px',
                  minHeight: '80px',
                  resize: 'vertical'
                }}
              />

              <button
                onClick={handleUpload}
                disabled={isUploading}
                style={{
                  width: '100%',
                  padding: '12px',
                  backgroundColor: '#2196f3',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  opacity: isUploading ? 0.7 : 1
                }}
              >
                {isUploading ? 'Uploading...' : 'Upload Documents'}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ScanDocuments;





// import React, { useState, useEffect } from 'react';

// // Import specific Material UI icons
// import {
//   CloudUpload as UploadIcon,
//   Visibility as ViewIcon // Import the view icon
// } from '@material-ui/icons';

// // Import services
// import { scanDocumentaccService } from '../../../_services/scanDocumentaccservice';
// import { uploadFileToBucket } from '../../../_helpers/upload';
// import datehelper from "../../../_helpers/datehelper"

// import swal from 'sweetalert';

// const ScanDocuments = () => {
//   // State declarations with useState hook
//   const [documents, setDocuments] = useState([]);
//   const [isUploading, setIsUploading] = useState(false);
//   const [showUploadModal, setShowUploadModal] = useState(false);
//   const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
//   const [comment, setComment] = useState('');
//   const [spinner, setSpinner] = useState(false);
//   const [selectedFiles, setSelectedFiles] = useState([]);
//   const [searchQuery, setSearchQuery] = useState('');
//   const [documentType, setDocumentType] = useState('');
//   const [description, setDescription] = useState('');
//   const [documentUrls, setDocumentUrls] = useState('');
//   const bucket = process.env.REACT_APP_s3docsBucket;

//   const documentTypes = [
//     'Accounts Document Repository',
//     'Convense / travelling expense',
//     'Petty cash',
//     'Rent sheet',
//     'Bank statement',
//     'Compliance sheet',
//     'Others'
//   ];


//    // State for card counts
//  const [totalDocumentsCount, setTotalDocumentsCount] = useState(0);
//  const [todayDocumentsCount, setTodayDocumentsCount] = useState(0);

//  // Fetch document counts (total and today's count)
//  const fetchDocumentCounts = async () => {
//    try {
//      const response = await scanDocumentaccService.getDocumentCounts(); // This new API gets the counts
//      if (response.data) {
//        setTotalDocumentsCount(response.data.totalDocuments); // Set total documents
//        setTodayDocumentsCount(response.data.todayDocuments); // Set today's documents
//      }
//    } catch (error) {
//      console.error('Error fetching document counts:', error);
//    }
//  };

//  useEffect(() => {
//   fetchDocumentCounts(); // Fetch document counts when component mounts
// }, []);

// // Fetch all documents and count logic
// const fetchDocuments = async () => {
//   try {
//     const response = await scanDocumentaccService.getAllDocuments(); // Assuming this service gets all documents
//     if (response.data) {
//       setDocuments(response.data);
//     }
//   } catch (error) {
//     console.error('Error fetching documents:', error);
//   }
// };

// // Call fetchDocuments when component mounts
// useEffect(() => {
//   fetchDocuments();
// }, []);
//   // Search handling logic
//   const handleSearch = async () => {
//     if (searchQuery.trim() === '') {
//       swal({
//         title: 'Input Required',
//         text: 'Please enter a search query.',
//         icon: 'warning',
//         button: 'OK',
//       });
//       return;
//     }

//     try {
//       const response = await scanDocumentaccService.searchData(searchQuery); // Directly passing searchQuery

//       if (!response.error) {
//         if (response.data && response.data.length > 0) {
//           // Documents found
//           setDocuments(response.data);
//           swal({
//             title: 'Search Successful',
//             text: 'The search query was processed successfully.',
//             content: {
//               element: 'div',
//               attributes: {
//                 innerHTML: `
//                   <div style="
//                     padding: 16px; 
//                     background-color: #f9f9f9; 
//                     border: 1px solid #ddd; 
//                     border-radius: 8px; 
//                     max-height: 300px; 
//                     overflow-y: auto;
//                     font-family: Arial, sans-serif;
//                   ">
//                     ${response.data
//                       .map(
//                         (doc) => `
//                       <div style="
//                         margin-bottom: 16px; 
//                         padding: 12px; 
//                         border: 1px solid #ccc; 
//                         border-radius: 4px; 
//                         background-color: #ffffff;
//                       ">
//                         <p style="margin: 0; font-weight: bold; color: #333;">Comment:</p>
//                         <p style="margin: 0; color: #555;">${doc.comment}</p>
//                         <button
//                           style="margin-top: 10px; background-color: #4caf50; color: white; border: none; border-radius: 4px; padding: 8px 12px; cursor: pointer;"
//                           onclick="viewDocument(${doc._id}, '${doc.documentUrl}')"
//                         >
//                           View Document
//                         </button>
//                       </div>
//                     `
//                       )
//                       .join('')}
//                   </div>
//                 `,
//               },
//             },
//             buttons: {
//               confirm: {
//                 text: 'Close',
//                 value: true,
//                 visible: true,
//                 className: 'btn btn-primary',
//                 closeModal: true,
//               },
//             },
//           });
//         } else {
//           // No documents found
//           swal({
//             title: 'No Results Found',
//             text: 'No documents match the entered search query.',
//             icon: 'info',
//             button: 'OK',
//           });
//         }
//       } else {
//         swal({
//           title: 'Error',
//           text: 'An error occurred while processing your search request.',
//           icon: 'error',
//           button: 'OK',
//         });
//         console.error('Error searching documents:', response.message);
//       }
//     } catch (error) {
//       swal({
//         title: 'Error',
//         text: 'An unexpected error occurred. Please try again later.',
//         icon: 'error',
//         button: 'OK',
//       });
//       console.error('Error searching documents:', error.message);
//     }
//   };
//   const handleFileSelect = (event) => {
//     const files = Array.from(event.target.files);
//     setSelectedFiles(files);
//   };

//   const handleDragOver = (event) => {
//     event.preventDefault();
//   };

//   const handleDrop = (event) => {
//     event.preventDefault();
//     const files = Array.from(event.dataTransfer.files);
//     setSelectedFiles(files);
//   };

//   const handleUpload = async () => {
//     if (selectedFiles.length > 0) {
//       setSpinner(true);
  
//       for (const file of selectedFiles) {
//         const fileExtension = file.name.split('.').pop().toLowerCase();
//         let num1 = Math.floor(Math.random() * 1000);
//         let num2 = Math.floor(Math.random() * 100);
        
//         const filePath = `${bucket}/loandata/scandocumentsaccount/${num1}.${fileExtension}`;
  
//         try {
//           const link = await uploadFileToBucket(bucket, file.type, filePath, file);
//           console.log('File uploaded successfully:', link);
//           setDocumentUrls(link);
//         } catch (error) {
//           console.error('Error uploading document:', error);
//           alert('Failed to upload document. Please try again.');
//         }
//       }
//   console.log('Document Urls:', documentUrls);
//       try {
//         const payload = { 
//           type: documentType,
//           comment: description,
//           documentUrl: documentUrls,
//         };
//         console.log('Payload:', payload);
//         const result = await scanDocumentaccService.upload(payload);
  
//         if (result.error) {
//           alert('Failed to upload some documents. Please try again.');
//           console.error('Error uploading documents:', result.message);
//         } else {
//           setDocuments([...documents, result.data]);
//           setIsUploadDialogOpen(false);
//           setSelectedFiles([]);
//           alert('Documents uploaded successfully!');
//         }
//       } catch (error) {
//         console.error('Error uploading documents:', error);
//         alert('Failed to upload documents. Please try again.');
//       } finally {
//         setSpinner(false);
//       }
//     }
//   };

//   // Handle viewing a document by clicking the "View Document" button
//   const viewDocument = async (documentId, documentUrl) => {
//     try {
//       swal({
//         title: 'Document Details',
//         content: {
//           element: 'iframe',
//           attributes: {
//             src: documentUrl,
//             style: 'width:100%; height:400px;',
//           },
//         },
//         buttons: {
//           download: {
//             text: 'Download Document',
//             value: 'download',
//             visible: true,
//             className: 'btn btn-primary',
//             closeModal: true,
//           },
//           close: {
//             text: 'Close',
//             value: 'close',
//             visible: true,
//             className: 'btn btn-secondary',
//             closeModal: true,
//           },
//         },
//       }).then((value) => {
//         if (value === 'download') {
//           window.open(documentUrl, '_blank');
//         }
//       });
//     } catch (error) {
//       swal({
//         title: 'Error',
//         text: 'An unexpected error occurred. Please try again later.',
//         icon: 'error',
//         button: 'OK',
//       });
//       console.error('Error viewing document:', error.message);
//     }
//   };


//   return (
//     <div style={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
//             <div style={{
//         display: 'flex',
//         justifyContent: 'space-between',
//         marginBottom: '24px'
//       }}>
//         {/* Card for Total Documents Count */}
//         <div style={{
//           backgroundColor: '#d4f7d4', // Light green
//           borderRadius: '8px',
//           padding: '24px',
//           width: '48%',
//           textAlign: 'center',
//           boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
//         }}>
//           <h3>Total Documents</h3>
//           <p style={{ fontSize: '36px', fontWeight: '600' }}>{totalDocumentsCount}</p>
//         </div>

//         {/* Card for Today's Documents Count */}
//         <div style={{
//           backgroundColor: '#e0f7fa', // Light blue
//           borderRadius: '8px',
//           padding: '24px',
//           width: '48%',
//           textAlign: 'center',
//           boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
//         }}>
//           <h3>Today's Documents</h3>
//           <p style={{ fontSize: '36px', fontWeight: '600' }}>{todayDocumentsCount}</p>
//         </div>
//       </div>
//       <div style={{ 
//         backgroundColor: 'white', 
//         borderRadius: '8px', 
//         padding: '24px',
//         boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
//       }}>
//         <div style={{ 
//           display: 'flex', 
//           justifyContent: 'space-between', 
//           alignItems: 'center',
//           marginBottom: '24px'
//         }}>
//           <div>
//             <h2 style={{ 
//               margin: '0',
//               fontSize: '24px',
//               fontWeight: '600',
//               color: '#333'
//             }}>Scan Documents</h2>
//             <p style={{ 
//               margin: '8px 0 0',
//               color: '#666',
//               fontSize: '14px'
//             }}>Manage and access your scanned documents</p>
//           </div>
//           <button
//             onClick={() => setShowUploadModal(true)}
//             style={{
//               backgroundColor: '#2196f3',
//               color: 'white',
//               border: 'none',
//               borderRadius: '4px',
//               padding: '10px 20px',
//               display: 'flex',
//               alignItems: 'center',
//               cursor: 'pointer',
//               fontSize: '14px'
//             }}
//           >
//             <UploadIcon style={{ marginRight: '8px' }} />
//             Upload Documents
//           </button>
//         </div>

//         <div style={{ 
//           display: 'flex',
//           marginBottom: '24px'
//         }}>
//           <input
//             type="text"
//             placeholder="Search Documents by name, number..."
//             value={searchQuery}
//             onChange={(e) => setSearchQuery(e.target.value)}
//             style={{
//               flex: 1,
//               padding: '10px',
//               border: '1px solid #ddd',
//               borderRadius: '4px',
//               marginRight: '12px',
//               fontSize: '14px'
//             }}
//           />
//           <button
//             onClick={handleSearch}
//             style={{
//               backgroundColor: '#2196f3',
//               color: 'white',
//               border: 'none',
//               borderRadius: '4px',
//               padding: '10px 24px',
//               cursor: 'pointer'
//             }}
//           >
//             Search
//           </button>
//         </div>

//         {/* Document Table with "View" button */}
//         <div>
//           {documents.map(doc => (
//             <div key={doc._id} style={{
//               marginBottom: '16px',
//               padding: '12px',
//               border: '1px solid #ddd',
//               borderRadius: '8px',
//               backgroundColor: '#f9f9f9'
//             }}>
//               <p><strong>Document Name:</strong> {doc.type}</p>
//               <p><strong>Comment:</strong> {doc.comment}</p>
//               <p><strong>Date:</strong> {datehelper.displayDate(new Date(doc.created_date))}</p>
              
//               <button
//                 onClick={() => viewDocument(doc._id, doc.documentUrl)}
//                 style={{
//                   backgroundColor: '#4caf50',
//                   color: 'white',
//                   border: 'none',
//                   borderRadius: '4px',
//                   padding: '8px 16px',
//                   cursor: 'pointer'
//                 }}
//               >
//                 <ViewIcon style={{ marginRight: '8px' }} />
//                 View Document
//               </button>
//             </div>
//           ))}
//         </div>

//         {showUploadModal && (
//           <div style={{
//             position: 'fixed',
//             top: '0',
//             left: '0',
//             right: '0',
//             bottom: '0',
//             backgroundColor: 'rgba(0,0,0,0.5)',
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             zIndex: 1000
//           }}>
//             <div style={{
//               backgroundColor: 'white',
//               borderRadius: '8px',
//               padding: '24px',
//               width: '500px',
//               maxWidth: '90%'
//             }}>
//               <div style={{
//                 display: 'flex',
//                 justifyContent: 'space-between',
//                 alignItems: 'center',
//                 marginBottom: '20px'
//               }}>
//                 <h3 style={{ margin: 0 }}>Upload Documents</h3>
//                 <button
//                   onClick={() => setShowUploadModal(false)}
//                   style={{
//                     border: 'none',
//                     background: 'none',
//                     fontSize: '20px',
//                     cursor: 'pointer'
//                   }}
//                 >
//                   ×
//                   </button>
//               </div>

//               <div
//                 onDragOver={handleDragOver}
//                 onDrop={handleDrop}
//                 style={{
//                   border: '2px dashed #ddd',
//                   borderRadius: '4px',
//                   padding: '40px',
//                   textAlign: 'center',
//                   marginBottom: '20px',
//                   position: 'relative'
//                 }}
//               >
//                 <UploadIcon style={{ fontSize: '48px', color: '#666', marginBottom: '12px' }} />
//                 <div>Choose Files</div>
//                 <div style={{ fontSize: '12px', color: '#666', marginTop: '8px' }}>
//                   or drag and drop
//                 </div>
//                 <div style={{ fontSize: '12px', color: '#666', marginTop: '4px' }}>
//                   PDF, DOCX, JPG, PNG (Max 20MB)
//                 </div>
//                 <input
//                   type="file"
//                   multiple
//                   onChange={handleFileSelect}
//                   style={{
//                     position: 'absolute',
//                     width: '100%',
//                     height: '100%',
//                     top: 0,
//                     left: 0,
//                     opacity: 0,
//                     cursor: 'pointer'
//                   }}
//                 />
//               </div>

//               <select
//                 value={documentType}
//                 onChange={(e) => setDocumentType(e.target.value)}
//                 style={{
//                   width: '100%',
//                   padding: '10px',
//                   marginBottom: '12px',
//                   border: '1px solid #ddd',
//                   borderRadius: '4px'
//                 }}
//               >
//                 <option value="">Select Document Type</option>
//                 {documentTypes.map((type) => (
//                   <option key={type} value={type}>{type}</option>
//                 ))}
//               </select>

//               <textarea
//                 placeholder="Add description (optional)"
//                 value={description}
//                 onChange={(e) => setDescription(e.target.value)}
//                 style={{
//                   width: '100%',
//                   padding: '10px',
//                   marginBottom: '20px',
//                   border: '1px solid #ddd',
//                   borderRadius: '4px',
//                   minHeight: '80px',
//                   resize: 'vertical'
//                 }}
//               />

//               <button
//                 onClick={handleUpload}
//                 disabled={isUploading}
//                 style={{
//                   width: '100%',
//                   padding: '12px',
//                   backgroundColor: '#2196f3',
//                   color: 'white',
//                   border: 'none',
//                   borderRadius: '4px',
//                   cursor: 'pointer',
//                   opacity: isUploading ? 0.7 : 1
//                 }}
//               >
//                 {isUploading ? 'Uploading...' : 'Upload Documents'}
//               </button>
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default ScanDocuments;
