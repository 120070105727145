import { Box, Button, Modal } from '@material-ui/core'
import React, { useState } from 'react'
import Product from '../../_helpers/product'
import roleChecker from '../../_helpers/rolechecker'
import { employeeService } from '../../_services'
import productbyrole from '../../_helpers/productbyrole'
const SwitchAccountModal = () => {
    const [open, setOpen] = useState(false)
    const user = employeeService.userValue;
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    function handleProductClick(productType) {
        employeeService.setProduct(productType)
    }
    const style = {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        height: 500,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        borderRadius: '5px'
    };
    const ArrayofProducts=productbyrole(user.role);
    return (
        <div>
            {ArrayofProducts.length>1  && (
                    <Button onClick={handleOpen} style={{ color: '#3F51B5', textTransform: 'capitalize', fontSize: '16px' }}
                    onMouseOver={(e) => e.target.style.textDecoration = 'underline'}
                    onMouseOut={(e) => e.target.style.textDecoration = 'none'}
                    >Switch Panel</Button>
            )}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <h4 style={{ textAlign: "center", fontWeight: 'bold', marginBottom: '10px' }}>Switch Panel To</h4>
                    <div style={{ display: "flex", justifyContent: "space-around", flexDirection: 'column',justifyContent:'center' }}>
                        {ArrayofProducts.includes('sbl') && employeeService.productValue != Product.SBL && <div
                            style={{ marginTop:'20px',display: 'flex', flexDirection: "row" }}
                        >
                            <Button onClick={() => handleProductClick(Product.SBL)}
                                style={{ color: '#0C2294', border: "2px solid #0C2294", height: '32px', background: '9990FF' }}>
                                LOS
                            </Button>
                            <br />
                        </div>}

                        {ArrayofProducts.includes('collection') && employeeService.productValue != Product.Collection && <div style={{marginTop:'20px'}}>
                            <Button onClick={() => handleProductClick(Product.Collection)}
                                style={{ color: '#0C2294', border: "2px solid #0C2294", height: '32px', background: '9990FF' }}>
                                Collection
                            </Button>
                            <br />
                        </div>}
                      
                        
                        {ArrayofProducts.includes('accounting') && employeeService.productValue != Product.Accounting && <div style={{marginTop:'20px'}}>
                            <Button onClick={() => handleProductClick(Product.Accounting)}
                                style={{ color: '#0C2294', border: "2px solid #0C2294", height: '32px', background: '9990FF' }}>
                                Accounting
                            </Button>
                            <br />
                        </div>}

                        {ArrayofProducts.includes('colending') &&  employeeService.productValue != Product.CoLending && <div style={{marginTop:'20px'}}>
                            <Button onClick={() => handleProductClick(Product.CoLending)}
                                style={{ color: '#0C2294', border: "2px solid #0C2294", height: '32px', background: '9990FF' }}>
                               CoLending
                            </Button>
                            <br />
                        </div>}

                      
                        { ArrayofProducts.includes('customercare') && employeeService.productValue != Product.CustomerCare && <div style={{marginTop:'20px'}}>
                            <Button onClick={() => handleProductClick(Product.CustomerCare)}
                                style={{ color: '#0C2294', border: "2px solid #0C2294", height: '32px', background: '9990FF' }}>
                                Customer Care
                            </Button>
                            <br />
                        </div>}
                      
                        { ArrayofProducts.includes('legal') && employeeService.productValue != Product.Legal && <div style={{marginTop:'20px'}}>
                            <Button onClick={() => handleProductClick(Product.Legal)}
                                style={{ color: '#0C2294', border: "2px solid #0C2294", height: '32px', background: '9990FF' }}>
                                Legal
                            </Button>
                            <br />
                        </div>}
                      
                        {ArrayofProducts.includes('HR') && employeeService.productValue != Product.HR && <div style={{marginTop:'20px'}}>
                            <Button onClick={() => handleProductClick(Product.HR)}
                                style={{ color: '#0C2294', border: "2px solid #0C2294", height: '25px', background: '9990FF' }}
                            >
                                HR
                            </Button>
                        </div>}
                        {ArrayofProducts.includes('vendorpanel') && employeeService.productValue != Product.VendorPanel && <div style={{marginTop:'20px'}}>
                            <Button onClick={() => handleProductClick(Product.VendorPanel)}
                                style={{ color: '#0C2294', border: "2px solid #0C2294", height: '25px', background: '9990FF' }}
                            >
                                Vendor Panel
                            </Button>
                        </div>}
                       
                    </div>
                </Box>
            </Modal>
        </div>
    )
}
export default SwitchAccountModal;
