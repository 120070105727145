import React from 'react'
import Product from '../_helpers/product'
import { employeeService } from '../_services'
import productbyrole from '../_helpers/productbyrole'
import './style.css';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CallIcon from '@material-ui/icons/Call';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import GavelIcon from '@material-ui/icons/Gavel';
import GroupIcon from '@material-ui/icons/Group';
import WcIcon from '@material-ui/icons/Wc';
const SwitchAccountModal = () => {
    const user = employeeService.userValue;
    function handleProductClick(productType) {
        employeeService.setProduct(productType)
    }
    const ArrayofProducts = productbyrole(user.role);
    return (
        <div>
            {ArrayofProducts.length > 1 && (<div className="icon-container">
                {ArrayofProducts.includes('sbl') && employeeService.productValue != Product.SBL &&
                    <div onClick={() => handleProductClick(Product.SBL)} className="losPanel">
                        <VpnKeyIcon /> <span>LOS</span>
                    </div>}
                {ArrayofProducts.includes('collection') && employeeService.productValue != Product.Collection &&
                    <div className="collectionPanel" onClick={() => handleProductClick(Product.Collection)}>
                        <AccountBalanceWalletIcon /> <span>Collection</span>
                    </div>}
                {ArrayofProducts.includes('accounting') && employeeService.productValue != Product.Accounting &&
                    <div className="accountingPanel" onClick={() => handleProductClick(Product.Accounting)}>
                        <AccountBalanceIcon /> <span>Accounting</span>
                    </div>}
                {ArrayofProducts.includes('colending') && employeeService.productValue != Product.CoLending &&
                    <div className="colendingPanel" onClick={() => handleProductClick(Product.CoLending)}>
                        <AttachMoneyIcon /> <span>Co-Lending</span>
                    </div>}
                {ArrayofProducts.includes('customercare') && employeeService.productValue != Product.CustomerCare &&
                    <div className="customercarePanel" onClick={() => handleProductClick(Product.CustomerCare)}>
                        <CallIcon /> <span>Customer Care</span>
                    </div>}
                {ArrayofProducts.includes('legal') && employeeService.productValue != Product.Legal &&
                    <div className="legelPanel" onClick={() => handleProductClick(Product.Legal)}>
                        <GavelIcon /> <span>Legal</span>
                    </div>}
                {ArrayofProducts.includes('HR') && employeeService.productValue != Product.HR &&
                    <div className="hrPanel" onClick={() => handleProductClick(Product.HR)}>
                        <GroupIcon /> <span>HR</span>
                    </div>}
            </div>)}
        </div>
    )
}
export default SwitchAccountModal;
