import { fetchWrapper } from "../_helpers/fetch-wrapper";

const apiUrl = process.env.REACT_APP_API_URL;

const baseUrl = `${apiUrl}/ms/customer`;

export const  customerService = {
  getAll,
  create,
  searchCustomer,
  findCustomerData,
  updateCustomer,
  addToLoan,
  verifyCustomerMobileNumber,
  numberNotMatches,
  deleteCustomer,
  updateCustomerInLoan,
  promoteApplicantToCoApplicant,
  getExistingLoan,
  getTargetDashboard,
  getTarget,
  searchTarget,
  searchTargets,
  getSetAlert,
  searchSetAlert,
  updateTarget,
  // getCersaiReport,
  // getSearchCersaiReport,
  UpdateRps,
  getugrosheet,
  getProcessEnach,
  loantracking,
  consentRequest,
  consentStatus,
  getConsentResponse,
  requestBankStatements,
  fetchAllBankStatements,
  getAllCustomerOfLoan
};
function getAll() {
  return fetchWrapper.get(baseUrl);
}

function create(params)
{
    return fetchWrapper.post(baseUrl,params);
}

function searchCustomer(field){
  return fetchWrapper.post(`${baseUrl}/searchby`, field)
}
function findCustomerData(field){
  return fetchWrapper.post(`${baseUrl}/findcustomerdata`, field)
}
function updateCustomer (id, params) {
  console.log(id)
  return fetchWrapper.put(`${baseUrl}/updatecustomer/${id}`, params)
}
function verifyCustomerMobileNumber (id, params) {
  console.log('idddd',id,params)
  return fetchWrapper.put(`${baseUrl}/verifycustomermobilenumber/${id}`,params)
}
function numberNotMatches (id) {
  console.log('id is for number change',id)
  return fetchWrapper.put(`${baseUrl}/numbernotmatches/${id}`)
}
function addToLoan(params){
  // return fetchWrapper.post(`${baseUrl}/addtoloan`,params)
  return fetchWrapper.post(`${baseUrl}/addtoloan`,params)
}

function deleteCustomer(params){
  return fetchWrapper.post(`${baseUrl}/deletecustomer`, params)
}

function updateCustomerInLoan(params){
  console.log(params)
  return fetchWrapper.post(`${baseUrl}/updateinloan`, params)
}
function promoteApplicantToCoApplicant(params){
  console.log(params)
  return fetchWrapper.post(`${baseUrl}/promoteapplicanttocoapplicant`, params)
}
function getExistingLoan(params){
  console.log(params,"paramsparamsparamsparams")
  return fetchWrapper.post(`${baseUrl}/existing_loan`, params)
}
function getTargetDashboard(params){
  console.log(params,"paramsparamsparamsparams")
  return fetchWrapper.post(`${baseUrl}/dashboard/get_target_template`, params)
}
function getTarget(params){
  console.log(params,"paramsparamsparameeeeeeeeeeeeeeeeeeeeeesparams")
  return fetchWrapper.post(`${baseUrl}/dashboard/assign_targets`, params)
}
function searchTarget(field){
  return fetchWrapper.post(`${baseUrl}/searchTargetby`, field)
}
function updateTarget(params) {
  console.log(params, "Request parameters");
  return fetchWrapper.post(`${baseUrl}/updateTarget`, params);
}
function searchTargets(field){
  return fetchWrapper.post(`${baseUrl}/searchTargetsby`, field)
}
function getSetAlert(id,field){
  console.log(field,"fdffdfdfd")
  return fetchWrapper.post(`${baseUrl}/alerts_workflow`, field)
}
function searchSetAlert(field){
  return fetchWrapper.get(`${baseUrl}/search_setalert`, field)
}
// function getCersaiReport(params){
//   console.log(params,"paramsparamsparamsparams")
//   return fetchWrapper.post(`${baseUrl}/cersai`, params)
// }
// function getSearchCersaiReport(params){
//   console.log(params,"paramsparamsparamsparams")
//   return fetchWrapper.post(`${baseUrl}/asset_search_action_details`, params)
// }
function UpdateRps(params){
  console.log(params,"paramsparamsparamsparams")
  return fetchWrapper.post(`${baseUrl}/allcloud/run_rps`, params)
}
function getugrosheet(params){
  console.log(params,"paramsparamsparamsparams")
  return fetchWrapper.post(`${baseUrl}/colending/ugro_sheet`, params)
}
function getProcessEnach(params){
  return fetchWrapper.post(`${baseUrl}/process_enach`,params)
}
function loantracking(params){
  console.log(params,"paramsparamsparamsparams")
  return fetchWrapper.post(`${baseUrl}/updated_loan_tracking`, params)
}
function consentRequest(params){
  return fetchWrapper.post(`${baseUrl}/consentrequest`,params)
}
function consentStatus(params){
  return fetchWrapper.post(`${baseUrl}/consentstatus`,params)
}
function getConsentResponse(params){
  return fetchWrapper.post(`${baseUrl}/getconsentresponse`,params)
}
function requestBankStatements(params){
  return fetchWrapper.post(`${baseUrl}/requestbankstatements`,params)
}
function fetchAllBankStatements(params){
  return fetchWrapper.post(`${baseUrl}/fetchallbankstatements`,params)
   
 }
 function getAllCustomerOfLoan(params){
  return fetchWrapper.post(`${baseUrl}/getallcustomerofloan`,params)
   
 }