import React, { useEffect, useState } from 'react';
import { collectionService } from '../../../_services/collection.service';
import datehelper from "../../../_helpers/datehelper";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Typography,
  CircularProgress,
  TablePagination
} from '@material-ui/core';
import { employeeService } from '../../../_services';


function TeleCalling({ cust }) {
  console.log('TeleCalling', cust);
  const [callingData, setCallingData] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [employeeList, setEmployeeList] = useState({});
   
  async function getAllEmployees() {
    await employeeService.getAll().then((res) => {
      let list = {};
      res.map((e) => {
        list[e.emp_id] = e.first_name + " " + e.last_name;
      });
      setEmployeeList(list);
    });
  }
  useEffect(()=>{
    getAllEmployees()
  },[])
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    console.log('TeleCalling', cust);
    async function fetchData() {
      try {
        const data = await collectionService.getAllCallOFLoans({ application_id: cust.application_id });
        console.log('Response is:', data);
        setCallingData(data);
        setSpinner(false);
      } catch (err) {
        console.error('Error fetching TeleCalling:', err);
        setSpinner(false);
      }
    }
    if (cust?.application_id) {
      fetchData();
    }
  }, [cust]); // Dependency array ensures the effect runs when `cust` changes

  return (
    <div>
      <Typography variant="h5" gutterBottom style={{ marginBottom: '20px', color: '#2949B9' }}>
        Tele Calling Details
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead style={{ backgroundColor: '#525155' }}>
            <TableRow>
              <TableCell style={{ textAlign: 'center', color: 'white' }}>#</TableCell>
              <TableCell style={{ textAlign: 'center', color: 'white' }}>Calling Date</TableCell>
             
              <TableCell style={{ textAlign: 'center', color: 'white' }}>Agent Name</TableCell>
              <TableCell style={{ textAlign: 'center', color: 'white' }}>PTP</TableCell>
              <TableCell style={{ textAlign: 'center', color: 'white' }}>Status</TableCell>
              <TableCell style={{ textAlign: 'center', color: 'white' }}>Comment</TableCell>
              <TableCell style={{ textAlign: 'center', color: 'white' }}>Recording</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {spinner ? (
              <TableRow>
                <TableCell colSpan={7} style={{ textAlign: 'center', padding: '20px' }}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : callingData.length > 0 ? (
              callingData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((call, index) => (
                <TableRow key={call?._id?.$oid || index}>
                  <TableCell style={{ textAlign: 'center' }}>{index + 1}</TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    {call.last_calling_date && datehelper.displayDate(new Date(call.last_calling_date))}
                  </TableCell>
                 
                  <TableCell style={{ textAlign: 'center' }}>{employeeList[call.saved_by]}</TableCell>
                  <TableCell style={{ textAlign: 'center' }}>{call.next_follow_up_date && datehelper.displayDate(new Date(call.next_follow_up_date))}</TableCell>
                  <TableCell style={{ textAlign: 'center' }}>{call.call_status}</TableCell>
                  <TableCell style={{ textAlign: 'center' }}>{call.remarks}</TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    {call.calls[0]?.response_data
                      ?.recording_url  && (
                      <audio controls>
                        <source src={call.calls[0]?.response_data
?.recording_url} type="audio/mpeg" />
                        Your browser does not support the audio element.
                      </audio>
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} style={{ textAlign: 'center', padding: '20px' }}>
                  No tele calling records available.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {callingData.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={callingData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
}

export default TeleCalling;