import React from "react";
import { Button, Paper, Typography } from "@material-ui/core";
import { loanService } from '../../_services';
export default function DocumentVerification() {
  const [id, setId] = React.useState("pan");
  const [inp, setInp] = React.useState({});
  const [verificationSuccessMessage, setVerificationSuccessMessage] = React.useState("");
  const [verificationFailedMessage, setVerificationFailedMessage] = React.useState("");
  const [spinner, setSpinner] = React.useState(false);
  function changeId(idValue)
  {
     setVerificationFailedMessage("");
     setVerificationSuccessMessage("");
     setId(idValue);
  }

  function printDocument()
  {
     window.print();
  }


   const fetchIdentity = async () => {
    var info;

    if (id === "pan")
      info = {
        name: inp.name,
        number: inp.number,
        fuzzy: "true",
      };
    else if( id ==="dl")
      info = {
        number: inp.number,
        dob: inp.dob.toString().split("-").reverse().join("/"),
        issueDate: inp.issueDate.toString().split("-").reverse().join("/"),
      };
    else if ( id =="vid")
        info={
          epicNumber: inp.number,
          name: inp.name,
          state: inp.state,
        };

        setSpinner(true);
        
        loanService.fetchPanIdentity(info)
        .then(async (response) => {
          const data = await response.json();
          console.log(response);
          let verificationMessage="Request id:"+data.response.instance.id+" . "+data.response.result.message+".  ";
          setVerificationSuccessMessage(verificationMessage);
          if((id==='vid' && !data.response.result.verification) || ((id ==='pan' || id ==='dl') && !data.response.result.verified))
             setVerificationFailedMessage(" Negative result. Please check carefully");
          else
             setVerificationFailedMessage("");
        })
        .catch((err) => {
          console.log(err);
          setVerificationSuccessMessage("");
          setVerificationFailedMessage("Verification Failed");
        });
  }
  return (
    <div >
  
      <Paper
        style={{
          padding: 20,
        }}
        
      >
       
       <div  className="row justify-content-center" style={{'margin-bottom':'20px'}}>
          <Typography variant="h5">Driving Licence Verify</Typography>
          </div>
        <div className="row d-flex justify-content-around">
      
            <div>
              <input
                type="text"
                name="DL"
                placeholder="DL Number"
                onChange={(e) => setInp({ ...inp, number: e.target.value })}
              ></input>
              <br />
              <br />
              <label htmlFor="DOB">Date of Birth</label>
              <br />
              <input
                label="Date"
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                name="DOB"
                placeholder="Date of Birth"
                onChange={(e) => setInp({ ...inp, dob: e.target.value })}
                style={{width:"11.5rem"}}
              ></input>
              <br />
              <br />
              <label htmlFor="Issue Date">Date of Issue</label>
              <br />
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                name="Issue Date"
                placeholder="Issue Date"
                onChange={(e) => setInp({ ...inp, issueDate: e.target.value })}
                style={{width:"11.5rem"}}
              ></input>
            </div>
        

         
     
        </div>
        <br />
        <div className="d-flex row justify-content-center">
            <Button variant="contained" style={{'backgroundColor':'orange','color':'black'}} onClick={() => fetchIdentity()}>
              Submit Details
            </Button>
        </div>
       
          <br/>
        <div className="row justify-content-center" style={{'margin-top':'20px'}}>
          <span style={{color:'green'}}><b>{verificationSuccessMessage}</b></span>
          <span style={{color:'red'}}><b>{verificationFailedMessage}</b></span>
        </div>

        <div className="row justify-content-center" style={{'margin-top':'20px'}}>
        <Button variant="contained" style={{'backgroundColor':'gray','color':'white'}} onClick={() => printDocument()}>
              Print
            </Button>
        </div>
      </Paper>
    </div>
  );
}
