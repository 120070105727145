import React, { useState } from 'react';
import { Modal,Button } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import { uploadFileToBucket } from '../../../_helpers/upload';
import { collectionService } from '../../../_services';
import BackupTwoToneIcon from '@material-ui/icons/BackupTwoTone';
const KpBulkUpload = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [uploadedFileLink, setUploadedFileLink] = useState(null);
  const [showMessage, setShowMessage] = useState('');
  const bucket = process.env.REACT_APP_s3sblBucket;
  const randomUid = uuidv4();

  const handleOpenModal = () => setIsModalOpen(true);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setUploadedFileLink(null);
    setShowMessage('');
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    handleFileUpload(e.dataTransfer.files[0]);
  };

  const handleFileInput = (e) => {
    handleFileUpload(e.target.files[0]);
  };

  const handleFileUpload = async (file) => {
    setSpinner(true);

    const fileExtension = file.name.split('.').pop().toLowerCase();
    if (fileExtension !== 'csv') {
      setShowMessage('Invalid file type. Only CSV files are allowed.');
      setSpinner(false);
      return;
    }

    try {
      const link = await uploadFileToBucket(bucket,'application/csv',`${bucket}/bulkupload/${randomUid}.csv`,file);
      console.log('link is',link)
      setUploadedFileLink(link);
     } catch (error) {
      setShowMessage('Error uploading file. Please try again.');
    } finally {
      setSpinner(false);
    }
  };

  const handleSubmit = async () => {
    setSpinner(true);
    setShowMessage(`Uploading file. Please don't reload the page.`);
    try {
      const response = await collectionService.kpAssignment({ csv_path: uploadedFileLink });
      setShowMessage(response.message);
    } catch {
      setShowMessage('Unable to upload! Reload the page and try again.');
    } finally {
      setSpinner(false);
    }
  };

  return (
    <div style={{float:'right'}}>
      <button style={styles.uploadBtn} onClick={handleOpenModal}>
        Upload CSV
      </button>

      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <div style={styles.modalContainer}>
          <h2 style={styles.modalTitle}>Bulk Upload KP Allocation</h2>
          {showMessage && <p style={styles.message}>{showMessage}</p>}
          {spinner && <div className="spinner-border spinner-border-lg align-center"></div>}

          <div
            style={styles.dropBox}
            onDragOver={(e) => e.preventDefault()}
            onDrop={handleFileDrop}
            onClick={() => document.getElementById('fileInput').click()}
          >
            <input
              type="file"
              accept=".csv"
              id="fileInput"
              style={{ display: 'none' }}
              onChange={handleFileInput}
            />
            <div>

            <BackupTwoToneIcon style={{    fontSize: '-webkit-xxx-large'}}/>
            <p style={styles.dropBoxText}>
              Drag and drop your CSV file  
            </p>
            <p>
              or
            </p>
            <Button variant='outlined' color="secondary">Browse File</Button>
            </div>
          </div>

          {uploadedFileLink && (
            <div style={styles.fileActions}>
              <a
                href={uploadedFileLink}
                target="_blank"
                rel="noopener noreferrer"
                style={styles.viewFileLink}
              >
                View Uploaded File
              </a>
            </div>
          )}

          <a
            href="https://sbl-credit-uat.s3.ap-south-1.amazonaws.com/sbl-credit-uat/bulkupload/fc137895-e6ad-4c16-9cfe-5571120346ff.csv"
            style={styles.referenceLink}
          >
            View Reference CSV File
          </a>

          <button style={styles.submitBtn} onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </Modal>
    </div>
  );
};

const styles = {
  uploadBtn: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    fontSize: '1rem',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  modalContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#D5DCDE',
    padding: '20px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    width: '60vw',
    height: '75vh',
    borderTopRightRadius: '5rem',
    borderBottomLeftRadius: '5rem', 
     textAlign: 'center',
     alignContent:'center',
    backgroundColor: '#f5f5f5',
  },
  modalTitle: {
    marginBottom: '20px',
    color: '#9e0000',
  },
  message: {
    margin: '10px 0',
    color: '#007bff',
  },
  dropBox: {
    border: '2px dashed #c1c4c7',
    borderRadius: '8px',
    padding: '40px',
    backgroundColor: '#f9f9f9',
    cursor: 'pointer',
    height: '200px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dropBoxText: {
    margin: 0,
    color: '#007bff',
    fontSize: '1rem',
  },
  fileActions: {
    marginTop: '20px',
  },
  viewFileLink: {
    textDecoration: 'none',
    color: 'green',
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  referenceLink: {
    display: 'block',
    marginTop: '10px',
    textDecoration: 'none',
    color: '#007bff',
    fontSize: '1rem',
  },
  submitBtn: {
    marginTop: '20px',
    padding: '10px 20px',
    border: 'none',
    backgroundColor: '#007bff',
    color: '#fff',
    fontSize: '1rem',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
};

export default KpBulkUpload;
