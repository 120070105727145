import React, { useState, useEffect, useContext } from 'react';
import { TabContext } from '../TabBar';
import { employeeService } from '../../../../_services';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PreviewModal from '../../Applications/ApplicationData/UploadDocuments/PreviewModal';
import urlhelper from '../../../../_helpers/urlhelper';

const PdUploadedPreview = () => {
  const TabContextValue = useContext(TabContext);
  const cust = TabContextValue.cust;
  
  const [employeeList, setEmployeeList] = useState({});
  const [allDocuments, setAllDocuments] = useState([]);
  
  const baseUrl = window.location.origin;

  // Fetch the list of employees
  const getAllEmployees = async () => {
    const res = await employeeService.getAll();
    const list = {};
    res.forEach((e) => {
      list[e.emp_id] = `${e.first_name} ${e.last_name}`;
    });
    setEmployeeList(list);
  };

  // Gather documents that have a URL
  const gatherDocuments = () => {
    const docs = [];
    if (cust?.documents_preview?.length > 0) {
      cust.documents_preview.forEach((doc) => {
        if (/PD Pics/.test(doc.category) && doc.url) {
          docs.push({
            uri: doc.url,
            category: doc.category,
            comment: doc.comment,
          });
        }
      });
    }
    setAllDocuments(docs);
  };

  useEffect(() => {
    gatherDocuments(); // Gather documents once the component mounts
  }, [cust]);

  useEffect(() => {
    getAllEmployees(); // Get the employee list when the component mounts
  }, []);

  // Handle copying the document link to clipboard
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        alert('Link copied to clipboard!');
      })
      .catch((err) => {
        alert('Failed to copy text: ', err);
      });
  };

  return (
    <div>
      <PreviewModal documents={allDocuments} />
      
      {cust?.documents_preview?.length > 0 && (
        <>
          <h3>PD Attachments</h3>
          {
            // Check if any document category matches "PD Pics"
            cust.documents_preview.some(val => /PD Pics/.test(val.category)) && (
              <table style={styles.table}>
                <thead>
                  <tr>
                    <th style={styles.tableHeader}>Category</th>
                    <th style={styles.tableHeader}>Added By</th>
                    <th style={styles.tableHeader}>Document Link</th>
                    <th style={styles.tableHeader}>Comment</th>
                    <th style={styles.tableHeader}>Copy Link</th>
                  </tr>
                </thead>
                <tbody>
                  {cust.documents_preview.map((val, idx) => {
                    // Check if the category matches "PD Pics"
                    if (/PD Pics/.test(val.category)) {
                      return (
                        <tr key={idx} style={styles.tableRow}>
                          <td style={styles.tableData}>{val.category}</td>
                          <td style={styles.tableData}>{employeeList[val.created_by]}</td>
                          <td style={styles.tableData}>
                            {val.url && val.url !== "" && (
                              <a
                                href={`/home/pdfviewer?pdf_url=${urlhelper.encodeToBase64(val.url)}&download=1`}
                                target='_blank'
                                rel="noopener noreferrer"
                              >
                                View Document
                              </a>
                            )}
                          </td>
                          <td style={styles.tableData}>{val.comment}</td>
                          {val.url && val.url !== "" && (
                            <td
                              style={styles.tableData}
                              onClick={() =>
                                handleCopy(`${baseUrl}/home/pdfviewer?pdf_url=${urlhelper.encodeToBase64(val.url)}&download=1`)
                              }
                            >
                              <FileCopyIcon />
                            </td>
                          )}
                        </tr>
                      );
                    }
                    return null; // Return null if no match
                  })}
                </tbody>
              </table>
            )
          }
        </>
      )}
    </div>
  );
};

// Styles for the table and other elements
const styles = {
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  tableHeader: {
    border: "1px solid",
    padding: "10px",
    backgroundColor: "#f4f4f4",
    textAlign: "left",
  },
  tableRow: {
    border: "1px solid",
    padding: "10px",
  },
  tableData: {
    border: "1px solid",
    padding: "10px",
    cursor: "pointer",
  },
};

export default PdUploadedPreview;
