import React, { useEffect, useState } from 'react'
import { useReactToPrint } from "react-to-print";
import { loanService } from '../../../_services';
import capitalfinance from "../../../assets/capitalfinance.png";
import logo from "../../../assets/kaabil.png";

const VernacularDeclaration = (props) => {

    const data = props.data
    const branches = props.branches
    // const VernacularDeclarationRef = React.useRef();



    // const handleSubmissionPrint = useReactToPrint({
    //     content: () => VernacularDeclarationRef.current,
    //     pageStyle: "print",
    // });

    const headerDiv = () => (
        <div>
          <img src={logo} style={{ height: "100px" }} alt="KFPL Logo" />
          
            <img
              src={capitalfinance}
              style={{
                height: "100px",
                position: "absolute",
                top: "2",
                right: "0",
                marginRight: "3rem",
              }}
              alt="Capital Finance Logo"
            />
          
          
        </div>
      );
    return (
        <>
            {/* <div style={{ textAlign: "center" }}>
                <button onClick={handleSubmissionPrint}>Print Letter</button>
            </div> */}

            <div
                style={{
                    width: "290mm",
                    height: "410mm",
                    backgroundColor: "white",
                    padding: 40,
                    position: "relative",
                    fontSize: "20px"
                }}
            >
                {headerDiv()}<br/><br/>
                <span style={{ marginLeft: "40%" }}><b>DECLARATION</b></span><br />
                <span style={{ marginLeft: "25%" }}><b>(Signing in Vernacular Language / Thumb Impression)</b></span><br /><br /><br />
                <div style={{ textAlign: "justify" }}>
                    <span>
                        This Declaration is executed at &nbsp;{branches.branch_city}, on this ____________ day of _________________ 20__
                        I, _____________________________________________________________, aged ________ years, a relative / friend
                        of __________________________________________________________, and residing at
                        ______________________________________________________________________________________, do hereby
                        state and declare as under:
                    </span><br /><br />

                    <div>
                        <span>{data && data.applicant && data.applicant.general_information && data.applicant.general_information.name}</span><br /><br />
                        {data && data.co_applicants && data.co_applicants.length > 0 && data.co_applicants.map((e, idx) => {
                            return <div>
                                <span>{e.general_information.name}</span><br /><br />
                            </div>
                        })}
                    </div>

                    <span>
                        I declare that I have read out and explained to the Borrower/s in vernacular (the language known to the
                        Borrower/s), the contents and nature of the loan agreement and other transaction documents executed / to be
                        executed by the Borrower/s (including this Declaration) to avail the Loan from the Lenders.
                    </span><br /><br />

                    <span>
                        I confirm that the Borrower/s have understood fully and have agreed to abide by, all the terms and conditions
                        of the loan agreement and other transaction documents and have acknowledged and accepted the same in
                        my presence.
                    </span><br /><br />

                    <span>
                        ______________________________________
                        Signature of Deponent
                    </span><br /><br />

                    <span>
                        I/We, the Borrower/s do hereby confirm that I/we have been explained the contents of the loan agreement and
                        all other documents signed by me/ us (including this declaration), to avail the Loan and I/we have understood
                        and accepted the same to my/ our complete satisfaction.
                    </span><br />

                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span style={{ width: "300px" }}>Name of the Borrower/s</span>
                        <span style={{ width: "300px" }}>Signature/ Thumb Impression</span>
                    </div><br />
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span span style={{ width: "300px" }}>{data && data.applicant && data.applicant.general_information && data.applicant.general_information.name}</span><br />
                        <span span style={{ width: "200px" }}>________________________</span><br /><br />
                    </div>
                    {data && data.co_applicants && data.co_applicants.length > 0 && data.co_applicants.map((e, idx) => {
                        return <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span span style={{ width: "300px" }}>{e.general_information.name}</span><br />
                            <span span style={{ width: "200px" }}>________________________</span><br /><br />
                        </div>
                    })}
                </div>
            </div>
        </>
    )
}

export default VernacularDeclaration