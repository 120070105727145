import React from "react";
import { Button, Modal, Typography, Box, MenuItem, Select } from "@material-ui/core"; // Added Select and MenuItem
import roleChecker from "../../_helpers/rolechecker";
import { makeStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { reportsService } from "../../_services/reports.service";
import { Input } from "antd";
import { employeeService } from "../../_services";
import { icicienachService } from "../../_services/icicienach.service";
const { Search } = Input;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 820,
  height: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
};

export default function MyTasksList() {
  const user = employeeService.userValue; // Example for the current user's branch ID
  const [applicants, setApplicants] = React.useState([]);
  const [spinner, setSpinner] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [searchFilter, setSearchFilter] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [employees, setEmployees] = React.useState([]);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [paymentData, setPaymentData] = React.useState(null);
  const [branchFilter, setBranchFilter] = React.useState("all"); // New state for branch filter

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  let branchId = "";
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  React.useEffect(() => {
    let isMounted = true; // Track if the component is still mounted
    
    const getNotRegisteredenachcount = () => {
      if (roleChecker.isASMLevelAccess(user.role) ||  ["ACM","ACCM","RSM","RCM","RCCM","ZCCM"].includes(user.role) || roleChecker.isZSMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isCollectionManager(user.role)||["BM"].includes(user.role)|| ["BCM"].includes(user.role)) {
        if (roleChecker.isASMLevelAccess(user.role)|| ["ACM","ACCM"].includes(user.role)) {
           branchId =  {area_id : user.branch_id}
         }
         else if (roleChecker.isZSMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || ["ZCCM"].includes(user.role)) {
           branchId =  { zone_id: user.branch_id}
         } 
         else if ( ["RSM","RCM","RCCM"].includes(user.role)) {
           branchId = { region_id: user.branch_id}
         }else if (roleChecker.isBMLevelAccess(user.role)  || roleChecker.isBCMLevelAccess(user.role)) {
          branchId =  { branch_id: user.branch_id}
        } 
         else {
            console.log('User role ---->>> ')
         }
      
        reportsService
        .getNotregisterdata({ role:user.role, enach_status:"Not Registered",...branchId})
          .then((response) => {
            setApplicants(response);
          })
          .catch((err) => {
            console.log(err);
          });
      } else if ((["CEO","CFO","Admin","HOCL","PIVG"].includes(user.role)||roleChecker.isCollectionManager(user.role))){
        reportsService
          .getNotregisterdata({enach_status: "Not Registered"})
          .then((response) => {
            setApplicants(response);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      else {
        console.log("data not found")
      }
    };
    getNotRegisteredenachcount();
    return () => {
      isMounted = false; // Cleanup on component unmount
    };
  }, [user]);

  // Filter applicants based on the search input and branch filter
  const filteredApplicants = applicants?.filter((applicant) => {
    const matchesSearch = applicant?.application_id?.toLowerCase()?.includes(searchFilter?.toLowerCase());
    const matchesBranch = branchFilter === "all" || applicant?.branch_id === branchFilter;
    return matchesSearch && matchesBranch;
  });


  const handleViewDetailsClick = async (applicationid) => {
    try {
      const response = await icicienachService.getEnach({ loan_account: applicationid });
      console.log(response, "responseresponse");
      setPaymentData(response); // Store the payment data in the state
      setModalVisible(true); // Open the modal when the data is fetched
    } catch (error) {
      console.error("Error fetching ENACH payments:", error);
    }
  };

  return (
    <div>
      <h1>Enach Not Registered</h1>

      <div className="d-flex justify-content-between">
        <Search
          onChange={(e) => setSearchFilter(e.target.value)}
          placeholder="Search Only Loan No."
          enterButton
          style={{
            position: "sticky",
            top: "0",
            left: "0",
            width: "200px",
            marginTop: "2vh",
          }}
        />
        <Select
          value={branchFilter}
          onChange={(e) => setBranchFilter(e.target.value)}
          displayEmpty
          style={{ width: "200px", marginLeft: "1rem" }}
        >
          <MenuItem value="all">Select All Branch </MenuItem>
  {[...new Set(applicants.map((applicant) => applicant?.branch_id))].map((branchId, idx) => {
    // Find the branch name corresponding to the branch_id
    const branch = applicants.find((applicant) => applicant.branch_id === branchId);
    return (
      <MenuItem key={idx} value={branchId}>
        {branch?.branch_name} ({branchId})
      </MenuItem>
    );
  })}
        </Select>
      </div>
      <br />

      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th style={{ width: "12%" }}>Applicant Name</Th>
                            <Th style={{ width: "12%" }}>Loan NO.</Th>
                            <Th style={{ width: "12%" }}>Branch Name & ID</Th>
                            <Th style={{ width: "12%" }}>RO</Th>
                            <Th style={{ width: "12%" }}>Enach Comment</Th>
                            <Th style={{ width: "10%" }}>ENACH History</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredApplicants.length > 0 ? (
              filteredApplicants
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((applicant, idx) => (
                  <Tr key={idx}>
                    <Td>{applicant?.applicant_name}</Td>
                    <Td>{applicant?.application_id}</Td>
                    <Td>{applicant?.branch_name} ({applicant?.branch_id})</Td>
                     <Td>{applicant.ro_name}</Td>
                    <Td>{applicant?.enach_comment}</Td>
                    <Td>
                      <Button
                        onClick={() => handleViewDetailsClick(applicant?.application_id)}
                        size="small"
                        color="primary"
                      >
                        View History
                      </Button>
                    </Td>
                  </Tr>
                ))
            ) : (
              <Tr>
                <Td colSpan="6" className="text-center">
                  {searchFilter ? (
                    <Typography variant="h6" color="textSecondary">
                      Data not found for "{searchFilter}"
                    </Typography>
                  ) :spinner ? (
                    <span className="spinner-border spinner-border-lg align-center"></span>
                  ) : (
                    <Typography variant="h6" color="textSecondary">
                    No applicants found
                  </Typography>
                  )}
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>

      {filteredApplicants?.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredApplicants.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
      {/* Existing Modal Code */}
      <Modal
        open={modalVisible}
        onClose={() => setModalVisible(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            gutterBottom
          >
           ENACH History
          </Typography>

          {paymentData && paymentData.length > 0 ? (
        <Table>
          <Thead>
            <Tr>
            <Th><strong>S No.</strong></Th>
              <Th><strong>Loan Number</strong></Th>
              <Th><strong>Amount</strong></Th>
              <Th><strong>Date</strong></Th>
              <Th><strong>Enach Status</strong></Th>
              <Th><strong>Customer</strong></Th>
              <Th><strong>Bank Name</strong></Th>
              <Th><strong>EMI Start</strong></Th>
              <Th><strong>EMI End</strong></Th>
            </Tr>
          </Thead>
          <Tbody>
            {paymentData && paymentData?.slice().reverse().map((e, index) => (
              <Tr key={e._id}>
                 <Td>{index +1}</Td>
                <Td>{e.loan_number}</Td>
                <Td>{e.emi_amount}</Td>
                <Td>{new Date(e.updated).toLocaleDateString()}</Td>
                <Td style={{border:"1px solid", padding:"5px"}}><br />
              {e.enach_status == 'Active' && (
                <p style={{ color: "green" }}>Active</p>


              )}

              {e.enach_status == 'Initiated' && (
                <p style={{ color: "blue" }}>Initiated</p>
              )}
              {e.enach_status == 'Failed' && (
                <p style={{ color: "red" }}>Failed</p>
              )}
              {e.enach_status == 'Pending' && (
                <p>Pending</p>
              )}
              {
                ((e.enach_status == 'Pending' || e.enach_status == 'Failed' || e.enach_status == 'Initiated') && (
                  <p>{e.enach_form_id}<br />
                    {e.reference_code}</p>
                ))
              }
              {
                (e.enach_status == 'Failed' && e.enach_status_comment && (
                  <p>Fail Reason Msg: {e.enach_status_comment}<br />
                  </p>
                ))
              }

              {
                (e.enach_status == 'Active' && (
                  <>
                    <p>Request Id: {e.reference_code}</p><br />
                    <p>Bank Ref: {e.mandate_reference}</p>
                    <br />
                    <p>Mandate Token: {e.mandate_token}</p>
                  </>
                ))
              }
              {
                (e.enach_status == 'Initiated' && (
                  <>
                    <p>Request Id: {e.reference_code}</p><br />
                    <p>Mandate Token: {e.mandate_token}</p>
                  </>
                ))
              }
              {
                ((e.enach_status == 'Pending' || e.enach_status == 'Failed') && (
                  <></>
                  // <Link onClick={() => resendEnach(e.reference_code)}>Request Again</Link>
                ))
              }
            </Td>
            <Td style={{border:"1px solid", padding:"5px"}}>{e.name}<br />{e.contact}</Td>
            <Td style={{border:"1px solid", padding:"5px"}}>{e.bank_name}</Td>
            <Td style={{border:"1px solid", padding:"5px"}}>{new Date(e.start_date).toLocaleDateString()}</Td>
            <Td style={{border:"1px solid", padding:"5px"}}>{new Date(e.end_date).toLocaleDateString()}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <Typography variant="body1" color="text.secondary">
           ENACH History is Not  available.
        </Typography>
      )}
       
        </Box>
      </Modal>
    </div>
  );
}
