import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { State, City } from "country-state-city";
import { customerService } from "../../_services";
import { Stepper } from "@material-ui/core";
import CameraAltOutlinedIcon from "@material-ui/icons/CameraAltOutlined";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import { Step } from "@material-ui/core";
import { StepLabel } from "@material-ui/core";
import Webcam from "react-webcam";
import { uploadFile } from "../../_helpers/upload";
import "./customerAddEdit.css";
import { loanService } from '../../_services';
import { Box, Button, Modal } from '@material-ui/core'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import AadharOtpVerifyModal from "./AadharOtpVerifyModal";
function CustomerAddEdit() {
  const { aadhaar } = useParams();
  const [step, setStep] = useState(1);
  const [skip, setSkip] = useState(false);
  const [same, setSame] = useState(false);
  const [id, setId] = useState();
  const aadhaarforntRef = React.useRef(null);
  const aadhaarbackRef = React.useRef(null);
  const [value, setValue] = useState({
    verification_details: {
      aadhaar_no: "",
      aadhaarFront: "",
      aadhaarBack: "",
    },
    general_details: {
      customer_title: "Mr.",
      customer_firstname: "",
      customer_middlename: "",
      customer_lastname: "",
      customer_mobile_num: "",
      fathername: "",
      mothername: "",
      customer_dob: "",
      birthplace: "",
      citizenship: "Indian",
      email: "",
      gender: "Male",
      mobile_no_verified: false
    },
    personal_details: {
      mother_tounge: "Hindi",
      communication_lang: "Hindi",
      category: "General",
      marital_status: "unmarried",
      name_of_spouce: "",
      date_of_marriage: "",
      religion: "hindu",
      mentally_challenged: "no",
      physically_challenged: "no",
      educational_qualification: "below_ssc",
      pan_no: "",
      correspondence_address: {
        address_type: "residential",
        address_line_1: "",
        state: "RJ",
        district: "Jaipur",
        city: "",
        pin_code: "",
      },
      permanent_address: {
        address_type: "residential",
        address_line_1: "",
        state: "RJ",
        district: "Jaipur",
        city: "",
        pin_code: "",
      },
    },
    economic_details: {
      annual_income: "less than 1 lakh",
      occupation_type: "private",
      source_of_income: "none",
      organisation: "None",
      designation: "",
      net_worth: "",
      bank_details: {
        bank_name: '',
        account_number: '',
        ifsc_code: '',
        account_holder: '',
      },
      form60: 'Yes',
      other_pid: ''
    },
  });
  useEffect(() => {
    async function getCustomers() {
      {
        aadhaar
          ? await customerService
            .findCustomerData({ _id: aadhaar })
            .then((x) => {
              console.log(x);
              setValue(x[0]);
              setId(x[0]._id);
              if(x[0]&&x[0].general_details&&x[0].general_details.mobile_no_verified){
                setVerified(x[0].general_details.mobile_no_verified)
              }
            })
          : console.log("add");
      }
    }
    getCustomers();
  }, []);

  const handleChange = (e) => {
    if (step === 1) {
      setValue({
        ...value,
        verification_details: {
          ...value.verification_details,
          [e.target.name]: e.target.value,
        },
      });
    } else if (step === 2) {
      setValue({
        ...value,
        general_details: {
          ...value.general_details,
          [e.target.name]: e.target.value,
        },
      });
    } else if (step === 3) {
      setValue({
        ...value,
        personal_details: {
          ...value.personal_details,
          [e.target.name]: e.target.value,
        },
      });
    } else if (step === 4) {
      e.target.className === 'bank field' ? setValue({
        ...value,
        economic_details: {
          ...value.economic_details,
          bank_details: {
            ...value.economic_details.bank_details, [e.target.name]: e.target.value,
          }
        },
      }) : setValue({
        ...value,
        economic_details: {
          ...value.economic_details,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  const handleCorrespondenceAddress = (e) => {
    setValue({
      ...value,
      personal_details: {
        ...value.personal_details,
        correspondence_address: {
          ...value.personal_details.correspondence_address,
          [e.target.name]: e.target.value,
        },
      },
    });
  };

  const sameAddress = (e) => {
    setSame(!same);
    e.target.checked
      ? setValue({
        ...value,
        personal_details: {
          ...value.personal_details,
          permanent_address: {
            ...value.personal_details.permanent_address,
            address_type:
              value.personal_details.correspondence_address.address_type,
            address_line_1:
              value.personal_details.correspondence_address.address_line_1,
            state: value.personal_details.correspondence_address.state,
            district: value.personal_details.correspondence_address.district,
            city: value.personal_details.correspondence_address.city,
            pin_code: value.personal_details.correspondence_address.pin_code,
          },
        },
      })
      : setStep(2);
  };
  const handlePermanentAddress = (e) => {
    same == false
      ? setValue({
        ...value,
        personal_details: {
          ...value.personal_details,
          permanent_address: {
            ...value.personal_details.permanent_address,
            [e.target.name]: e.target.value,
          },
        },
      })
      : setStep(2);
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    await customerService
      .updateCustomer(id, value)
      .then(() => {
        window.alert("Updated Successfully");
        window.history.back();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    step === 2
      ? await customerService
        .create(
          value
          //   {
          //   verification_details: { ...value.verification_details },
          //   general_details: { ...value.general_details },
          // }
        )
        .then(() => {
          window.alert("Customer created");
          window.history.back();
        })
        .catch((error) => (error ? alert("some error occured") : null))
      : await customerService
        .create(value)
        .then(() => {
          window.alert("Customer created");
          window.history.back();
        })
        .catch((error) => (error ? alert("some error occured") : null));
  };

  const capturefront = React.useCallback(
    async (number) => {
      if (number) {
        const front = aadhaarforntRef.current.getScreenshot();
        const link = await uploadFile(`${number}/front.jpg`, front);
        setValue((value) => ({
          ...value,
          verification_details: {
            ...value.verification_details,
            aadhaarFront: link,
          },
        }));
      } else {
        alert("Please enter 12 digit Aadhaar Number");
      }
    },
    [aadhaarforntRef]
  );
  const captureback = React.useCallback(
    async (number) => {
      if (number) {
        const back = aadhaarbackRef.current.getScreenshot();
        const link1 = await uploadFile(`${number}/back.jpg`, back);
        setValue((value) => ({
          ...value,
          verification_details: {
            ...value.verification_details,
            aadhaarBack: link1,
          },
        }));
      } else {
        alert("Please enter 12 digit Aadhaar Number");
      }
    },
    [aadhaarbackRef]
  );
  const videoConstraints = {
    width: 350,
    height: 230,
    facingMode: "user",
  };
  const discard = (id) => {
    if (id === "front") {
      setValue((value) => ({
        ...value,
        verification_details: {
          ...value.verification_details,
          aadhaarFront: "",
        },
      }));
    } else {
      setValue((value) => ({
        ...value,
        verification_details: {
          ...value.verification_details,
          aadhaarBack: "",
        },
      }));
    }
  };

  const autofiller = (data, setValue) => {
    const name = data.name.split(" ");
    setValue({
      ...value,
      general_details: {
        ...value.general_details,
        customer_firstname: name[0],
        gender: data.gender,
        fathername: data.summary.guradianName,
        birthplace: data.splitAddress.district[0],
        gender: data.gender,
        dob: data.dob.split("/").reverse().join("-"),
        birthplace: data.splitAddress.district[0],
      },
      personal_details: {
        ...value.personal_details,
        correspondence_address: {
          ...value.personal_details.permanent_address,
          address_line_1: data.splitAddress.addressLine,
          city: data.splitAddress.city[0],
          district: data.splitAddress.district[0],
          state: data.splitAddress.state[0][0],
          pin_code: data.splitAddress.pincode,
        },
      },
    });
  };


  const next = (event) => {
    event.preventDefault();
    step !== 4 ? setStep(step + 1) : setStep(1);
  };
  const handleAadharVerificationSuccess = () => {
    step !== 4 ? setStep(step + 1) : setStep(1);
  };
  const back = () => {
    step !== 1 ? setStep(step - 1) : setStep(4);
  };
  const steps = [
    "Varification",
    "General Details",
    "Personal Details",
    "Economic Details",
  ];
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 490,
    height: 150,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const contact = value.general_details.customer_mobile_num
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const [OtpValue, setOtpValue] = React.useState({})
  const handleChangeOtp = (e) => {
    OtpValue[e.target.name] = e.target.value
    setOtpValue(OtpValue)
  }

  const [otpResponse, setOtpResponse] = useState({})
  const otp = Math.floor(1000 + Math.random() * 9000);
  const sendOtp = () => {
    loanService.sendOtp({ contact: contact, otp: otp })
      .then((data) => {
        setOtpResponse(data.otp)
      })
      .catch((err) => console.log(err))
  }
  const [verified, setVerified] = useState(false)
  const verifyOtp = () => {
    if (OtpValue.otp == otpResponse) {
      setValue({ ...value, general_details: { ...value.general_details, ['mobile_no_verified']: true } })
      alert("Otp verified")
      setVerified(true)
      handleClose()
    } else {
      alert("Incorrect Otp")
    }
  }
  const handleChangeContact = () => {
    setVerified(false)
  }
  const handleNext = () => {
    return alert("First verify contact")
  }
// console.log("Test model set  in current ",value)
  return (
    <div
      style={{
        display: "flex",
        margin: "auto",
        maxWidth: "1000px",
        flexDirection: "column",
      }}
    >
      <Stepper
        style={{ marginTop: "50px", marginBottom: "30px" }}
        activeStep={step - 1}
        alternativeLabel
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {(() => {
        if (step === 1) {
          return (
            <form onSubmit={next}>
              <div className="formfields">
                <span
                  style={{
                    display: "flex",
                    width: "100vw",
                    justifyContent: "space-between",
                    fontSize: "30px",
                    marginTop: "50px",
                    marginBottom: "20px",
                  }}
                >
                  Upload Aadhaar
                  {/* <button
                    type="button"
                    style={{ fontSize: "20px", borderRadius: "5px" }}
                    // onClick={() => fetchIdentity(setValue)}
                  >
                    Fetch Details
                  </button> */}
                  <AadharOtpVerifyModal
                    customer={value.verification_details}
                    setVerificationDetails={(newVerificationDetails) =>
                      setValue((prevValue) => ({
                        ...prevValue,
                        verification_details: { ...prevValue.verification_details, ...newVerificationDetails }
                      }))
                    }
                    setGeneralDetails={(newGeneralDetails) =>
                      setValue((prevValue) => ({
                        ...prevValue,
                        general_details: { ...prevValue.general_details, ...newGeneralDetails }
                      }))
                    }
                    setPersonalDetails={(newPersonalDetails) =>
                      setValue((prevValue) => ({
                        ...prevValue,
                        personal_details: {
                          ...prevValue.personal_details,
                          correspondence_address: {
                            ...prevValue.personal_details.correspondence_address, ...newPersonalDetails
                          }
                        }
                      }))
                    }
                    // setVerified={() => setVerified(true)}
                    onVerificationSuccess={handleAadharVerificationSuccess}
                  />
                </span>
                <label className="label">
                  Aadhaar No.
                  <input
                    name="aadhaar_no"
                    onChange={handleChange}
                    type="text"
                    className="field"
                    value={value.verification_details.aadhaar_no}
                    placeholder="Aadhaar No."
                    pattern="[0-9]{12}"
                    title="Aadhaar no. should be of 12 numeric digits"
                    required
                  />
                </label>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    width: "100vw",
                    // marginBottom: "80px",
                  }}
                >
                  {value.verification_details.aadhaarFront ? (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span style={{ fontSize: "25px", margin: "auto" }}>
                        Fornt side
                      </span>
                      <img
                        src={value.verification_details.aadhaarFront}
                        alt="Id photo"
                      />
                      <button
                        style={{ height: "40px", border: "none" }}
                        type="button"
                        onClick={() => discard("front")}
                      >
                        <ClearOutlinedIcon />
                      </button>
                    </div>
                  ) : (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span style={{ fontSize: "25px", margin: "auto" }}>
                        Fornt side
                      </span>
                      <Webcam
                        audio={false}
                        height={230}
                        screenshotFormat="image/jpeg"
                        width={350}
                        ref={aadhaarforntRef}
                        videoConstraints={videoConstraints}
                      ></Webcam>
                      <button
                        style={{ height: "40px", border: "none" }}
                        type="button"
                        onClick={() =>
                          capturefront(value.verification_details.aadhaar_no)
                        }
                      >
                        <CameraAltOutlinedIcon />
                      </button>
                    </div>
                  )}
                  {value.verification_details.aadhaarBack ? (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span style={{ fontSize: "25px", margin: "auto" }}>
                        Back side
                      </span>
                      <img
                        src={value.verification_details.aadhaarBack}
                        alt="Id photo"
                      />
                      <button
                        style={{ height: "40px", border: "none" }}
                        type="button"
                        onClick={() => discard()}
                      >
                        <ClearOutlinedIcon />
                      </button>
                    </div>
                  ) : (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span style={{ fontSize: "25px", margin: "auto" }}>
                        Back side
                      </span>
                      <Webcam
                        audio={false}
                        height={230}
                        screenshotFormat="image/jpeg"
                        width={350}
                        ref={aadhaarbackRef}
                        videoConstraints={videoConstraints}
                      ></Webcam>
                      <button
                        style={{ height: "40px", border: "none" }}
                        type="button"
                        onClick={() =>
                          captureback(value.verification_details.aadhaar_no)
                        }
                      >
                        <CameraAltOutlinedIcon />
                      </button>
                    </div>
                  )}
                </div>
              </div>
              {/* {value.verification_details.aadhaarFront &&
                value.verification_details.aadhaarBack ? (
                <div className="btncontainer">
                  <button className="btnblue"> Next </button>
                </div>
              ) : null} */}
            </form>
          );
        } else if (step === 2) {
          return (
            <>
              <h1>General Details</h1>
              <form onSubmit={skip === true ? handleSubmit : next}>
                <div className="formfields">
                  <label className="label">
                    Title
                    <select
                      name="customer_title"
                      onChange={handleChange}
                      className="field"
                      value={value.general_details.customer_title}
                    >
                      <option value="Mr.">Mr.</option>
                      <option value="Mrs.">Mrs.</option>
                    </select>
                  </label>
                  <label className="label">
                    First Name
                    <input
                      name="customer_firstname"
                      onChange={handleChange}
                      type="text"
                      className="field"
                      value={value.general_details.customer_firstname}
                      placeholder="First Name"
                      required
                    />
                  </label>
                  <label className="label">
                    Middle Name
                    <input
                      name="customer_middlename"
                      onChange={handleChange}
                      type="text"
                      className="field"
                      value={value.general_details.customer_middlename}
                      placeholder="Middle Name"
                    />
                  </label>
                  <label className="label">
                    Last Name
                    <input
                      name="customer_lastname"
                      onChange={handleChange}
                      type="text"
                      className="field"
                      value={value.general_details.customer_lastname}
                      placeholder="Last Name"
                      required
                    />
                  </label>
                  <div style={{ width: "210px", marginTop: "22px", marginLeft: "20px" }}>
                    <label>
                      Mobile Number
                      <input
                        style={{ width: "130px", borderRadius: "5px", height: "30px", border: "1px solid grey" }}
                        name="customer_mobile_num"
                        onChange={(e) => { handleChange(e); handleChangeContact() }}
                        type="text"
                        className="field"
                        pattern="[0-9]{10,10}"
                        title="Mobile number should be of 10 numeric digit"
                        value={value.general_details.customer_mobile_num}
                        placeholder="Mobile Number"
                        size={10}
                        required
                      />
                      {(verified === true)?
                       <span style={{ color: "blue", height: "33px" }}><VerifiedUserIcon style={{ width: "75px", height: '30px' }} /></span> :
                        <Button style={{ backgroundColor: "green", color: "white", width: "35px", height: "33px", borderRadius: "5px" }} onClick={() => (contact.length === 10 ? (sendOtp(), handleOpen()) : alert("Please Enter valid Number"))}>Verify</Button>}
                    </label>
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                          <div>
                            <input style={{ marginLeft: "15px", width: "150px", borderRadius: "5px", marginTop: "30px" }} type='number' name='otp' onChange={handleChangeOtp} placeholder="Enter the Otp" />
                            <button style={{ marginLeft: "15px", width: "100px", height: "25px", backgroundColor: "blue", color: "white", border: "none", borderRadius: "5px" }} onClick={verifyOtp}>Verify</button>
                            <button style={{ marginLeft: "15px", width: "100px", height: "25px", backgroundColor: "red", color: "white", border: "none", borderRadius: "5px" }} onClick={sendOtp}>Resend Otp</button>
                          </div>
                      </Box>
                    </Modal>
                  </div>
                  <label className="label">
                    Email Id
                    <input
                      name="email"
                      onChange={handleChange}
                      type="email"
                      className="field"
                      value={value.general_details.email}
                      placeholder="Email"
                      size={10}
                    // required
                    />
                  </label>
                  {/* <label className="label">
                    Aadhaar No.
                    <input
                      name="adhaar_no"
                      onChange={handleChange}
                      type="text"
                      className="field"
                      value={value.general_details.adhaar_no}
                      placeholder="Aadhaar No."
                      pattern="[0-9]{12}"
                      title="Aadhaar no. should be of 12 numeric digits"
                      required
                    />
                  </label> */}
                  <label className="label">
                    Father's Name
                    <input
                      name="fathername"
                      onChange={handleChange}
                      type="text"
                      className="field"
                      value={value.general_details.fathername}
                      placeholder="Father's Name"
                      required
                    />
                  </label>
                  <label className="label">
                    Mother's Name
                    <input
                      name="mothername"
                      onChange={handleChange}
                      type="text"
                      className="field"
                      value={value.general_details.mothername}
                      placeholder="Mother's Name"
                      required
                    />
                  </label>
                  <label className="label">
                    Date Of Birth
                    <input
                      name="customer_dob"
                      onChange={handleChange}
                      type="Date"
                      onKeyDown={(e) => e.preventDefault()}
                      className="field"
                      value={value.general_details.customer_dob}
                      placeholder="Date Of Birth"
                      required
                    />
                  </label>
                  <label className="label">
                    Place Of Birth
                    <input
                      name="birthplace"
                      onChange={handleChange}
                      type="text"
                      className="field"
                      value={value.general_details.birthplace}
                      placeholder="Place Of Birth"
                      required
                    />
                  </label>
                  <label className="label">
                    Gender
                    <select
                      name="gender"
                      onChange={handleChange}
                      className="field"
                      value={value.general_details.gender}
                      id="title"
                    >
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                  </label>
                  <label className="label">
                    Citizenship
                    <select
                      name="citizenship"
                      onChange={handleChange}
                      className="field"
                      id="title"
                      value={value.general_details.citizenship}
                    >
                      <option value="Indian">Indian</option>
                      <option value="Other">Other</option>
                    </select>
                  </label>
                </div>
                {id ? null : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    {/* <span>Skip further steps </span>
                    <input
                      onClick={() => setSkip(!skip)}
                      type="checkbox"
                      name="skip"
                      style={{ marginLeft: "5px" }}
                    /> */}
                  </div>
                )}

                <div className="btncontainer">
                  <button type="button" className="btnred" onClick={back}>
                    {" "}
                    Previous{" "}
                  </button>
                  {skip == true ? (
                    <button type="submit" className="btnblue">
                      {" "}
                      Submit{" "}
                    </button>
                  ) : 
                  (verified === true ?
                    <button type="submit" className="btnblue">
                      {" "}
                      Next{" "}
                    </button> : 
                    <Button onClick={() => handleNext()} style={{ backgroundColor: "blue", height: "30px", width: "150px", color: "white", borderRadius: "5px" }}>Next</Button>
                  )}
                </div>
              </form>
            </>
          );
        } else if (step === 3) {
          return (
            <div style={{ margin: "30px" }}>
              <form onSubmit={next}>
                <h1>Pesonal Details</h1>
                <div className="formfields">
                  <label className="label">
                    Mother Tounge
                    <input
                      name="mother_tounge"
                      onChange={handleChange}
                      type="text"
                      className="field"
                      value={value.personal_details.mother_tounge}
                      placeholder="Mother Tounge"
                      required
                    />
                  </label>
                  <label className="label">
                    Communication Language
                    <input
                      name="communication_lang"
                      onChange={handleChange}
                      type="text"
                      className="field"
                      value={value.personal_details.communication_lang}
                      placeholder="Communication Language"
                      required
                    />
                  </label>
                  <label className="label">
                    Category
                    <select
                      name="category"
                      onChange={handleChange}
                      className="field"
                      value={value.personal_details.category}
                    >
                      <option value="General">General</option>
                      <option value="OBC">OBC</option>
                      <option value="SC">SC</option>
                      <option value="ST">ST</option>
                      <option value="Weaker Section">Weaker Section</option>
                    </select>
                  </label>
                  <label className="label">
                    Religion
                    <select
                      name="religion"
                      onChange={handleChange}
                      className="field"
                      value={value.personal_details.religion}
                    >
                      <option value="hindu">Hindu</option>
                      <option value="muslim">Muslim</option>
                      <option value="christiam">Christian</option>
                      <option value="sikh">Sikh</option>
                      <option value="other">Other</option>
                    </select>
                  </label>
                  <label className="label">
                    Marital Status
                    <select
                      name="marital_status"
                      onChange={handleChange}
                      className="field"
                      value={value.personal_details.marital_status}
                    >
                      <option value="unmarried">Unmarried</option>
                      <option value="married">Married</option>
                    </select>
                  </label>
                  {value.personal_details.marital_status === "married" ? (
                    <>
                      {" "}
                      <label className="label">
                        Date of marriage
                        <input
                          name="date_of_marriage"
                          onChange={handleChange}
                          type="date"
                          onKeyDown={(e) => e.preventDefault()}
                          className="field"
                          value={value.personal_details.date_of_marriage}
                          placeholder="Date of marriage"
                        // required
                        />
                      </label>
                      <label className="label">
                        Name of spouce
                        <input
                          name="name_of_spouce"
                          onChange={handleChange}
                          type="text"
                          className="field"
                          value={value.personal_details.name_of_spouce}
                          placeholder="Name of spouce"
                          required
                        />
                      </label>
                    </>
                  ) : null}
                  <label className="label">
                    PAN NO.
                    <input
                      name="pan_no"
                      onChange={handleChange}
                      type="text"
                      className="field"
                      value={value.personal_details.pan_no}
                      pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}"
                      title="Pan no must be of a valid format"
                      placeholder="PAN NO"
                    />
                  </label>
                  <label className="label">
                    Mentally Challanged
                    <select
                      name="mentally_challenged"
                      onChange={handleChange}
                      className="field"
                      value={value.personal_details.mentally_challenged}
                    >
                      <option value="no">No</option>
                      <option value="yes">Yes</option>
                    </select>
                  </label>
                  <label className="label">
                    Physically Challanged
                    <select
                      name="physically_challenged"
                      onChange={handleChange}
                      className="field"
                      value={value.personal_details.physically_challenged}
                    >
                      <option value="no">No</option>
                      <option value="yes">Yes</option>
                    </select>
                  </label>

                  <label className="label">
                    Educational Qualification
                    <select
                      name="educational_qualification"
                      onChange={handleChange}
                      className="field"
                      value={value.personal_details.educational_qualification}
                    >
                      <option value="below_ssc">Below SSC</option>
                      <option value="ssc">SSC</option>
                      <option value="hsc">HSC</option>
                      <option value="graduate">Graduate</option>
                      <option value="illiterate">Illiterate</option>
                    </select>
                  </label>
                </div>
                <h1 style={{ marginTop: "30px" }}>Correspondence Address</h1>
                <div className="formfields">
                  <label className="label">
                    Address Type
                    <select
                      className="field"
                      name="address_type"
                      onChange={handleCorrespondenceAddress}
                      value={
                        value.personal_details && value.personal_details.correspondence_address && value.personal_details.correspondence_address
                          .address_type
                      }
                    >
                      <option value="residential">Residential</option>
                      <option value="business">Business</option>
                      <option value="unspecified">Unspecified</option>
                    </select>
                  </label>
                  <label className="label">
                    Address
                    <input
                      className="field"
                      name="address_line_1"
                      type="text"
                      placeholder="address"
                      onChange={handleCorrespondenceAddress}
                      value={
                        value.personal_details && value.personal_details.correspondence_address && value.personal_details.correspondence_address
                          .address_line_1
                      }
                      required
                    />
                  </label>
                  <label className="label">
                    State
                    <select
                      className="field"
                      name="state"
                      onChange={handleCorrespondenceAddress}
                      value={
                        value.personal_details && value.personal_details.correspondence_address && value.personal_details.correspondence_address.state
                      }
                    >
                      {State.getStatesOfCountry("IN").map((item) => (
                        <option
                          defaultValue={item.isoCode === "RJ"}
                          value={item.isoCode}
                          key={item.isoCode}
                        >
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label className="label">
                    District
                    <select
                      className="field"
                      name="district"
                      onChange={handleCorrespondenceAddress}
                      value={
                        value.personal_details && value.personal_details.correspondence_address && value.personal_details.correspondence_address.district
                      }
                    >
                      {City.getCitiesOfState(
                        "IN",
                        value.personal_details && value.personal_details.correspondence_address && value.personal_details.correspondence_address.state
                      ).map((item) => (
                        <option
                          defaultValue={item.name === "Jaipur"}
                          key={item.name}
                        >
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label className="label">
                    City
                    <input
                      type="text"
                      className="field"
                      placeholder="City"
                      name="city"
                      onChange={handleCorrespondenceAddress}
                      value={value.personal_details && value.personal_details.correspondence_address && value.personal_details.correspondence_address.city}
                      required
                    />
                  </label>
                  <label className="label">
                    Pin Code
                    <input
                      type="text"
                      className="field"
                      placeholder="Pin Code"
                      name="pin_code"
                      onChange={handleCorrespondenceAddress}
                      value={
                        value.personal_details &&  value.personal_details.correspondence_address && value.personal_details.correspondence_address.pin_code
                      }
                      pattern="[0-9]{6}"
                      title="Pin code should be of 6 numeric digit"
                      required
                    />
                  </label>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "30px",
                    alignItems: "center",
                  }}
                >
                  <h1 style={{ marginTop: "30px" }}>Permanent Address</h1>{" "}
                  <span>
                    <input
                      style={{ marginLeft: "5px" }}
                      type="checkbox"
                      onClick={sameAddress}
                    />
                    Same as above
                  </span>
                </div>
                <div className="formfields">
                  <label className="label">
                    Address Type
                    <select
                      key="permanent_address"
                      className="field"
                      name="address_type"
                      onChange={handlePermanentAddress}
                      value={
                        value.personal_details.permanent_address.address_type
                      }
                    >
                      <option value="residential">Residential</option>
                      <option value="business">Business</option>
                      <option value="unspecified">Unspecified</option>
                    </select>
                  </label>
                  <label className="label">
                    Address
                    <input
                      key="permanent_address"
                      className="field"
                      name="address_line_1"
                      type="text"
                      placeholder="address"
                      onChange={handlePermanentAddress}
                      value={
                        value.personal_details.permanent_address.address_line_1
                      }
                      required
                    />
                  </label>
                  <label className="label">
                    State
                    <select
                      className="field"
                      name="state"
                      onChange={handlePermanentAddress}
                      value={value.personal_details.permanent_address.state}
                    >
                      {State.getStatesOfCountry("IN").map((item) => (
                        <option
                          defaultValue={item.isoCode === "RJ"}
                          value={item.isoCode}
                          key={item.isoCode}
                        >
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label className="label">
                    District
                    <select
                      className="field"
                      name="district"
                      onChange={handlePermanentAddress}
                      value={value.personal_details.permanent_address.district}
                    >
                      {City.getCitiesOfState(
                        "IN",
                        value.personal_details.permanent_address.state
                      ).map((item) => (
                        <option
                          defaultValue={item.name === "Jaipur"}
                          key={item.name}
                        >
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label className="label">
                    City
                    <input
                      type="text"
                      className="field"
                      placeholder="City"
                      name="city"
                      onChange={handlePermanentAddress}
                      value={value.personal_details.permanent_address.city}
                      required
                    />
                  </label>
                  <label className="label">
                    Pin Code
                    <input
                      type="text"
                      className="field"
                      placeholder="Pin Code"
                      name="pin_code"
                      onChange={handlePermanentAddress}
                      value={value.personal_details.permanent_address.pin_code}
                      pattern="[0-9]{6}"
                      title="Pin code should be of 6 numeric digit"
                      required
                    />
                  </label>
                </div>
                <div className="btncontainer">
                  <button type="button" className="btnred" onClick={back}>
                    {" "}
                    Previous{" "}
                  </button>
                  <button type="submit" className="btnblue">
                    {" "}
                    Next{" "}
                  </button>
                </div>
              </form>
            </div>
          );
        } else {
          return (
            <div style={{ margin: "30px" }}>
              <h1>Economic Details</h1>
              <form onSubmit={id ? handleEdit : handleSubmit}>
                <div className="formfields">
                  <label className="label">
                    Annual Income
                    <select
                      name="annual_income"
                      onChange={handleChange}
                      className="field"
                      value={ value.economic_details &&  value.economic_details.annual_income}
                    >
                      <option value="less than 1 lakh">Less than 1 Lakh</option>
                      <option value="1 to 5 lakh">1 to 5 Lakh</option>
                      <option value="5 to 10 lakh">5 to 10 Lakh</option>
                      <option value="10 to 25 lakh">10 to 25 Lakh</option>
                      <option value="greater than 25 lakh">
                        Greater than 25 Lakh
                      </option>
                    </select>
                  </label>
                  <label className="label">
                    Occupation Type
                    <select
                      name="occupation_type"
                      onChange={handleChange}
                      className="field"
                      value={ value.economic_details && value.economic_details.occupation_type}
                    >
                      <option value="Private Sector">Private Sector</option>
                      <option value="Public Sector">Public Sector</option>
                      <option value="Govt Sector">Govt Sector</option>
                      <option value="Business">Business</option>
                      <option value="Profession">Profession</option>
                      <option value="Agriculture">Agriculture</option>
                      <option value="Self Employed">Self Employed</option>
                      <option value="Retired">Retired</option>
                      <option value="Housewife">Housewife</option>
                      <option value="Traders">Traders</option>
                      <option value="Student">Student</option>
                      <option value="Others">Others</option>
                    </select>
                  </label>
                  <label className="label">
                    Source of Income
                    <select
                      name="source_of_income"
                      onChange={handleChange}
                      className="field"
                      value={value.economic_details && value.economic_details.source_of_income}
                    >
                      <option value="None">None</option>
                      <option value="Salary">Salary</option>
                      <option value="Business">Business</option>
                      <option value="Agriculture">Agriculture</option>
                      <option value="Self employed">Self employed</option>
                      <option value="Pension">Pension</option>
                      <option value="Other">Other</option>
                    </select>
                  </label>
                  <label className="label">
                    Organisation
                    <input
                      name="organisation"
                      onChange={handleChange}
                      type="text"
                      className="field"
                      value={value.economic_details && value.economic_details.organisation}
                      placeholder="Organisation"
                      required
                    />
                  </label>
                  <label className="label">
                    Designation
                    <input
                      name="designation"
                      onChange={handleChange}
                      type="text"
                      className="field"
                      value={ value.economic_details && value.economic_details.designation}
                      placeholder="Designation"
                      required
                    />
                  </label>
                  <label className="label">
                    Net worth in Rupees
                    <input
                      name="net_worth"
                      onChange={handleChange}
                      type="number"
                      className="field"
                      value={ value.economic_details && value.economic_details.net_worth}
                      placeholder="Net Worth"
                      required
                    />
                  </label>
                  <label className="label">
                    Form 60
                    <input
                      name="form60"
                      onChange={handleChange}
                      type="text"
                      className="field"
                      value={ value.economic_details && value.economic_details.form60}
                      placeholder="Form 60"
                    />
                  </label>
                  <label className="label">
                    Other PID
                    <input
                      name="other_pid"
                      onChange={handleChange}
                      type="text"
                      className="field"
                      value={ value.economic_details && value.economic_details.other_pid}
                      placeholder="Other PID"
                    />
                  </label>
                </div>
                <h1 style={{ marginTop: "30px" }}>Bank Details</h1>
                <div className='formfields'>
                  <label className="label">
                    Bank Name
                    <input
                      name="bank_name"
                      onChange={handleChange}
                      type="text"
                      className="bank field"
                      value={value.economic_details && value.economic_details.bank_details && value.economic_details.bank_details.bank_name}
                      placeholder="Bank Name"
                    />
                  </label>
                  <label className="label">
                    Account Number
                    <input
                      name="account_number"
                      onChange={handleChange}
                      type="number"
                      className="bank field"
                      value={value.economic_details && value.economic_details.bank_details && value.economic_details.bank_details.account_number}
                      placeholder="Account Number"
                    />
                  </label>
                  <label className="label">
                    IFSC Code
                    <input
                      name="ifsc_code"
                      onChange={handleChange}
                      type="text"
                      className="bank field"
                      value={ value.economic_details && value.economic_details.bank_details && value.economic_details.bank_details.ifsc_code}
                      placeholder="IFSC Code"
                    />
                  </label>
                  <label className="label">
                    Account Holder
                    <input
                      name="account_holder"
                      onChange={handleChange}
                      type="text"
                      className="bank field"
                      value={ value.economic_details && value.economic_details.bank_details && value.economic_details.bank_details.account_holder}
                      placeholder="Account Holder"
                    />
                  </label>
                </div>
                <div className="btncontainer">
                  <button type="button" className="btnred" onClick={back}>
                    {" "}
                    Previous{" "}
                  </button>
                  <button type="submit" className="btnblue">
                    {id ? "Update" : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          );
        }
      })()}
      {console.log(value)}
    </div>
  );
}

export { CustomerAddEdit };
