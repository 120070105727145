import React, { useEffect, useState } from 'react'
import { useReactToPrint } from "react-to-print";
import { loanService } from '../../../_services';
import datehelper from "../../../_helpers/datehelper";
import capitalfinance from "../../../assets/capitalfinance.png";
import logo from "../../../assets/kaabil.png"
const  IrrevocablePowerOfAttorney = (props) => {

    const data = props.data
    const branches = props.branches


    // const MoeRef = React.useRef();



    // const handleSubmissionPrint = useReactToPrint({
    //     content: () => MoeRef.current,
    //     pageStyle: "print",
    // });

    const one = ["", "One ", "Two ", "Three ", "Four ", "Five ", "Six ", "Seven ", "Eight ", "Nine ", "Ten ", "Eleven ", "Twelve ", "Thirteen ", "Fourteen ", "Fifteen ",
        "Sixteen ", "Seventeen ", "Eighteen ", "Nineteen "];

    var ten = ["", "", "Twenty ", "Thirty ", "Forty ", "Fifty ", "Sixty ", "Seventy ", "Eighty ", "Ninety "];

    function numToWords(n, s) {
        let str = "";
        // if n is more than 19, divide it
        if (n > 19) {
            str += ten[parseInt(n / 10)] + one[n % 10];
        }
        else {
            str += one[n];
        }
        if (n != 0) {
            str += s;
        }

        return str;
    }

    function convertToWords(n) {
        // stores word representation of given number n
        let out = "";

        // handles digits at ten millions and hundred
        // millions places (if any)
        out += numToWords(parseInt(n / 10000000),
            "Crore ");

        // handles digits at hundred thousands and one
        // millions places (if any)
        out += numToWords(parseInt((n / 100000) % 100),
            "Lakh ");

        // handles digits at thousands and tens thousands
        // places (if any)
        out += numToWords(parseInt((n / 1000) % 100),
            "Thousand ");

        // handles digit at hundreds places (if any)
        out += numToWords(parseInt((n / 100) % 10),
            "Hundred ");

        if (n > 100 && n % 100 > 0) {
            out += "and ";
        }

        // handles digits at ones and tens places (if any)
        out += numToWords(parseInt(n % 100), "");

        return out;
    }

    const headerDiv = () => (
        <div>
          <img src={logo} style={{ height: "100px" }} alt="KFPL Logo" />
          
            <img
              src={capitalfinance}
              style={{
                height: "100px",
                position: "absolute",
                top: "2",
                right: "0",
                marginRight: "3rem",
              }}
              alt="Capital Finance Logo"
            />
          
          
        </div>
      );
    return (
        <>
            {/* <div style={{ textAlign: "center" }}>
                <button onClick={handleSubmissionPrint}>Print Letter</button>
            </div> */}

            <div
                style={{
                    width: "290mm",
                    // height:'800mm',
                    backgroundColor: "white",
                    padding: 40,
                    position: "relative",
                    fontSize: "20px"
                }}
            >{headerDiv()}<br/><br/>
                <span style={{ marginLeft: "40%" }}><b>SCHEDULE</b></span><br />
                <span style={{ marginLeft: "30%" }}><b>Irrevocable Power of Attorney</b></span><br /><br />
                <table style={{ width: "90%", marginLeft: "5%", marginTop: "20px", fontSize: "20px" }}>
                    <tr style={{ border: "1px solid" }}>
                        <td  colspan="1" style={{width:'10px', textAlign: "center", border: "1px solid" }}><b>S. NO.</b></td>
                        <td  colspan="1" style={{ textAlign: "center", border: "1px solid" }}><b>PARTICULARS</b></td>
                        <td colspan="4"  style={{ textAlign: "center", border: "1px solid" }}><b>TERMS</b></td>
                    </tr>
                    <tr style={{ border: "1px solid" }}>
                    <td style={{
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"start"
                        }}>
                           1.
                        </td>
                        <td style={{ border: "1px solid",verticalAlign:'top' }} >
                           <b>Loan </b>
                        </td>
                        <td style={{ border: "1px solid" }}> 
                      <b>  Rs. {data && data.loan && data.loan.sanction_amount}/- (Rupees {convertToWords(data && data.loan && data.loan.sanction_amount)} only)</b>
                        </td>
                    </tr>
                    <tr style={{ border: "1px solid" }}>
                    <td style={{
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"start"
                        }}>
                           2.
                        </td>
                        <td style={{ border: "1px solid" ,verticalAlign:'top'}} >
                        <b>Loan Agreement</b>
                        </td>
                        <td style={{ border: "1px solid" }}> 
                       <b> dated {datehelper.displayDate(new Date(data.loan?.agreement_stamp_date))} executed by and between the Borrowers and the 
Lenders.</b>
                        </td>
                    </tr>
                  
                    <tr style={{ border: "1px solid" }}>
                    <td style={{
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"start"
                        }}>
                           3.
                        </td>
                        <td style={{ border: "1px solid",verticalAlign:'top' }} >
                       <b> Execution</b>
                        </td>
                        <td style={{ border: "1px solid" }}> 
                        <b>Date of execution :__________________________________________________
Place of execution:__________________________________________________
</b>  </td>
                    </tr>
                    <tr style={{ border: "1px solid" }}>
                        <td style={{
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"start"
                        }}>
                           4.
                        </td>
                        <td style={{ border: "1px solid", width: "30%" ,verticalAlign:'top'}}><b>Hypothecators:</b></td>
                        <td>
                            <tr><b>1) Name: __________________________________________________________<br/>___________________________________________________________________</b></tr>
                            <tr><b>Residence/registered office/corporate office address: ____________________<br/>___________________________________________________________________<br/>___________________________________________________________________</b></tr>
                            <tr><b> PAN/CIN No.: _______________________________________________________</b></tr>
                            
                            <br /><br />
                            <tr><b>2) Name: __________________________________________________________<br/>___________________________________________________________________</b></tr>
                            <tr><b>Residence/registered office/corporate office address: ____________________<br/>___________________________________________________________________<br/>___________________________________________________________________</b></tr>
                            <tr><b> PAN/CIN No.: _______________________________________________________</b></tr>
                            
                            <br /><br />
                            <tr><b>3) Name: __________________________________________________________<br/>___________________________________________________________________</b></tr>
                            <tr><b>Residence/registered office/corporate office address: ____________________<br/>___________________________________________________________________<br/>___________________________________________________________________</b></tr>
                            <tr><b> PAN/CIN No.: _______________________________________________________</b></tr>
                            
                            <br /><br />
                            
                        </td>
                    </tr>
                    <tr style={{ border: "1px solid" }}>
                        <td style={{
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"start"
                        }}>
                           5.
                        </td>
                        <td style={{ border: "1px solid", width: "30%" ,verticalAlign:'top'}}><b>Borrowers:</b></td>
                        <td>
                            <tr><b>Name: {data && data.applicant && data.applicant.general_information && data.applicant.general_information.name}</b></tr>
                            <tr><b>Residence/registered office/corporate office address:: {data && data.applicant && data.applicant.general_information && data.applicant.general_information.address}</b></tr><br /><br />
                            {data && data.co_applicants && data.co_applicants.length > 0 && data.co_applicants.map((e, idx) => {
                                return <>
                                    <tr><b>Name: {e.general_information.name}</b></tr>
                                    <tr><b>Residence/registered office/corporate office address:: {e.general_information.address}</b></tr><br /><br />
                                </>
                            })}
                        </td>
                    </tr>
                
                    
                     
 
            
          
           
                  
                </table>

                 
            </div>
          
            <br/>  <br/>  <br/>
            <div  style={{
                    width: "290mm",
                    height: "200mm",
                    backgroundColor: "white",
                    padding: 40,
                    position: "relative",
                    fontSize: "20px"
                }}>
            <b>IN WITNESS WHEREOF</b>, this Power of Attorney has been executed at _______________on this day of ______________, in the manner hereinafter appearing.
<br/>
<b>
SIGNED AND DELIVERED by within named</b>
<br/>
<b>“Hypothecators”.</b>
<br/>
<br/>
<div style={{display:"flex", justifyContent:"space-between"}}>    
                    <span span style={{width:"300px"}}>{data && data.applicant && data.applicant.general_information && data.applicant.general_information.name}</span><br />
                    <span span style={{width:"200px"}}>________________________</span><br /><br/>
                 </div>   
                    {data && data.co_applicants && data.co_applicants.length > 0 && data.co_applicants.map((e, idx) => {
                        return <div style={{display:"flex", justifyContent:"space-between"}}>
                            <span span style={{width:"300px"}}>{e.general_information.name}</span><br />
                            <span span style={{width:"200px"}}>________________________</span><br /><br/>
                        </div>
                    })}
            </div>

            <div>
                <p style={{textAlign:'center'}}>
                Before me _______________ dated this __________ day of ____________ 20__
                </p>
            </div>
        </>
    )
}

export default IrrevocablePowerOfAttorney