import React, { useEffect, useState } from 'react';
import {
  TextField,
  Typography,
  Grid,
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import { Pie } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { collectionService } from '../../../_services';
import { Input } from "antd";
import TablePagination from "@material-ui/core/TablePagination";
import  LoanRepaymentModal from "../../SBL/Applications/LoanRepaymentModal.jsx"

const ViewKpAssignedLoans = ({match}) => {
  const [loanData, setLoanData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [applicationIdFilter, setApplicationIdFilter] = useState('');
  const emp_id = match.params.id
  const { Search } = Input;
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [selectedBucket, setSelectedBucket] = useState('NPA');  

  useEffect(() => {
    const fetchLoanData = async () => {
      try {
        const response = await collectionService.viewAssignedLoansToKps({'emp_id':emp_id});
        setLoanData(response.data);
        const defaultFilteredData = response.data.filter((item) => item.bucket === selectedBucket);

        setFilteredData(defaultFilteredData);
        setLoading(false);
      } catch (err) {
        setError('Error fetching loan data');
        setLoading(false);
      }
    };

    fetchLoanData();
  }, []);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleBucketChange = (event) => {
    const selected = event.target.value;
    setSelectedBucket(selected);

    const filtered = selected === 'All'
      ? loanData
      : loanData.filter((item) => item.bucket === selected);

    setFilteredData(filtered);
    setPage(0); // Reset pagination on filter change
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleFilterChange = (e) => {
    setApplicationIdFilter(e.target.value);
    const filtered = loanData.filter((item) =>
      item.application_id.toString().includes(e.target.value)
    );
    setFilteredData(filtered);
  };

  const prepareData = () => {
    const totalPos = filteredData.reduce((sum, item) => sum + item.pos, 0);
    const totalArrears = filteredData.reduce((sum, item) => sum + item.arrears, 0);
    const totalLoans = filteredData.length;

    
    return {
      totalPos,
      totalArrears,
      totalLoans,
     
    };
  };

  const {
    totalPos,
    totalArrears,
    totalLoans,
     
  } = prepareData();

  

 
  if (loading) return <div><span className="spinner-border spinner-border-sm"></span></div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Loan Data
      </Typography>

      {/* Summary Section */}
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={4}>
          <Card>
            <CardContent style={{background:"#ffebee"}}>
              <Typography variant="h6" align="center">Total Loans</Typography>
              <Typography variant="h5" align="center">{totalLoans}</Typography>
            </CardContent>
          </Card>
        </Grid>
        {/* <Grid item xs={12} sm={4}>
          <Card>
            <CardContent style={{background:"#e3f2fd"}}>
              <Typography variant="h6" align="center">Total POS</Typography>
              <Typography variant="h5" align="center">{Math.round(totalPos).toLocaleString()}</Typography>
            </CardContent>
          </Card>
        </Grid> */}
        <Grid item xs={12} sm={4}>
          <Card>
            <CardContent style={{background:"#e8f5e9"}}>
              <Typography variant="h6" align="center">Total Arrears</Typography>
              <Typography variant="h5" align="center">{Math.round(totalArrears).toLocaleString()}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Filters */}
      {/* <Box mt={4}>
        <TextField
        style={{width:'200px',float:'right',marginBottom:'1rem'}}
          label="Application ID Filter"
          variant="outlined"
          value={applicationIdFilter}
          onChange={handleFilterChange}
          fullWidth
        />
      </Box> */}
      <Box mt={2}>
      <FormControl variant="outlined" style={{ minWidth: 200 }}>
          <InputLabel>Filter by Bucket</InputLabel>
          <Select value={selectedBucket} onChange={handleBucketChange} label="Filter by Bucket">
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="NPA">NPA</MenuItem>
            <MenuItem value="PNPA">PNPA</MenuItem>
            <MenuItem value="Bucket_1">Bucket 1</MenuItem>
            <MenuItem value="Bucket_0">Bucket 0</MenuItem>
            <MenuItem value="Bucket_X">Bucket X</MenuItem>
          </Select>
        </FormControl>
      <Search
            onChange={(e) => handleFilterChange(e)}
            placeholder="Loan Number"
            // onKeyPress={(e) => handleEnterKey(e)}
            enterButton={true}
            // onSearch={() => handleFilterChange()}
            style={{
                width: "200px",
              float:'right',
              marginTop:'1rem',
              marginBottom:'1rem'
            }}
          />
      </Box>

     
      <Box mt={5}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Application ID</TableCell>
                <TableCell>Branch ID</TableCell>
                <TableCell>Customer Name</TableCell>
                <TableCell>Bucket</TableCell>
                 <TableCell>Arrears</TableCell>
                 <TableCell>View RPS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData && filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((loan, index) => (
                <TableRow key={index}>
                  <TableCell>{loan.application_id}</TableCell>
                  <TableCell>{loan.branch_id}</TableCell>
                  <TableCell>{loan.customer_name}</TableCell>
                  <TableCell>{loan.bucket}</TableCell>
                 
                  <TableCell>{Math.ceil(loan.arrears)>0 && Math.ceil(loan.arrears).toLocaleString()}</TableCell>
                  <TableCell> <LoanRepaymentModal application_id={loan.application_id}/> </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {filteredData ? (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : (
        <div />
      )}
      </Box>
    </div>
  );
};

export default ViewKpAssignedLoans;
