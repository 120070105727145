import React from "react";
import { Route, Switch } from "react-router-dom";
import ApplicantsList from "./ApplicantsList";
import ApplicationDetails from "./Applications/ApplicationDetails";
import { CreditList } from "./Credit/CreditList";
import { SanctionList } from "./SanctionList";
import OperationList from "./OperationList";
import ColendingList from "./ColendingList";
import SalesList from "./SalesList";
import MyTasksList from "./MyTasks";
import SanctionLetterList from "./SanctionLetter/SanctionLetterList";
import AddEditApplication from "./Applications/AddEditApplication";
import CibilList from "./CibilList";
import { DisbursalList } from "./DisbursalList";
import {PostDisbursalList}  from "./PostDisbursalList";
import ApplicationForm from "./Letters/ApplicationForm";
import PreviewApplicationForm from "./Letters/PreviewApplicationForm";
import ApplicationFormList from "./SblLettersList/ApplicationFormList";
import { CollectionList } from "./CollectionList";
import SearchLoans from "./SearchLoans";
import SblBranchSummary from "./SblBranchSummary";
import AnnexureList from "./SanctionAnnexure/AnnexureList";
import SblCollectionSummary from "../Collections/SblCollectionSummary";
import DisbursementPaymentReceipt from "./Applications/ApplicationData/DisbursementPaymentReceipt";
import TabBar from './LoanFlow/TabBar'
import BridgeComponent from "./LoanFlow/SblLoanBridgeComponent";
import NotRegistered from "./NotRegistered"
import BalanceInsufficient from "./BalanceInsufficient"
// import SBLSanction from "./SanctionLetter/SBLSanction";

function SBLIndex({ match }) {
  const { path } = match;

  return (
    <Switch>                             
      <Route exact path={path} component={ApplicantsList} />
      <Route path={`${path}/details/:id`} component={BridgeComponent} />
      {/* <Route path={`${path}/loandetails/:id`} component={TabBar} /> */}
      <Route path={`${path}/add`} component={AddEditApplication} />
      <Route path={`${path}/creditlist`} component={CreditList} />
      <Route path={`${path}/saleslist`} component={SalesList} />
      <Route path={`${path}/operationlist`} component={OperationList} />
      <Route path={`${path}/colendinglist`} component={ColendingList} />
      <Route path={`${path}/mytaskslist`} component={MyTasksList} />
      <Route path={`${path}/sanctionlist`} component={SanctionList} />
      <Route path={`${path}/disbursallist`} component={DisbursalList} />
      <Route path={`${path}/postdisbursallist`} component={PostDisbursalList} />
      <Route path={`${path}/collectionlist`} component={CollectionList} />
      <Route path={`${path}/letterlist`} component={SanctionLetterList}/>
      <Route path={`${path}/cibillist`} component={CibilList}/>
      <Route path={`${path}/applicationform`} component={ApplicationForm}/>
      <Route path={`${path}/preview/applicationform`} component={PreviewApplicationForm}/>
      <Route path={`${path}/edit/applicationform`} component={ApplicationForm}/>
      <Route path={`${path}/preview/form`} component={PreviewApplicationForm}/>
      <Route path={`${path}/list/applicationform`} component={ApplicationFormList}/> 
      <Route path={`${path}/searchloans`} component={SearchLoans}/>
      <Route path={`${path}/sblsummary`} component={SblBranchSummary} />
      <Route path={`${path}/sblcollectionsummary`} component={SblCollectionSummary} />
      <Route path={`${path}/annexure/list`} component={AnnexureList} />
      <Route path={`${path}/disbursement/receipt`} component={DisbursementPaymentReceipt} />
      <Route path={`${path}/NotRegistered`} component={NotRegistered} />
      <Route path={`${path}/BalanceInsufficient/:id`} component={BalanceInsufficient} />


    </Switch>
  );
}

export { SBLIndex };
