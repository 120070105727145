import { Paper } from "@material-ui/core";
import React from "react";
import KpBulkUpload from './KpBulkUpload'
import DashboardProfile from './DashboardProfile'
import ViewKpAssignment from './ViewKpAssignedLoans'
import { employeeService } from '../../../_services';


export default function KpDashboard() {
  
  const user = employeeService.userValue
 
  return (
   <div>
     <div>
        <h2>KP's Assginment Dashboard</h2>
     </div>
     <div>
       {["PIVG","Admin","CEO","CFO"].includes(user.role) &&  <KpBulkUpload/>}
        <DashboardProfile/>
        {/* <ViewKpAssignment/> */}
     </div>
   </div>
  );
}
