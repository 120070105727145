import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import {
  Modal,
  Button,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  CircularProgress
} from "@material-ui/core";
import { loanService } from "../../_services/loan.service";
import EditIcon from '@material-ui/icons/Edit';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import roleChecker from "../../_helpers/rolechecker";
import { employeeService } from "../../_services/employee.service";

const ColenderAccessTokenList = () => {
  const [colenders, setColenders] = useState([]);
  const [selectedColender, setSelectedColender] = useState(null);
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [spinner, setSpinner] = useState(false);
  const user = employeeService.userValue;

  const validationSchema = Yup.object({
    colenderID: Yup.string().required("Colender ID is required"),
    colenderName: Yup.string().required("Colender Name is required"),
    accessToken: Yup.string().required("Access Token is required"),
  });

  useEffect(() => {
    const fetchColenders = async () => {
      try {
        setSpinner(true);
        const response = await loanService.getColenderList();
        setColenders(response);
      } catch (error) {
        console.error("Error fetching colenders:", error);
      } finally {
        setSpinner(false);
      }
    };
    fetchColenders();
  }, []);

  const handleAddColender = async (values) => {
    try {
      setSpinner(true);
      const response = await loanService.addColenderList(values);
      console.log(  response);
      if (response.status === "success") {
        setColenders([...colenders, response.data]);
      } else {
        alert(response.message);
      }
    } catch (error) {
      console.error("Error adding colender:", error);
    } finally {
      setSpinner(false);
      handleCloseModal();
    }
  };

  const handleUpdateColender = async (values) => {
    try {
      setSpinner(true);
      const response = await loanService.updateColenderList(values);
      if (response.status === "success") {
        setColenders((prev) =>
          prev.map((col) => (col._id === values._id ? response.data : col))
        );
      } else {
        alert(response.message);
      }
    } catch (error) {
      console.error("Error updating colender:", error);
    } finally {
      setSpinner(false);
      handleCloseModal();
    }
  };

  const handleEditColender = (col) => {
    setSelectedColender(col);
    setOpen(true);
  };

  const handleRegenerateToken = (setFieldValue) => {
    setFieldValue("accessToken", uuidv4());
  };

  const handleCloseModal = () => {
    setSelectedColender(null);
    setOpen(false);
  };

  const handleDeleteColender = async (id) => {
    if (window.confirm('Are you sure you wish to delete this colender?')) {
      try {
        setSpinner(true);
        await loanService.deleteColenderList(id);
        setColenders((prev) => prev.filter((col) => col._id !== id));
      } catch (error) {
        console.error("Error deleting colender:", error);
      } finally {
        setSpinner(false);
      }
    }
  };

  const filteredColenders = colenders.filter((col) =>
    col.colenderName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Paper style={{ padding: "20px", border: "1px solid #f2f2f2", borderRadius: "10px", margin: "auto" }}>
      {spinner && <CircularProgress size={24} />}
      
      <Modal open={open}>
        <div style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          padding: "20px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          width: "500px",
          borderRadius: "8px",
        }}>
          <Typography variant="h5" gutterBottom style={{ textAlign: "center", fontWeight: 'bolder' }}>
            {selectedColender ? "Edit Colender" : "Add New Colender"}
          </Typography>
          <Button onClick={handleCloseModal} style={{ position: "absolute", top: "10px", right: "10px" }}>
            <CloseIcon />
          </Button>
          <Formik
            initialValues={{
              colenderID: selectedColender?.colenderID || "",
              colenderName: selectedColender?.colenderName || "",
              accessToken: selectedColender?.accessToken || uuidv4(),
              bank_name:  selectedColender?.bank_name || "",
              bank_account: selectedColender?.bank_account || ""
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              if (selectedColender) {
                handleUpdateColender({ ...values, _id: selectedColender._id });
              } else {
                handleAddColender(values);
              }
            }}
          >
            {({ setFieldValue, values, errors, touched }) => (
              <Form>
                <Field
                  as={TextField}
                  fullWidth
                  label="Colender Name"
                  variant="outlined"
                  name="colenderName"
                  error={touched.colenderName && !!errors.colenderName}
                  helperText={<ErrorMessage name="colenderName" />}
                  margin="normal"
                />
                <div style={{ display: "flex", width: "100%", marginTop: "20px", alignItems: 'center' }}>
                  <TextField
                    fullWidth
                    label="Access Token"
                    variant="outlined"
                    name="accessToken"
                    value={values.accessToken}
                    disabled
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleRegenerateToken(setFieldValue)}
                    style={{ marginLeft: "10px", backgroundColor: "black", color: "white", height: "56px" }}
                    disabled={spinner}
                  >
                    <AutorenewIcon />
                  </Button>
                </div>
                <Field
                  as={TextField}
                  fullWidth
                  label="Colender ID"
                  variant="outlined"
                  name="colenderID"
                  error={touched.colenderID && !!errors.colenderID}
                  helperText={<ErrorMessage name="colenderID" />}
                  margin="normal"
                />
                <Field
                  as={TextField}
                  fullWidth
                  label="Bank Name"
                  variant="outlined"
                  name="bank_name"
                    margin="normal"
                />
                 <Field
                  as={TextField}
                  fullWidth
                  label="Account Number"
                  variant="outlined"
                  name="bank_account"
                    margin="normal"
                />
                <div style={{ marginTop: "20px", display: "flex", justifyContent: 'flex-end', gap: '10px' }}>
                  <Button type="button" variant="outlined" color="secondary" onClick={handleCloseModal} disabled={spinner}>
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained" color="primary" style={{ backgroundColor: "black", color: "white" }} disabled={spinner}>
                    {selectedColender ? "Update Colender" : "Add Colender"}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>

      <Typography variant="h4" gutterBottom style={{ textAlign: "center", fontWeight: 'bolder' }}>
        Co-lender Management
      </Typography>

      <div style={{ display: "flex", justifyContent: "center", gap: '20px', marginBottom: '20px' }}>
        <TextField
          fullWidth
          label="Search by Colender Name"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
          style={{ marginRight: "10px", width: "250px" }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
          style={{ backgroundColor: "black", color: "white", width: "250px" }}
          disabled={spinner}
        >
          Add New Colender
        </Button>
      </div>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: 'bolder', fontSize: "large" }}>Colender Name</TableCell>
            <TableCell style={{ fontWeight: 'bolder', fontSize: "large" }}>Access Token</TableCell>
            <TableCell style={{ fontWeight: 'bolder', fontSize: "large" }}>Bank Name</TableCell>
            <TableCell style={{ fontWeight: 'bolder', fontSize: "large" }}>Bank Account</TableCell>
            <TableCell style={{ fontWeight: 'bolder', fontSize: "large" }}>Colender ID</TableCell>
            <TableCell style={{ fontWeight: 'bolder', fontSize: "large" }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredColenders.map((col) => (
            <TableRow key={col._id}>
              <TableCell>{col.colenderName}</TableCell>
              <TableCell style={{ color: 'gray' }}>{col.accessToken}</TableCell>
              <TableCell style={{ color: 'gray' }}>{col.bank_name}</TableCell>
              <TableCell style={{ color: 'gray' }}>{col.bank_account}</TableCell>

              <TableCell style={{ color: 'gray' }}>{col.colenderID}</TableCell>
              {(roleChecker.isGMLevelAccess(user.role) || roleChecker.isITAccess(user.role)) && (
                <TableCell>
                  <Button
                    style={{ color: 'gray', border: 'none' }}
                    variant="outlined"
                    color="primary"
                    onClick={() => handleEditColender(col)}
                    disabled={spinner}
                  >
                    <EditIcon />
                  </Button>
                  <Button
                    style={{ color: 'gray', border: 'none' }}
                    variant="outlined"
                    color="error"
                    onClick={() => handleDeleteColender(col._id)}
                    disabled={spinner}
                  >
                    <DeleteIcon />
                  </Button>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default ColenderAccessTokenList;
