import { fetchWrapper } from "../_helpers";

const apiUrl = process.env.REACT_APP_API_URL;

const baseUrl = `${apiUrl}/reports`;

export const reportsService = {
  create,
  getAll,
  getChartsData,
  getMetricsData,
  // getMetricsZoneNameData, 
  getRealMetricsData,
  getenachcollectpayments,
  getNotregisterdata,
  getNotregisterdatacount,
  getdiscontinuedcount
};

function create(params) {
  return fetchWrapper.post(baseUrl, params);
}

function getAll(id)
{
  return fetchWrapper.get(baseUrl);
}

async function getChartsData(params) {
  return fetchWrapper.post(baseUrl+"/charts", params);
}

async function getMetricsData(params) {
  return fetchWrapper.post(baseUrl+"/metrics", params);
}
// async function getMetricsZoneNameData(params) {
//   return fetchWrapper.post(baseUrl+"/metricszonename", params);
// }

// new update
async function getRealMetricsData(params) {
  return fetchWrapper.post(baseUrl+"/realmetrics", params);
}

async function getenachcollectpayments(params) {
  return fetchWrapper.post(baseUrl+"/getenachcollectpayments", params);
}
async function getNotregisterdata(params) {
  return fetchWrapper.post(baseUrl+"/getNotregisterdata", params);
}
async function getNotregisterdatacount(params) {
  return fetchWrapper.post(baseUrl+"/getNotregisterdatacount", params);
}
async function getdiscontinuedcount(params) {
  return fetchWrapper.post(baseUrl+"/getdiscontinuedcount", params);
}

