import { fetchWrapper } from "../_helpers";
const baseUrl = process.env.REACT_APP_API_URL
export const receiptService  ={
  Create,
  getAll,
  getByDate,
  update, 
  updateReceipt,
  getCashByAccount,
  getAllByAccount,
  receiptGeneratepaymentFees,
  allEmployeeRemainingData,
  getAllCashPaymentOfTheLoan
}

function Create(params){
   return fetchWrapper.post( `${baseUrl}/receipts`, params)
}

function getAll(){
   return fetchWrapper.get( `${baseUrl}/receipts`)
}

function getByDate(params){
   return fetchWrapper.post(`${baseUrl}/receipts/date`,params)
}


function getCashByAccount(params){
   return fetchWrapper.post(`${baseUrl}/receipts/cashbyaccount`,params)
}


function getAllByAccount(params){
   return fetchWrapper.post(`${baseUrl}/receipts/allbyaccount`,params)
}


function update(params){
   console.log(params)
   return fetchWrapper.post(`${baseUrl}/receipts/update/discardstatus`, params)
}

function updateReceipt(id, params){
   console.log(params)
   return fetchWrapper.post(`${baseUrl}/receipts/update/discardstatus`, params)
}
function receiptGeneratepaymentFees(id, params){
   console.log(params)
   return fetchWrapper.post(`${baseUrl}/receipts/receiptgeneratepaymentfees`, params)
}
function allEmployeeRemainingData(params){
   console.log(params)
   return fetchWrapper.post(`${baseUrl}/receipts/employeeremaningamount`, params)
}
function getAllCashPaymentOfTheLoan(params){
    return fetchWrapper.post(`${baseUrl}/receipts/getallcashpaymentoftheloan`, params)
}