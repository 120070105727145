import { TextField } from "@material-ui/core"
import React from "react";
import { employeeService } from "../../../_services"
import roleChecker from "../../../_helpers/rolechecker"
import EditStageModal from "../Applications/EditStageModal"
import { LoanStages } from "../../../constants/loanstages"
import datehelper from "../../../_helpers/datehelper"
import displayhelper from "../../../_helpers/displayhelper"
import './OverviewCss.css'
import Switch from '@material-ui/core/Switch';
import { history } from "../../../_helpers";
import { virtualAccountService } from "../../../_services/virtualaccount.service";
import { Button } from "@material-ui/core";
export const Overview = (props) => {
    console.log("pprrpp",props)
    const user = employeeService.userValue
    const cust = { ...props.cust }
    console.log("cuttt",cust)
    let stage = cust.stage

    const [state, setState] = React.useState({
        switch_panel: true
    });
    const [va_details, setVADetails] = React.useState(null);

    const handlePanel = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });

        if(!event.target.checked){
            history.push(`/home/sblloans/details/${cust._id}?isold_view=1`)
            window.location.reload()
        }

        console.log(event.target.checked)
    };

    const refreshPage = () => {
        window.location.reload()
    }
    React.useEffect(() => {

        let fields = { 'loan_account': cust.application_id }
        virtualAccountService.getByLoanAccount(fields).then((va) => {
           console.log("va",va);
          setVADetails(va);
        })
          .catch((err) => {
            console.log(err);
          })
    
    
      }, []);

      async function createQRCode() {
        let fields = {
          'loan_account': cust.application_id + "",
          'customer_name': cust.applicant.general_information.name,
          'customer_mobile': cust.applicant.general_information.contact + ""
        }
        console.log(fields);
        virtualAccountService.create(fields).then((va) => {
          console.log("va data")
          console.log(va);
          setVADetails(va);
        })
          .catch((err) => {
            console.log(err);
          })
    
      }
    return (
        <div>

            <div style={{ display: "flex", justifyContent: "flex-end", flexDirection: "row" ,marginRight:"3rem"}} >
                <label  >LMS</label> <div style={{ marginTop: "-10px" }} >
                    <Switch
                        checked={state.switch_panel}
                        onChange={handlePanel}
                        color="primary"
                        name="switch_panel"
                    // inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </div><label style={{ color:'blue' }} >LOS</label>
            </div>

            {cust && cust.application_id &&
                <div className="main">
                    {cust && cust.application_id && <TextField
                        id="outlined-read-only-input"
                        label="Application ID"
                        defaultValue={cust.application_id}
                        className="input_field"
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="outlined"
                    />}
                    {cust && cust.applicant && cust.applicant.general_information && cust.applicant.general_information.name &&
                        <TextField
                            id="outlined-read-only-input"
                            label="Applicant"
                            defaultValue={cust.applicant.general_information.name}
                            className="input_field"
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                        />}
                    {cust && cust.loan && cust.loan.sanction_amount && <TextField
                        id="outlined-read-only-input"
                        label="Sanction Amount"
                        defaultValue={displayhelper.displayNumberAccounting(cust.loan.sanction_amount)}
                        className="input_field"
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="outlined"
                    />}
                    {cust && cust.loan && cust.loan.roi && <TextField
                        id="outlined-read-only-input"
                        label="ROI"
                        defaultValue={cust.loan.roi + '%'}
                        className="input_field"
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="outlined"
                    />}
                    {cust && cust.loan && cust.loan.tenure && <TextField
                        id="outlined-read-only-input"
                        label="Tenure"
                        defaultValue={cust.loan.tenure + " Months"}
                        className="input_field"
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="outlined"
                    />}
                    <TextField
                        id="outlined-read-only-input"
                        label="Loan Stage"
                        defaultValue={cust.stage}
                        className="input_field"
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="outlined"
                    />
                    {cust.loan && cust.loan.disbursement_date && (
                        <TextField
                            id="outlined-read-only-input"
                            label="Loan Agreement Date"
                            defaultValue={datehelper.displayDate(new Date(cust.loan.disbursement_date))}
                            className="input_field"
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                        />
                    )}
                    {cust.sub_stage && (
                        <TextField
                            id="outlined-read-only-input"
                            label="Loan Pending Step"
                            defaultValue={cust.sub_stage}
                            className="input_field"
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                        />
                    )}

                    {cust.branches.length > 0 && (
                        <TextField
                            id="outlined-read-only-input"
                            label="Branch Name"
                            defaultValue={cust.branches[0].branch_name}
                            className="input_field"
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                        />
                    )}
                    {cust.employees && cust.employees.length > 0 && (
                        <TextField
                            id="outlined-read-only-input"
                            label="RO/Sales Agent"
                            defaultValue={cust.employees[0].first_name + " " + cust.employees[0].last_name + " /ID: " + cust.employees[0].emp_id + " "}
                            className="input_field"
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                        />)}
                </div>}
            <div style={{ margin: "10px", display: 'flex', justifyContent: 'center' }}>
                {roleChecker.isHOLevelAccess(user.role) && stage && ![LoanStages.Closed, LoanStages.Collection, LoanStages.Rejected, LoanStages.Discarded].includes(cust.stage) && (
                    <EditStageModal loan_data={cust} refreshParent={() => refreshPage()}></EditStageModal>
                )}

                {(roleChecker.isBranchLevelAccess(user.role) || roleChecker.isCreditTeamAccess(user.role)) && stage && ![LoanStages.Closed, LoanStages.Collection, LoanStages.Rejected, LoanStages.Discarded, LoanStages.Sanction, LoanStages.Operation,LoanStages.Disbursal].includes(stage) && (
                    <EditStageModal loan_data={cust} refreshParent={() => refreshPage()}></EditStageModal>
                )}
            </div>
            {
                console.log(cust)
            }
            {va_details && (
        <div>
          <h2>Payment QR Code</h2>
          <div
            style={{ paddingLeft: '50px', paddingTop: '50px' }}
          >
            <p><b>Account Payee:</b> Kaabil Finance Private Ltd</p>
            <p><b>Bank Name:</b> ICICI Bank</p>
            <p><b>Account Number:</b> {va_details && va_details.va_num}</p>
            <p><b>IFSC Code:</b> ICIC0000106</p>
            {
              va_details && process.env.REACT_APP_QR_CODE_TYPE === "VPA"  && va_details.vpa_qrcode_url && (
                <div><img src={va_details.vpa_qrcode_url}></img></div>
              )
              }
            { va_details && process.env.REACT_APP_QR_CODE_TYPE !=="VPA" && (
                <div><img src={va_details.qrcode_url}></img></div>
              )}
          </div>
        </div>
      )}
      {!va_details && (
        <div>
          <br />
          <Button
            color="primary"
            variant='contained'
            style={{ "margin": 10 }}
            onClick={() => createQRCode()}>Create QR Code</Button>
        </div>
      )}
        </div>
    )
}