import React from "react";
import "./sanction.css";
import logo from "../../../assets/kaabil.png";
import capitalfinance from "../../../assets/capitalfinance.png";
import capital from "../../../assets/capital.png";
import ugroFooter from "../../../assets/kaabilfooter_ugro.png";
import ugroLogo from "../../../assets/ugrow_capital.png";
import bfplLogo from "../../../assets/bfpl_logo.png";
import vistaarLogo from "../../../assets/Vistaar.jpg"
import capitalFooter from "../../../assets/kaabil_footer.png";
import kaabilFooter from "../../../assets/kaabil_footer_capital_india.png";
import KaabilOfficeFooter from "../../../assets/KaabilFooter.png"
import { misService } from "../../../_services";
import bhalaFooter from "../../../assets/kaabilfooter_bhala.png";
import { virtualAccountService } from "../../../_services/virtualaccount.service";
import nachform from "../../../assets/nachform.png";
import vistaarFooter from "../../../assets/kaabilfooter_vistaar.png"
import EmailIcon from '@material-ui/icons/Email';
import CallIcon from '@material-ui/icons/Call';
import LanguageIcon from '@material-ui/icons/Language';
import "./Footer.css";
const footerDiv = (bank) => (
  <div>
     {bank === "KFPL" && (
     <div className="header-info">
     <div className="header-top">
       <div>
         <span>
           <strong><EmailIcon/>  care@kaabilfinance.com</strong>
         </span>
       </div>
       <div>
         <span>
           <strong><CallIcon/> 0141-4005012</strong>
         </span>
       </div>
       <div>
         <strong><LanguageIcon/> www.kaabilfinance.com</strong>
       </div>
     </div>
     <div className="header-bottoms">
      
         <p> <strong>Registered & Corporate Office </strong> :- Kaabil Towers, Plot No. 1, Scheme No. 1, Income Tax Colony, Model Town, Jagatpura, Jaipur Rajasthan 302017 CIN No. U65929RJ2017PTC058138 ,GSTIN : 08AAGCK6104N1ZA</p>
         
     </div>
     {/* Fixed bottom footer */}
     <div className="footer-container"></div>
   </div>
  )}
    {bank === "Capital" && (
     <div className="header-info">
        <div className="header-top">
          <div>
            <span>
              <strong><EmailIcon/> care@kaabilfinance.com</strong>
            </span>
          </div>
          <div>
            <span>
              <strong><CallIcon/> 0141-4005012</strong>
            </span>
          </div>
          <div>
            <strong><LanguageIcon/> www.kaabilfinance.com</strong>
          </div>
        </div>
        <div className="header-bottom">
          <div className="address">
            <strong>Registered and Corporate Office </strong>
            <p>Kaabil Towers, Plot No. 1, Scheme No. 1, Income Tax Colony, Model Town, Jagatpura, Jaipur Rajasthan 302017</p>
          </div> 
          <div className="corporate-office">
            <strong>Registered Office Capital India Finance Limited</strong>
            <p>2nd floor, DLF Centre, Sansad Marg, New Delhi 110001</p>
          </div>
        </div>
        {/* Fixed bottom footer */}
        <div className="footer-container"></div>
      </div>
    )}
    {bank === "ugro" && (
       <div className="header-info">
       <div className="header-top">
         <div>
           <span>
             <strong><EmailIcon/> care@kaabilfinance.com</strong>
           </span>
         </div>
         <div>
           <span>
             <strong><CallIcon/> 0141-4005012</strong>
           </span>
         </div>
         <div>
           <strong><LanguageIcon/> www.kaabilfinance.com</strong>
         </div>
       </div>
       <div className="header-bottom">
         <div className="address">
           <strong>Registered and Corporate Office</strong>
           <p>Kaabil Towers, Plot No. 1, Scheme No. 1, Income Tax Colony, Model Town, Jagatpura, Jaipur Rajasthan 302017</p>
         </div>
         <div className="corporate-office">
           <strong>Registered Office Ugro Capital Limited</strong>
           <p>Equinox Business Park, Tower 3, Fourth Floor, Off BKC, LBS Road,kurla, Mumbai, Maharashtra 400070</p>
           <p>CIN No. L67120MH1993PLC070739</p>
         </div>
       </div>
       {/* Fixed bottom footer */}
       <div className="footer-container"></div>
     </div>
    )}
    {bank === "bfpl" && (
       <div className="header-info">
       <div className="header-top">
         <div>
           <span>
             <strong><EmailIcon/>  care@kaabilfinance.com</strong>
           </span>
         </div>
         <div>
           <span>
             <strong><CallIcon/> 0141-4005012</strong>
           </span>
         </div>
         <div>
           <strong><LanguageIcon/> www.kaabilfinance.com</strong>
         </div>
       </div>
       <div className="header-bottom">
         <div className="address">
           <strong>Registered and Corporate Office </strong>
           <p>Kaabil Towers, Plot No. 1, Scheme No. 1, Income Tax Colony, Model Town, Jagatpura, Jaipur Rajasthan 302017</p>
         </div>
         <div className="corporate-office">
           <strong>Bhala Finance Pvt. Ltd.</strong>
           <p>Man Upasana, 4th Floor, Sardar Patel Marg, C Scheme, Ashok Nagar, Jaipur, Rajasthan 302001</p>
         </div>
       </div>
       {/* Fixed bottom footer */}
       <div className="footer-container"></div>
     </div>
    )}
    {bank === "vistaar" && (
      <div className="header-info">
      <div className="header-top">
        <div>
          <span>
            <strong><EmailIcon/>  care@kaabilfinance.com</strong>
          </span>
        </div>
        <div>
          <span>
            <strong><CallIcon/> 0141-4005012</strong>
          </span>
        </div>
        <div>
          <strong><LanguageIcon/> www.kaabilfinance.com</strong>
        </div>
      </div>
      <div className="header-bottom">
        <div className="address">
          <strong>Registered and Corporate Office</strong>
          <p>Kaabil Towers, Plot No. 1, Scheme No. 1, Income Tax Colony, Model Town, Jagatpura, Jaipur Rajasthan 302017</p>
        </div>
        <div className="corporate-office">
          <strong>Vistaar Finance</strong>
          <p>Plot No. 59 & 60-23, 22nd Cross, 29th Main BTM Layout, 2nd  Stage NA Bengaluru Bangalore KA 560076 IN</p>
          <p>CIN No. U67120KA1991PTC059126</p>
        </div>
      </div>
      {/* Fixed bottom footer */}
      <div className="footer-container"></div>
    </div>
    )}
    {bank === "home" && (
           <div className="header-info">
           <div className="header-top">
             <div>
               <span>
                 <strong><EmailIcon/>  care@kaabilfinance.com</strong>
               </span>
             </div>
             <div>
               <span>
                 <strong><CallIcon/> 0141-4005012</strong>
               </span>
             </div>
             <div>
               <strong><LanguageIcon/> www.kaabilfinance.com</strong>
             </div>
           </div>
           <div className="header-bottom">
             <div className="address">
               <strong>Registered and Corporate Office</strong>
               <p>Kaabil Towers, Plot No. 1, Scheme No. 1, Income Tax Colony, Model Town, Jagatpura, Jaipur Rajasthan 302017</p>
             </div>
             <div className="corporate-office">
               <strong>Registered Office Capital India Home Loans Limited</strong>
               <p>2nd floor, DLF Center, Sansad Marg, New Delhi 110001</p>
             </div>
           </div>
           {/* Fixed bottom footer */}
           <div className="footer-container"></div>
         </div>
    )}
  </div>
);

const headerDiv = (bank) => (
  <div  >
    <img src={logo} style={{ height: "100px" }} alt="KFPL Logo" />
    {bank === "Capital" && (
      <img
        src={capitalfinance}
        style={{ height: "100px", position: "absolute", top: "2", right: "0",marginRight:"3rem" }}
        alt="Capital Finance Logo"
      />
    )}
    {bank === "ugro" && (
      <img
        src={ugroLogo}
        style={{ height: "100px", position: "absolute", top: "2", right: "0",marginRight:"3rem" }}
        alt="Ugrow Capital Logo"
      />
    )}
    {bank === "bfpl" && (
      <img src={bfplLogo} style={{ height: "100px", position: "absolute", top: "2", right: "0",marginRight:"3rem" }} alt="Bfpl Capital Logo" />
    )}
    {bank === "vistaar" && (
      <img src={vistaarLogo} style={{ height: "100px", position: "absolute", top: "2", right: "0",marginRight:"3rem" }} alt="Vistaar Finance Logo" />
    )}
    {bank === "home" && (
      <img
        src={capital}
        style={{ height: "100px", position: "absolute", top: "2", right: "0",marginRight:"3rem" }}
        alt="Capital India Home Logo"
      />
    )}
  </div>
);

export default class SanctionLetter extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loanno: "", vaDetails: null };
  }

  getEMI(p, r, n) {
    var rate = r / 1200;
    console.log(p, rate, n);
    var numerator = p * rate * Math.pow(1 + rate, n);
    var denominator = Math.pow(1 + rate, n) - 1;

    return numerator / denominator;
  }

  nth = function (d) {
    if (d > 3 && d < 21) return "th";
    switch (d % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  componentDidMount() {
    misService.getLoanNo("SBL").then((result) => {
      this.setState({
        loanno:
          result.loan_no > 999
            ? result.loan_no.toString()
            : result.loan_no.toString().padStart(3, "0"),
      });
    });

    let fields = {
      loan_account: this.props.dataFill.application_id,
    };
    virtualAccountService
      .getByLoanAccount(fields)
      .then((va) => {
        console.log("got va details");
        console.log(va);
        this.setState({ vaDetails: va });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    const data = this.props.dataFill;
    console.log("egrhty", data)
    console.log("Data received:", data);
    
    // Make sure data.createdAt is available before proceeding
    if (!data || !data.createdAt) {
      return <h2>No date available</h2>;
    }

    // Perform EMI date calculation
    const today = new Date(data.createdAt);
    let targetMonth, targetYear;
  
    if (today.getDate() <= 5) {
      targetMonth = today.getMonth() + 1;
      targetYear = today.getFullYear();
      if (targetMonth > 11) {
        targetMonth = 0; // January (0-based index)
        targetYear += 1;
      }
    } else {
      targetMonth = today.getMonth() + 2;
      targetYear = today.getFullYear();
      if (targetMonth > 11) {
        targetMonth -= 12;
        targetYear += 1;
      }
    }
  
    // Create the target date for the 5th of the determined month
    const emiDate = new Date(targetYear, targetMonth, 5);
    const formattedEmiDate = `${String(emiDate.getDate()).padStart(2, '0')}-${String(emiDate.getMonth() + 1).padStart(2, '0')}-${emiDate.getFullYear()}`;
    
    console.log("EMI Date:", emiDate);
    var emi_table = [];
    // var tenure_per_page = Math.ceil( data.tenure/25)

    var emi = this.getEMI(
      data.sanction_amount,
      data.interest_rate,
      data.tenure
    );
    var first_interest = (data.interest_rate / 1200) * data.sanction_amount;
    emi_table.push({
      start_balance: data.sanction_amount,
      date: new Date(
        new Date(emiDate).setMonth(
          new Date(emiDate).getMonth() - 1
        )
      ),
      interest_paid: first_interest,
      payment_amount: emi,
      capital_paid: emi - first_interest,
      balance_remaining: data.sanction_amount - (emi - first_interest),
    });

    for (var i = 1; i <= data.tenure; i++) {
      var current_interest =
        (data.interest_rate / 1200) * emi_table[i - 1].balance_remaining;
      emi_table.push({
        start_balance: emi_table[i - 1].balance_remaining,
        date: new Date(
          emi_table[i - 1].date.setMonth(emi_table[i - 1].date.getMonth() + 1)
        ),
        payment_amount: emi,
        interest_paid: current_interest,
        capital_paid: emi - current_interest,
        balance_remaining:
          emi_table[i - 1].balance_remaining - (emi - current_interest),
      });
    }

    const date = new Date();
    const  pre_emi = Math.ceil(Number(data.sanction_amount)  * data.interest_rate * 30 / 36000)
    console.log(pre_emi,"pre_emi")
    let principal = Number(data.sanction_amount) -  ((Number(data?.sanction_amount) * 0.03) +  (Number(data?.sanction_amount) * 0.03) * 0.18 + 2500 + 2500 +  (Number(data?.sanction_amount) * 0.06)+pre_emi);
    

let rate = data.interest_rate / 12 / 100;
let months = data.tenure +1;

// Step 2: Calculate EMI value
const emiValue = (principal * rate * Math.pow(1 + rate, months)) / 
    (Math.pow(1 + rate, months) - 1);

console.log(Math.floor(emiValue), "Original EMI");
console.log(principal, "APR");

let sanctionAmount = Number(data.sanction_amount); // Get the sanction amount
let feesWithTaxAndAdditionals = (Number(data.sanction_amount) * 0.06);
let processingFeess = (Number((data?.sanction_amount) * 0.03) + (Number((data?.sanction_amount) * 0.03) * 0.18) + 2500 +2500 +pre_emi).toFixed(2) // Get the processing fees
console.log(rate, "Rate");
console.log(months, "Months");

// Step 3: Get the formatted EMI value
let epi = new Intl.NumberFormat('en-IN').format(Math.round(
  this.getEMI(
        data?.sanction_amount,
        data?.interest_rate,
        data?.tenure
    )
));

// Convert `epi` to a number for accurate comparison
let formattedEMI = Number(epi.replace(/,/g, ''));
console.log(formattedEMI, "formattedEMI");

// Step 4: Calculate the adjusted interest rate
let newRate;
let adjustedEmiValue;
let closestIndex = null;

// Initialize a variable to store the last annual interest rate
let lastAnnualInterestRate = null;

for (let i = 1; i <= 100; i++) {
    newRate = (data.interest_rate + i) / 12 / 100;
    adjustedEmiValue = (principal * newRate * Math.pow(1 + newRate, months)) / 
        (Math.pow(1 + newRate, months) - 1);

    console.log(Math.floor(adjustedEmiValue), `adjustedEmiValue at increment ${i}`, adjustedEmiValue);

    if (Math.floor(adjustedEmiValue) < formattedEMI) {
        closestIndex = i;
    }

    if (Math.floor(adjustedEmiValue) === formattedEMI) {
        console.log(newRate * 12 * 100, "Interest Rate per Month that matches EMI");
        break;
    }
}

// Step 5: Refine the search if a closest match was found
const closestIndexs = closestIndex;
if (closestIndexs !== null) {
    console.log(`Refining search starting from closest integer increment: ${closestIndexs}`);
    for (let j = 1; j < 10; j++) {
        let decimalIncrement = closestIndexs + j / 10;
        console.log(decimalIncrement, "decimalIncrement");

        newRate = (data.interest_rate + decimalIncrement) / 12 / 100;
        adjustedEmiValue = (principal * newRate * Math.pow(1 + newRate, months)) / 
            (Math.pow(1 + newRate, months) - 1);

        console.log(Math.floor(adjustedEmiValue), `adjustedEmiValue at refined increment ${decimalIncrement}`);

        if (Number(Math.ceil(adjustedEmiValue)) < Number(formattedEMI)) {
            let totalInterestRate = data.interest_rate + decimalIncrement;
            lastAnnualInterestRate = totalInterestRate ; // Store last annual interest rate
            console.log(lastAnnualInterestRate,"lastAnnualInterestRate")
        }

        if (Math.floor(adjustedEmiValue) === formattedEMI) {
            let totalInterestRate = data.interest_rate + decimalIncrement;
            console.log(totalInterestRate, "totalInterestRatetotalInterestRateeee");
            let annualInterestRate = totalInterestRate;
            
            console.log(`Interest Rate per Year: ${annualInterestRate.toFixed(2)}%`);
            console.log(newRate * 12 * 100, "Interest Rate per Month that matches EMI (Refined)");
            break;
        }

        if (Math.floor(adjustedEmiValue) < formattedEMI) {
            closestIndex = decimalIncrement;
        }
    }

    console.log(`Further refining search from closest refined increment: ${closestIndex}`);
    for (let k = 1; k <= 9; k++) {
        let fineDecimalIncrement = closestIndex + k / 100;
        newRate = (data.interest_rate + fineDecimalIncrement) / 12 / 100;
        adjustedEmiValue = (principal * newRate * Math.pow(1 + newRate, months)) / 
            (Math.pow(1 + newRate, months) - 1);

        console.log(Math.floor(adjustedEmiValue), `adjustedEmiValue at fine increment ${fineDecimalIncrement}`);

        if (Number(Math.ceil(adjustedEmiValue)) === Number(formattedEMI) || Number(Math.ceil(adjustedEmiValue)) < Number(formattedEMI)|| Number(Math.ceil(adjustedEmiValue)) > Number(formattedEMI)) {
            let totalInterestRate = data.interest_rate + fineDecimalIncrement;
            console.log(totalInterestRate,"totalInterestRate")
            lastAnnualInterestRate = totalInterestRate ; // Store last annual interest rate
            console.log(lastAnnualInterestRate,"lastAnnualInterestRate")
            console.log(newRate * 12 * 100, "Interest Rate per Month that matches EMI (Fine-Tuned)");
        }
    }
}
let APRRATE = lastAnnualInterestRate?.toFixed(2)
// Log the last annual interest rate if it was set
if (lastAnnualInterestRate !== null) {
    console.log(`Interest Rate per Year (Last Value): ${lastAnnualInterestRate.toFixed(2)}%`);
    
}
    // Calculation for the subtraction
   
    let pd_date = new Date(data.pd_date);
    pd_date = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    const paymentDivs = (paymentData) => {
      let divs = [];
      let i = 0;

      while (i < paymentData.length) {
        divs.push(
          <div
            style={{
              width: "290mm",
              height: "410mm",
              backgroundColor: "white",
              padding: 40,
              position: "relative",
            }}
          >
            {headerDiv(data.bank)}
            <br />
            <p>
              <h4>Annexure-B</h4>
              <table class="stretch">
                <tr style={{textAlign:"center",verticalAlign:"middle"}}>
                  <th>Payment Number</th>
                  {/* <th>Date</th> */}
                  <th>Start Balance</th>
                  <th>Payment Amount</th>
                  <th>Capital Paid</th>
                  <th>Interest Paid</th>
                  <th>Remaining Balance</th>
                </tr>
                {paymentData.slice(i, i + 48).map(
                  (item, idx) =>
                    idx + i !== data.tenure && (
                      <tr>
                        <td style={{ width: "10%" ,textAlign:'center'}}>{idx + i + 1}</td>{" "}
                        {/* <td>
                          {" "}
                          {item.date.getDate()}-
                          {item.date.toLocaleString("default", {
                            month: "long",
                          })}
                          -{item.date.getFullYear()}
                        </td> */}
                        <td style={{textAlign:'center'}}>{new Intl.NumberFormat('en-IN').format(Math.round(item.start_balance) === -0 ? 0 : Math.round(item.start_balance))}</td>
                        <td style={{textAlign:'center'}}>{new Intl.NumberFormat('en-IN').format(Math.round(item.payment_amount) === -0 ? 0 : Math.round(item.payment_amount))}</td>
                        <td style={{textAlign:'center'}}>{new Intl.NumberFormat('en-IN').format(Math.round(item.capital_paid) === -0 ? 0 : Math.round(item.capital_paid))}</td>
                        <td style={{textAlign:'center'}}>{new Intl.NumberFormat('en-IN').format(Math.round(item.interest_paid) === -0 ? 0 : Math.round(item.interest_paid))}</td>
                        <td style={{textAlign:'center'}}>{new Intl.NumberFormat('en-IN').format(Math.round(item.balance_remaining) === -0 ? 0 : Math.round(item.balance_remaining))}</td>
                      </tr>
                    )
                )}
              </table>
            </p>
            {footerDiv(data.bank)}
          </div>
        );
        i += 48;
      }

      return divs;
    };
    const paymentDivss = (paymentData) => {
      let divs = [];
      let i = 0;

      while (i < paymentData.length) {
        divs.push(
          <div
            style={{
              width: "290mm",
              height: "410mm",
              backgroundColor: "white",
              padding: 40,
              position: "relative",
            }}
          >
            {headerDiv(data.bank)}
            <br />
            <p>
            
            <h4 style={{textAlign:"center",fontWeight:"bold"}}>Illustrative Repayment Schedule under Equated Periodic Installment for the hypothetical loan illustrated in Annexure C</h4>
              <table class="stretch">
                <tr style={{textAlign:"center",verticalAlign:"middle"}}>
                  <th>Installment Number</th>
                  <th>Installment Date</th>
                  <th>Start Balance</th>
                  <th>Payment Amount</th>
                  <th>Capital Paid</th>
                  <th>Interest Paid</th>
                  <th>Remaining Balance</th>
                </tr>
                {console.log(paymentData,"paymentDatapaymentDatapaymentData")}
                {paymentData.slice(i, i + 48).map(
                  (item, idx) =>
                    idx + i !== data.tenure && (
                      <tr style={{textAlign:"center",verticalAlign:"middle"}}>
                        <td style={{ width: "10%" }}>{idx + i + 1}</td>{" "}
                        <td >
  {" "}
  {item.date.getDate()}-
  {item.date.toLocaleString("default", {
    month: "2-digit", // This will give the two-digit number for the month, e.g., "09"
  })}
  -{item.date.getFullYear()}
</td>


                        <td>{new Intl.NumberFormat('en-IN').format(Math.round(item.start_balance) === -0 ? 0 : Math.round(item.start_balance))}</td>
                        <td>{new Intl.NumberFormat('en-IN').format(Math.round(item.payment_amount) === -0 ? 0 : Math.round(item.payment_amount))}</td>
                        <td>{new Intl.NumberFormat('en-IN').format(Math.round(item.capital_paid) === -0 ? 0 : Math.round(item.capital_paid))}</td>
                        <td>{new Intl.NumberFormat('en-IN').format(Math.round(item.interest_paid) === -0 ? 0 : Math.round(item.interest_paid))}</td>
                        <td>{new Intl.NumberFormat('en-IN').format(Math.round(item.balance_remaining)  === -0 ? 0 : Math.round(item.balance_remaining))}</td>
                      </tr>
                    )
                )}
              </table>
            </p>
            {footerDiv(data.bank)}
          </div>
        );
        i += 48;
      }

      return divs;
    };
    return (
      <div className="sanction">
        <style type="text/css" media="print">
          {"\
   @page { size: A4; }\
"}
        </style>
        {/* <br /> */}
        <div>
        {(data.bank==="KFPL" ) && 
        <>
           <div>
          <div
            style={{
              width: "290mm",
              height: "410mm",
              backgroundColor: "white",
              padding: "40px 40px 20px 40px",
              position: "relative",
            }}
          >
            {headerDiv(data.bank)}
            <>
              <h3 style={{ textAlign: "center", fontWeight: "bold" }}>Annexure-A </h3>
              <h6 style={{ textAlign: "center", fontWeight: "bold" }}>Key Fact Statement</h6>
              <h6 style={{ textAlign: "center", fontWeight: "bold" }}>Part 1 (Interest rate and Fees/Charges)</h6>
              <table>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td className="tablebold">Loan Proposal/account No.</td>
                    <td colspan="2">{data?.application_id}</td>
                    <td className="tablebold">Type of Loan</td>
                    <td colspan="3" >Secured Business Loans</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td colspan="3" className="tablebold">Sanctioned Loan Amount (in Rupees)</td>
                    <td colspan="4">INR. {new Intl.NumberFormat('en-IN').format(data?.sanction_amount)} /- </td>
                  </tr>
                  {/* <tr>
                    <td  rolspan="2">3</td>
                    <td colspan="3" rolspan="2"  className="tablebold"><b>Disbursal schedule</b><br />
                      (i) Disbursement in stages or 100% upfront.<br />
                     </td>
                    <td colspan="4">100% Upfront </td>
                  </tr>
                  <tr>
                  <td></td>
                    <td colspan="3" className="tablebold">(ii) If it is stage wise, mention the clause of loan agreement having relevant details.</td>
                    <td colspan="4">Not Applicable</td>
                  </tr> */}
                  <tr>
  <td rowspan="2">3</td> 
  <td colspan="3"className="tablebold"><b>Disbursal schedule</b><br />
    (i) Disbursement in stages or 100% upfront.<br />
  </td>
  <td colspan="4">100% Upfront</td>
</tr>
<tr>
<td colspan="3" className="tablebold">(ii) If it is stage wise, mention the clause of loan agreement having relevant details.</td>
<td colspan="4">Not Applicable</td>
</tr>

                  <tr>
                    <td>4</td>
                    <td colspan="3" className="tablebold">Loan term (months)</td>
                    <td colspan="4">{data.tenure} Months</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td colspan="7" className="tablebold">Installment details</td>
                  </tr>

                  <tr>
                    <td>Type of Installments</td>
                    <td colspan="2">Number of EPIs</td>
                    <td colspan="2">EPI (₹)</td>
                    <td colspan="3"> Commencement of repayment, post sanction</td>
                  </tr>



                  <tr>
                    <td>Monthly</td>
                    <td colspan="2">{data.tenure}</td>
                    <td colspan="2">INR. {" "}
                      {new Intl.NumberFormat('en-IN').format(Math.round(
                        this.getEMI(
                          data?.sanction_amount,
                          data?.interest_rate,
                          data?.tenure
                        )
                      ))}
                      /-</td>
                    <td colspan="3">{formattedEmiDate}</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td colspan="3" className="tablebold">Interest rate (%) and type (fixed or floating or hybrid)</td>
                    <td colspan="4">{data.interest_rate}% P.a – Fixed Rate</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td colspan="7" className="tablebold">Additional Information in case of Floating rate of interest</td>
                  </tr>
                  <tr>
                    <td>Reference
                      Benchmark</td>
                    <td className="tablebold">Benchmark
                      rate (%) (B)</td>
                    <td>Spread (%) (S)</td>
                    <td>Final rate
                      (%)
                      R = (B) + (S)</td>
                    <td colspan="2">Reset
                      periodicity   
                      (Months)</td>
                    <td colspan="2">Impact of change in the reference benchmark
                      (for 25 bps change in ‘R’ , change in
                      )</td>
                  </tr>
                  <tr>
                    <td colspan="4"></td>
                    <td>B</td>
                    <td>S</td>
                    <td>EPI(₹)</td>
                    <td>No. of EPIs</td>
                  </tr>
                  <tr>
                    <td>Not Applicable</td>
                    <td>Not Applicable</td>
                    <td>Not Applicable</td>
                    <td>Not Applicable</td>
                    <td>Not Applicable</td>
                    <td>Not Applicable</td>
                    <td>Not Applicable</td>
                    <td>Not Applicable</td>
                  </tr>
                  <tr >
                    <td >8</td>
                    <td colSpan="7" className="tablebold"> Fees/charges</td>
                  </tr>
                  <tr>


                    <td colspan="2"></td>
                    <td colspan="2">Payable to the RE (A)</td>
                    <td colspan="4">Payable to a third party through RE (B)</td>
                  </tr>
                  <tr>

                    <td></td>
                    <td ></td>
                    <td >One-time/ Recurring</td>
                    <td >Amount (in ₹) or Percentage (%) as applicable</td>
                    <td colspan="2" >One- time/Recurring</td>
                    <td colspan="2" >Amount (in ₹) or Percentage (%) as applicable </td>
                  </tr>
                  <tr>
                    <td>(i)</td>
                    <td>Processing fees</td>
                    <td>One- Time</td>
                    <td>INR. {new Intl.NumberFormat('en-IN').format(Number(data?.sanction_amount) * 0.03) }/- + GST</td>
                    <td colspan="2">Not Applicable</td>
                    <td colspan="2">Not Applicable</td>
                  </tr>
                  <tr>
                    <td>(ii)</td>
                    <td>Insurance charges</td>
                    <td>Not Applicable</td>
                    <td>Not Applicable</td>
                    <td colspan="2">One- Time</td>
                    <td colspan="2">INR. {new Intl.NumberFormat('en-IN').format(Math.ceil(Number(data?.sanction_amount) * 0.06 / 1.18))}/- + GST </td>
                  </tr>
                  <tr>
                    <td>(iii)</td>
                    <td>Valuation fees</td>
                    <td>Not Applicable</td>
                    <td>Not Applicable</td>
                    <td colspan="2">Not Applicable</td>
                    <td colspan="2">Not Applicable</td>
                  </tr>
                  <tr>
                    <td>(iv)</td>
                    <td>Any other (please specify)</td>
                    <td></td>
                    <td></td>
                    <td colspan="2"></td>
                    <td colspan="2"></td>
                  </tr>

                  <tr>
                    <td>A</td>
                    <td>Initial Login Fee </td>
                    <td>One- Time</td>
                    <td>INR. 2,119/- + GST</td>
                    <td colspan="2">Not Applicable</td>
                    <td colspan="2">Not Applicable</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td>IMD</td>
                    <td>One- Time</td>
                    <td>INR. 2,119/- + GST</td>
                    <td colspan="2">Not Applicable</td>
                    <td colspan="2">Not Applicable</td>
                  </tr>
                  <tr>
                    <td>C</td>
                    <td>Pre EMI</td>
                    <td>One- Time</td>
                    <td>INR. {new Intl.NumberFormat('en-IN').format(Math.ceil(pre_emi)) }/-</td>
                    <td colspan="2">Not Applicable</td>
                    <td colspan="2">Not Applicable</td>
                  </tr>
                  <tr style={{padding:"0px"}}>
                    <td  style={{padding:"4px"}}>D</td>
                    <td  style={{padding:"4px"}}>Stamp Duty</td>
                    <td  style={{padding:"4px"}}>Not Applicable</td>
                    <td  style={{padding:"4px"}}>Not Applicable</td>
                    <td  style={{padding:"4px"}} colspan="2">Not Applicable</td>
                    <td  style={{padding:"4px"}} colspan="2">Not Applicable</td>
                  </tr>
                </tbody>
              </table>
            </>
            {footerDiv(data.bank)}
          </div>
          <div
            style={{
              width: "290mm",
              height: "410mm",
              backgroundColor: "white",
              padding: 40,
              position: "relative",
            }}
          >
            {headerDiv(data.bank)}
            <div>

              <table border="1" cellPadding="10" cellSpacing="0">
                <tbody>
                  <tr>
                    <td>9</td>
                    <td colspan="3">Annual Percentage Rate (APR) (%)</td>
                    <td colspan="3">{APRRATE} %</td>
                  </tr>
                  <tr >
                    <td >10</td>
                    <td colSpan="7" className="tablebold"> Details of Contingent Charges (in or %, as applicable) </td>
                  </tr>
                  <tr >
                    <td>(A)</td>
                    <td colSpan="3" className="tablebold">Penal charges, if any , in case of delayed payment</td>
                    <td colSpan="3">INR. 2,000/- + GST</td>
                  </tr>
                  <tr >
                    <td>(B)</td>
                    <td colSpan="3" className="tablebold">Other penal charges, if any</td>
                    <td colSpan="3">Not Applicable </td>
                  </tr>
                  <tr >
                    <td>(C)</td>
                    <td colSpan="3" className="tablebold">Foreclosure charges, if applicable</td>
                    <td colSpan="3">Up to 10.0% + GST</td>
                  </tr>
                  <tr >
                    <td>(D)</td>
                    <td colSpan="3" className="tablebold">Charges for switching of loans from floating to fixed rate and vice versa</td>
                    <td colSpan="3">Not Applicable </td>
                  </tr>
                  <tr >
                    <td>(E)</td>
                    <td colSpan="3" className="tablebold">Any other charges (please specify)</td>
                    <td colSpan="3">Not Applicable</td>
                  </tr>
                  <tr >
                    <td>(F)</td>
                    <td colSpan="3" className="tablebold">Additional Repayment Schedule</td>
                    <td colSpan="3"> INR. 2,000/- + GST</td>
                  </tr>
                  <tr >
                    <td>(G)</td>
                    <td colSpan="3" className="tablebold">Statement of Account(SOA)Charges</td>
                    <td colSpan="3">INR. 2,000/- + GST</td>
                  </tr>
                  <tr >
                    <td>(H)</td>
                    <td colSpan="3" className="tablebold">List of Document(LOD) Issuance Charges</td>
                    <td colSpan="3">INR. 2,000/- + GST</td>
                  </tr>
                  <tr >
                    <td>(I)</td>
                    <td colSpan="3" className="tablebold">Partial Prepayment</td>
                    <td colSpan="3">10% prepayment fee for partial prepayment. No partial prepayment allowed in loans less than
                      6 MOB. Partial prepayments allowed only 2 times in a year. There should be a minimum gap
                      of 4 months between two partial prepayments</td>
                  </tr>
                  <tr >
                    <td>(J)</td>
                    <td colSpan="3" className="tablebold">Swap Charges</td>
                    <td colSpan="3">INR. 2,000/- per swap+ GST
                    </td>
                  </tr>
                  <tr >
                    <td>(K)</td>
                    <td colSpan="3" className="tablebold">Cheque/ECS/NACH Bounce Charges</td>
                    <td colSpan="3">INR. 1,000 /-  + GST</td>
                  </tr>
                  <tr >
                    <td>(L)</td>
                    <td colSpan="3" className="tablebold">Late Payment Charges</td>
                    <td colSpan="3">Charged upto 4.00 % p.m. on overdue/delays/defaults payable Amount. </td>
                  </tr>
                  <tr >
                    <td>(M)</td>
                    <td colSpan="3" className="tablebold">Cheque Replenishment Charge/Administration Fee</td>
                    <td colSpan="3">INR. 750/- + GST</td>
                  </tr>
                  <tr >
                    <td>(N)</td>
                    <td colSpan="3" className="tablebold">Document Retrieval Charges/ Balance Outstanding Letter</td>
                    <td colSpan="3">INR. 5,000/- + GST</td>
                  </tr>
                </tbody>
              </table>
            
            <h6 style={{ textAlign: "center", fontWeight: "bold" }}>Other Qualitative Information (Part 2)</h6>
            <table>

              <tbody>
                <tr>
                  <td>1</td>
                  <td>Clause of Loan agreement relating to engagement of recovery agents</td>
                  <td colspan="3">Please refer <b>'Article 11'</b> in <b>'Secured Business Loan Agreement'</b></td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Clause of Loan agreement which details grievance redressal mechanism</td>
                  <td colspan="3">Please refer <b>'Clause 6.1(j)'</b> in <b>'Secured Business Loan Agreement'</b></td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Phone number and email id of the nodal grievance redressal officer</td>
                  <td colspan="3">
                    0141-4005012 / <a href="mailto:care@kaabilfinance.com">care@kaabilfinance.com</a>
                  </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>
                    Whether the loan is, or in future maybe, subject to transfer to other REs or securitisation (Yes/No)
                  </td>
                  <td colspan="3">Yes</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td colspan="4">
                    In case of lending under collaborative lending arrangements (e.g., co-lending/outsourcing),
                    following additional details may be furnished:
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td><b>Name of the originating RE, along with its funding proportion</b> <sup>*1</sup></td>
                  <td><b>Name of the partner RE, along with its proportion of funding</b> <sup>*2</sup></td>
                  <td><b>Blended rate of interest</b></td>
           
                  
          
                </tr>
                <tr>
                  <td></td>
                  <td>Kaabil Finance Private Limited</td>   
                  <td></td>
                  <td >{data.interest_rate}% p.a.</td>
                </tr>
             
                <tr>
                  <td>6</td>
                  <td colspan="4">In case of digital loans, following specific disclosures may be furnished:</td>
                </tr>
                <tr>
                  <td></td>
                  <td>i. Cooling off/lock-up period, during which borrower shall not be charged any penalty on prepayment of loan</td>
                  <td colspan="3">Not Applicable</td>
                </tr>
                <tr>
                  <td></td>
                  <td>ii. Details of LSP acting as recovery agent and authorized to approach the borrower</td>
                  <td colspan="3">Not Applicable</td>
                </tr>
              </tbody>
            </table>
            <p style={{ marginTop: "1rem" }}>1* As Per the Sanction Letter Of Kaabil Finance Private Limited<br/>
            2* As Per the Sanction Letter Of colender/_____________________(Name of colender)</p>

          
            </div>
            {footerDiv(data.bank)}
          </div>
        </div>
        {/* <div
          style={{
            width: "290mm",
            height: "410mm",
            backgroundColor: "white",
            padding: 40,
            position: "relative",
          }}
        >
          <br />
          {headerDiv(data.bank)}
          <br />


          <div className="container">
            <h6 style={{ textAlign: "center", fontWeight: "bold" }}>Other Qualitative Information (Part 2)</h6>
            <table>

              <tbody>
                <tr>
                  <td>1</td>
                  <td>Clause of Loan agreement relating to engagement of recovery agents</td>
                  <td colspan="3">Please refer <b>'Article 11'</b> in <b>'Secured Business Loan Agreement'</b></td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Clause of Loan agreement which details grievance redressal mechanism</td>
                  <td colspan="3">Please refer <b>'Clause 6.1(j)'</b> in <b>'Secured Business Loan Agreement'</b></td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Phone number and email id of the nodal grievance redressal officer</td>
                  <td colspan="3">
                    0141-4005012 / <a href="mailto:care@kaabilfinance.com">care@kaabilfinance.com</a>
                  </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>
                    Whether the loan is, or in future maybe, subject to transfer to other REs or securitisation (Yes/No)
                  </td>
                  <td colspan="3">Yes</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td colspan="4">
                    In case of lending under collaborative lending arrangements (e.g., co-lending/outsourcing),
                    following additional details may be furnished:
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td><b>Name of the originating RE, along with its funding proportion</b> <sup>*1</sup></td>
                  <td><b>Name of the partner RE, along with its proportion of funding</b> <sup>*2</sup></td>
                  <td><b>Blended rate of interest</b></td>
           
                  
          
                </tr>
                <tr>
                  <td></td>
                  <td>Kaabil Finance Private Limited</td>   
                  <td></td>
                  <td >{data.interest_rate}% p.a.</td>
                </tr>
             
                <tr>
                  <td>6</td>
                  <td colspan="4">In case of digital loans, following specific disclosures may be furnished:</td>
                </tr>
                <tr>
                  <td></td>
                  <td>i. Cooling off/lock-up period, during which borrower shall not be charged any penalty on prepayment of loan</td>
                  <td colspan="3">Not Applicable</td>
                </tr>
                <tr>
                  <td></td>
                  <td>ii. Details of LSP acting as recovery agent and authorized to approach the borrower</td>
                  <td colspan="3">Not Applicable</td>
                </tr>
              </tbody>
            </table>
            <p style={{ marginTop: "2rem" }}>1* As Per the Sanction Letter Of Kaabil Finance Private Limited</p>
            <p>2* As Per the Sanction Letter Of colender/_____________________(Name of colender)             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;        <sub>(Signature of BCM/BM)</sub></p>
          </div>

          <br />
          {footerDiv(data.bank)}
        </div> */}
        <div
          style={{
            width: "290mm",
            height: "410mm",
            backgroundColor: "white",
            padding: 40,
            position: "relative",
          }}
        >
          <br />
          {headerDiv(data.bank)}
          <br />


          <div className="container">
            <h4>Annexure-B</h4>
            <h4 style={{ textAlign: "center", fontWeight: "bold" }}>Illustration for computation of APR for Retail and MSME loans</h4>
            <table>

              <tbody>
                <tr>
                  <th style={{ textAlign: "center" }}>Sr.No.</th>
                  <th style={{ textAlign: "center" }}>Parameter</th>
                  <th style={{ textAlign: "center" }}>Details</th>
                </tr>
                <tr>
                  <td>1</td>
                  <td>Sanctioned Loan amount (in Rupees) (Annexure-A Sr.No. 2 of the KFS – Part 1)</td>
                  <td>INR. {new Intl.NumberFormat('en-IN').format(data?.sanction_amount)}/-</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Loan Term (in years/ months/ days) (Annexure-A Sr.No. 4 of the KFS – Part 1)</td>
                  <td>
                    {data?.tenure} (Months)
                  </td>
                </tr>
                <tr>
                  <td>A</td>
                  <td>No. of installments for payment of principal, in case of non- equated periodic loans</td>
                  <td>
                  Not Applicable
                  </td>
                </tr>
                <tr>
                  <td>B</td>
                  <td>Type of EPI<br />
                    Amount of each EPI (in Rupees) <br />
                    nos. of EPIs (e.g., no. of EMIs in case of monthly installments)
                    (Annexure-A Sr.No. 5 of the KFS – Part 1)
                  </td>
                  <td>
                    Monthly<br />
                    INR. {new Intl.NumberFormat('en-IN').format(Math.round(emi))}/-<br />
                    {data?.tenure} (Months)
                  </td>
                </tr>
                <tr>
                  <td>C</td>
                  <td>No. of installments for payment of capitalised interest, if any</td>
                  <td>
                  Not Applicable
                  </td>
                </tr>
                <tr>
                  <td>D</td>
                  <td>Commencement of repayments, post sanction (Annexure-A Sr.No. 5 of the KFS – Part 1)</td>
                  <td>
                    {formattedEmiDate}
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Interest rate type (fixed or floating or hybrid) (Annexure-A Sr.No. 6 of the KFS – Part 1)</td>
                  <td>
                    Fixed Rate
                  </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Rate of Interest (Annexure-A Sr.No. 6 of the KFS – Part 1)
                  </td>
                  <td>{data?.interest_rate}% </td>
                </tr>
                <tr>
                  <td>5</td>
                  <td >
                    Total Interest Amount to be charged during the entire tenor of the loan as per the rate prevailing on sanction date (in Rupees)
                  </td>
                  <td>  INR. {new Intl.NumberFormat('en-IN').format((Math.round(emi) * data?.tenure) - data?.sanction_amount)}/-</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td colspan="2"><b>Fee/ Charges payable (in Rupees)</b></td>

                </tr>
                <tr>
                  <td>A</td>
                  <td>Payable to the RE (Annexure-A Sr.No. 8 of the KFS - Part 1)	As per the case</td>
                  <td>INR. {new Intl.NumberFormat('en-IN').format((Number((data?.sanction_amount) * 0.03) + (Number((data?.sanction_amount) * 0.03) * 0.18) + 2500 +2500+pre_emi).toFixed(2))}/-</td>
                </tr>
                <tr>
                  <td>B</td>
                  <td>Payable to third-party routed through RE (Annexure-A Sr.No. 8 of the KFS – Part 1)</td>
                  <td>INR. {new Intl.NumberFormat('en-IN').format((Number(data?.sanction_amount) * 0.06))}/-</td>
                </tr>
                <tr>
                  <td>7</td>
                  <td >Net disbursed amount (Deduction of Annexure-B Sr.No. 1 -  Annexure-B Sr.No. 6 ) (in Rupees)</td>
                  <td>INR. {new Intl.NumberFormat('en-IN').format(Number(sanctionAmount) - (Number(feesWithTaxAndAdditionals) + Number(processingFeess)))}/-  </td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>Total amount to be paid by the borrower <sup>1</sup> (sum of Annexure-B Sr.No.1 and Annexure-B Sr.No. 5) (in Rupees)</td>
                  <td>INR. {new Intl.NumberFormat('en-IN').format(Math.round(emi) * data.tenure)}/-</td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>Annual Percentage rate- Effective annualized interest rate (in percentage) <sup>2</sup>(Annexure-A Sr.No. 9 of the KFS - Part 1)</td>
                  <td>{APRRATE} %</td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>Schedule of disbursement as per terms and conditions</td>
                  <td>Refer illustrative Repayment Schedule</td>
                </tr>
                <tr>
                  <td>11</td>
                  <td>Due date of payment of installment and interest</td>
                  <td>
                    {formattedEmiDate}
                  </td>
                </tr>
              </tbody>
            </table>
            <p style={{paddingTop:"5rem"}}>
1 The difference in repayment amount calculated from the total of installments given under the detailed repayment schedule i.e.,
{new Intl.NumberFormat('en-IN').format(Math.round(
                        this.getEMI(
                          data?.sanction_amount,
                          data?.interest_rate,
                          data?.tenure
                        )
                      )* data.tenure) } (= {new Intl.NumberFormat('en-IN').format(Math.round(
                        this.getEMI(
                          data?.sanction_amount,
                          data?.interest_rate,
                          data?.tenure
                        )
                      ))}*{data.tenure}) vis-à-vis the amount of INR.{new Intl.NumberFormat('en-IN').format((Math.round(emi) * data?.tenure) - data?.sanction_amount + data?.sanction_amount)} (INR. {new Intl.NumberFormat('en-IN').format(data.sanction_amount)} (loan amount) + INR. {new Intl.NumberFormat('en-IN').format((Math.round(emi) * data?.tenure) - data?.sanction_amount)} (Interest charges) mentioned under (11) is
due to rounding off the instalment amount of INR. {emi.toFixed(2)} to INR. {new Intl.NumberFormat('en-IN').format(Math.round(emi))}/- under the detailed repayment schedule <br/>
2 Computed on net disbursed amount using IRR approach and reducing balance method</p>
          </div>

          <br />
          {footerDiv(data.bank)}
        </div>
          {paymentDivss(emi_table)}
          </>
} 
          <div
            style={{
              width: "290mm",
              height: "410mm",
              backgroundColor: "white",
              padding: 40,
              position: "relative",
            }}
          >
            {headerDiv(data.bank)}
            <br />
            <h4>Credit Arrangement Letter</h4>
            <h4 style={{ textDecoration: "none" }}>
              <span class="box">
                {" "}
                KFPL/SBL/CAL/{new Date().getFullYear()}/{this.state.loanno}
              </span>
            </h4>
            <br />
            <p>
              Date: {date.getDate()}
              {this.nth(date.getDate())}{" "}
              {date.toLocaleString("default", { month: "long" })}{" "}
              {date.getFullYear()} <br />
              <br />
              Applicant - {data.applicant} <br />
              Co Applicants -
              {data.coapplicants.map((item, idx) => (
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "70px",
                  }}
                >
                  {item.general_information.title}{" "}
                  {item.general_information.name}{" "}
                  {item.general_information.kyc_relation_type}{" "}
                  {item.general_information.kyc_relation}
                </span>
              ))}{" "}
              <br />
              Guarantors -
              {data.guarantors.map((item, idx) => (
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "70px",
                  }}
                >
                  {item.general_information.title}{" "}
                  {item.general_information.name}{" "}
                  {item.general_information.kyc_relation_type}{" "}
                  {item.general_information.kyc_relation}
                  {console.log(item)}
                </span>
              ))}{" "}
              <br />
              Address – {data.address}
            </p>
            <h4 style={{ textDecoration: "none" }}>
              Attention : {data.applicant} (Applicant)
            </h4>
            <p>
              Dear Sir/Madam, <br />
              <b>
                Sub: In–principle sanction of loan facility vide Application
                No. KFPL/SBL/{data.application_id}
              </b>
              <br />
              Thank you for choosing Kaabil Finance Private Limited “KFPL”. We
              are pleased to inform you that with reference to the above
              application, we have in-principle sanctioned you a loan facility,
              the details of which are given below and overleaf. This loan is
              also subject to the conditions that contained in the documents,
              which you shall execute between and in favour of the company.{" "}
              <br />
            </p>
            <table style={{ fontSize: 12 }}>
            <tr>
                <td style={{padding:"10px  10px",fontWeight:'bold'}}>Personal Guarantee</td>
                <td   style={{padding:"0px  10px"}}>
                  {data.applicant}
                  {data.coapplicants.map((item, idx) => (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {item.general_information.title}{" "}
                      {item.general_information.name}{" "}
                      {item.general_information.kyc_relation_type}{" "}
                      {item.general_information.kyc_relation}
                    </div>
                  ))}
                  {data.guarantors.map((item, idx) => (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {item.general_information.title}{" "}
                      {item.general_information.name}{" "}
                      {item.general_information.kyc_relation_type}{" "}
                      {item.general_information.kyc_relation}
                    </div>
                  ))}
                  {/* 1. {data.applicant} <br />
                  {data.guarantors.map((item, idx) => (
                    <div>
                      {idx + 2}. {item.name} <br />
                    </div>
                  ))}
                  {data.coapplicants.map((item, idx) => (
                    <div>
                      {idx + data.guarantors.length + 2}. {item.name} <br />
                    </div>
                  ))} */}
                </td>
              </tr>
              <tr>
                <td style={{ width: "50%",padding:"10px  10px",fontWeight:'bold'}}>Lender</td>
                <td   style={{padding:"0px  10px"}}>
                  {data.bank === "Capital" || data.bank === "KFPL" ? (
                    <span> Kaabil Finance Pvt. Limited (NBFC1/Lender) </span>
                  ) : null}
                  {data.bank === "Capital" ? (
                    <span>
                      & CAPITAL INDIA Finance Limited (NBFC 2). Both NBFC 1 and
                      NBFC 2 are collectively termed as ‘Lender’ (hereinafter
                      called the "Lender")
                    </span>
                  ) : (
                    <span></span>
                  )}
                  {data.bank === "home" || data.bank === "KFPL" ? (
                    <span> Kaabil Finance Pvt. Limited (NBFC1/Lender) </span>
                  ) : null}
                  {data.bank === "home" ? (
                    <span>
                      & CAPITAL INDIA Home Loans Ltd (NBFC 2). Both NBFC 1 and
                      NBFC 2 are collectively termed as ‘Lender’ (hereinafter
                      called the "Lender")
                    </span>
                  ) : (
                    <span></span>
                  )}
                  {data.bank === "ugro" ? (
                    <span>
                      Kaabil Finance Pvt. Limited (NBFC/Lender) (10%), U GRO
                      CAPITAL LIMITED (90%)
                    </span>
                  ) : null}
                  {data.bank === "vistaar" ? (
                    <span>
                      Kaabil Finance Pvt. Limited (NBFC/Lender) (20%), Vistaar Financial Services Pvt Ltd (80%)
                    </span>
                  ) : null}
                  {data.bank === "bfpl" || data.bank === "KFPL" ? (
                    <span> Kaabil Finance Pvt. Limited (NBFC1/Lender) </span>
                  ) : null}
                  {data.bank === "bfpl" ? (
                    <span>
                      & BHALA Finance Pvt. Limited (NBFC 2). Both NBFC 1 and
                      NBFC 2 are collectively termed as ‘Lender’ (hereinafter
                      called the "Lender")
                    </span>
                  ) : null}
                </td>
              </tr>
              <tr>
                <td style={{padding:"10px  10px",fontWeight:'bold'}}>Type of Loan</td>
                <td   style={{padding:"0px  10px"}}>MSME</td>
              </tr>
             {(data.bank==="ugro" || data.bank==='vistaar') ?  <tr>
                <td style={{fontSize:'large',fontWeight:'bold',padding:"10px  10px"}} >Loan Amount Sanctioned</td>
                <td style={{fontSize:'large',fontWeight:'bold' ,padding:"0px  10px"}} >INR. {new Intl.NumberFormat('en-IN').format(data.sanction_amount)}</td>
              </tr> :<tr>
                <td style={{padding:"10px  10px",fontWeight:'bold'}} >Loan Amount Sanctioned</td>
                <td   style={{padding:"0px  10px"}}>INR.{new Intl.NumberFormat('en-IN').format(data.sanction_amount)}</td>
              </tr> }
              {(data.bank==="ugro" || data.bank==='vistaar') ? (
                <tr>
                  <td style={{padding:"10px  10px",fontWeight:'bold'}}>Interest Type</td>
                  <td  style={{padding:"0px  10px"}}>Fixed</td>
                </tr>
              ) : null}
              {(data.bank==="ugro") ? (
                <tr>
                  <td style={{padding:"8px  10px",fontWeight:'bold'}}>UGRO Reference</td>
                  <td  style={{padding:"0px  10px"}}>{data.ugro_reference}</td>
                </tr>
              ) : null}
              {(  data.bank==='vistaar') ? (
                <tr>
                  <td style={{padding:"8px  10px",fontWeight:'bold'}}>VISTAAR Reference</td>
                  <td  style={{padding:"0px  10px"}}> N/A</td>
                </tr>
              ) : null}
             {(data.bank==="ugro" || data.bank==='vistaar') ? <tr>
                <td style={{fontSize:'large',fontWeight:'bold',padding:"8px  10px"}}>Term of Loan/tenure</td>
                <td style={{fontSize:'large',fontWeight:'bold',padding:"0px  10px"}}>{data.tenure} Months</td>
              </tr> :  <tr>
                <td style={{padding:"10px  10px",fontWeight:'bold'}} >Term of Loan/tenure</td>
                <td  style={{padding:"0px  10px"}}>{data.tenure} Months</td>
              </tr>}
              {(data.bank==="ugro" || data.bank==='vistaar') ? (
                <tr>
                  <td style={{fontSize:'large',fontWeight:'bold',padding:"8px  10px"}}> Interest Rate applicable and type</td>
                  <td style={{fontSize:'large',fontWeight:'bold',padding:"0px  10px"}}>Interest @ {data.interest_rate} % p.a.</td>
                </tr>
              ) : (
                <tr>
                  <td style={{padding:"10px  10px",fontWeight:'bold'}}>Interest Rate applicable and type</td>
                  <td style={{padding:"0px  10px"}}>Interest @ {data.interest_rate} % p.a. and Fixed</td>
                </tr>
              )}
              {(data.bank==="ugro"  ) ? (
                <tr>
                  <td style={{fontSize:'large',fontWeight:'bold',padding:"8px  10px"}}>UGRO Interest rate </td>
                  <td style={{fontSize:'large',fontWeight:'bold',padding:"0px  10px"}}>14% p.a.</td>
                </tr>
              ) : null}
              {(   data.bank==='vistaar') ? (
                <tr>
                  <td style={{fontSize:'large',fontWeight:'bold',padding:"8px  10px"}}>Vistaar Interest rate </td>
                  <td style={{fontSize:'large',fontWeight:'bold',padding:"0px  10px"}}>14.25% p.a.</td>
                </tr>
              ) : null}
            
            {(data.bank==="ugro" || data.bank==='vistaar') ?  <tr>
                <td style={{fontSize:'large',fontWeight:'bold',padding:"10px  10px"}}>Amount of each installment</td>
                <td style={{fontSize:'large',fontWeight:'bold',padding:"0px  10px"}}>
                  INR.{" "}
                  {new Intl.NumberFormat('en-IN').format(Math.round(
                    this.getEMI(
                      data.sanction_amount,
                      data.interest_rate,
                      data.tenure
                    )
                  ))}
                  /-
                </td>
              </tr> : 
              
              <tr>
                <td style={{padding:"10px  10px",fontWeight:'bold'}} >Amount of each installment</td>
                <td  style={{padding:"0px  10px"}}>
                  INR.{" "}
                  {new Intl.NumberFormat('en-IN').format(Math.round(
                    this.getEMI(
                      data.sanction_amount,
                      data.interest_rate,
                      data.tenure
                    )
                  ))}
                  /-
                </td>
              </tr>}
              <tr>
                <td style={{padding:"10px  10px",fontWeight:'bold'}}>Insurance</td>
                <td style={{padding:"0px  10px"}}>Insurance amount will be approximately 6% of Sanctions amount. however it may vary based on tenure and Age</td>
              </tr>
              <tr>
                <td style={{padding:"10px  10px",fontWeight:'bold'}}>Processing Fees (non- refundable)</td>
                <td style={{padding:"0px  10px"}}>{data.process_fees}% + GST </td>
              </tr>
              <tr>
                <td style={{padding:"10px  10px",fontWeight:'bold'}}>Security</td>
                <td style={{padding:"0px  10px"}}>As Per Annexure-A {data.bank==='vistaar' && "(PROPERTY PAPER)"}</td>
              </tr>
              <tr>
                <td style={{padding:"10px  10px",fontWeight:'bold'}}>Mode of Repayment</td>
                <td style={{padding:"0px  10px"}}>ECS/NACH/Cheque/cash{data.bank==='vistaar' && "/RTGS"}</td>
              </tr >
              {data.bank==='ugro' &&  <tr>
                <td style={{padding:"10px  10px",fontWeight:'bold'}}>No Of Post-Dated Cheques</td>
                <td style={{padding:"0px  10px"}}>
                  _____ Post-Dated Cheques of borro+wer and _____ Post-dated
                  cheques of co-applicant _____post-dated cheques of the
                  guarantor, Drawn in favour of Kaabil Finance Private Limited
                </td>
              </tr>}
            </table>
            {footerDiv(data.bank)}
          </div>
          
          <div
            style={{
              width: "290mm",
              height: "410mm",
              backgroundColor: "white",
              padding: 40,
              position: "relative",
            }}
          >
            {headerDiv(data.bank)}
     <>
    <table>
        <thead>
            <tr>
                <th rowspan="2" style={{textAlign:"center",verticalAlign:"middle"}}>Charges/Fees</th>
                <th colspan="3" class="centered">Secured Business Loan</th>
            </tr>
            <tr>
                <th class="centered">SE</th>
                <th class="centered">ME</th>
                <th class="centered">SME</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Cheque/ECS/NACH Bounce Charges</td>
                <td>INR. 1,000/- + Taxes as applicable per presentation</td>
                <td>INR. 1,000/- + Taxes as applicable per presentation</td>
                <td>INR. 1,000/- + Taxes as applicable per presentation</td>
            </tr>
            <tr>
                <td>Late Payment Charges</td>
                <td>Charged up to 4.00 % p.m. on overdue/delays/defaults of any monies payable</td>
                <td>Charged up to 4.00 % p.m. on overdue/delays/defaults of any monies payable</td>
                <td>Charged up to 4.00 % p.m. on overdue/delays/defaults of any monies payable</td>
            </tr>
            <tr>
                <td>Swap Charges</td>
                <td>INR. 1,000/- per swap + applicable Taxes</td>
                <td>INR. 1,000/- per swap + applicable Taxes</td>
                <td>INR. 1,000/- per swap + applicable Taxes</td>
            </tr>
            <tr>
                <td>Statement of Account</td>
                <td>INR. 1,000/- per statement + applicable Taxes</td>
                <td>INR. 1,000/- per statement + applicable Taxes</td>
                <td>INR. 1,000/- per statement + applicable Taxes</td>
            </tr>
            <tr>
                <td>Prepayment Charges</td>
                <td>10% on the amount prepaid and all amounts tendered by the borrower towards prepayment of the loan during the last 1yr from the date of final prepayment on outstanding</td>
                <td>10% on the amount prepaid and all amounts tendered by the borrower towards prepayment of the loan during the last 1yr from the date of final prepayment on outstanding</td>
                <td></td>
            </tr>
            <tr>
                <td colspan="4">GST extra, as applicable</td>
            </tr>
            <tr>
                <td colspan="4">Month on Books - The period to be calculated from the date of disbursal</td>
            </tr>
            <tr>
                <td>Partial Prepayment</td>
                <td>10% prepayment fee for partial prepayment. No partial prepayment allowed in loans less than 6 MOB. Partial prepayments allowed only 2 times in a year. There should be a minimum gap of 4 months between two partial prepayments.</td>
                <td>10% prepayment fee for partial prepayment. No partial prepayment allowed in loans less than 6 MOB. Partial prepayments allowed only 2 times in a year. There should be a minimum gap of 4 months between two partial prepayments.</td>
                <td></td>
            </tr>
            <tr>
                <td>Interest Certificate</td>
                <td>INR. 1,000 per statement + applicable Taxes</td>
                <td>INR. 1,000 per statement + applicable Taxes</td>
                <td>INR. 1,000 per statement + applicable Taxes</td>
            </tr>
            <tr>
                <td>Additional Repayment Schedule</td>
                <td>INR. 1,000 + applicable Taxes</td>
                <td>INR. 1,000 + applicable Taxes</td>
                <td>INR. 1,000 + applicable Taxes</td>
            </tr>
            <tr>
                <td>Document Retrieval Charges/ Balance Outstanding Letter</td>
                <td>INR. 1,000 + applicable Taxes</td>
                <td>INR. 1,000 + applicable Taxes</td>
                <td>INR. 1,000 + applicable Taxes</td>
            </tr>
           
            <tr>
                <td>Cheque Replenishment Charge/Administration Fee</td>
                <td>INR. 750 + applicable Taxes</td>
                <td>INR. 750 + applicable Taxes</td>
                <td>INR. 750 + applicable Taxes</td>
            </tr>
            <tr>
                <td>Initial Login Fee</td>
                <td>INR. -- plus applicable taxes</td>
                <td>INR. -- plus applicable taxes</td>
                <td>INR. -- plus applicable taxes</td>
            </tr>
            
        </tbody>
    </table>
    <table>
    
            <tr>
                <td rowspan="4" style={{padding:"8px 10px"}}>Repricing Fee</td>
                <td colspan="2"></td>
                <td colspan="2"></td>
                <td colspan="2"></td> 
            </tr>
            <tr>
                <td  style={{padding:"8px 10px"}}>Loan Amount (INR. in Lakhs)</td>
                <td  style={{padding:"8px 10px"}}>Fee (INR.)</td>
                <td  style={{padding:"8px 10px"}}>Loan Amount (INR. in Lakhs)</td>
                <td  style={{padding:"8px 10px"}}>Fee (INR.)</td>
                <td  style={{padding:"8px 10px"}}>Loan Amount (INR. in Lakhs)</td>
                <td  style={{padding:"8px 10px"}}>Fee (INR.)</td>
            </tr>
            <tr>
                <td  style={{padding:"8px 10px"}}> Upto 25 Lacs</td>
                <td  style={{padding:"8px 10px"}}>1500</td>
                <td  style={{padding:"8px 10px"}}> Upto 25 Lacs</td>
                <td  style={{padding:"8px 10px"}}>1500</td>
                <td  style={{padding:"8px 10px"}}> Upto 25 Lacs</td>
                <td  style={{padding:"8px 10px"}}>1500</td>
            </tr>
            <tr>
                <td  style={{padding:"8px 10px"}}> 25 Lacs & Above</td>
                <td  style={{padding:"8px 10px"}}>2500</td>
                <td  style={{padding:"8px 10px"}}> 25 Lacs & Above</td>
                <td  style={{padding:"8px 10px"}}>2500</td>
                <td  style={{padding:"8px 10px"}}> 25 Lacs & Above</td>
                <td  style={{padding:"8px 10px"}}>2500</td>
            </tr>
    </table>
    <table>
    <tr>
                  <th class="centered">Particulars</th>
                  <th class="centered">Charges</th>
                  <th class="centered">Particulars</th>
                  <th class="centered">Charges</th>
                </tr>
                <tbody>
                <tr>
                  <td>Pre-Payment request charges </td>
                  <td>3,000/-(Plus Applicable Taxes) </td>
                  <td>IMD Bouncing Charges (MSME & HL) </td>
                  <td>3,000/-(Plus Applicable Taxes)</td>
                </tr>
                <tr>
                  <td>Cheque Swapping Charges</td>
                  <td>1,000/-(Plus Applicable Taxes) </td>
                  <td>IMD Bouncing Charges (FTL) </td>
                  <td>2,000/-(Plus Applicable Taxes)</td>
                </tr>
                <tr>
                  <td>Legal Charges (Per Property) </td>
                  <td>2,000/-(Plus Applicable Taxes) </td>
                  <td>EMI Bouncing Charges </td>
                  <td>1,000/-(Plus Applicable Taxes)</td>
                </tr>
                <tr>
                  <td>Valuation Charges (Per Visit) (Per Property) </td>
                  <td>2,000/-(Plus Applicable Taxes) </td>
                  <td>EMI Bouncing Charges (MSME & HL) </td>
                  <td>1,000/-(Plus Applicable Taxes)</td>
                </tr>
                </tbody>
    </table>
   </>
            {footerDiv(data.bank)}
          </div>
          <div
            style={{
              width: "290mm",
              height: "410mm",
              backgroundColor: "white",
              padding: 40,
              position: "relative",
            }}
          >
            {headerDiv(data.bank)}
            <p>
              <h4> Terms & Conditions of this letter of offer:</h4>
              1. In case of part or full & final prepayment the prepayment
              charges would be 10.0% of outstanding principle + applicable taxes{" "}
              <br />
              2. No prepayment will be done within one year of loan
              disbursement, If done, then interest at the prevailing rate will
              be charges of the remaining period of that year along with the
              prepayment charges at 10.0% of the outstanding principle
              +applicable taxes <br />
              3. The sanction loan will be disbursed only after the scrutiny and
              clearance of proposed property by Kaabil Finance Private Limited
              and as per the rules of Kaabil Finance Private Limited. <br />
              4. <b> A. </b> Pre EMI Interest will be calculated from the date
              of disbursement to the date of 1st EMI due and the same be charged
              and deducted from the disbursed amount. <br />
              <b> B. </b> The EMI comprises of principal and interest calculated
              on the basis of monthly/ yearly rest at the rate applicable, which
              is rounded off to the next higher rupee. <br />
              <b> C. </b> Kaabil Finance Private Limited may at its sole
              discretion alter the rate of interest, suitably and prospectively
              under unforeseen or extraordinary changes in the money market
              conditions <br />
              5. Pre-EMI/EMI interests are to be paid on due date or before last
              date of every month. In case Pre EMI/EMI is not deposited on
              before the due date. Penal interest @ 3% p.m. compounded for
              number of days delayed in EMI payment will be charged debited in
              the customer loan account. 6. The loan will be secured by first
              mortgage of the property proposed for availing this loan and/or
              such other security., as Kaabil Finance Private Limited may find
              necessary and acceptable. Such document/reports/evidence as may be
              required by Kaabil Finance Private Limited shall be produced to
              ascertain that the property to be mortgaged with Kaabil Finance
              Private Limited has a clear and marketable title. The original
              title deeds to the property proposed to be purchased shall be
              deposited by the borrower for securing the loan. <br />
              6. The loan will be secured by first mortgage of the property
              proposed for availing this loan and/or such other security., as
              Kaabil Finance Private Limited may find necessary and acceptable.
              Such document/reports/evidence as may be required by Kaabil
              Finance Private Limited shall be produced to ascertain that the
              property to be mortgaged with Kaabil Finance Private Limited has a
              clear and marketable title. The original title deeds to the
              property proposed to be purchased shall be deposited by the
              borrower for securing the loan. <br />
              7. In case of additional limits, the existing mortgage shall be
              extended to cover the proposed additional limit and/or as per the
              sanction conditions. <br />
              8. Kaabil Finance Private Limited shall be informed in writing
              about any changes in correspondence address, change in employment,
              loss of job, business, profession, as the case may be immediately
              after such change/loss, notify the causes of delay, loss damage to
              the property, notify the addition/alteration to the property.{" "}
              <br />
              9. The loan amount has been fixed, inter alia on the assumption
              that the cost of the dwelling unit to be mortgaged will be as
              estimated in the application. In the event of the cost actually
              incurred being less, Kaabil Finance Private Limited. Reserves the
              right to suitably reduce the loan amount. <br />
              10. This letter of offer shall stand revoked and cancelled and
              shall be absolutely null and void if: <br />
              <b> A. </b> Any material changes occur in the proposal for which
              this loan is, in principle sanctioned. <br />
              <b> B. </b> Any material fact concerning income, or ability to
              repay or any other relevant aspect of the proposal or application
              for loan is withheld, suppressed, concealed of not made known to
              Kaabil Finance Private Limited. <br />
              <b> C. </b> Any statement made in the loan application is found to
              be incorrect or untrue. <br />
              11. All stamp duty and registration charges, if any as per state
              stamp act of any document executed by you in favors of Kaabil
              Finance Private Limited shall be payable by you in full. <br />
              12. In the event of any non-compliance of legal and technical
              formalities required by Kaabil Finance Private Limited all the
              fees paid to Kaabil Finance Private Limited will be
              non-refundable. <br />
              13. The issuance of this letter of offer, does not give confer any
              legal rights and Kaabil Finance Private Limited will be at full
              liberty to revoke this offer due to any of the mentioned above or
              otherwise. <br />
              14. The rate of interest, mentioned in the letter of offer, is
              fixed for entire tenure of the loan. <br />
              
              15. The rate of interest and the approach for gradations of risk
              and rationale for charging rate of interest is mentioned on the
              website:www.kaabilfinance.com <br />
              16. In internal BT Top Up cases existing LAN
              Number <b>{data && data.customer && data.customer.length > 0 && data.customer[0].loan && data.customer[0].loan.bt_loan_no ? data.customer[0].loan.bt_loan_no : '__________________'}</b> to be closed before payment
              release. <br />
              17. Alteration/Re-scheduling of EMI: If borrower has not drawn the
              entire loan amount within a period of 9 months from date of
              sanction, then Kaabil Finance Private Limited reserve the right to
              alter and re-scheduling the EMI in such manner and to such extent
              as lender may on its sole discretion, decide and repayment will
              thereupon be made as per said alteration or re-scheduling
              notwithstanding anything stated in the loan agreement. Where part
              of loan amount remain undisbursed for period exceeding
              availability period is mentioned in schedule 1 of loan agreement
              then lender reserves the right to cancel such undisbursed amount
              at its sole discretion.
            </p>
            {footerDiv(data.bank)}
          </div>
          <div
            style={{
              width: "290mm",
              height: "410mm",
              backgroundColor: "white",
              padding: 40,
              position: "relative",
            }}
          >
            {headerDiv(data.bank)}
            <p>
              18. A loan Agreement on terms acceptable to Kaabil Finance Private
              Limited and other documents in relation shall be executed by the
              Borrower. The terms in the Loan Agreement shall supersede all
              previous communications whether written/ verbal, in respect of
              Finance Facility.
              <br />
              19. All type of stamp Duty to be borne by Borrowers. <br/>
              20. Kaabil
              Finance Private Limited reserves its right to amend, alter the
              terms and conditions or withdraw all or any of the credit limits
              sanctioned at any time at its discretion without assigning any
              reasons whatsoever. <br />
              21. Kaabil Finance Private Limited reserves its right to amend,
              alter the terms and conditions or withdraw all or any of the
              credit limits sanctioned at any time at its discretion without
              assigning any reasons whatsoever. <br />
              22. The loan facility should be utilised for the specific purpose
              for which the same has been sanctioned and if Kaabil Finance
              Private Limited has reason to believe that you have violated, or
              apprehends that you are about to violate the said conditions, the
              Company shall have the option to exercise its right to recall the
              entire loan or any part thereof at once, in addition to its right
              to withdraw the undrawn amount not withstanding anything contrary
              contained in this Sanction Advice. It is affirmed that this right
              is without prejudice to the KFPL’s right to demand the Loan amount
              for violation of other terms and conditions of the sanction and /
              or the terms reflected in the loan / security documents to be
              executed by you. <br />
              23. The referred loan facility is extended to you solely at the
              KFPL’s discretion. In addition to what is stated in para 3, the
              Company reserves the right to withdraw partially / wholly or
              regulate such loan facility on the occurrence of any one or all of
              the following events:
              <br />
              24. Your non-compliance with terms and conditions of sanction
              Indulging in activities which are detrimental to the image /
              interest of the Company viz., acts that are unlawful, malafide
              etc. <br />
              25. In terms of directives in force now or as may be modified from
              time to time default in repayment of installments and / or
              servicing of interest for a notified period, automatically results
              in categorisation of all your borrowal accounts as Non-Performing
              Asset (NPA). Such categorization renders you ineligible from
              seeking Waiver of overdue interest Soft recovery measures etc.{" "}
              <br />
              26. The Company is under no obligation to consider your request,
              if any, for additional Loan facility(ies) without a comprehensive
              review of the existing credit limits, term loan operations in the
              accounts and past performance in meeting commitments such as
              servicing of interest charged to the loan account(s) repayment of
              loan installments, prompt submission of stock-statements., upkeep
              of records of inventory and books of accounts, upkeep of machinery
              financed, honouring commitments etc, as applicable to the purpose
              for which loan facility has been extended.
              <br />
              27. Duplicate copies of loan documents to be executed by you for
              the Loan facility(ies) referred herein, will be provided at your
              cost, on receipt of a specific request in writing to the
              undersigned. <br />
              28. Upon availment of credit facility on terms agreed, you have to
              produce necessary documentary evidence for end-use and facilitate
              verification by the Company. As and when called for, a certificate
              from your Auditor regarding end-use of the loan availed should be
              produced to the Company. <br />
              29. The information / data pertaining to all your borrowal
              accounts shall be furnished to Credit Information Bureau of India
              Limited (CIBIL) and other Agencies / Authorities, from time to
              time in terms of mandatory provisions in force. <br />
              30. Guarantor/ s is/are advised to take notice that the Company
              shall have the right to exercise discretion with regard to <br />
              31. The Borrower/(s), The Guarantor(s) and The Lender (s) agree
              that if any dispute/ disagreement/ differences (“Dispute”) arises
              between the Guarantor and the Lender during the subsistence of the
              Loan Documents (including this Deed) in connection with any nature
              whatsoever, then, the Dispute shall be referred to a sole
              arbitrator who shall be nominated/ appointed by the Lender only.
              The place of the arbitration shall be Jaipur and the arbitration
              proceedings shall be governed by the Arbitration & Conciliation
              Act, 1996 (or any statutory re-enactment thereof, for the time
              being in force) and shall be in the English language <br />
              {data.bank==='vistaar'? "": "32. You are required to provide postdated cheque’s (PDCS), to be replenished as and when they are exhausted towards payment of balance EMIs, till such time the entire loan is paid off" }<br />
            </p>
            <p>
              {/* 33. Fees and other charges :- <br />
              <table>
                
              </table> */}
              <br />
              {(data.bank==="ugro" || data.bank==='vistaar') ? (
                <p>
                  We look forward to a long lasting relationship with you.{" "}
                  <br />
                  Thanking You, <br />
                  Yours Sincerely, <br />
                  <br />
                  Authorized Signatory <br />
                  (On behalf of Kaabil Finance Pvt. Ltd.) <br />
                  <br />
                  <br />
                </p>
              ) : null}
            </p>
            {footerDiv(data.bank)}
          </div>
          <div
            style={{
              width: "290mm",
              height: "410mm",
              backgroundColor: "white",
              padding: 40,
              position: "relative",
            }}
          >
            {headerDiv(data.bank)}
            {(data.bank==="ugro" || data.bank==='vistaar') ? (
              <p>
                <h4>Other terms and conditions:</h4>
                1. Disbursement shall be subject to execution of transaction
                documents and creation of security and the facility/ loan
                agreement and other transaction documents may / will contain
                terms and in addition to or in modification of those set out in
                this letter.
                <br />
                2. The continuance of the Facility is subject to cancellation
                and / or repayment to {(data.bank==="ugro" ) && "UGRO" } {(data.bank==="vistaar" ) && "VISTAAR"}  on demand without assigning any
                reason for the same.
                <br />
                3. {(data.bank==="ugro" ) && "UGRO" } {(data.bank==="vistaar" ) && "VISTAAR"} is entitled to add to, delete or modify all or any of
                the terms and conditions for the facility and/or the Standard
                Terms applicable to the Facility.
                <br />
                4. The borrower/s shall immediately intimate {(data.bank==="ugro" ) && "UGRO" } {(data.bank==="vistaar" ) && "VISTAAR"} in the event
                of any change in the repayment capacity of the borrower/s
                including a loss / change in job / profession etc. any change in
                the information submitted earlier.
                <br />
                5. You have an option to enrol under Group Insurance scheme to
                cover the loan facility availed in full or part disbursal. You
                are free to avail insurance from any of the Insurance
                intermediaries and companies operating in the market. You will
                be required to pay insurance premium separately as applicable.
                <br />
                6. The collateral is to be insured at all times. The customer
                has the option to provide Insurance from any third party as long
                as the following conditions are met: a) Sum assured is not lower
                than the reconstruction cost b) the term is for the remaining
                tenure of the loan and c) the policy is lien marked to U Gro
                Capital. In absence of a valid policy, the borrower (s) agree to
                re-insure the collateral by debiting the loan account within 30
                days of the expiry of the policy.
                <br />
                7. Please note that risk-based pricing is determined based on
                the risk associated with type of loan (Risk Gradation), cost of
                funds, tenor of the loan, collateral and quantum of loan. In
                addition, there is a regular review exercise of the interest
                rates and product features with the competition offerings. An
                additional risk premium may incorporated in the pricing which is
                based on the credit risk associated with the customer which is a
                function of his credit history, bureau information, internal
                rating along with other factors like the borrowers income etc.
                Hence, it should be noted, that the interest rate applied is
                different from customer to customer and his/her loans/advances.
                The range of rates of interest and approach for gradation of
                risks are also available on the website of {(data.bank==="ugro" ) && "UGRO" } {(data.bank==="vistaar" ) && "VISTAAR"} Capital Ltd. at
                {(data.bank==="ugro" ) && "www.ugrocapital.com." } {(data.bank==="vistaar" ) && "contactus@vistaarfinance.com."}
                <br />
                8. Please note that this is the only sanction letter issued by
                {(data.bank==="ugro" ) && "UGRO" } {(data.bank==="vistaar" ) && "VISTAAR"} in respect of the abovementioned proposed loan facility.
                {(data.bank==="ugro" ) && "UGRO" } {(data.bank==="vistaar" ) && "VISTAAR"} shall not be liable, responsible or bound by any other
                letter / communication issued by any person in relation to the
                proposed loan facility.
                <br />
                9. Revision in reference rate and interest reset shall be
                applied from the first of the month following the month of the
                year in which URR is changed. In absence of any specific
                instruction, {(data.bank==="ugro" ) && "UGRO" } {(data.bank==="vistaar" ) && "VISTAAR"} will prefer to keep the EMI amount constant
                irrespective of revisions in URR.
                <br />
                10. Clause on right to co-lend ( {(data.bank==="ugro" ) && "UGRO" } {(data.bank==="vistaar" ) && "VISTAAR"} shall have a right to
                either process and disburse the entire loan amount singly or
                jointly together with such other co-lending partners i.e.
                bank/NBFCs as it may be deemed fit )
                <br />
              </p>
            ) : null}
            {(data.bank==="ugro" || data.bank==='vistaar') ? (
              <p>
                <b>Clarification on due dates and defaults</b>
                <br />
                If any amount due under the Loan is not received before the
                Lender runs its day end process on the due date, the same shall
                be considered as overdue and if continued to be overdue the
                account will be classified as SMA-1 at 31st day, SMA-2 at 61st
                day and -performing Asset at 91st day after the day end process
                of the respective dates.{" "}
                <b>
                  For example, if due date is 05-01-22 then if continuously
                  being unpaid the same shall be classified as SMA-1 as at
                  04-02-22, SMA-2 as at 06-03-22 and NPA as at 05-04-22.
                </b>
                If there is any overdue in an account, the default/
                non-repayment is reported with the credit bureau companies like
                CIBIL etc. and the CIBIL report of the customer will reflect
                defaults and its classification status and no. of days for which
                an account remains overdue is known as DPD (Days past due). Once
                an account is classified as NPAs then it shall be upgraded as
                ‘standard’ asset only if entire arrears of interest and
                principal are paid by the borrower. Detailed illustration and
                information about the same shall be available in the Loan
                agreement and is available at our website at
                {(data.bank==="ugro" ) && " “www.ugrocapital.com”" } {(data.bank==="vistaar" ) && "VISTAAR"}
               
                <br />
                <br />
                <p>
                  We look forward to a long lasting relationship with you.{" "}
                  <br />
                  Thanking You, <br />
                  Yours Sincerely,
                  <br />
                  Authorized Signatory <br />
                  (On behalf of Kaabil Finance Pvt. Ltd.) <br />
                </p>
              </p>
            ) : null}
             {(data.bank==="ugro" || data.bank==='vistaar')?null:(
              <>
             <h4>Annexure-A</h4>
            I / We accept the above terms and conditions
            <br />
            <table class="stretch" style={{padding: "10px 5px 10px 5px", fontSize: "20px"}}>
              <tr style={{textAlign: "center"}}>
                <th style={{width: "20%"}}>Borrowers</th>
                <th style={{width: "50%"}}>Name</th>
                <th style={{width: "30%"}}>Sign</th>
              </tr>
              <tr style={{height: "100px"}}>
                <td style={{textAlign: "center"}}>Applicant</td>
                <td style={{padding:"10px 10px"}}>{data.applicant}</td>
                <td >&nbsp;</td>
              </tr>
              {(data && data.coapplicants && data.coapplicants.length < 5) && (data.guarantors && data.guarantors.length < 5) && <> {data.coapplicants.map((item) => (
                <tr style={{height: "100px"}}>
                  <td style={{textAlign: "center"}}>Co Applicant</td>
                  <td style={{padding:"10px 10px"}}>
                    {item.general_information.title}{" "}
                    {item.general_information.name}{" "}
                    {item.general_information.kyc_relation_type}{" "}
                    {item.general_information.kyc_relation}
                  </td>
                  <td>&nbsp;</td>
                </tr>
              ))}
                {data.guarantors.map((item) => (
                  <tr style={{height: "100px"}}>
                    <td style={{textAlign: "center"}}>Guarantor</td>
                    <td style={{padding:"10px 10px"}}>
                      {item.general_information.title}{" "}
                      {item.general_information.name}{" "}
                      {item.general_information.kyc_relation_type}{" "}
                      {item.general_information.kyc_relation}
                    </td>
                    <td>&nbsp;</td>
                  </tr>
                ))}</>}
            </table>

               <table class="stretch" style={{marginTop:"5rem",padding: "10px 5px 10px 5px", fontSize: "20px"}}> 
              <tr style={{textAlign: "center"}}>
                <th>Sr. No.</th>
                <th>Property Owner</th>
                <th>Property Title Paper</th>
                <th>Property Area (in Sq Yards)</th>
                <th>Property Address</th>
              </tr>
              {data && data?.properties?.map((item, idx) => (
                <tr style={{height: "100px"}}>
                  <td style={{width: "10%",textAlign: "center"}}>{idx + 1}</td>
                  <td style={{width: "20%",padding:"10px 10px"}}>{item?.owner}</td>
                  <td style={{width: "20%",padding:"10px 10px"}}>{item?.type}</td>
                  <td style={{width: "20%",padding:"10px 10px"}}>{item?.area}</td>
                  <td style={{width: "20%",padding:"10px 10px"}}>{item?.address}</td>
                </tr>
              ))}
            </table>
            </>)}
            {footerDiv(data.bank)}
          </div>

          {(data.bank==="ugro" || data.bank==='vistaar')?(
          <div
            style={{
              width: "290mm",
              height: "410mm",
              backgroundColor: "white",
              padding: 40,
              position: "relative",
            }}
          >
            {headerDiv(data.bank)}
           
             <h4>Annexure-A</h4>
            I / We accept the above terms and conditions
            <br />
            <table class="stretch" style={{padding: "10px 5px 10px 5px", fontSize: "20px"}}>
              <tr style={{textAlign: "center"}}>
                <th style={{width: "20%"}}>Borrowers</th>
                <th style={{width: "50%"}}>Name</th>
                <th style={{width: "30%"}}>Sign</th>
              </tr>
              <tr style={{height: "100px"}}>
                <td style={{textAlign: "center"}}>Applicant</td>
                <td style={{padding:"10px 10px"}}>{data.applicant}</td>
                <td >&nbsp;</td>
              </tr>
              {(data && data.coapplicants && data.coapplicants.length < 5) && (data.guarantors && data.guarantors.length < 5) && <> {data.coapplicants.map((item) => (
                <tr style={{height: "100px"}}>
                  <td style={{textAlign: "center"}}>Co Applicant</td>
                  <td style={{padding:"10px 10px"}}>
                    {item.general_information.title}{" "}
                    {item.general_information.name}{" "}
                    {item.general_information.kyc_relation_type}{" "}
                    {item.general_information.kyc_relation}
                  </td>
                  <td>&nbsp;</td>
                </tr>
              ))}
                {data.guarantors.map((item) => (
                  <tr style={{height: "100px"}}>
                    <td style={{textAlign: "center"}}>Guarantor</td>
                    <td style={{padding:"10px 10px"}}>
                      {item.general_information.title}{" "}
                      {item.general_information.name}{" "}
                      {item.general_information.kyc_relation_type}{" "}
                      {item.general_information.kyc_relation}
                    </td>
                    <td>&nbsp;</td>
                  </tr>
                ))}</>}
            </table>

               <table class="stretch" style={{marginTop:"5rem",padding: "10px 5px 10px 5px", fontSize: "20px"}}> 
              <tr style={{textAlign: "center"}}>
                <th>Sr. No.</th>
                <th>Property Owner</th>
                <th>Property Title Paper</th>
                <th>Property Area (in Sq Yards)</th>
                <th>Property Address</th>
              </tr>
              {data && data?.properties?.map((item, idx) => (
                <tr style={{height: "100px"}}>
                  <td style={{width: "10%",textAlign: "center"}}>{idx + 1}</td>
                  <td style={{width: "30%",padding:"10px 10px"}}>{item?.owner}</td>
                  <td style={{width: "30%",padding:"10px 10px"}}>{item?.type}</td>
                  <td style={{width: "30%",padding:"10px 10px"}}>{item?.area}</td>
                  <td style={{width: "20%",padding:"10px 10px"}}>{item?.address}</td>
                </tr>
              ))}
            </table>
            
            {footerDiv(data.bank)}
          </div>):null}



          {((data && data.coapplicants && data.coapplicants.length >= 5) || (data.guarantors && data.guarantors.length >= 5)) && <div style={{
            width: "290mm",
            height: "410mm",
            backgroundColor: "white",
            padding: 40,
            position: "relative",
          }}
          >
            {headerDiv(data.bank)}
            <table class="stretch">
              <tr>
                <th>Borrowers</th>
                <th>Name</th>
                <th>Sign</th>
              </tr>
              {data.coapplicants.map((item) => (
                <tr>
                  <td>Co Applicant</td>
                  <td>
                    {item.general_information.title}{" "}
                    {item.general_information.name}{" "}
                    {item.general_information.kyc_relation_type}{" "}
                    {item.general_information.kyc_relation}
                  </td>
                  <td>&nbsp;</td>
                </tr>
              ))}
              {data.guarantors.map((item) => (
                <tr>
                  <td>Guarantor</td>
                  <td>
                    {item.general_information.title}{" "}
                    {item.general_information.name}{" "}
                    {item.general_information.kyc_relation_type}{" "}
                    {item.general_information.kyc_relation}
                  </td>
                  <td>&nbsp;</td>
                </tr>
              ))}
            </table>
            {footerDiv(data.bank)}
          </div>}
          {paymentDivs(emi_table)}
          {(data.bank==="ugro" || data.bank==='vistaar') && ( <div
            style={{
              width: "290mm",
              height: "410mm",
              backgroundColor: "white",
              padding: 40,
              position: "relative",
            }}
          >
            <br />

            {headerDiv(data.bank)}
            <br />
            <table class="stretch">
              <tr>
                <th>Sr. No.</th>
                <th>Property Owner</th>
                <th>Property Title Paper</th>
                <th>Property Area (in Sq Yards)</th>
                <th>Property Address</th>
              </tr>
              {data && data?.properties?.map((item, idx) => (
                <tr>
                  <td>{idx + 1}</td>
                  <td>{item?.owner}</td>
                  <td>{item?.type}</td>
                  <td>{item?.area}</td>
                  <td>{item?.address}</td>
                </tr>
              ))}
            </table>
            {footerDiv(data.bank)}
          </div>)}
          <div
            style={{
              width: "290mm",
              height: "410mm",
              backgroundColor: "white",
              padding: 40,
              position: "relative",
            }}
          >
            <br />
            {headerDiv(data.bank)}
            <br />
            <b>Insurance Details:</b>
            <table class="stretch">
              <tr style={{textAlign:"center",verticalAlign:"middle"}}>
                <th>Insured</th>
                <th>Sum Insured</th>
                <th>Insurance Premium</th>
                <th>Insurance Provider</th>
              </tr>
              <tr style={{height:"50px"}}>
                <td>&nbsp;</td>
                <td style={{padding:"0px 10px"}}>INR {data.sanction_amount}</td>
                <td style={{padding:"0px 10px"}}>INR</td>
                <td>      </td>
              </tr>
            </table>
            <br />
            <table class="stretch">
              <tr style={{textAlign:"center",verticalAlign:"middle"}}>
                <th style={{ width: "40%" }}>Borrowers</th>
                <th style={{ width: "30%" }}>Name</th>
                <th style={{ width: "30%" }}>Sign</th>
              </tr>
              <tr style={{ height: "60px" }}>
                <td style={{textAlign:"center"}}>Applicant</td>
                <td style={{padding:"0px 10px"}}>{data.applicant}</td>
                <td>&nbsp;</td>
              </tr>
              {data.coapplicants.map((item) => (
                <tr style={{ height: "60px" }}>
                  <td style={{textAlign:"center"}}>Co Applicant</td>
                  <td style={{padding:"0px 10px"}}>
                    {item.general_information.title}{" "}
                    {item.general_information.name}{" "}
                    {item.general_information.kyc_relation_type}{" "}
                    {item.general_information.kyc_relation}
                  </td>
                  <td>&nbsp;</td>
                </tr>
              ))}
              {data.guarantors.map((item) => (
                <tr style={{ height: "60px" }}>
                  <td style={{textAlign:"center"}}>Guarantor</td>
                  <td style={{padding:"0px 10px"}}>
                    {item.general_information.title}{" "}
                    {item.general_information.name}{" "}
                    {item.general_information.kyc_relation_type}{" "}
                    {item.general_information.kyc_relation}
                  </td>
                  <td>&nbsp;</td>
                </tr>
              ))}
            </table>
            <br />
            {footerDiv(data.bank)}
          </div>
          {data.bank==='KFPL' &&   <div
            style={{
              width: "290mm",
              height: "410mm",
              backgroundColor: "white",
              padding: 40,
              position: "relative",
            }}
          >
            <br />
            {headerDiv(data.bank)}
            <br />
            <div className="container my-5">
      <h4 className="text-center">Annexure C (Schedule VII)</h4>
      
      {/* PAN Detail Section */}
      <div className="mt-4">
        <h5 className="text-center">Aadhar and PAN Detail Borrower/Co-Borrower(s)</h5>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Name</th>
              <th>Aadhar No.</th>
              <th>PAN No.</th>
              <th>Signature</th>
            </tr>
          </thead>
          <tbody>
          {data?.allapplicantdata?.map((item,index) => (
              <tr >
                <td>{index+1}</td>
                  <td>{item?.general_information?.title}{" "}
                    {item?.general_information?.name}</td> 
                    <td>{item?.general_information?.masked_aadhar}</td>
                <td>{item?.general_information?.pan_no}</td>
                <td></td>
              </tr>
                    ))}
                {data?.coapplicants?.map((item,index) => (
              <tr >
                <td>{index+2}</td>
                  <td>{item?.general_information?.title}{" "}
                    {item?.general_information?.name}</td> 
                    <td>{item?.general_information?.masked_aadhar}</td>
                <td>{item?.general_information?.pan_no}</td>
                <td></td>
              </tr>
                    ))}
            
          </tbody>
        </table>
      </div>

    </div>
          
            <br />
            {footerDiv(data.bank)}
          </div>}
          <div
            style={{
              width: "290mm",
              height: "410mm",
              backgroundColor: "white",
              padding: 40,
              position: "relative",
            }}
          >

            {headerDiv(data.bank)}
            <br />
            {/* {this.state.vaDetails && (
              <div>
                <h2>Payment QR Code</h2>
                <div style={{ paddingLeft: "50px", paddingTop: "50px" }}>
                  <p>
                    <b>Account Payee:</b> Kaabil Finance Private Ltd
                  </p>
                  <p>
                    <b>Bank Name:</b> ICICI Bank
                  </p>
                  <p>
                    <b>Account Number:</b>{" "}
                    {this.state.vaDetails && this.state.vaDetails.va_num}
                  </p>
                  <p>
                    <b>IFSC Code:</b> ICIC0000106
                  </p>
                  {this.state.vaDetails && (
                    <div>
                      <img
                        src={this.state.vaDetails.qrcode_url}
                        alt="QR Code"
                      ></img>
                    </div>
                  )}
                </div>
              </div>
            )} */}
            {(data.bank==="ugro" || data.bank==='vistaar') && (
              <img
                src={nachform}
                style={{
                  // position: "absolute",
                  width: "270mm",
                   marginTop: "20px",
                }}
                alt="KFPL Footer"
              />
            )}
            {footerDiv(data.bank)}
          </div>
          {Math.ceil(data.tenure / 48) % 2 === 0 ? <div style={{
            width: "290mm",
            height: "410mm",
            backgroundColor: "white",
            padding: 40,
            position: "relative",
          }}>
             {footerDiv(data.bank)}
          </div> : null}
          <div
            style={{
              width: "290mm",
              height: "410mm",
              backgroundColor: "white",
              padding: 40,
              position: "relative",
            }}
          >
            {headerDiv(data.bank)}
            <br />
            <div style={{ height: '140mm', marginTop: "20px" }}>
            <table className="stretch">
                <thead>
                  <tr style={{textAlign:"center",verticalAlign:"middle"}}>
                    <th>S.NO.</th>
                    <th>Document Name</th>
                    <th>First Party</th>
                    <th>Second Party</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>Loan agreement</td>
                    <td>{data.applicant} and others</td>
                    <td>Kaabil Finance Private Limited</td>
                    <td>{Math.ceil(data.sanction_amount * (0.25 / 100)/10)*10}</td>
                  </tr>
                   
                    <tr >
                      <td>2.</td>
                      <td>Undertaking</td>
                      <td>{data.applicant} and others</td>
                    <td>Kaabil Finance Private Limited</td>
                      <td> {100}</td>
                    </tr>
                    <tr >
                      <td>3.</td>
                      <td>Undertaking</td>
                      <td>{data.applicant} and others</td>
                    <td>Kaabil Finance Private Limited</td>
                      <td> {100}</td>
                    </tr>
                    <tr >
                      <td>4.</td>
                      <td>Undertaking</td>
                      <td>{data.applicant} and others</td>
                    <td>Kaabil Finance Private Limited</td>
                      <td> {100}</td>
                    </tr>

                  {data && data.guarantors.map((item, index) => (
                    <tr key={`guarantor_${index + 1}`}>
                      <td>{  index + 5}.</td>
                      <td>Letter of Guarantee</td>
                      <td>
                        {item.general_information.title} {item.general_information.name}{" "}
                        {item.general_information.kyc_relation_type}{" "}
                        {item.general_information.kyc_relation}
                      </td>
                      <td>Kaabil Finance Private Limited</td>
                      <td> {500}</td>
                    </tr>
                  ))}

                </tbody>
              </table>
            </div>
            <h1> ✂---------------------------------------------------------------------✂  </h1>
            <div style={{ marginTop: '50px' }}>
              <table className="stretch">
                <thead>
                  <tr style={{textAlign:"center",verticalAlign:"middle"}}>
                    <th>S.NO.</th>
                    <th>Document Name</th>
                    <th>First Party</th>
                    <th>Second Party</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>Loan agreement</td>
                    <td>{data.applicant} and others</td>
                    <td>Kaabil Finance Private Limited</td>
                    <td>{Math.ceil(data.sanction_amount * (0.25 / 100)/10)*10}</td>
                  </tr>
                   
                    <tr >
                      <td>2.</td>
                      <td>Undertaking</td>
                      <td>{data.applicant} and others</td>
                    <td>Kaabil Finance Private Limited</td>
                      <td> {100}</td>
                    </tr>
                    <tr >
                      <td>3.</td>
                      <td>Undertaking</td>
                      <td>{data.applicant} and others</td>
                    <td>Kaabil Finance Private Limited</td>
                      <td> {100}</td>
                    </tr>
                    <tr >
                      <td>4.</td>
                      <td>Undertaking</td>
                      <td>{data.applicant} and others</td>
                    <td>Kaabil Finance Private Limited</td>
                      <td> {100}</td>
                    </tr>

                  {data && data.guarantors.map((item, index) => (
                    <tr key={`guarantor_${index + 1}`}>
                      <td>{  index + 5}.</td>
                      <td>Letter of Guarantee</td>
                      <td>
                        {item.general_information.title} {item.general_information.name}{" "}
                        {item.general_information.kyc_relation_type}{" "}
                        {item.general_information.kyc_relation}
                      </td>
                      <td>Kaabil Finance Private Limited</td>
                      <td> {500}</td>
                    </tr>
                  ))}

                </tbody>
              </table>
            </div>
            {footerDiv(data.bank)}
          </div>

        </div>
      </div>
    );
  }
}

