import React, { useEffect, useState } from 'react'
import { useReactToPrint } from "react-to-print";
import { loanService } from '../../../_services';
import datehelper from "../../../_helpers/datehelper";
import vistaarLogo from "../../../assets/Vistaar.jpg"
import capitalfinance from "../../../assets/capitalfinance.png";
import logo from "../../../assets/kaabil.png";
const DisbursementRequestForm = (props) => {

    const data = props.data
    const branches = props.branches
    const colender = props.colender
    // const DisbursementRequestFormRef = React.useRef();



    // const handleSubmissionPrint = useReactToPrint({
    //     content: () => DisbursementRequestFormRef.current,
    //     pageStyle: "print",
    // });
    const verifiedDisbursements = data?.disbursement?.disbursement_memo?.filter(
        (disbursement) => disbursement.verification === true
      );
    const headerDiv = (colender) => (
        <div>
          <img src={logo} style={{ height: "100px" }} alt="KFPL Logo" />
          {colender.includes("CIFL") && (
            <img
              src={capitalfinance}
              style={{
                height: "100px",
                position: "absolute",
                top: "2",
                right: "0",
                marginRight: "3rem",
              }}
              alt="Capital Finance Logo"
            />
          )}
          {colender.includes("VistaarFinance") && (
            <img
              src={vistaarLogo}
              style={{
                height: "100px",
                position: "absolute",
                top: "2",
                right: "0",
                marginRight: "3rem",
              }}
              alt="Vistaar Finance Logo"
            />
          )}
        </div>
      );

    return (
        <>

            {/* <div style={{ textAlign: "center" }}>
                <button onClick={handleSubmissionPrint}>Print Letter</button>
            </div> */}

            <div
                style={{
                    width: "290mm",
                    height: "410mm",
                    backgroundColor: "white",
                    padding: 40,
                    position: "relative",
                    fontSize: "20px"
                }}
            >
                   {headerDiv(colender)}<br/><br/>
                <span style={{ marginLeft: "35%" }}><b>DISBURSEMENT REQUEST FORM</b></span><br /><br />
                <div style={{ marginLeft: "80%", display: "flex", flexDirection: "column" }}>
                    <span>Place: {branches.branch_city}</span><br />
                    <span>Date: <b>{colender.includes("CIFL")  ?datehelper.displayDate(new Date(data?.loan?.agreement_stamp_date)):datehelper.displayDate(new Date(data.created))} </b> </span>
                </div>
                <div style={{ textAlign: "justify" }}>

                    <span>To</span><br />
                    <span>
                        Kaabil Finance Private Limited,<br />
                        Plot No. 1, Scheme No. 1, Income Tax Colony, Model Town, <br/>Jagatpura, Jaipur Rajasthan 302017, (“Lender 1”)
                    </span><br /><br />
                    {colender.includes("CIFL") ?
                    <span>Capital India Finance Limited<br />
                    2nd Floor, DLF Centre, Sansad Marg <br />
                    New Delhi, Central Delhi – 110001, (“Lender 2”).</span>
                    :<span> Vistaar Financial Services Pvt Ltd <br />
                    Plot No. 59 & 60-23, 22nd Cross, 29th Main BTM Layout, <br />
                    2nd  Stage NA Bengaluru Bangalore KA 560076(“Lender 2”).</span>}<br /><br />

                    <span>(Collectively “Lenders”)</span><br /><br />

                    <span>Ref: Term loan of <b>Rs. {data && data.loan && data.loan.sanction_amount}/-</b> approved by the Lenders, vide the Sanction Letter dated <b>{colender.includes("CIFL")  ?datehelper.displayDate(new Date(data?.loan?.agreement_stamp_date)):datehelper.displayDate(new Date(data.created))} </b>
                        (“Loan”)</span><br /><br />

                    <span>Dear Sir / Madam,</span><br /><br />

                    <span>With reference to the Loan, I/we, the Borrower/s, hereby request you to disburse the following amounts, as per <br />
                        the details given below:</span><br /><br />
                        <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr style={{ border: "1px solid", textAlign: "center" }}>
            <td style={{ border: "1px solid", padding: "8px" }}>Sr.No</td>
            <td style={{ border: "1px solid", padding: "8px" }}>Amount to be Disbursed</td>
            <td style={{ border: "1px solid", padding: "8px" }}>Mode of payment</td>
            <td style={{ border: "1px solid", padding: "8px" }}>Name of the payee</td>
            <td style={{ border: "1px solid", padding: "8px" }}>Bank account number of the payee</td>
            <td style={{ border: "1px solid", padding: "8px" }}>Name & IFSC code of payee’s bank</td>
          </tr>
        </thead>
        <tbody>
          {/* Check if there are any verified disbursements */}
          {verifiedDisbursements && verifiedDisbursements.length > 0 ? (
            verifiedDisbursements.map((disbursement, index) => (
              <tr key={disbursement._id} style={{ textAlign: "center", border: "1px solid" }}>
                <td style={{ border: "1px solid", padding: "8px" }}>{index + 1}</td>
                <td style={{ border: "1px solid", padding: "8px" }}>{disbursement.payment_amount}</td>
                <td style={{ border: "1px solid", padding: "8px" }}>RTGS</td>
                <td style={{ border: "1px solid", padding: "8px" }}>{disbursement.customer_bank_name}</td>
                <td style={{ border: "1px solid", padding: "8px" }}>{disbursement.customer_acc_no}</td>
                <td style={{ border: "1px solid", padding: "8px" }}>
                  {disbursement.customer_bank_ifsc_code}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" style={{ textAlign: "center", padding: "8px", border: "1px solid" }}>
                No verified disbursements available.
              </td>
            </tr>
          )}
        </tbody>
      </table><br />
                    {/* <tr style={{ border: "1px solid", textAlign: "center" }}>
                            <td style={{ border: "1px solid" }}>&nbsp;</td>
                            <td style={{ border: "1px solid" }}>&nbsp;</td>
                            <td style={{ border: "1px solid" }}>RTGS</td>
                            <td style={{ border: "1px solid" }}>KAABIL FINANCE PRIVATE LIMITED</td>
                            <td style={{ border: "1px solid" }}>678705600547</td>
                            <td style={{ border: "1px solid" }}>ICIC0006787</td>
                        </tr> */}
                    <span>
                        In case of disbursement by cheque / pay order / demand draft, I/we authorize the Lender to handover the said
                        cheque / pay order / demand draft, directly to the payee, and also to collect original receipts of such payment.
                    </span><br /><br />

                    <span>Further, I/we understand and acknowledge that the interest shall be payable by me/us at the agreed interest rate
                        on the total amount disbursed, in terms of loan agreement executed in relation to the Loan. Thanking You,</span><br />

                    <span>Yours Faithfully,</span><br />

                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span style={{ width: "300px" }}>Name of the Borrower/s</span>
                        <span style={{ width: "300px" }}>Signature/ Thumb Impression</span>
                    </div><br />
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span span style={{ width: "300px" }}>{data && data.applicant && data.applicant.general_information && data.applicant.general_information.name}</span><br />
                        <span span style={{ width: "200px" }}>________________________</span><br /><br />
                    </div>
                    {data && data.co_applicants && data.co_applicants.length > 0 && data.co_applicants.map((e, idx) => {
                        return <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span span style={{ width: "300px" }}>{e.general_information.name}</span><br />
                            <span span style={{ width: "200px" }}>________________________</span><br /><br />
                        </div>
                    })}
                </div>
            </div>
        </>
    )
}

export default DisbursementRequestForm