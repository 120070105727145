import React from "react";
import { Grid, Card, Button, CardActions } from "@material-ui/core";
import { Edit, Add } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { loanService } from "../../_services/loan.service";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { employeeService } from "../../_services";
import roleChecker from "../../_helpers/rolechecker";
import { ColendingSubStages } from "../../constants/colendingsubstages";
import { history } from "../../_helpers";
import { BarChart, PieChart ,Pie , Cell ,Bar, CartesianGrid, XAxis, YAxis, Legend, Tooltip, LabelList, ResponsiveContainer, Label } from "recharts";
import { reportsService } from "../../_services/reports.service";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SwitchPanel from "../SwitchPanel";
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  root: {
    display: "flex",
    flexGrow: 1,
  },
  card: {
    minHeight: 250,
    minWidth: 250,
  },
}));

export default function ColendingDashboard({ match }) {
  const { path } = match;
  const classes = useStyles();
  const user = employeeService.userValue;
  const [pendingTasks, setPendingTasks] = React.useState(null);
  const [colenderSummary, setColenderSummary] = React.useState(null);
  const [spinner, setSpinner] = React.useState(true);
  const [chartData, setChartData] = React.useState({})
  const [page, setPage] = React.useState(0);
  const [colenderChartType, setColenderChartType] = React.useState('colender_weekly_count')
  const [disburseChartType, setDisburseChartType] = React.useState('disburse_month_amount')
  let colors = { 'Ugro': '#3760F1', 'CIHL': '#6D67E4', 'CIFL': 'green', 'KamalAutoFinance': '#46C2CB', 'AshvFinance': '#800000','VistaarFinance':'green' }
  let colenders = ['Ugro', 'CIHL', 'CIFL', 'KamalAutoFinance']
  let exclusionColenderStages = [ColendingSubStages.AmountReceivedFromColender, ColendingSubStages.ColenderLoanClosed, ColendingSubStages.ColendingCancelled, ColendingSubStages.RejectedByColender]
  async function loadCharts()
  {
    if(roleChecker.isColendingAccess(user.role) || roleChecker.isBMLevelAccess(user.role) || roleChecker.isASMLevelAccess(user.role) || roleChecker.isCCMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isBCMLevelAccess(user.role) || roleChecker.isCSMLevelAccess(user.role) || roleChecker.isRSMLevelAccess(user.role) || roleChecker.isColendingAccess(user.role) || roleChecker.isZSMLevelAccess(user.role) ) 
    { 
      
      if(roleChecker.isASMLevelAccess(user.role)  || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isBCMLevelAccess(user.role) ){
        // console.log('User role ---->>> ',user.role, user.branch_id)
        var branch_id = 'area-' + user.branch_id
      }
      else if(roleChecker.isCSMLevelAccess(user.role) || roleChecker.isCCMLevelAccess(user.role)){
        // console.log('User role ---->>> ',user.role, user.branch_id)
          var branch_id = 'cluster-' + user.branch_id
        }
      else if(roleChecker.isRSMLevelAccess(user.role)){
        // console.log('User role ---->>> ',user.role, user.branch_id)
          var branch_id = 'region-' + user.branch_id
        }else if( roleChecker.isZSMLevelAccess(user.role) ){
          var branch_id = 'zone-' + user.branch_id
        }
      else{
        var branch_id = user.branch_id
      }

      reportsService.getChartsData({ 'chart_key': "all" ,'branch_key': branch_id}).
      then((chartListObj) => {
        chartListObj.map((chartDataObj) => {
          // console.log('BKey ---->>>',chartDataObj.branch_key,branch_id)

          if (chartDataObj.branch_key === branch_id)
          {
                let dataArray = JSON.parse(chartDataObj.display_data);
              
                let data = [];
                Object.keys(dataArray).map((dataKey) => {
                    let dataItem = dataArray[dataKey];

                    // console.log('dataitem-----------',dataItem)
                    

                    if (chartDataObj.key== 'Loan_status_pie_chart' || chartDataObj.key== 'nachs_pie_chart' || chartDataObj.key == 'gold_colender_loans_pie'){
                      data.push({
                        'name': dataItem['name'],
                        'value': dataItem['value'],
                        
                      })
                    }
                    else if (chartDataObj.key == 'login_weekly_count' || chartDataObj.key == 'sanction_letter_weekly_count' || chartDataObj.key == 'disburse_weekly_count'|| chartDataObj.key == 'colender_weekly_count'){
                      // console.log('inside login count data')
                      data.push({
                        'display_x': dataItem['display_x'],
                        'Current Month': dataItem['curr_month'],
                        'Previous Month': dataItem['prev_month'],
                        
                      })
                    }
                    else if (chartDataObj.key=='current_month_bucket_plot' || chartDataObj.key=='previous_month_bucket_plot'  ){
                      data.push({
                      'display_x': dataItem['bucket'],
                      'Collected': dataItem['completed'],
                      'Total': dataItem['total']
                      })
                    }
                    else if  (chartDataObj.key=='gold_amount_monthly'  ){
                      data.push({
                      'display_x': dataItem['display_x'],
                      'amount': dataItem['amount'],
                      
                      })
                    }
                    else if  (chartDataObj.key=='bucket_x_monthly'  ){
                      data.push({
                      'display_x': dataItem['display_x'],
                      'outstanding_amount': dataItem['outstanding_amount'],
                      'collected_amount': dataItem['collected_amount'],

                      })
                    }
                    else {
                      data.push({
                      'name': dataItem['display_x'],
                      'value': dataItem['display_y'],
                      'count': dataItem['count']
                    })
                  }

              })
              // console.log('chartdata -->> ',dataItem)
              // console.log('createing chartdataobj key')
             
              chartData[chartDataObj.key ] = data;
      }
      })
      // console.log(chartData);
      setChartData(chartData);
      })
      .catch((err)=> {
        console.log(err);
      })
   }
  }
  
  React.useEffect(() => {
    loadCharts()
  }, [user])


  const ColenderHandleClick = (event) => {
    console.log('Button was clicked!');
    setColenderChartType(event)
    // console.log(event)
  

  };
  const DisburseHandleClick = (event) => {
    console.log('Button was clicked!');
    setDisburseChartType(event)
    console.log(event)


  };

  React.useEffect(() => {
    async function getApplicants() {
      setSpinner(true);
      var cust = await loanService.getAllColenderLoans();
      //console.log(cust);
      let tmpColendingSummary = {}
      cust.map((l) => {
        if (l.co_lender_data && !exclusionColenderStages.includes(l.co_lender_data.co_lender_status)) {
          let colenderName = l.co_lender_data.co_lender_name;
          if (!(Object.keys(tmpColendingSummary).includes(colenderName))) {

            tmpColendingSummary[colenderName] = { 'count': 0 }
          }
          tmpColendingSummary[colenderName]['count'] = tmpColendingSummary[colenderName]['count'] + 1
          // console.log(l.co_lender_data)

        }

      })
      //console.log("colending summary is ");
      //console.log(tmpColendingSummary)
      setColenderSummary(tmpColendingSummary)
      let tempLoans = cust.filter((l) => {
        if (!l.stage_owners || l.stage_owners.length == 0 || l.stage_owners[0].emp_id !== user.emp_id) {
          return false;
        }
        return true;
      });
      // console.log(tempLoans);
      let tmpPendingTaskList = {}
      tempLoans.map((l) => {
        if (l.co_lender_data) {
          let colenderName = l.co_lender_data.co_lender_name;
          if (exclusionColenderStages.includes(l.co_lender_data.co_lender_status)) {
            return l;
          }
          // console.log(colenderName);
          if (!(Object.keys(tmpPendingTaskList).includes(colenderName))) {

            tmpPendingTaskList[colenderName] = { 'count': 0 }
          }
          tmpPendingTaskList[colenderName]['count'] = tmpPendingTaskList[colenderName]['count'] + 1
        }

      })
      // console.log(tmpPendingTaskList)
      setPendingTasks(tmpPendingTaskList);
      setSpinner(false);

    }

    if (roleChecker.isColendingAccess(user.role) ) {
      getApplicants();
    }

  }, []);

  return (
    <div>
      <h1>CoLending Dashboard</h1>
      <hr style={{ width: '100%' }} /><br />
      <div className="d-flex justify-content-between">
        {pendingTasks && Object.keys(pendingTasks).length > 0 && (
          <div>
            <h2>My Tasks</h2>
            <div style={{ display: 'flex' }}>
              {pendingTasks && Object.keys(pendingTasks).map((cn, idx) => {
                return (
                  <div
                    style={{ width: '150px', height: '100px', backgroundColor: colors[cn], borderRadius: '10px', color: 'white', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around' }}>
                    <b>{cn}</b>
                    {pendingTasks[cn]['count']}
                    <Button size="small" style={{ color: 'white', marginLeft: "25px" }} onClick={() => history.push('/home/colending/mytasks/' + cn.toLowerCase())}>...More</Button>
                  </div>

                )
              })}
            </div>
            <hr style={{ width: '100%' }} /><br />
          </div>

        )}
      </div>
      <br />

      <div className="d-flex justify-content-between">
        {colenderSummary && Object.keys(colenderSummary).length > 0 && (
          <div>
            <h2>Colender Loan in Processing</h2>
            <div style={{ display: 'flex' }}>
              {colenderSummary && Object.keys(colenderSummary).map((cn, idx) => {
                return (
                  <div
                    style={{ width: '150px', height: '100px', backgroundColor: colors[cn], borderRadius: '10px', color: 'white', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around' }}>
                    <b>{cn}</b>
                    {colenderSummary[cn]['count']}
                    <Button size="small" style={{ color: 'white', marginLeft: "25px" }} onClick={() => history.push('/home/colending/loans/' + cn.toLowerCase())}>...More</Button>
                  </div>

                )
              })}
            </div>
            <hr style={{ width: '100%' }} /><br />
          </div>
        )}
        
      </div>
      
      <div class="row">
                <div class="col-md-6">
                  {(roleChecker.isHOLevelAccess(user.role) || roleChecker.isGMLevelAccess(user.role) || roleChecker.isBMLevelAccess(user.role) || roleChecker.isASMLevelAccess(user.role) || roleChecker.isCCMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isBCMLevelAccess(user.role) || roleChecker.isCSMLevelAccess(user.role) || roleChecker.isRSMLevelAccess(user.role) || roleChecker.isZSMLevelAccess(user.role) || roleChecker.isColendingAccess(user.role)) && chartData && chartData.disburse_month_amount && (
                    <>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <b>Disbursement Count</b>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          style={{ marginLeft: "20px", marginBottom: "10px" }}
                          value={disburseChartType}
                          onChange={(e) => DisburseHandleClick(e.target.value)}
                        >
                          <MenuItem value={'disburse_weekly_count'}>Weekly</MenuItem>
                          <MenuItem value={'disburse_month_amount'}>Monthly</MenuItem>

                        </Select>
                      </div>

                      {disburseChartType === 'disburse_month_amount' ?

                        <ResponsiveContainer width="90%" height={250}>
                          <BarChart data={chartData["disburse_month_amount"]}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis type="number" domain={[0, 'dataMax + 200']} />
                            <Tooltip />
                            <Legend />
                            <Bar isAnimationActive={false} dataKey="value" fill="#40e0d0">
                              <LabelList dataKey="value" position="top" />


                            </Bar>
                            <Bar isAnimationActive={false} dataKey="count" fill="#8884d8">
                              <LabelList dataKey="count" position="top" />
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                        :

                        <BarChart width={490} height={250} data={chartData['disburse_weekly_count']}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="display_x" />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          <Bar isAnimationActive={false} dataKey="Previous Month" fill="#FA803A" >
                            <LabelList dataKey="Previous Month" position="top" />
                          </Bar>
                          <Bar isAnimationActive={false} dataKey="Current Month" fill="#40e0d0" >
                            <LabelList dataKey="Current Month" position="top" />
                          </Bar>

                        </BarChart>
                      }
                    </>
                  )}
                </div>
                <div class="col-md-6">

                  {(roleChecker.isColendingAccess(user.role) || roleChecker.isGMLevelAccess(user.role)) && chartData && chartData.colender_month_amount && (
                    <>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <b>Colender Disbursement Count</b>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          style={{ marginLeft: "20px", marginBottom: "10px" }}
                          value={colenderChartType}
                          onChange={(e) => ColenderHandleClick(e.target.value)}
                        >
                          <MenuItem value={'colender_weekly_count'}>Weekly</MenuItem>
                          <MenuItem value={'colender_month_amount'}>Monthly</MenuItem>
                        </Select>
                      </div>

                      {colenderChartType === 'colender_month_amount' ?

                        <ResponsiveContainer width="90%" height={250}>
                          <BarChart data={chartData["colender_month_amount"]}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis type="number" domain={[0, 'dataMax + 200']} />
                            <Tooltip />
                            <Legend />
                            <Bar isAnimationActive={false} dataKey="value" fill="#40e0d0">
                              <LabelList dataKey="value" position="top" />


                            </Bar>
                            <Bar isAnimationActive={false} dataKey="count" fill="#8884d8">
                              <LabelList dataKey="count" position="top" />
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                        :

                        <BarChart width={490} height={250} data={chartData['colender_weekly_count']}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="display_x" />
                          <YAxis type="number" domain={[0, 'dataMax + 200']} />
                          <Tooltip />
                          <Legend />
                          <Bar isAnimationActive={false} dataKey="Previous Month" fill="#FA803A" >
                            <LabelList dataKey="Previous Month" position="top" />
                          </Bar>
                          <Bar isAnimationActive={false} dataKey="Current Month" fill="#40e0d0" >
                            <LabelList dataKey="Current Month" position="top" />
                          </Bar>

                        </BarChart>
                      }
                    </>
                  )}
                </div>
              </div>
              <SwitchPanel/>
    </div>
  );
}
