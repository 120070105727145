import React from 'react'
import { history } from '../../../_helpers'
import LegalCasesPersonalInfo from './LegalCasesPersonalInfo'

function LegalPersonalCase(props){
  let data = props.history.location.state


    return (
        <div>
            <h1>Legal Personal Case</h1>
          <LegalCasesPersonalInfo data={data}/>
        </div>
    )
}

export default LegalPersonalCase