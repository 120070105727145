import React from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  Link,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import ApplicantDetails from "./ApplicantDetails";
import { loanService } from "../../../_services/loan.service";
import LoanDetails from "./LoanDetails";
import CollateralDetails from "./CollateralDetails";
import PaymentDetails from "./PaymentDetails";
import ColenderDetails from "./ColenderDetails";
import SalesDetails from "./SaleDetails"
import { employeeService } from "../../../_services";
import TrackingDetails from "./TrackingDetails";
import KycDetails from "./KycDetails";
import CibilRequests from "./LoanCreditDetails";
import AddDisbursementAccount from "./ApplicationData/AddDisbursementAccount";
import DisburseTab from "./ApplicationData/DisburseTab";
import roleChecker from "../../../_helpers/rolechecker";
import LoanOverView from "./LoanOverview";
import { LoanStages } from "../../../constants/loanstages"
import InsuranceDetailTab from "./ApplicationData/InsuranceDetailTab";
import workflowHelper from "../../../_helpers/workflowhelper";
import BridgeComponent from "../LoanFlow/SblLoanBridgeComponent";
import AddCheckList from "../LoanFlow/DocumentPendency"
import TabBar from "../LoanFlow/TabBar";
import { history } from "../../../_helpers";
import Switch from '@material-ui/core/Switch';
import AllDocuments from "./ApplicationData/UploadDocuments/AllDocuments"
import CollectionTab from "../Collections/CollectionTab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Customer(props) {
  const classes = useStyles();
  const  id  = props.id;
  const [cust, setCust] = React.useState(null);
  const [value, setValue] = React.useState(0);
  const [moreValue, setMoreValue] = React.useState(10);
  const user = employeeService.userValue;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const changeComponent = () => {
    // setValue(2)
    history.push(`/home/sblloans/details/${id}?isnew_view=1`)
    window.location.reload()
  }
  

  const handleTabDropDown = (v) => {
    console.log("handle drop down ...");
    //console.log(e.target.value);
    console.log(v);
    setValue(v);
    setMoreValue(v);


  };

  React.useEffect(() => {
    async function getApplicant() {
      const data = await loanService.getById(id);
      setCust(data);
    }

    getApplicant();
  }, [id]);



  const [state, setState] = React.useState({
    switch_panel: true
  });
  const handlePanel = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });

    if (!event.target.checked) {
      history.push(`/home/sblloans/details/${cust._id}?isnew_view=1`)
      window.location.reload()
    }

    console.log(event.target.checked)
  };
  return (
    <div className={classes.root}>
      <AppBar position="static" style={{ zIndex: 0 }}>
        {cust && (
          <>
            <Tabs
              value={value}
              aria-label="simple tabs example"
              variant="scrollable"
              style={{ backgroundColor: cust.stage == LoanStages.Closed ? 'green' : cust.stage == LoanStages.Collection ? '#8f00ff' : [LoanStages.Rejected, LoanStages.Suspense, LoanStages.Discarded, LoanStages.Suspense].includes(cust.stage) ? 'red' : '#6495ED' }}
            >
              {workflowHelper.isInLOS(cust) && (
                <>
                      
                    <Link onClick={() => setValue(0)} style={{ color: 'white', padding: '10px', fontSize: '0.9rem' }}>Overview</Link>
                    <Link onClick={() => changeComponent()} style={{ color: 'white', padding: '10px', fontSize: '0.9rem' }}>Sales</Link>
                    <Link onClick={() => changeComponent()} style={{ color: 'white', padding: '10px', fontSize: '0.9rem' }}>Applicants</Link>
                    <Link onClick={() => changeComponent()} style={{ color: 'white', padding: '10px', fontSize: '0.9rem' }}>KYC</Link>
                    {![LoanStages.Sales, LoanStages.Discarded, LoanStages.Rejected, LoanStages.Suspense].includes(cust.stage) && (

                      <Link onClick={() => setValue(4)} style={{ color: 'white', padding: '10px', fontSize: '0.9rem' }}>Payment</Link>
                    )}
                
                      <Link onClick={() => setValue(12)} style={{ color: 'white', padding: '10px', fontSize: '0.9rem' }}>Check List</Link>
                  
                    {![LoanStages.Sales, LoanStages.Discarded, LoanStages.Rejected, LoanStages.Suspense].includes(cust.stage) && (
                      <Link onClick={() => changeComponent()} style={{ color: 'white', padding: '10px', fontSize: '0.9rem' }}>Credit</Link>
                    )}

                    {![LoanStages.Sales, LoanStages.Discarded, LoanStages.Rejected, LoanStages.Suspense].includes(cust.stage) && (
                      <Link onClick={() => setValue(6)} style={{ color: 'white', padding: '10px', fontSize: '0.9rem' }}>Sanction</Link>
                    )}

                    {![ LoanStages.Discarded, LoanStages.Rejected, LoanStages.Suspense].includes(cust.stage) &&  !["Sales"].includes(user.role) && (
                      <Link onClick={() => setValue(7)} style={{ color: 'white', padding: '10px', fontSize: '0.9rem' }}>Collateral</Link>

                    )}
                    {![LoanStages.Sales, LoanStages.Discarded, LoanStages.Rejected, LoanStages.Suspense, LoanStages.Credit, LoanStages.Sanction].includes(cust.stage) && (roleChecker.isHOLevelAccess(user.role) || roleChecker.hasAccountsAccess(user.role) || roleChecker.isColendingAccess(user.role) || roleChecker.isOperationTeamAccess(user.role)) && (
                      <>
                        <Link onClick={() => setValue(8)} style={{ color: 'white', padding: '10px', fontSize: '0.9rem' }}>Disburse</Link>
                      </>
                    )}


                    {![LoanStages.Sales, LoanStages.Discarded, LoanStages.Rejected, LoanStages.Suspense, LoanStages.Credit].includes(cust.stage) && (roleChecker.isColendingAccess(user.role) || roleChecker.isHOLevelAccess(user.role)) && (
                      <Link onClick={() => setValue(9)} style={{ color: 'white', padding: '10px', fontSize: '0.9rem' }}>Colending</Link>

                    )}
                    {![LoanStages.Discarded, LoanStages.Rejected, LoanStages.Suspense].includes(cust.stage) && (roleChecker.isCreditTeamAccess(user.role) ||  roleChecker.isHOLevelAccess(user.role) || roleChecker.isClusterSaleLevelAccess(user.role) || roleChecker.isBMLevelAccess(user.role) || roleChecker.isColendingTeamAccess(user.role) || roleChecker.isAuditorAccess(user.role)    ) && (
                      <Link onClick={() => setValue(13)} style={{ color: 'white', padding: '10px', fontSize: '0.9rem' }}>Documents</Link>

                    )}
                    {/* {  (roleChecker.isCFOAccess(user.role) ||  roleChecker.isCollectionManager(user.role)  ) && (
                      <Link onClick={() => setValue(14)} style={{ color: 'white', padding: '10px', fontSize: '0.9rem' }}>Collection</Link>

                    )} */}
                    {(roleChecker.isHOLevelAccess(user.role) ||  roleChecker.isOperationTeamAccess(user.role) ||roleChecker.isAreaLevelAccess(user.role) || roleChecker.isClusterLevelAccess(user.role)) && (
                      <>
                        <Select
                          id="demo-simple-select"
                          label="More ..."
                          value={moreValue}
                          style={{ color: 'white', fontSize: '0.9rem' }}

                        >
                          <MenuItem value={10} onClick={() => handleTabDropDown(10)}>Tracking</MenuItem>

                          {![LoanStages.Sales, LoanStages.Discarded, LoanStages.Rejected, LoanStages.Suspense, LoanStages.Credit, LoanStages.Sanction].includes(cust.stage) && (roleChecker.isHOLevelAccess(user.role) || roleChecker.hasAccountsAccess(user.role) || roleChecker.isOperationTeamAccess(user.role)) && (
                            <>
                              <MenuItem value={11} onClick={() => handleTabDropDown(11)}>Insurance Details</MenuItem>
                            </>
                          )}

                        </Select>
                      </>
                    )}
                </>
              )}

             {workflowHelper.isInLMS(cust) && (
                <>
                      
                    <Link onClick={() => setValue(0)} style={{ color: 'white', padding: '10px', fontSize: '0.9rem' }}>Overview</Link>
                    <Link onClick={() => changeComponent()} style={{ color: 'white', padding: '10px', fontSize: '0.9rem' }}>Applicants</Link>
                    {![LoanStages.Sales, LoanStages.Discarded, LoanStages.Rejected, LoanStages.Suspense].includes(cust.stage) && (

                      <Link onClick={() => setValue(4)} style={{ color: 'white', padding: '10px', fontSize: '0.9rem' }}>Payment</Link>
                    )}
                    
                    {![LoanStages.Sales, LoanStages.Discarded, LoanStages.Rejected, LoanStages.Suspense].includes(cust.stage) && (
                      <Link onClick={() => setValue(6)} style={{ color: 'white', padding: '10px', fontSize: '0.9rem' }}>Sanction</Link>
                    )}

                    {![LoanStages.Discarded, LoanStages.Rejected, LoanStages.Suspense].includes(cust.stage) && !["Sales"].includes(user.role) && (
                      <Link onClick={() => setValue(7)} style={{ color: 'white', padding: '10px', fontSize: '0.9rem' }}>Collateral</Link>

                    )}
                    {![LoanStages.Sales, LoanStages.Discarded, LoanStages.Rejected, LoanStages.Suspense, LoanStages.Credit, LoanStages.Sanction].includes(cust.stage) && (roleChecker.isHOLevelAccess(user.role) || roleChecker.hasAccountsAccess(user.role) || roleChecker.isColendingAccess(user.role) || roleChecker.isOperationTeamAccess(user.role) || ["AUDITOR"].includes(user.role)) && (
                      <>
                        <Link onClick={() => setValue(8)} style={{ color: 'white', padding: '10px', fontSize: '0.9rem' }}>Disburse</Link>
                      </>
                    )}


                    {![LoanStages.Sales, LoanStages.Discarded, LoanStages.Rejected, LoanStages.Suspense, LoanStages.Credit].includes(cust.stage) && roleChecker.isColendingAccess(user.role) && (
                      <Link onClick={() => setValue(9)} style={{ color: 'white', padding: '10px', fontSize: '0.9rem' }}>Colending</Link>

                    )}
                    {![LoanStages.Discarded, LoanStages.Rejected, LoanStages.Suspense].includes(cust.stage) && (roleChecker.isCreditTeamAccess(user.role) ||  roleChecker.isHOLevelAccess(user.role) || roleChecker.isClusterSaleLevelAccess(user.role) || roleChecker.isBMLevelAccess(user.role) || roleChecker.isColendingTeamAccess(user.role) || roleChecker.isAuditorAccess(user.role) ) && (
                      <Link onClick={() => setValue(13)} style={{ color: 'white', padding: '10px', fontSize: '0.9rem' }}>Documents</Link>

                    )}
                    {  (roleChecker.isCFOAccess(user.role) ||  roleChecker.isCollectionManager(user.role)  ) && (
                      <Link onClick={() => setValue(14)} style={{ color: 'white', padding: '10px', fontSize: '0.9rem' }}>Collection</Link>

                    )}
                    {(roleChecker.isHOLevelAccess(user.role) || roleChecker.isOperationTeamAccess(user.role)) && (
                      <>
                        <Select
                          id="demo-simple-select"
                          label="More ..."
                          value={moreValue}
                          style={{ color: 'white', fontSize: '0.9rem' }}

                        >
                          <MenuItem value={10} onClick={() => handleTabDropDown(10)}>Tracking</MenuItem>
                          <MenuItem value={11} onClick={() => handleTabDropDown(11)}>Insurance Details</MenuItem>
                          <MenuItem value={5} onClick={() => 
                          // handleTabDropDown(5)
                          changeComponent()
                          }>Credit Details</MenuItem>
                          <MenuItem value={3} onClick={() => handleTabDropDown(3)}>KYC Details</MenuItem>
                         
                        </Select>
                      </>
                    )}
                    {roleChecker.isColendingAccess(user.role) && (
                      <>
                        <Select
                          id="demo-simple-select"
                          label="More ..."
                          value={moreValue}
                          style={{ color: 'white', fontSize: '0.9rem' }}

                        >
                          <MenuItem value={5} onClick={() => 
                          // handleTabDropDown(5)
                          changeComponent()
                          }>Credit Details</MenuItem>
                          <MenuItem value={3} onClick={() => handleTabDropDown(3)}>KYC Details</MenuItem>
                         
                        </Select>
                      </>
                    )}
                    {(roleChecker.isCollectionTeam(user.role) || roleChecker.isCustomerCareTeam(user.role) ) && (
                      <>
                        <Select
                          id="demo-simple-select"
                          label="More ..."
                          value={moreValue}
                          style={{ color: 'white', fontSize: '0.9rem' }}

                        >
                          <MenuItem value={3} onClick={() => handleTabDropDown(3)}>KYC Details</MenuItem>
                         
                        </Select>
                      </>
                    )}
                </>
              )}

           {workflowHelper.isNotTracked(cust) && (
                <>      
                    <Link onClick={() => setValue(0)} style={{ color: 'white', padding: '10px', fontSize: '0.9rem' }}>Overview</Link>
                    <Link onClick={() =>changeComponent()} style={{ color: 'white', padding: '10px', fontSize: '0.9rem' }}>Applicants</Link>
                    
                    {roleChecker.isHOLevelAccess(user.role) && (
                      <>
                        <Select
                          id="demo-simple-select"
                          label="More ..."
                          value={moreValue}
                          style={{ color: 'white', fontSize: '0.9rem' }}

                        >
                          <MenuItem value={10} onClick={() => handleTabDropDown(10)}>Tracking</MenuItem>
                        </Select>
                      </>
                    )}
                </>
              )}

            
            </Tabs>
            <div style={{ display: "flex", justifyContent: "flex-end", flexDirection: "row" , paddingRight: "3rem" ,backgroundColor:"white",width:"100%", paddingTop:"5px"}} >
        <label style={{color:"black"}} >LOS</label> <div style={{ marginTop: "-10px" }} >
          <Switch
            checked={state.switch_panel}
            onChange={handlePanel}
            color="primary"
            name="switch_panel"
          // inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </div><label style={{color: 'blue' }} >LMS</label>
      </div>
          </>

        )}
      </AppBar>
      <TabPanel value={value} index={0}>
        {cust && <LoanOverView cust={cust}></LoanOverView>}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {cust && <BridgeComponent ></BridgeComponent>}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {cust && <BridgeComponent ></BridgeComponent>}
      </TabPanel>

      <TabPanel value={value} index={3}>
        {cust && <KycDetails cust={cust}></KycDetails>}
      </TabPanel>
      <TabPanel value={value} index={4}>
        {cust && <PaymentDetails cust={cust}></PaymentDetails>}
      </TabPanel>
      <TabPanel value={value} index={5}>
        {cust && <CibilRequests cust={cust}></CibilRequests>}
      </TabPanel>
      <TabPanel value={value} index={6}>
        {cust && <LoanDetails cust={cust}></LoanDetails>}
      </TabPanel>
      <TabPanel value={value} index={12}>
        {cust && <AddCheckList cust={cust}></AddCheckList>}
      </TabPanel>
      <TabPanel value={value} index={13}>
        {cust &&  <AllDocuments cust={cust} /> }
      </TabPanel>
      {(roleChecker.isCFOAccess(user.role) ||  roleChecker.isCollectionManager(user.role)  ) &&  <TabPanel value={value} index={14}>
        {cust &&  <CollectionTab cust={cust} /> }
      </TabPanel>}
      {!["Sales"].includes(user.role) && (
        <TabPanel value={value} index={7}>
          {cust && <CollateralDetails cust={cust}></CollateralDetails>}
        </TabPanel>
      )}

      {(roleChecker.isHOLevelAccess(user.role) || roleChecker.hasAccountsAccess(user.role) || roleChecker.isColendingAccess(user.role) || roleChecker.isOperationTeamAccess(user.role) || ["AUDITOR"].includes(user.role)) && (
        <TabPanel value={value} index={8}>
          {cust && <DisburseTab cust={cust} ></DisburseTab>}
        </TabPanel>
      )}
       {(roleChecker.isColendingAccess(user.role) || roleChecker.isHOLevelAccess(user.role)) && (
        <TabPanel value={value} index={9}>
          {cust && <ColenderDetails cust={cust}></ColenderDetails>}
        </TabPanel>
      )}
      {(roleChecker.isHOLevelAccess(user.role) || roleChecker.isOperationTeamAccess(user.role) ||roleChecker.isAreaLevelAccess(user.role) || roleChecker.isClusterLevelAccess(user.role) ) && (
        <TabPanel value={value} index={10}>
          {cust && <TrackingDetails cust={cust}></TrackingDetails>}
        </TabPanel>
      )}

     {(roleChecker.isHOLevelAccess(user.role) || roleChecker.isOperationTeamAccess(user.role)) && (
        <TabPanel value={value} index={11}>
          {cust && <InsuranceDetailTab cust={cust}></InsuranceDetailTab>}
        </TabPanel>
      )}
    </div>
  );
}
