import React from "react";
import { useState, useEffect } from "react";
import roleChecker from "../../_helpers/rolechecker";
import { employeeService, misService } from "../../_services";
import { BarChart, PieChart, Pie, Cell, Bar, CartesianGrid, XAxis, YAxis, Legend, Tooltip, LabelList, ResponsiveContainer, Label } from "recharts";
import { reportsService } from "../../_services/reports.service";
import Product from "../../_helpers/product";
import { loanService } from "../../_services";
import { Button } from "@material-ui/core";
import { history } from "../../_helpers";
import { Paper } from "@material-ui/core";
import { makerCheckerServices } from "../../_services/makerchecker.service";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import CourtCaseServices from "../../_services/courtcase.service";
import datehelper from "../../_helpers/datehelper";
import { ledgerService } from "../../_services/ledger.service";
import displayhelper from "../../_helpers/displayhelper";
import SwitchPanel from "../SwitchPanel";
function  CommonDashboard(){
    const user = employeeService.userValue;
    const [assignedRequests, setAssignedRequests] = useState(0)
    const [requests, setRequests] = useState({})
    const [metricsData, setMetricsData] = useState({})
    let req = ['Pending', 'Approved', 'Rejected']
    let color_for_req = ['#6D67E4', '#355E3B', '#922724'] 

    const myRequests = () => {
        makerCheckerServices.getRequestsByEmpid({ emp_id: user.emp_id })
          .then(res => {
            let list = {}
            list['Pending'] = res.pendingRequests.length
            list['Approved'] = res.approvedRequests.length
            list['Rejected'] = res.rejectedRequests.length
            list['total_requests'] = list['Pending'] + list['Approved'] + list['Rejected']
            setRequests(list)
          })
          .catch(err => console.log(err))
      }
      const getAssignedRequests = () => {
        makerCheckerServices.getRequests()
          .then(res => {
            let rqst = res.filter(req => {
              if (req && Number(req.emp_id) !== Number(user.emp_id) && req.approval_access && roleChecker[req.approval_access](user.role)) {
                return req
              }
            })
            setAssignedRequests(rqst.length)
          }
          )
          .catch(err => console.log(err))
      }
      const getCfoposData = () => {
      reportsService.getMetricsData({ 'metric_key': "all" }).
      then((metricListObj) => {
        console.log('metricListObjmetricListObjmetricListObjmetricListObj',metricListObj);
        let tmpMetricsData={}
        metricListObj.map((mo)=> {
          tmpMetricsData[mo.key]=mo.display_data
        })
        console.log('tmpMetricsDatatmpMetricsDatatmpMetricsDatatmpMetricsData',tmpMetricsData);
        setMetricsData(tmpMetricsData);
      })
      .catch((err)=> {
        console.log(err);
      })
    }
    
    const formatNumber = (number) => {
      // Format the number with commas as thousands separators
      return number.toString().replace(/\B(?=(\d{2})+(?!\d))/g, ",");
    };
    
    const formatJSON = (data) => {
      if (Array.isArray(data)) {
        // Assuming each item in the array is a number
        return data.map((item, index) => `${index + 1}. ${formatNumber(item)}`).join('\n');
      } else if (typeof data === 'object' && data !== null) {
        // If it's an object, format each value if it's a number
        return Object.entries(data).map(([key, value]) =>
          `${key}: ${formatNumber(value)}`
        ).join('\n');
      }
      return formatNumber(data);
    };
      useEffect(() => {
        myRequests()
        getAssignedRequests()
        getCfoposData()
         
    }, [])
    
    return <>
        <h1>Hi {user.first_name}!</h1>
          <p>Welcome to the Kaabil Finance!</p>
          <hr style={{ width: '100%' }} />
          <div style={{ display: 'flex' }}>
            {requests && requests.total_requests > 0 ?
              <Paper style={{ width: '408px', borderRadius: '10px', margin: '10px', padding: '20px' }}>
                <h3>My Requests</h3>
                <hr style={{ width: '100%' }} />
                <div style={{ display: 'flex' }}>
                  {
                    req.map((re, idx) => {
                      return <div
                        style={{ width: '100px', height: '100px', backgroundColor: color_for_req[idx], borderRadius: '10px', color: 'white', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around' }}>
                        <b>{re}</b>
                        {requests[re]}
                        <Button size="small" style={{ color: 'white', marginLeft: "25px" }} onClick={() => history.push('/home/approvals/myrequests')}>...More</Button>
                      </div>
                    })
                  }
                </div>
              </Paper> :
              null}
            {
              assignedRequests && assignedRequests > 0 ?
                <Paper style={{ width: '215px', borderRadius: '10px', margin: '10px', padding: '20px' }}>
                  <h3>My Approvals</h3>
                  <hr style={{ width: '100%' }} />
                  <div style={{ width: '100px', height: '100px', backgroundColor: '#003366', borderRadius: '10px', color: 'white', margin: 'auto', marginTop: '25px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around' }}>
                    <b>Pending</b>
                    {assignedRequests}
                    <Button size="small" style={{ color: 'white', marginLeft: "25px" }} onClick={() => history.push('/home/approvals/list')}>...More</Button>
                  </div>
                </Paper> :
                null}
            
            <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
              {metricsData && metricsData.current_loans && roleChecker && roleChecker.isCFOAccess(user.role) && (
                <Paper style={{ width: '350px', borderRadius: '10px', margin: '10px', padding: '20px', textAlign: 'center' }}>
                  <h3 style={{textAlign:'center', color:'#002E6E'}}>Loans in Collection:</h3>
                  <hr style={{ width: '100%' }} />
                  <h3 style={{textAlign:'center', color:'#EC1C24'}}>{ displayhelper.displayNumberAccounting(Number(metricsData.current_loans))}</h3>
                </Paper>
              )}

              {/* {metricsData && metricsData.current_pos && roleChecker && roleChecker.isCFOAccess(user.role) && (
                <Paper style={{ width: '350px', borderRadius: '10px', margin: '10px', padding: '20px', textAlign: 'center' }}>
                  <h3 style={{textAlign:'center', color:'#002E6E'}}>Outstanding Principal:</h3>
                  <hr style={{ width: '100%' }} />
                  <h3 style={{textAlign:'center',color:'#EC1C24'}}>₹{ displayhelper.displayNumberAccounting(Number(metricsData.current_pos))}</h3>
                
                </Paper>
              )} */}
            </div>
            <SwitchPanel/>
          </div>
    </>
}

export default CommonDashboard;