import React, { useEffect, useState } from 'react'
import { useReactToPrint } from "react-to-print";
import { loanService } from '../../../_services';
import datehelper from "../../../_helpers/datehelper";
import vistaarLogo from "../../../assets/Vistaar.jpg"
import capitalfinance from "../../../assets/capitalfinance.png";
import logo from "../../../assets/kaabil.png";
const DemandPromissoryNote = (props) => {

    // const DemandPromissoryNoteRef = React.useRef();

    const data = props.data
    const branches = props.branches
    const colender = props.colender
    // const handleSubmissionPrint = useReactToPrint({
    //     content: () => DemandPromissoryNoteRef.current,
    //     pageStyle: "print",
    // });

    const one = ["", "One ", "Two ", "Three ", "Four ", "Five ", "Six ", "Seven ", "Eight ", "Nine ", "Ten ", "Eleven ", "Twelve ", "Thirteen ", "Fourteen ", "Fifteen ",
        "Sixteen ", "Seventeen ", "Eighteen ", "Nineteen "];

    var ten = ["", "", "Twenty ", "Thirty ", "Forty ", "Fifty ", "Sixty ", "Seventy ", "Eighty ", "Ninety "];

    function numToWords(n, s) {
        let str = "";
        // if n is more than 19, divide it
        if (n > 19) {
            str += ten[parseInt(n / 10)] + one[n % 10];
        }
        else {
            str += one[n];
        }
        if (n != 0) {
            str += s;
        }

        return str;
    }

    function convertToWords(n) {
        // stores word representation of given number n
        let out = "";

        // handles digits at ten millions and hundred
        // millions places (if any)
        out += numToWords(parseInt(n / 10000000),
            "Crore ");

        // handles digits at hundred thousands and one
        // millions places (if any)
        out += numToWords(parseInt((n / 100000) % 100),
            "Lakh ");

        // handles digits at thousands and tens thousands
        // places (if any)
        out += numToWords(parseInt((n / 1000) % 100),
            "Thousand ");

        // handles digit at hundreds places (if any)
        out += numToWords(parseInt((n / 100) % 10),
            "Hundred ");

        if (n > 100 && n % 100 > 0) {
            out += "and ";
        }

        // handles digits at ones and tens places (if any)
        out += numToWords(parseInt(n % 100), "");

        return out;
    }

    const headerDiv = (colender) => (
        <div>
          <img src={logo} style={{ height: "100px" }} alt="KFPL Logo" />
          {colender.includes("CIFL") && (
            <img
              src={capitalfinance}
              style={{
                height: "100px",
                position: "absolute",
                top: "2",
                right: "0",
                marginRight: "3rem",
              }}
              alt="Capital Finance Logo"
            />
          )}
          {colender.includes("VistaarFinance") && (
            <img
              src={vistaarLogo}
              style={{
                height: "100px",
                position: "absolute",
                top: "2",
                right: "0",
                marginRight: "3rem",
              }}
              alt="Vistaar Finance Logo"
            />
          )}
        </div>
      );


    return (
        <>

            {/* <div style={{ textAlign: "center" }}>
                <button onClick={handleSubmissionPrint}>Print Letter</button>
            </div> */}

            <div
                style={{
                    width: "290mm",
                    height: "410mm",
                    backgroundColor: "white",
                    padding: 40,
                    position: "relative",
                    fontSize: "20px"
                }}
            > {headerDiv(colender)}<br/><br/>
                <span style={{ marginLeft: "40%" }}><b>DEMAND PROMISSORY NOTE</b></span><br /><br /><br />
                <div style={{ textAlign: "justify" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div>
                        {colender.includes("CIFL") ? <span>Capital India Finance Limited<br />
                            2nd Floor, DLF Centre, Sansad Marg,<br />
                            New Delhi, Delhi 110001</span>:
                         <span>Vistaar Financial Services Pvt Ltd<br />
                        Plot No. 59 & 60-23, 22nd Cross, 29th Main BTM Layout,<br />
                        2nd  Stage NA Bengaluru Bangalore KA 560076</span>}
                       <br /><br />
                        <span>
                        Kaabil Finance Private Limited,<br />
                        Plot No. 1, Scheme No. 1, Income Tax Colony, <br/>Model Town, Jagatpura, Jaipur Rajasthan 302017
                    </span><br /><br />
                        </div>
                       
                        <span style={{ marginLeft: "30%" }}>
                            Place: &nbsp; {branches.branch_city}<br />
                            Date:  <b>{colender.includes("CIFL")  ?datehelper.displayDate(new Date(data?.loan?.agreement_stamp_date)):datehelper.displayDate(new Date(data.created))} </b> 
                        </span>
                    </div><br />

                    <span> I/We,
                         <b> {data && data.applicant && data.applicant.general_information && data.applicant.general_information.name}</b><br />
                        Unconditionally and irrevocably, jointly and severally, promise to pay on demand,<b>{colender.includes("CIFL") ?"Capital India Finance Limited":"Vistaar Financial Services Pvt Ltd"} (80%)</b> , A company within the meaning of the Companies Act, 2013, having its registered office
                        at {colender.includes("CIFL") ?" 2nd Floor, DLF Centre, Sansad Marg, New Delhi, Delhi 110001":"Plot No. 59 & 60-23, 22nd Cross, 29th Main BTM Layout, 2nd Stage NA Bengaluru Bangalore KA 560076 "} &
                       <b> Kaabil Finance Private Limited (20%)</b>  having its registered office at Plot No. 1, Scheme No. 1, Income Tax Colony,
                        Model Town, Jagatpura, Jaipur Rajasthan 302017 or order, for value received, a sum
                        of <b>Rs.{data && data.loan && data.loan.sanction_amount} /- (Rupees{convertToWords(data && data.loan && data.loan.sanction_amount)} only) </b>
                        with Interest <b>{data && data.loan && data.loan.roi}% </b>thereon, along with all costs, charges, taxes, cess, levies, duties and penalty(s), as
                        specified in the Loan Agreement executed on even date.<br /><br />
                        I/We also agree that this note may be assigned / pledged / hypothecated to any one as required by <b>{colender.includes("CIFL") ?"Capital India Finance Limited":"Vistaar Financial Services Pvt Ltd"} (80%)</b>  & <b>Kaabil Finance Private Limited (20%)</b> Without notice to me/us.<br /><br />
                        Presentment for payment and noting and protest of this note, are hereby unconditionally and irrevocably waived.<br />
                    </span><br /><br />

                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span style={{ width: "300px" }}>Name of the Borrower/s</span>
                        <span style={{ width: "300px" }}>Signature/ Thumb Impression</span>
                    </div><br />
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span span style={{ width: "300px" }}>{data && data.applicant && data.applicant.general_information && data.applicant.general_information.name}</span><br />
                        <span span style={{ width: "200px", display:"flex", flexDirection:"row" }}><div style={{fontSize:"10px", border:"1px solid", width:"30%", textAlign:"center"}} >Revenue<br/> stamp of<br/> Rs.1</div><p>________________________</p></span><br /><br />
                    </div>
                    {data && data.co_applicants && data.co_applicants.length > 0 && data.co_applicants.map((e, idx) => {
                        return <div style={{ display: "flex", justifyContent: "space-between", marginTop:"5%" }}>
                            <span span style={{ width: "300px" }}>{e.general_information.name}</span><br />
                            <span span style={{ width: "200px", display:"flex", flexDirection:"row" }}><div style={{fontSize:"10px", border:"1px solid", width:"30%", textAlign:"center"}} >Revenue<br/> stamp of<br/> Rs.1</div><p>________________________</p></span><br /><br />
                        </div>
                    })}
                </div>
            </div>

            {/* <div
                style={{
                    width: "290mm",
                    height: "410mm",
                    backgroundColor: "white",
                    padding: 40,
                    position: "relative",
                    fontSize: "20px"
                }}
            >
                <span style={{ marginLeft: "40%" }}>DEMAND PROMISSORY NOTE</span><br /><br /><br />
                <div style={{ textAlign: "justify" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                    <span>
                        Kaabil Finance Private Limited,<br />
                        Plot No. 1, Scheme No. 1, Income Tax Colony, <br/>Model Town, Jagatpura, Jaipur Rajasthan 302017
                    </span>

                        <span style={{ marginLeft: "30%" }}>
                            Place: &nbsp; {branches.branch_city}<br />
                            Date: <b>{datehelper.displayDate(new Date(data.created))} </b> 
                        </span>
                    </div><br />

                    <span> I/We, <b> {data && data.applicant && data.applicant.general_information && data.applicant.general_information.name}</b>
                        ,{data && data.co_applicants && data.co_applicants.length > 0 && data.co_applicants.map((e, idx) => {
                            return <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                <span style={{ width: "100%" }}>{e.general_information.name},</span>
                            </div>
                        })}<br/>
                        Unconditionally and irrevocably, jointly and severally, promise to pay on demand, <b>Kaabil Finance Private
                            Limited</b>, a company within the meaning of the Companies Act, 2013, having its registered office
                        at Plot No. 59 & 60-23, 22nd Cross, 29th Main BTM Layout,
                        2nd Stage NA Bengaluru Bangalore KA 560076 &
                        Kaabil Finance Private Limited (20%) having its registered office at Plot No. 1, Scheme No. 1, Income Tax Colony,
                        Model Town, Jagatpura, Jaipur Rajasthan 302017  or order, for value received, a sum
                        of <b>Rs.{data && data.loan && data.loan.sanction_amount}</b>(Rupees{convertToWords(data && data.loan && data.loan.sanction_amount)}only)
                        with Interest thereon, along with all costs, charges, taxes, cess, levies, duties and penalty(s), as
                        specified in the Loan Agreement executed on even date.<br /><br />
                        I/We also agree that this note may be assigned / pledged / hypothecated to any one as required by Vistaar Financial Services Pvt Ltd (80%) & Kaabil Finance Private Limited (20%)
                        Presentment for payment and noting and protest of this note, are hereby unconditionally and irrevocably waived.<br />
                    </span><br /><br />

                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span style={{ width: "300px" }}>Name of the Borrower/s</span>
                        <span style={{ width: "300px" }}>Signature/ Thumb Impression</span>
                    </div><br />
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span span style={{ width: "300px" }}>{data && data.applicant && data.applicant.general_information && data.applicant.general_information.name}</span><br /><br/>
                        <span span style={{ width: "200px", display:"flex", flexDirection:"row" }}><div style={{fontSize:"10px", border:"1px solid", width:"30%", textAlign:"center"}} >Revenue<br/> stamp of<br/> Rs.1</div><p>________________________</p></span><br /><br />
                    </div>
                    {data && data.co_applicants && data.co_applicants.length > 0 && data.co_applicants.map((e, idx) => {
                        return <div style={{ display: "flex", justifyContent: "space-between", marginTop:"5%" }}>
                            <span span style={{ width: "300px" }}>{e.general_information.name}</span><br />
                            <span span style={{ width: "200px", display:"flex", flexDirection:"row" }}><div style={{fontSize:"10px", border:"1px solid", width:"28%", textAlign:"center"}} >Revenue<br/> stamp of<br/> Rs.1</div><p>________________________</p></span><br /><br />
                        </div>
                    })}

                </div>
            </div> */}

        </>
    )
}

export default DemandPromissoryNote