import React, { useEffect, useState } from 'react'
import LoadingOverlay from "react-loading-overlay";
import { Paper } from "@material-ui/core";
import enach_collect_payment from "../../../assets/enach_collect_payment.csv"
import { enachService } from '../../../_services/enach.service';
import { employeeService, customerService } from '../../../_services';
import datehelper from '../../../_helpers/datehelper';
import EnachCollectRequestModal from './EnachCollectRequestModal';
import swal from 'sweetalert';
import EnachInProgress from "./EnachInProgress"
import { Button } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { Table, Thead, Tbody, Tr, Td, Th } from 'react-super-responsive-table';
import VisibilityIcon from '@material-ui/icons/Visibility';
import roleChecker from "../../../_helpers/rolechecker";
import {
  Modal,
  Backdrop,
  Fade,

  Typography

} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
const EnachCollectPayments = () => {

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: '#F2F0EF',
      padding: theme.spacing(2),
      borderRadius: '10px',
      width: '40%',
      // maxWidth: '1000px',
      maxHeight: "600px",
      overflow: 'auto',
    },
    formField: {
      marginBottom: theme.spacing(2),
    },
    formRow: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(2),
      gap: '1rem',
    },
    entityWrapper: {
      marginBottom: theme.spacing(2),
      position: 'relative',
    },
    deleteButton: {
      position: 'absolute',
      top: '0',
      right: '0',
    },
  }));
  const classes = useStyles();
  const [spinneractive, setSpinneractive] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [state, setState] = React.useState(false)
  const [data, setData] = React.useState([]);
  const [pendingdata, setPendingData] = React.useState([]);
  const [allData, setAllData] = useState([]); // Holds all data
  const [inProcessData, setInProcessData] = useState([]); // Holds "In Process" data
  const [selectedOption, setSelectedOption] = useState('All'); // 'All' or 'In Process'
  const user = employeeService.userValue;
  const [minAmount, setMinAmount] = useState(0);
  const [maxAmount, setMaxAmount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [opened, setOpened] = useState(false);
  const [lastData, setLastData] = useState("");
  const handleOpen = () => {
    setOpen(true);
  };
  const handleOpened = () => {
    setOpened(true);
  };
  const handleClose = () => setOpen(false);
  const handleClosed = () => setOpened(false);
  const arr = []


  const onChangeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const getAllData = () => {
    enachService.getPaymentCollections()
      .then((x) => {
        console.log(x);
        setAllData(x);
        if (selectedOption === 'All') setData(x);
      })
      .catch((err) => {
        console.log("Data Not Found", err)
      })
  }
  const getPendingAllData = () => {
    enachService.getPendingPaymentCollections()
      .then((x) => {
        setPendingData(x);
        console.log(x, 'pending data');
        processPendingData(x);
      })
      .catch((err) => {
        console.log("Data Not Found", err)
      })
  }
  const getInProcessData = () => {
    enachService.getNachInProcess()
      .then((x) => {
        console.log(x, 'dddddddddddddddddddddddd');
        setInProcessData(x);
        if (selectedOption === 'In Process') setData(x);
      })
      .catch((err) => {
        console.log('Data Not Found', err);
      });
  };
  const handleFilterChange = (option) => {
    setSelectedOption(option);
    if (option === 'All') {
      setData(allData);
    } else if (option === 'In Process') {
      setData(inProcessData);
    }
  };


  const getLatestEnachApprovalRequest = () => {
    enachService.getLatestEnachApprovalRequest()
      .then((x) => {
        setLastData(x);
        console.log(x, ' datalast');
      })
      .catch((err) => {
        console.log("Data Not Found", err)
      })
  }
  useEffect(() => {
    getAllData();
    getInProcessData();
    getPendingAllData();
    getLatestEnachApprovalRequest();
  }, [])

  const processPendingData = (data) => {
    if (!data || !Array.isArray(data)) {
      console.error("Invalid data");
      return;
    }

    // Calculate min, max, total count, and duplicate reference codes
    let minAmount = Infinity;
    let maxAmount = -Infinity;
    let totalCount = 0;
    const referenceCodeMap = new Map();
    const duplicateReferenceCodes = [];

    data.forEach((item) => {
      const { amount, reference_code } = item;

      // Update min and max amounts
      minAmount = Math.min(minAmount, amount);
      maxAmount = Math.max(maxAmount, amount);

      // Count total entries
      totalCount++;

      // Track reference codes for duplicates
      if (referenceCodeMap.has(reference_code)) {
        // Mark as duplicate if it appears again
        if (!duplicateReferenceCodes.includes(reference_code)) {
          duplicateReferenceCodes.push(reference_code);
        }
      } else {
        referenceCodeMap.set(reference_code, true);
      }
    });
    setMinAmount(minAmount);
    setMaxAmount(maxAmount);
    setTotalCount(totalCount);
  };

  const onClickHandler = async () => {
    setSpinneractive(true);

    const data = new FormData();
    data.append("file", selectedFile);
    console.log(data.entries())
    for (var key of data.entries()) {
      console.log(key[0] + ', ' + key[1]);
    }

    await enachService.uploadEnachCollectionCsv(data, user.emp_id).then((response) => {
      // setSpinneractive(false);

      if (!response.ok) {
        alert("An Error Occurred, Please try again");
      } else {
        alert("Data Uploaded");
        window.location.reload();
      }
    });
  };

  console.log(typeof(lastData?.is_approved), 'lastData');
  const show = () => {
    if (lastData && lastData?.is_approved===false) {
      swal({
        title: 'Pending Collection',
        text: 'Please approve previous request.',
        icon: 'warning',
        button: 'OK'
      });
    } else {
    setState(!state);
    }
  }

  // const [processEnach, setProcessEnach] = useState(null);
  // const getProcess_Enach = async () => {
  //   try {

  //     // Assuming customerService.getProcessEnach is a function that makes a GET request
  //     const data = await customerService.getProcessEnach(); // No need to pass any parameters here
  //     setProcessEnach(data);
  //     console.log(processEnach?.status,"processEnach?.status")
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };
  const hasNotStarted = pendingdata && pendingdata?.some(item => item.collection_status === "Not_Started");
  console.log(hasNotStarted, "hasNotStarted")
  const getNot_Started = () => {
    swal({
      title: 'Pending Collection',
      text: 'Please approve previous request.',
      icon: 'warning',
      button: 'OK'
    });

  }
  const getLatestApprovalRequest = () => {
    enachService.getLatestEnachApprovalRequest()
      .then((x) => {
        if (x && x._id) {
          console.log(x._id, "sssssssssshhhh")
          getProcess_Enach(x._id);
        } else {
          console.error('Invalid data received:', x);
          swal('Error', 'Invalid data received!', 'error');
        }
      })
      .catch((err) => {
        console.log("Data Not Found", err)
      })
  }
  const getProcess_Enach = async (id) => {
    try {
      // Displaying pending message
      swal({
        title: 'Please wait. Do not refresh; otherwise, your task will restart.',
        icon: 'info',
        button: false,
      });
      const data = await customerService.getProcessEnach({ request_id: id });
      // Setting process status
      // Closing pending message
      swal.close();
      // Displaying success or failure message based on status
      if (data.status === 'success') {
        swal('Success', 'Process completed successfully!', 'success')
          .then(() => {
            window.location.reload(); // Reload only after clicking OK
          });
      } else {
        swal('Error', 'Process failed!', 'error')
          .then(() => {
            window.location.reload(); // Reload only after clicking OK
          });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      swal('Error', 'Failed to fetch data!', 'error')
        .then(() => {
          // Optionally reload on error, if needed
          window.location.reload();
        });
    }
  };


  async function getApprovalRequest() {
    // Show SweetAlert confirmation dialog
    const result = await swal({
      title: "Are you sure?",
      text: "Do you want to approve this request?",
      icon: "warning",
      buttons: ["No", "Yes"],
      dangerMode: true,
    });

    // If the user clicks "Yes", proceed with the request
    if (result) {
      const inputValues = {
        requestId: lastData && lastData._id,
        approved_by: user.emp_id,
        is_approved: true,
      };

      console.log(inputValues);

      // Assuming `enachService.createEnachApprovalRequest` is a function that makes an API call
      enachService.updateEnachApprovalRequest(inputValues)
        .then((x) => {
          swal("Your Approved", {
            icon: "success",
          }).then(() => {
            getLatestApprovalRequest()
            // Assuming this function handles any updates needed after approval
            // window.location.reload(); // Reload the page to reflect changes
          });
        })
        .catch((err) => {
          console.log("Error adding collect request");
          swal("Error", "There was an issue with the approval request.", "error");
        });
    } else {
      // If the user clicks "No", simply log the cancellation
      console.log("Approval request was cancelled");
    }
  }
  const isCFOAccess = roleChecker.isCFOAccess(user.role);
  const isAccountChecker = roleChecker.isAccountChecker(user.role);

  const isAboveThreshold = maxAmount === Number(-Infinity) ? 0 : maxAmount > 50000;
  const hasApprovalAccess = isAboveThreshold
    ? isCFOAccess
    : isAccountChecker || isCFOAccess ;

  async function getProcessEnachRequest() {
    // Show SweetAlert confirmation dialog
    const result = await swal({
      title: "Are you sure?",
      text: "Do you want to Process Enach Request ?",
      icon: "warning",
      buttons: ["No", "Yes"],
      dangerMode: true,
    });

    // If the user clicks "Yes", proceed with the request
    if (result) {
      const inputValues = {
        total_count: totalCount,
        max_amount: maxAmount,
        min_amount: minAmount,
        requested_by: user.emp_id,
        is_approved: false,
      };

      console.log(inputValues);

      // Assuming `enachService.createEnachApprovalRequest` is a function that makes an API call
      enachService.createEnachApprovalRequest(inputValues)
        .then((x) => {
          swal("Approval request added", {
            icon: "success",
          }).then(() => {
            window.location.reload(); // Reload the page to reflect changes
          });
        })
        .catch((err) => {
          console.log("Error adding collect request");
          swal("Error", "There was an issue with the approval request.", "error");
        });
    } else {
      // If the user clicks "No", simply log the cancellation
      console.log("Approval request was cancelled");
    }
  }
  return (
    <>
      <LoadingOverlay active={spinneractive} spinner text="Uploading Data...">

        <Paper style={{
          padding: 20,
        }}>
          <h2 style={{ textAlign: "center" }}>ICICI Enach Payment Collection List</h2><hr></hr>

          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            {lastData && lastData?.is_approved===false ? <Button onClick={getNot_Started} variant="outlined" style={{ color: "#1f84c1",padding:"3rem 2rem", backgroundColor:"rgb(227, 242, 253)", fontWeight:'bold', borderColor: "#1f84c1"}}>
                    Request ENach 
                  </Button> : 
            <EnachCollectRequestModal />
             }


            <Button
              variant="outlined" style={{ margin: "0rem  1rem", color: "#1eada2", backgroundColor: "rgb(232, 245, 233)", padding: "3rem 2rem", fontWeight: 'bold', borderColor: "#1eada2" }}
              onClick={show}
            >
              Upload File
            </Button>
            <Button
              variant="outlined" style={{ color: "#801ead", padding: "3rem 2rem", backgroundColor: "#e8d4fa", fontWeight: 'bold', borderColor: "#801ead" }}
              className="btn"
              // onClick={getProcess_Enach}
              onClick={handleOpen}
            >
              Request Process Enach
            </Button>
            <Button
              variant="outlined" style={{ margin: "0rem 0rem 0rem 1rem", backgroundColor: "rgb(255, 235, 238)", padding: "3rem 2rem", color: "#df3852", fontWeight: 'bold', borderColor: "#df3852" }} onClick={handleOpened}
            >
              Enach Approval
            </Button>
            <EnachInProgress />
          </div>

          {state === true ? <div className="container">
            <div className="offset-md-3 col-md-6">
              <form method="post" action="#" id="#">
                <div className="form-group files">
                  <label>Upload Your File</label>
                  <input
                    style={{ height: "30px" }}
                    accept=".csv"
                    type="file"
                    className="form-control"
                    onChange={(e) => onChangeHandler(e)}
                  />
                </div>
                <button
                  type="button"
                  className="btn btn-success btn-block"
                  onClick={() => onClickHandler()}
                >
                  Upload
                </button>
              </form>
              <div >
                <a href={enach_collect_payment} download style={{ display: "flex", justifyContent: "right", textDecoration: "none", textStyling: "none", color: "red" }} >Download Sample File <b>"date format as dd/mmy/yyyy Very strict"</b></a>
              </div>
            </div>
          </div> : null}

        </Paper>
      </LoadingOverlay>
      <select
        value={selectedOption}
        onChange={(e) => handleFilterChange(e.target.value)}
      >
        <option value="All">All</option>
        <option value="In Process">In Process</option>
      </select>
      <table>
        <thead>
          <tr>
            <th>Enach Ref Code</th>
            <th>Amount</th>
            <th>Collection Date</th>
            <th>Collection Status</th>
            <th>Collection Comment</th>
            <th>Collected By</th>
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0 ? (
            data.map((element) => (<tr key={element._id}>
              <td>{element.reference_code}</td>
              <td>{element.amount}</td>
              <td>{datehelper.displayDate(new Date(element.collection_date))}</td>
              <td>{element.collection_status === 'Failed' && (<span style={{ 'color': 'red' }}>Failed</span>)}{element.collection_status != 'Failed' && element.collection_status}</td>
              <td>{element.collection_comment}</td>
              <td>{element.collection_emp_id}</td>
            </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" style={{ textAlign: 'center' }}>No data found</td>
            </tr>
          )}
        </tbody>
      </table>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Button style={{ float: 'right', marginBottom: "2rem" }} variant='outlined' onClick={() => handleClose()}> <CloseIcon /></Button>

            {/* Travel Expenses Table */}
            <Paper style={{ marginTop: '50px', padding: '20px' }}>
              <Table>
                <Thead>
                  <Tr >
                    <Th>Total Count</Th>
                    <Th>Min Amount</Th>
                    <Th>Max Amount</Th>
                    <Th style={{ textAlign: "center" }}>Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {
                    !pendingdata || !pendingdata.some(item => item.collection_status === "Not_Started") ? (
                      <Tr>
                        <Td colSpan="4">No Request Available</Td>
                      </Tr>
                    ) : lastData && lastData.is_approved === false ? (
                      <Tr>
                        <Td colSpan="4" style={{ color: 'red' }}>
                          Your previous request is pending
                        </Td>
                      </Tr>
                    ) : (lastData && lastData.is_approved === true && pendingdata.some(item => item.collection_status === "Not_Started") ) ? (
                      <Tr>
                      <Td>{totalCount}</Td>
                      <Td>{minAmount === Number(Infinity) ? 0 : minAmount}</Td>
                      <Td>{maxAmount === Number(-Infinity) ? 0 : maxAmount}</Td>
                      <Td>
                        {/* Approval Button Logic */}
                      </Td>
                      <Td>
                        {hasNotStarted && (
                          <Button
                            style={{ color: 'white', backgroundColor: 'blue' }}
                            onClick={() => {
                              getProcessEnachRequest()
                            }}
                          >
                            Process Enach
                          </Button>
                        )}
                      </Td>
                    </Tr>
                    ): (
                      <Tr>
                        <Td>{totalCount}</Td>
                        <Td>{minAmount === Number(Infinity) ? 0 : minAmount}</Td>
                        <Td>{maxAmount === Number(-Infinity) ? 0 : maxAmount}</Td>
                        <Td>
                          {/* Approval Button Logic */}
                        </Td>
                        <Td>
                          {hasNotStarted && (
                            <Button
                              style={{ color: 'white', backgroundColor: 'blue' }}
                              onClick={() => {
                                getProcessEnachRequest()
                              }}
                            >
                              Process Enach
                            </Button>
                          )}
                        </Td>
                      </Tr>
                    )
                  }
                </Tbody>
              </Table>
            </Paper>
          </div>
        </Fade>
      </Modal>


      <Modal
        className={classes.modal}
        open={opened}
        onClose={handleClosed}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={opened}>
          <div className={classes.paper}>
            <Button style={{ float: 'right', marginBottom: "2rem" }} variant='outlined' onClick={() => handleClosed()}> <CloseIcon /></Button>

            {/* Travel Expenses Table */}
            <Paper style={{ marginTop: '50px', padding: '20px' }}>
              <Table>
                <Thead>
                  <Tr >
                    <Th>Total Count</Th>
                    <Th>Min Amount</Th>
                    <Th>Max Amount</Th>
                    <Th style={{ textAlign: "center" }}>Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                {
  lastData ? (
    lastData.is_approved === undefined ? (
      <Tr>
        <Td colSpan="4">No Request Available</Td>
      </Tr>
    ) : lastData.is_approved === true ? (
      <Tr>
        <Td colSpan="4" style={{ color: 'red' }}>
        No Request Available
        </Td>
      </Tr>
    ) :(
      <Tr>
        <Td>{totalCount}</Td>
        <Td>{minAmount === Number(Infinity) ? 0 : minAmount}</Td>
        <Td>{maxAmount === Number(-Infinity) ? 0 : maxAmount}</Td>
        <Td>
          {/* Approval Button Logic */}
        </Td>
        <Td>
          {/* {hasNotStarted && (
            <Button
              style={{ color: 'white', backgroundColor: 'blue' }}
              onClick={() => {
                getApprovalRequest();
              }}
            >
              Approve
            </Button>
          )} */}
          {
  hasApprovalAccess && lastData.requested_by !== user.emp_id && hasNotStarted && (
    <Button
      style={{ color: 'white', backgroundColor: 'blue' }}
      onClick={() => {
        getApprovalRequest();
      }}
    >
      Approve
    </Button>
  )
}
        </Td>
      </Tr>
    )
  ) : (
    <Tr>
      <Td colSpan="4">No Request Available</Td>
    </Tr>
  )
}

                </Tbody>
              </Table>
            </Paper>
          </div>
        </Fade>
      </Modal>
    </>
  )
}

export default EnachCollectPayments