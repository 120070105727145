import React,{useEffect , useState} from 'react'
import { MobileOtpVerificationModel } from './MobileOtpVerificationModel';
import {PanOtpSendModal} from './PanOtpSendModal'
import {VoterOtpSendModal} from './VoterOtpSendModal'
import Aadharcheck from './Aadharcheck'
import {  customerService } from "../../../../_services";

const KycVerificationSystem = (props) => {
    const customer = props.customer;
 const [customerData,setCustomerData]   = React.useState([]);

async function verifyMobileNumbers() {

      const customer_ids = []
      const applicant_id=[]
      const co_applicant_id=[]
      const guarantor_id = []
      let  applicantInfo;
      
      customer_ids.push(customer.applicant.customer_id)
      applicant_id.push(customer.applicant.customer_id)

      customer.co_applicants?.forEach(val => {
        customer_ids.push(val.customer_id)
        co_applicant_id.push(val.customer_id)
      });

      customer.guarantors?.forEach(val => {
        customer_ids.push(val.customer_id)
        guarantor_id.push(val.customer_id)
      });

      console.log('customer ids are',customer_ids)

    const data = await  customerService.getAllCustomerOfLoan({"customer_ids":customer_ids})

   data?.forEach((val) => {
        if (applicant_id.includes(val.customer_id)) {
          applicantInfo = val;
            val["role"] = "applicant";
        } else if (co_applicant_id.includes(val.customer_id)) {
            val["role"] = "co_applicant";
        } else if (guarantor_id.includes(val.customer_id)) {
            val["role"] = "guarantor";
        }
    });

    console.log('Data after role assignment:', data);
    data.sort((a,b)=>a.role.localeCompare(b.role))
    setCustomerData(data)
 };

useEffect(() => {
    verifyMobileNumbers();
}, [customer]); 

    
    console.log("customer details is", customerData)
    return (
        <div >

            <div>
                <h3>KYC VERIFICATION DETAILS</h3><br />

                <table>
                    <thead>
                        <tr>
                            <th style={{width:"20%"}}>Customer Name</th>
                            <th style={{width:"10%"}}>Role</th>
                            <th style={{width:"15%"}}>Aadhar No.</th>
                            <th style={{width:"15%"}}>Pan Card</th>
                            <th style={{width:"15%"}}>Voter Id</th>
                            {/* <th style={{width:"15%"}}>Driving Licence</th> */}
                            <th style={{width:"15%"}}>Mobile No.</th>
                          
                        </tr>
                    </thead>
                    <tbody>
                    {customerData && customerData.length > 0 && customerData.map((x, idx) => {
              return (
                <tr key={idx}>
                  <td>{`${x?.general_details?.customer_firstname} ${x?.general_details?.customer_middlename ? x.general_details.customer_middlename+' ' : ''}${x?.general_details?.customer_lastname ? x.general_details.customer_lastname : ''}`}</td>
                  <td>{x?.role}</td>
                  <td>{x?.verification_details?.aadhaar_mask_no ? (
                    <>
                      {x?.verification_details?.aadhaar_mask_no}
                      <Aadharcheck application_id={customer.application_id} customer={x} role={x.role} />
                    </>
                  ) : ""}</td>
                  <td>{x?.verification_details?.pan_no ? (
                    <>
                      {x.verification_details.pan_no}
                      <PanOtpSendModal application_id={customer.application_id} customer={x} role={x.role} index={idx} />
                    </>
                  ) : ""}</td>
                  <td>{x?.verification_details?.voter_id ? (
                    <>
                      {x.verification_details.voter_id}
                      <VoterOtpSendModal application_id={customer.application_id} customer={x} role={x.role} index={idx} />
                    </>
                  ) : ""}</td>
                  <td>{x?.general_details?.customer_mobile_num ? (
                    <>
                      {x.general_details.customer_mobile_num}
                      <MobileOtpVerificationModel application_id={customer.application_id} customer={x} role={x.role} index={idx} />
                    </>
                  ) : ""}</td>
                </tr>
              );
            })}
                    </tbody>
                </table>

            </div>

      
            {/* <div>
                <h3>MOBILE NUMBER VERIFICATION DETAILS</h3><br />

                <table>
                    <thead>
                        <tr>
                            <th>Customer Name</th>
                            <th>Customer Role</th>
                            <th>Mobile Number</th>
                            <th>Verification Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{customer.applicant.general_information.name}</td>
                            <td>Applicant</td>
                            <td>{customer.applicant.general_information.contact}</td>
                            <td>
                           
                                {customer && customer.personal_details && customer.personal_details.otp_verified ? <Button style={{ color: "green" }}
                                    variant="outlined" disabled>Verified <VerifiedUserIcon /></Button> : <MobileOtpVerificationModel application_id={customer.application_id} customer={customer.applicant} role="applicant" />}
                            </td>
                        </tr>
                        {customer && customer.co_applicants && customer.co_applicants.length > 0 && customer.co_applicants.map((x, idx) => {
                            return <tr key={idx}>
                                <td>{x.general_information.name}</td>
                                <td>Co-Applicant</td>
                                <td>{x.general_information.contact}</td>
                                <td> <MobileOtpVerificationModel application_id={customer.application_id} customer={x} role="co_applicant" index={idx} /> </td>
                            </tr>
                        })}
                        {customer && customer.guarantors && customer.guarantors.length > 0 && customer.guarantors.map((x, idx) => {
                            return <tr key={idx}>
                                <td>{x.general_information.name}</td>
                                <td>Guarantor</td>
                                <td>{x.general_information.contact}</td>
                                <td> <MobileOtpVerificationModel application_id={customer.application_id} customer={x} role="guarantor" index={idx} /> </td>
                            </tr>
                        })}
                    </tbody>
                </table>

            </div> */}

        </div>
    )
}

export default KycVerificationSystem