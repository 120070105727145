import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import { Button, Paper, Table } from "@material-ui/core";
import { termLoans } from "../../_services/termloans.service";
import datehelper from "../../_helpers/datehelper";
import { uploadFileToBucket } from "../../_helpers/upload";
import urlhelper from "../../_helpers/urlhelper";
import { TermLoansDocumentModal } from "./TermLoansDocumentModal";
import { TermLoansPaymentModal } from "./TermLoansPaymentModal";
import { Add, ArrowBack, ArrowForward, ArrowForwardIos, Description, InsertDriveFile } from "@material-ui/icons";
import { history } from '../../_helpers'
import { RepaymentSchedule } from "./RepaymentSchedule";
import excelIcon from "../../assets/excelIcon.png"
import * as XLSX from 'xlsx';
const PaymentTracking = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [csv, setCsv] = React.useState(false);
  const [data, setData] = React.useState();
  // const [status, setStatus] = React.useState(true)

  const [values, setValues] = React.useState({
    name_of_lender: "",
    loan_type: "",
    roi: "",
    processing_fees: "",
    sanction_date: "",
    availment_date: "",
    tenure: "",
    sanctioned_amt: "",
    amt_availed: "",
    loan_name: "",
    emi_day: "",
    loan_number:'',
    pos:'',
    security:'',
    charge_created:'',
    charge_id:'',
    remark:'',
    pg_or_cg:'',
    fldg:'',
    payment_collection:{
      mode:'Nach',
      bank:''
    }
    // status:""
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
  };

  const showCsv = () => {
    console.log(!csv);
    setCsv(!csv);
  };

  const bucket = process.env.REACT_APP_s3sblBucket;


  const handleFile = (value) => (e) => {
      // let { name, value } = e.target;
      let selectedFile = e.target.files[0]
      const data = new FormData();
      data.append("file", selectedFile);
      // let fileName = selectedFile.name.split(".")
      // fileName = fileName[0]
      const upload = async () => {
         await uploadFileToBucket(bucket, "application/pdf", `termloans/repayment_schedule${value._id}.pdf`, selectedFile)
        .then((url)=>{
          // value=data;
          console.log(url)
          // const newvalues = {...values};
          // newvalues[idx][name] = value;
          // setValues(newvalues);
          termLoans.update({...value, repayment_schedule: url})
        })
        .then((x)=>{
          alert("File uploaded");
        window.location.reload();
        })
        .catch((err)=>{
          alert("Please Try Again")
          window.location.reload();
        })
        .catch((err)=>{console.log(err)})         
      }
      upload()
  };

  const getData = async () => {
    await termLoans
      .getAll()
      .then((x) => {
        console.log(x);
        setData(x);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    getData();
  }, []);
  const downloadXLSX = () => {
    const xlsxData = [
      ["Name of lender", "Loan name", "Loan type", "Roi", "EMI Day", "Sanction Date", "Availment Date", "Tenure", "Amount Sanctioned", "Amount Availed", "POS", "Security", "PG/CG", "FLDG"]
    ];
    data.forEach((item) => {
      if(item.status){

        xlsxData.push([
          item.name_of_lender,
          item.loan_name,
          item.loan_type,
          item.roi,
          item.emi_day,
          item.sanction_date,
          item.availment_date,
          item.tenure,
          item.sanctioned_amt,
          item.amt_availed,
          item.pos,
          item.security,
          item.pg_or_cg,
          item.fldg
        ]);
      }
    });
    const worksheet = XLSX.utils.aoa_to_sheet(xlsxData);
    const columnWidths = xlsxData[0].map(column => ({ width: column.length + 4 }));
    worksheet['!cols'] = columnWidths;
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Term Loan');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'termLoan.xlsx';
    link.click();
  }
  const handleChange = (e) => {
    let name = e.target.name
    name = name.split('.')
    if(name[1]){
      values[name[0]][name[1]]=e.target.value
    }else{
      values[name[0]]=e.target.value
    }
    setValues(values)
    // setValues({ ...values, [e.target.name]: e.target.value });
    console.log(values);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    termLoans
      .create(values)
      .then((data) => {
        alert("Data added successfully");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleStatus = (temp) => {
    let status = !temp.status;
    console.log(status);
    termLoans
      .update({ ...temp, status })
      .then((data) => {
        alert("Status Updated");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const termLoanForm = ()=>{
    history.push("/home/accounting/termloanform")
}
  return (
    <>
      <div
        style={{marginLeft:'-10px' ,marginTop:'-10px',position: "fixed",  zIndex: "9999", width: "87%", backgroundColor: "#fff",padding:"25px",overflow: "hidden" }}
      >
        <h1 style={{ display: "flex",float:'left' }}>
          Term Loans
        </h1>
        <div style={{display:'flex',justifyContent:'flex-end',marginRight:'28px' }}>
        <Button  onClick={downloadXLSX}  style={{height:"35px",width:"160px"}}><img src={excelIcon} alt="Export Excel" style={{height:"40px",width:'160px',background:' #AFDEC4',borderRadius: "5px"}}/></Button>
        <Button
            style={{ borderRadius: "5px", backgroundColor: "blue" ,color:"white",marginLeft:'20px',width: "165px"}}
            onClick={termLoanForm}
          >
            <Add/>&nbsp;Add Loan
          </Button>
          </div>
      </div>
      <div style={{marginTop:'80px',width:'125%'}}>
        <table style={{background: "#C9D5FD",}}>
          <thead style={{ top: "170px",position: 'sticky', left: 0, zIndex: 1,height:'40px'}}>
            <tr style={{background:'#4570FF',color:"white",fontSize:'17px'}}>
              <th style={{ width: "13%" }}>Lender Name</th>
              <th style={{ width: "8%" }}>Loan Name</th>
              <th style={{ width: "7%" }}>Loan Type</th>
              <th style={{ width: "5%" }}>ROI(%)</th>
              <th style={{ width: "6%" }}>EMI Date</th>
              <th style={{ width: "9%" }}>Sanctioned Date</th>
              <th style={{ width: "9%" }}>Availment Date</th>
              <th style={{ width: "4%" }}>Tenure</th>
              <th style={{ width: "10%" }}>Amount Sanctioned</th>
              <th style={{ width: "10%" }}>Amount Availed</th>
              <th style={{ width: "4%" }}>POS</th>
              <th style={{ width: "5%" }}>Security</th>
              <th style={{ width: "5%" }}>PG/CG</th>
              <th style={{ width: "8%" }}>FLDG(%)</th>
              <th style={{ width: "20%" }}>Action</th>
            </tr>
          </thead>
          <br/>
          <tbody >
            {data &&
              data.map((e, idx) => {
                return (<>
                  <tr style={{ background: "#EFECEC", marginBottom: "80px" ,height:'176px',fontSize:'17px'}}>
                    <td>{e.name_of_lender}</td>
                    <td>{e.loan_name}</td>
                    <td>
                      {e.loan_type}<br/>
                      {/* <TermLoansDocumentModal loan={e}/>
                      <TermLoansPaymentModal loan={e}/> */}
                      {/* {e.repayment_schedule ? (
                        <div style={{ display: "flex" }}>
                    <a href={`/home/pdfviewer?pdf_url=`+urlhelper.encodeToBase64(e.repayment_schedule)+"&download=1"}>
                            View
                          </a>
                        </div>
                      ) : (
                        <input
                          type="file"
                          name="repayment_schedule"
                          accept=".pdf"
                          className="form-control"
                           onChange={handleFile(e)}
                        />
                      )} */}
                    </td>
                    <td>{e.roi}%</td>
                    <td>{e.emi_day}</td>
                    <td>{datehelper.displayDate(new Date(e.sanction_date))}</td>
                    <td>
                      {datehelper.displayDate(new Date(e.availment_date))}
                    </td>
                    <td>{e.tenure}</td>
                    <td>
                      {e.sanctioned_amt.toLocaleString("en-IN", {
                        maximumFractionDigits: 2,
                        style: "currency",
                        currency: "INR",
                      })}
                    </td>
                    <td>
                    {e.amt_availed.toLocaleString("en-IN", {
                        maximumFractionDigits: 2,
                        style: "currency",
                        currency: "INR",
                      })}
                    </td>
                    <td>{e.pos && e.pos.toLocaleString("en-IN", {
                        maximumFractionDigits: 2,
                        style: "currency",
                        currency: "INR",
                      })}</td>
                    <td>{e.security}</td>
                    <td>{e.pg_or_cg}</td>
                    <td>{e.fldg}%</td>
                    <td>
                    {e.status === true ? (
                        <sapn>
                          <Button
                            onClick={() => handleStatus(e)}
                            style={{ color: "green" ,border: "2px solid green",height:'25px',background:'93D38E',marginBottom:'5px'}}
                          >
                            Active
                          </Button>
                        </sapn>
                      ) : (
                        <span>
                          <Button
                            onClick={() => handleStatus(e)}
                            style={{ color: "red",height:'25px',border: "2px solid red",background:'F9D2D2',marginBottom:'5px'}}
                          >
                            Close
                          </Button>
                        </span>
                      )}
                      <br/>
                      <span>
                          <Button
                            onClick={()=>history.push(`/home/accounting/edit/${e._id}`,e)}
                            style={{ color: "#4570FF",border: "2px solid  #4570FF",height:'25px',background:'C8D4FD',marginBottom:'5px'}}
                          >
                           Edit
                          </Button>
                        </span>
                    <TermLoansDocumentModal loan={e}/>
                    <TermLoansPaymentModal loan={e}/>
                   {e.final_repayment_schedule?
                    <RepaymentSchedule loan={e}/>
                    :<RepaymentSchedule loan={e}/>}
                    </td>
                  </tr>
                  <br/>
                  </>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PaymentTracking;
