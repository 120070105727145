import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import { Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
} from 'chart.js';
import { employeeService, collectionService } from '../../../_services';

ChartJS.register(BarElement, ArcElement, Tooltip, Legend, CategoryScale, LinearScale);

const DashboardProfile = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [selectedBucket, setSelectedBucket] = useState('NPA');  

  const [documents, setDocuments] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [employeeList,setEmployeeList] =React.useState([]);

  const filteredData = documents.map((item) => ({
      key_person: item.key_person,
      bucket: item?.buckets?.find((b) => b.bucket === selectedBucket),
    }))
    .filter((item) => item.bucket);


    console.log('filter data is ',filteredData)

    const handleBucketChange = (event) => {
      setSelectedBucket(event.target.value);
    };
  // const handleChange = (event, newValue) => {
  //   setActiveTab(newValue);
  // };

  // const barData = {
  //   labels: documents?.map((doc) => `${doc?.key_person || 'Branch'}`),
  //   datasets: [
  //     {
  //       label: 'Total POS (₹)',
  //       data: documents?.map((doc) => doc?.POS || 0),
  //       backgroundColor: 'rgba(75, 192, 192, 0.6)',
  //       borderColor: 'rgba(75, 192, 192, 1)',
  //       borderWidth: 1,
  //     },
  //   ],
  // };

 

  const fetchKpData = async () => {
    try {
      setSpinner(true);
      const kpData = await collectionService.totalLoandsAssignedToKp();
      if (kpData?.success) {
        setDocuments(kpData.data || []);
      }
    } catch (err) {
      console.error('Error fetching KP data:', err);
    } finally {
      setSpinner(false);
    }
  };

  async function getAllEmployees() {
    await employeeService.getAll()
        .then((res) => {
            let list = {};
            res.map(e => {
                list[e.emp_id] = e.first_name + ' ' + e.last_name
            })
            setEmployeeList(list)
        })
}

 

  useEffect(() => {
    getAllEmployees()
    fetchKpData();

  }, []);

console.log('documents are',documents)

  return (
    <Container>
      
      {/* <Tabs value={activeTab} onChange={handleChange} centered>
        <Tab label="Table" />
        </Tabs> */}
       <Box mt={2}>
        <FormControl variant="outlined" style={{ minWidth: 200 }}>
          <InputLabel>Filter by Bucket</InputLabel>
          <Select value={selectedBucket} onChange={handleBucketChange} label="Filter by Bucket">
            <MenuItem value="NPA">NPA</MenuItem>
            <MenuItem value="PNPA">PNPA</MenuItem>
            <MenuItem value="Bucket_1">Bucket 1</MenuItem>
            <MenuItem value="Bucket_0">Bucket 0</MenuItem>

            <MenuItem value="Bucket_X">Bucket X</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box mt={4}>
        {activeTab === 0 && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>KP Person</TableCell>
                  <TableCell>Total Loans Assigned</TableCell>
                  {/* <TableCell>Total POS (₹)</TableCell> */}
                  <TableCell>Total Arrears (₹)</TableCell>
                  <TableCell>View Loans</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                
                {filteredData.length > 0 ? (
                  filteredData.map((doc, index) =>  doc.key_person && (
                   <TableRow key={index}>
                      <TableCell>{  employeeList[doc.key_person]  }</TableCell>
                      <TableCell>{doc?.bucket?.loan_count || 0}</TableCell>
                      {/* <TableCell>{doc?.bucket?.POS  || 0}</TableCell> */}
                      <TableCell>
  {doc?.bucket?.arrears && doc.bucket.arrears > 0 
    ? Math.round(doc.bucket.arrears).toLocaleString() 
    : 0}
</TableCell>

                      <TableCell>
                        <Button component="a"
                                      href={`/home/kp_dashboard_data/${doc.key_person}`}
                                      size="small"
                                      color="primary"
                                    >
                                      View Loans</Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      {spinner ? (
                        <span className="spinner-border spinner-border-lg mr-1"></span>
                      ) : (
                        <p>No Data Found</p>
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {/* {activeTab === 1 && (
          <Box>
            {spinner ? (
              <span className="spinner-border spinner-border-lg mr-1"></span>
            ) : (
              <Bar data={barData} options={{ responsive: true }} />
            )}
          </Box>
        )} */}

         
      </Box>
    </Container>
  );
};

export default DashboardProfile;
