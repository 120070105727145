import React, {useState, useEffect} from 'react'
import { loanService } from '../../../_services';
import { branchService } from '../../../_services/branch.service';
import LoanAgreement from './LoanAgreement'
import Moe from './Moe'
import { useReactToPrint } from "react-to-print";
import ChequeSubmissionForm from './ChequeSubmissionForm';
import DemandPromissoryNote from './DemandPromissoryNote';
import DisbursementRequestForm from './DisbursementRequestForm'
import EndUseDeclaration from './EndUseDeclaration';
import Indemnity from "./Indemnity"
import LetterOfContinuity from './LetterOfContinuity'
import PersonalGuarantee from './PersonalGuarantee'
import VernacularDeclaration from './VernacularDeclaration'
import MemorandumMortgage from './MemorandumMortgage'
import UnattestedDeedOfHypothecation from './UnattestedDeedOfHypothecation'
import IrrevocablePowerOfAttorney from './IrrevocablePowerOfAttorney'
// import LoanSummary from './LoanSummary'
import { useLocation } from 'react-router-dom';

const AnnexureList = (props) => {
    const location = useLocation();
    const { element, colender } = location.state || {};
    const application_id = element.application_id
    const AnnexureListRef = React.useRef();

    const handleSubmissionPrint = useReactToPrint({
        content: () => AnnexureListRef.current,
        pageStyle: "print",
        marginTop: "1rem",
        display: "block",
        pageBreakAfter: "always",
        breakAfter: "always",
        pageBreakInside: "always"
    });

    const [data, setData] = useState({})
    const [branches, setBranches] = useState({})

    const getBranch = () => {
        if(data && data.branch_id){
            branchService.getAll()
            .then((b)=>{
                b.map((x)=>{
                    if(x.branch_id == data.branch_id){
                        setBranches(x)
                        console.log(x, "data here")
                    }
                })
            })
            .catch((err)=>{
                console.log(err)
            })
        }
    }
        

    const getData = () => {
        loanService.getBySearch({application_id:application_id})
            .then((x) => {
                setData(x[0])
                console.log(x)
            })
            .catch((err) => {
                console.log(err)
            })
    }        


    useEffect(() => {
        getData()
    }, [props])

    useEffect(() => {
        getBranch()
    }, [data])



    return (
        <div>
            <div style={{ textAlign: "center" }}>
                <button onClick={handleSubmissionPrint}>Print Letter</button>
            </div>

            <div ref={AnnexureListRef} >
                <Moe data={data} branches={branches} colender={colender}/>
                <ChequeSubmissionForm data={data} branches={branches} colender={colender}/>
                <DisbursementRequestForm data={data} branches={branches} colender={colender}/>
                {colender.includes("CIFL") && <EndUseDeclaration data={data} branches={branches} />}
                <PersonalGuarantee data={data} branches={branches} colender={colender}/>
                {colender.includes("CIFL") && <VernacularDeclaration data={data} branches={branches} />}
                {colender.includes("CIFL") && <Indemnity data={data} branches={branches}/>}
                <DemandPromissoryNote data={data} branches={branches} colender={colender}/>
                {colender.includes("CIFL") && <LetterOfContinuity data={data} branches={branches} />}
                {colender.includes("CIFL") && <MemorandumMortgage data={data} branches={branches}/>}
                {colender.includes("CIFL") && <LoanAgreement data={data} branches={branches} />}
                {colender.includes("CIFL") && <UnattestedDeedOfHypothecation data={data} branches={branches} />}
                {colender.includes("CIFL") && <IrrevocablePowerOfAttorney data={data} branches={branches}/>}
                {/* {colender.includes("VistaarFinance") && <LoanSummary data={data} branches={branches}/>} */}
            </div>

        </div>
    )
}

export default AnnexureList