import React, { useState, useEffect } from 'react'
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { sanction } from '../../../../_services/sanctionletter.service';
import { Button } from '@material-ui/core';
import Provider from '../../SanctionLetter/Print';
import { history } from '../../../../_helpers/history'
import datehelper from "../../../../_helpers/datehelper"
import "../../../../css/common.css"
import roleChecker from '../../../../_helpers/rolechecker';
import { employeeService } from '../../../../_services';
import { LoanStages } from '../../../../constants/loanstages';
import { Edit,Add } from "@material-ui/icons";
import { Link } from "react-router-dom";
import swal from 'sweetalert';
import workFlowHelper from "../../../../_helpers/workflowhelper"

const SanctionLetterDetails = (props) => {

    const application_id = props.customer.application_id;
    const customer = props.customer;
    const user = employeeService.userValue;
    const [employeeList, setEmployeeList] = useState({})
console.log("user is",user)

    const [letters, setLetters] = useState([])

    const getLetters = () => {
        sanction.getLetterByLoan({ application_id: application_id })
            .then((data) => {
                console.log('applicant data is',data)
                setLetters(data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    
const verifySanctionLetter=(id)=>{
    console.log("verified")
    sanction.verifySanctionLetter({id:id,user:user})  .then((data) => {
        console.log('sanction data is',data)
        window.location.reload();
        // setLetters(data)
    })
    .catch((err) => {
        console.log(err)
    })
}
const resetSanctionLetter=(id)=>{
    console.log("reset",id)
    sanction.resetSanctionLetter({id:id,user:user})  .then((data) => {
        console.log('sanction data is',data)
        window.location.reload();
        // setLetters(data)
    })
    .catch((err) => {
        console.log(err)
    })
}
async function getAllEmployees() {
    await employeeService.getAll()
        .then((res) => {
            let list = {};
            res.map(e => {
                list[e.emp_id] = e.first_name + ' ' + e.last_name
            })
            setEmployeeList(list)
        })
}

    useEffect(() => {
        getLetters()
        getAllEmployees()
    }, [])

     console.log("letters is",letters)


     let colenderList=[]
     if(customer?.co_lending){
        customer?.co_lending?.map((cl)=> {
         if(!["ColendingCancelled"]?.includes(cl?.co_lender_status)){
   
           colenderList.push(cl?.co_lender_name)
         }
        })
     }
     console.log(colenderList,"colenderListmmmmmmm")
    return (
        <div>

            <h4>Sanction Letter</h4>

           { !letters.length >0 &&
             (roleChecker.isHOLevelAccess(user.role) && customer.stage === LoanStages.Sanction
            //  && customer.loan?.insurance_amount > 0
             )
              && (
                <Button
                    color="primary"
                   style={{border:"1px solid blue"}}
                   onClick={() => {
                    if (!customer.loan?.insurance_amount) {
                        swal("Error","Create a 'Calculate Insurance' button below","error");
                    } else {
                        history.push(`/home/sbl/${customer._id}`);
                    }
                }}
                    //  onClick={() => history.push(`/home/sbl/${customer._id}`)}
                >
                    Create Sanction Letter
                </Button>
             )
           } 

            <p style={{marginTop:"20px"}} >Sanction Letter Details</p>
            <Table className='table-border'>
                <Thead>
                    <Tr>
                        <Th className='table-row-th'>Date</Th>
                        <Th className='table-row-th'>Application ID</Th>
                        <Th className='table-row-th'>Name</Th>
                        <Th className='table-row-th'>Sanction Amount</Th>
                        <Th className='table-row-th'>Sanction Letter</Th>
                        <Th className='table-row-th'>Annexure</Th>
                        <Th className='table-row-th'>Edit</Th>
                        <Th className='table-row-th'>Verify</Th>
                        <Th className='table-row-th'>Verified By</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {letters && letters.length > 0  && letters.map((element, idx) => {
                        console.log("element is",element._id)
                        return <Tr key={idx}>
                            <Td className='table-row-td'>{datehelper.displayDate(new Date(element.createdAt))}</Td>
                            <Td className='table-row-td'>{element.application_id}</Td>
                            <Td className='table-row-td'>{element.applicant}</Td>
                            <Td className='table-row-td'>{element.sanction_amount}</Td>
                            <Td className='table-row-td'>{(((!element.verify_details || element.verify_details.is_verified) && workFlowHelper.isColenderAdded(props.customer)) || (roleChecker.isHOCreditApproverAccess(user.role) && user.emp_id!=element.created_emp_id )) && <Provider loan={customer} letter={element} />} </Td>
                            <Td className='table-row-td'>
                            {(((!element.verify_details || element.verify_details.is_verified)&& workFlowHelper.isColenderAdded(props.customer)) || (roleChecker.isHOCreditApproverAccess(user.role) && user.emp_id!=element.created_emp_id )) && <Button color='primary' onClick={() => history.push(`/home/sblloans/annexure/list`,  { element, colender: colenderList })}> Print   
                            </Button>}
                            </Td>
                            <Td className='table-row-td'>
                          { (!element.verify_details || !element.verify_details.is_verified) && <Link
                    to={`/home/sbl/${customer._id}`}
                    className="btn btn-sm btn-primary mr-1"
                  >
                    <Edit />
                  </Link>}
                            </Td>
                            <Td className='table-row-td'>{(element.verify_details &&  !element.verify_details.is_verified)?<>
                                {roleChecker.isHOCreditApproverAccess(user.role) && user.emp_id!=element.created_emp_id ? <Button variant="contained" color="primary" onClick={() => {
                                  if (window.confirm("Are you sure you want to verify?")) {
                                                   verifySanctionLetter(element._id);
    }
                                    }}>Verify</Button>:<Button disabled>Pending</Button>}
                            </>:<>
                            {
                                (element.verify_details && element.verify_details.is_verified) ? <> 
                                <p style={{color:'green'}}>Verified</p> 
                                {(roleChecker.isGMLevelAccess(user.role) || roleChecker.isHOCreditApproverAccess(user.role)) && <Button style={{ background:"red",color:"white"}} variant='contained'  onClick={() => {
                                  if (window.confirm("Are you sure you want to reset ?")) {
                                                   resetSanctionLetter(element._id);
    }
                                    }} >Reset</Button>}
                                </>:null
                            }
                               
                            </>} </Td>
                            <Td>
                                {
                                    (element.verify_details && element.verify_details.is_verified) && <p>{employeeList[element.verify_details.verified_by_emp_id]}</p>
                                }
                            </Td>
                        </Tr>
                    })}
                </Tbody>
            </Table>
        </div>
    )
}

export default SanctionLetterDetails