import { Button, Modal,Box } from '@material-ui/core'
import React, { useState } from 'react'
import { collectionService } from '../../../_services';
import { Cancel, VerifiedUser } from '@material-ui/icons';

const VerifyDepositModal = (props) => {
    const style = {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 550,
        height: 200,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        borderRadius: '5px',
        overflow: "scroll"
    };
    console.log("Test props",props)
    const totalAmount=props.depositSlip.total_payment
    const id=props.depositSlip._id
    const [open,setOpen]=useState(false)
    const handleOpen=()=>{
        setOpen(true)
    }
    const handleClose = ()=>{
        setOpen(false)
    }
  const verifyDepositAmount = () => {
    collectionService.verifyDepositAmount(id)
    .then((res) => {
      alert("Amount Verify")
      window.location.reload()
    })
      .catch((err) => console.log(err))
  }
  return (
    <div>
        <Button onClick={handleOpen}
                        style={{ width: '90px', height: "27px", background: "Green", marginTop: '10px', color: "white" }}
        >Verify</Button>
        <Modal
        open={open}
        onClose={handleClose}
        >
         <Box sx={style}>
            <h5 style={{fontWeight:"bold",textAlign:'center',color:'blue'}}>Are you sure want to verify amount:{totalAmount.toLocaleString("en-IN", { style: 'currency', currency: 'INR' })}</h5>
              <br/>
             <div style={{display:"flex",flexDirection:'row',justifyContent:"center",alignItems:"center"}}>
             {/* style={{background:"green",color:'white',marginTop:"10px"}} */}
             <Button
                onClick={()=>verifyDepositAmount()}
                style={{ width: '90px', height: "27px", background: "green", marginTop: '10px', color: "white" ,marginRight:'70px'}}
              ><VerifiedUser /> Yes</Button>
            <Button
               onClick={handleClose}
               style={{ width: '90px', height: "27px", background: "red", marginTop: '10px', color: "white" }} 
              ><Cancel/>No</Button>
             </div>
           </Box>
        </Modal>
      
    </div>
  )
}

export default VerifyDepositModal
