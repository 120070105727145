import React from "react";
import ApplicantData from "./ApplicationData/ApplicantData";
import CoApplicantData from "./ApplicationData/CoApplicantData";
import TextField from "@material-ui/core/TextField";
import { loanService, employeeService,customerService } from "../../../_services";
import GuarantorData from "./ApplicationData/GuarantorData";
import EditStageModal from "./EditStageModal";
import roleChecker from "../../../_helpers/rolechecker";
import { LoanStages } from "../../../constants/loanstages";
import datehelper from "../../../_helpers/datehelper";
import displayhelper from "../../../_helpers/displayhelper";
import { Button } from '@material-ui/core'
import Switch from '@material-ui/core/Switch';
import { history } from "../../../_helpers";
import RevertStageModal from "./RevertStageModal"
import swal from 'sweetalert';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';

export default function LoanOverView({ cust }) {
  const user = employeeService.userValue;
  const [totalEMI, setTotalEMI] = React.useState(0)
  const [totalEMIReceived, setTotalEMIReceived] = React.useState(0)
  const [totalLPIReceived, setTotalLPIReceived] = React.useState(0)
  const [totalLPIDue, setTotalLPIDue] = React.useState(1)
  console.log('cust is basically', cust)
  console.log('Application id  is basically', cust.application_id)
  // const [state, setState] = React.useState({
  //   switch_panel: true
  // });



  React.useEffect(() => {
    if (cust && cust.loan_repayments && cust.loan_repayments.length > 0 && cust.loan_repayments[0].emi_schedule) {
      let tmpEMI = 0
      let tmpEMIReceived = 0
      let tmpLPIPaid = 0
      let tmpLPITotal = 0
      let tmpLPIDue = 0
      cust.loan_repayments[0].emi_schedule.map((ei, idx) => {
        tmpEMI = tmpEMI + ei.emi_amount
        if (ei.emi_received) {
          tmpEMIReceived = tmpEMIReceived + ei.emi_received
        }
        if (ei.lpi) {
          tmpLPIPaid = tmpLPIPaid + ei.lpi
        }
        if (ei.emi_lpi) {
          tmpLPITotal = tmpLPITotal + ei.emi_lpi
        }
      })
      tmpLPIDue = tmpLPITotal - tmpLPIPaid
      setTotalEMI(tmpEMI)
      setTotalEMIReceived(tmpEMIReceived)
      setTotalLPIReceived(tmpLPIPaid)
      setTotalLPIDue(tmpLPIDue)
      console.log("total lpi due =" + tmpLPIDue);
    }
    return () => {
    }
  }, [])

  // const handlePanel = (event) => {
  //   setState({ ...state, [event.target.name]: event.target.checked });

  //   if (!event.target.checked) {
  //     history.push(`/home/sblloans/details/${cust._id}?isnew_view=1`)
  //     window.location.reload()
  //   }

  //   console.log(event.target.checked)
  // };

  //console.log(cust);
  const refreshPage = () => {
    window.location.reload();
  }

  const reOpen = (id) => {
    console.log('id is', id)
    loanService.reOpen({ id: id })
      .then((res) => {
        console.log('reopen api run successfully');
        window.location.reload()
      })
      .catch((err) => {
        console.log('err', err);
      })
  }
  const UpdateRpss = async () => {
    try {
      const data = await customerService.UpdateRps({ application_id: cust?.application_id });
      swal("Success", "Successfully synced data", "success").then(() => {
        window.location.reload();
      });
    } catch (err) {
      console.log(err);
      swal("Error", "Failed to sync data. Please try again.", "error");
    }
  };
  return (
    <div>

      {/* <div style={{ display: "flex", justifyContent: "flex-end", flexDirection: "row" , marginRight: "3rem" }} >
        <label >LOS</label> <div style={{ marginTop: "-10px" }} >
          <Switch
            checked={state.switch_panel}
            onChange={handlePanel}
            color="primary"
            name="switch_panel"
          // inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </div><label style={{color: 'blue' }} >LMS</label>
      </div> */}

      <div className="row">
        <TextField
          disabled
          id="appid"
          label="Application ID"
          defaultValue={cust.application_id}
          variant="outlined"
        />
        <TextField
          disabled
          id="cust_name"
          label="Applicant"
          defaultValue={cust.applicant.general_information.name}
          variant="outlined"
        />
        {cust.branches.length > 0 && (
          <TextField
            disabled
            id="branchname"
            label="Branch Name"
            defaultValue={cust.branches[0].branch_name}
            variant="outlined"
          />
        )}
        {cust.employees && cust.employees.length > 0 && (
          <TextField
            disabled
            id="ro_id"
            label="RO/Sales Agent"
            defaultValue={cust.employees[0].first_name + " " + cust.employees[0].last_name + " /ID: " + cust.employees[0].emp_id + " "}
            variant="outlined"
          />)}
      </div>

      {cust.loan && cust.loan.sanction_amount && (
        <div className="row" style={{ marginTop: "10px" }}>
          <TextField
            disabled
            id="santamount"
            label="Sanction Amount"
            defaultValue={displayhelper.displayNumberAccounting(cust.loan.sanction_amount)}
            variant="outlined"
          />
          <TextField
            disabled
            id="roi"
            label="ROI"
            defaultValue={cust.loan.roi + "%"}
            variant="outlined"
          />
          <TextField
            disabled
            id="tenure"
            label="Tenure"
            defaultValue={cust.loan.tenure + " Months"}
            variant="outlined"
          />
          {cust.loan && cust.loan.disbursement_date && (
            <TextField
              disabled
              id="agreementdate"
              label="Disbursement Booking Date"
              defaultValue={datehelper.displayDate(new Date(cust.loan.disbursement_date))}
              variant="outlined"
            />
          )}
        </div>
      )}

      {cust.loan_repayments && cust.loan_repayments.length > 0 && (
        <div className="row" style={{ marginTop: "10px" }}>
          {cust.loan_repayments[0]['arrear_amount'] >= 0 && (
            <TextField
              disabled
              id="arrear"
              label="Balance Remaining"
              defaultValue={displayhelper.displayNumberAccounting(cust.loan_repayments[0]['arrear_amount'])}
              variant="outlined"
            />
          )}


          <TextField
            disabled
            id="emi_remaining"
            label="EMI Remaining"
            defaultValue={cust.loan_repayments[0]['num_emi_due']}
            variant="outlined"
          />


          <TextField
            disabled
            id="lpi_due"
            label="LPI Due"
            value={displayhelper.displayNumberAccounting(totalLPIDue)}
            variant="outlined"
          />

          <TextField
            disabled
            id="bounce_charges"
            label="Bounce Charges"
            defaultValue={displayhelper.displayNumberAccounting(cust.loan_repayments[0]['bounce_charges'])}
            variant="outlined"
          />


        </div>
      )}

      

      <div className="row" style={{ marginTop: "10px" }}>
        <TextField
          disabled
          id="stage"
          label="Loan Stage"
          defaultValue={cust.stage}
          variant="outlined"
        />
        {cust.sub_stage && (
          <TextField
            disabled
            id="sub_stage"
            label="Loan Pending Step"
            defaultValue={cust.sub_stage}
            variant="outlined"
          />
        )}
        <div style={{
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
          marginLeft: '1rem'
        }}>

          {(roleChecker.isGMLevelAccess(user.role) || [20994,20904,20938].includes(user.emp_id) || ["ZSM"].includes(user.role)) && cust && [LoanStages.Rejected].includes(cust.stage) && <EditStageModal loan_data={cust} refreshParent={() => refreshPage()}></EditStageModal>
          } 
        </div>

        
        <div style={{ margin: "10px" }}>
          {roleChecker.isHOLevelAccess(user.role) && ![LoanStages.Closed, LoanStages.Collection, LoanStages.Rejected, LoanStages.Discarded].includes(cust.stage) && (
            <EditStageModal loan_data={cust} refreshParent={() => refreshPage()}></EditStageModal>
          )}

          {roleChecker.isBranchLevelAccess(user.role) && ![LoanStages.Closed, LoanStages.Collection, LoanStages.Rejected, LoanStages.Discarded, LoanStages.Sanction, LoanStages.Operation, LoanStages.Credit,LoanStages.Disbursal].includes(cust.stage) && (
            <EditStageModal loan_data={cust} refreshParent={() => refreshPage()}></EditStageModal>
          )}
          {(roleChecker.isCreditTeamAccess(user.role)) && ![LoanStages.Closed, LoanStages.Collection, LoanStages.Rejected, LoanStages.Discarded, LoanStages.Sanction, LoanStages.Operation,LoanStages.Disbursal].includes(cust.stage) && (
            <EditStageModal loan_data={cust} refreshParent={() => refreshPage()}></EditStageModal>
          )}
        </div>

        <div style={{
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
          marginLeft: '1rem'
        }}>

          {(roleChecker.isHOLevelAccess(user.role)) && [LoanStages.Disbursal,LoanStages.Operation].includes(cust.stage) && <RevertStageModal cust={cust}  ></RevertStageModal>
          } 
        </div>
        

      </div>
      <div>

      </div>


{cust.co_lending && cust.co_lending.length>0 && (roleChecker.isGMLevelAccess(user.role) ||  roleChecker.isColendingTeamAccess(user.role) || roleChecker.hasAccountsAccess(user.role) || roleChecker.isHOOpsTeamAccess(user.role) ) && cust.co_lending.map((cl,i)=> {
         return (
          <div className="row" style={{ marginTop: "10px" }}>
            <TextField
            disabled
            id="co_lender_name"
            label="Colender Name"
            defaultValue={cl.co_lender_name}
            variant="outlined"
          />
          <TextField
            disabled
            id="co_lender_status"
            label="Colender Status"
            defaultValue={cl.co_lender_status}
            variant="outlined"
          />
           </div>
         )
      })}


      {cust.loan_repayments && cust.loan_repayments.length > 0 && cust.loan_repayments[0].emi_schedule &&  !["Collections", "Sales", 'BM','ABM', 'BCM', 'CCM', 'CSM', 'BOP'].includes(user.role) && !["Unreviewed"].includes(cust.stage) && (
        <>
          <br />
          <div style={{display:"flex"}}> <h3>Loan Repayment</h3>  {((roleChecker.isGMLevelAccess(user.role)||roleChecker.isAccountChecker(user.role) || ["HOCL"].includes(user.role)) && (<SystemUpdateAltIcon  onClick={UpdateRpss} style={{cursor:"pointer",color:"blue",marginLeft:"1rem",marginTop:"0.3rem"}}/>))}</div>
          <table style={{ width: "100%", border: "1px solid", padding: "10px" }}><tr style={{ border: "1px solid", padding: "10px" }}><th style={{ paddingLeft: "5px" }}>EMI #</th><th>EMI Date</th><th>EMI Amount</th><th>Amount Received</th><th>Receive Date</th><th>LPI Received</th><th>LPI Total</th><th>Bounce Charges</th><th>Interest Paid</th><th>Principal Paid</th><th>Balance Remaining</th></tr>
            {cust.loan_repayments[0].emi_schedule.map((ei, idx) => {
              {
                let todayDate = new Date();
                var secondsDiff = datehelper.getDateDiffSeconds(todayDate, new Date(ei.emi_date));
                var isDPD = false;
                if (secondsDiff > 0) {
                  if (!ei.emi_received || ei.emi_received < ei.emi_amount) {
                    isDPD = true;
                  }
                }
                var interest_paid = 0
                var capital_paid = 0
                var balance_remaining = 0
                if (ei.emi_received && ei.emi_received > 0) {
                  if (ei.emi_received == ei.emi_amount) {
                    interest_paid = ei.interest_paid;
                    capital_paid = ei.capital_paid;
                    balance_remaining = ei.balance_remaining;
                  }
                  else {
                    if (ei.emi_received <= ei.interest_paid) {
                      interest_paid = ei.emi_received;
                      capital_paid = 0
                      balance_remaining = ei.balance_remaining + ei.capital_paid
                    }
                    else {
                      interest_paid = ei.interest_paid
                      capital_paid = ei.emi_received - interest_paid
                      balance_remaining = ei.balance_remaining + ei.capital_paid - capital_paid
                    }
                  }
                }
              }
              return (
                <>
                  {isDPD ? (<tr key={idx} style={{ border: "1px solid", padding: "10px", backgroundColor: "#FFE8F3" }}>
                    <td style={{ paddingLeft: "5px" }}>{ei.emi_number}</td>
                    <td style={{ paddingLeft: "5px" }}>{datehelper.displayDate(new Date(ei.emi_date))}</td>
                    <td style={{ paddingLeft: "5px" }}>{ei.emi_amount && displayhelper.displayNumberAccounting(ei.emi_amount)}</td>
                    <td style={{ paddingLeft: "5px" }}>{ei.emi_received && displayhelper.displayNumberAccounting(ei.emi_received)}</td>
                    <td style={{ paddingLeft: "5px" }}>{ei.emi_received_date && datehelper.displayDate(new Date(ei.emi_received_date))}</td>
                    <td style={{ paddingLeft: "5px", textAlign: "center" }}>{ei.lpi}</td>
                    <td style={{ paddingLeft: "5px", textAlign: "center" }}>{ei.emi_lpi}</td>
                    <td style={{ paddingLeft: "5px", textAlign: "center" }}>{ei.bounce_charge}</td>

                    <td style={{ paddingLeft: "5px" }}>{ei.emi_received && interest_paid}</td>
                    <td style={{ paddingLeft: "5px" }}>{ei.emi_received && capital_paid}</td>
                    <td style={{ paddingLeft: "5px" }}>{ei.emi_received && balance_remaining}</td>


                  </tr>)
                    :
                    (<tr key={idx} style={{ border: "1px solid", padding: "10px" }}>
                      <td style={{ paddingLeft: "5px" }}>{ei.emi_number}</td>
                      <td style={{ paddingLeft: "5px" }}>{datehelper.displayDate(new Date(ei.emi_date))}</td>
                      <td style={{ paddingLeft: "5px" }}>{ei.emi_amount && displayhelper.displayNumberAccounting(ei.emi_amount)}</td>
                      <td style={{ paddingLeft: "5px" }}>{ei.emi_received && displayhelper.displayNumberAccounting(ei.emi_received)}</td>
                      <td style={{ paddingLeft: "5px" }}>{ei.emi_received_date && datehelper.displayDate(new Date(ei.emi_received_date))}</td>
                      <td style={{ paddingLeft: "5px", textAlign: "center" }}>{ei.lpi}</td>
                      <td style={{ paddingLeft: "5px", textAlign: "center" }}>{ei.emi_lpi}</td>
                      <td style={{ paddingLeft: "5px", textAlign: "center" }}>{ei.bounce_charge}</td>
                      <td style={{ paddingLeft: "5px" }}>{ei.emi_received && interest_paid}</td>
                      <td style={{ paddingLeft: "5px" }}>{ei.emi_received && capital_paid}</td>
                      <td style={{ paddingLeft: "5px" }}>{ei.emi_received && balance_remaining}</td>

                    </tr>)}
                </>
              )
            })}
            <tr key="total" style={{ border: "1px solid", padding: "10px" }}><td style={{ paddingLeft: "5px" }}>Total</td> <td></td><td style={{ paddingLeft: "5px" }}>Rs. {displayhelper.displayNumberAccounting(totalEMI)}</td><td style={{ paddingLeft: "5px" }}>Rs. {displayhelper.displayNumberAccounting(totalEMIReceived)}</td><td></td><td style={{ paddingLeft: "5px" }}>Rs. {displayhelper.displayNumberAccounting(totalLPIReceived)}</td><td style={{ paddingLeft: "5px" }}>Rs. {displayhelper.displayNumberAccounting(totalLPIDue)}</td><td></td></tr>
          </table>
          <br />
          {cust && cust.loan_repayments && cust.loan_repayments.length > 0 && cust.loan_repayments[0].emi_schedule_sync_date && (
            <p>Last sync date - {datehelper.displayDateTimeFromString(cust.loan_repayments[0].emi_schedule_sync_date)}</p>
          )}
        </>
      )}
      
    </div>
  );
}