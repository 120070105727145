import React, { useState, useEffect } from 'react';
import { Modal, Backdrop, Fade, Button } from '@material-ui/core';
import { loanService, employeeService } from "../../../_services";
import datehelper from "../../../_helpers/datehelper";
import displayhelper from "../../../_helpers/displayhelper";
import CloseIcon from '@material-ui/icons/Close';

const LoanRepaymentModal = ({ application_id }) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null); // Set initial state to null
  const [spinner, setSpinner] = useState(false);
  const user = employeeService.userValue;

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const fetchData = async () => {
      if (open) {
        try {
          setSpinner(true);
          const response = await loanService.fetchRPS({ application_id });
          console.log('response is ', response);
          if (response?.status === "success") {
            setData(response.message);
          }
        } catch (err) {
          console.log('Error fetching data:', err);
        } finally {
          setSpinner(false);
        }
      }
    };

    fetchData();
  }, [open]);

  return (
    <>
      {/* Button to open the modal */}
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Open RPS
      </Button>

      {/* Modal for Loan Repayment Details */}
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div
            style={{
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '8px',
              width: '80%',
              margin: '5% auto',
              overflowY: 'auto',
              height: '80vh',
            }}
          >
            {spinner && <span className="spinner-border spinner-border-lg mr-1"></span>}

            {data?.emi_schedule && (
              <>
                <div style={{ display: "flex", justifyContent: 'space-between' }}>
                  <h3>Loan Repayment</h3>
                  <Button variant="outlined" color="secondary" onClick={handleClose}>
                    <CloseIcon />
                  </Button>
                </div>

                <table
                  style={{
                    width: "100%",
                    border: "1px solid",
                    padding: "10px",
                    marginTop: "10px",
                  }}
                >
                  <thead>
                    <tr>
                      <th>EMI #</th>
                      <th>EMI Date</th>
                      <th>EMI Amount</th>
                      <th>Amount Received</th>
                      <th>Receive Date</th>
                      <th>LPI Received</th>
                      <th>LPI Total</th>
                      <th>Bounce Charges</th>
                      <th>Interest Paid</th>
                      <th>Principal Paid</th>
                      <th>Balance Remaining</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.emi_schedule.map((ei, idx) => {
                      let todayDate = new Date();
                      let secondsDiff = datehelper.getDateDiffSeconds(todayDate, new Date(ei.emi_date));
                      let isDPD = secondsDiff > 0 && (!ei.emi_received || ei.emi_received < ei.emi_amount);

                      let interest_paid = 0, capital_paid = 0, balance_remaining = 0;

                      if (ei.emi_received && ei.emi_received > 0) {
                        if (ei.emi_received === ei.emi_amount) {
                          interest_paid = ei.interest_paid;
                          capital_paid = ei.capital_paid;
                          balance_remaining = ei.balance_remaining;
                        } else {
                          if (ei.emi_received <= ei.interest_paid) {
                            interest_paid = ei.emi_received;
                            capital_paid = 0;
                            balance_remaining = ei.balance_remaining + ei.capital_paid;
                          } else {
                            interest_paid = ei.interest_paid;
                            capital_paid = ei.emi_received - interest_paid;
                            balance_remaining = ei.balance_remaining + ei.capital_paid - capital_paid;
                          }
                        }
                      }

                      return (
                        <tr
                          key={idx}
                          style={{
                            backgroundColor: isDPD ? "#FFE8F3" : "transparent",
                            border: "1px solid",
                          }}
                        >
                          <td>{ei.emi_number}</td>
                          <td>{datehelper.displayDate(new Date(ei.emi_date))}</td>
                          <td>{ei.emi_amount && displayhelper.displayNumberAccounting(ei.emi_amount)}</td>
                          <td>{ei.emi_received && displayhelper.displayNumberAccounting(ei.emi_received)}</td>
                          <td>{ei.emi_received_date && datehelper.displayDate(new Date(ei.emi_received_date))}</td>
                          <td>{ei.lpi}</td>
                          <td>{ei.emi_lpi}</td>
                          <td>{ei.bounce_charge}</td>
                          <td>{ei.emi_received && interest_paid}</td>
                          <td>{ei.emi_received && capital_paid}</td>
                          <td>{ei.emi_received && balance_remaining}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default LoanRepaymentModal;
