import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import { Typography, Box, Button } from "@material-ui/core";
import { loanService } from "../../../../_services/loan.service";
import { employeeService } from "../../../../_services";

const AddEscrowDetails = (props) => {
  const { customer, account_data, idx, type, isEdit, button } = props;
  const escrow_details = customer.escrow_details[idx];
  const [spinner, setSpinner] = useState(false);
  // Initialize state
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    kaabil_account_number: "",
    kaabil_bank_name: "",
    account_holder_name: "",
    disbursed_account_number: "",
    disbursed_amount: "",
    disbursed_date: "",
    disbursed_utr_number: "",
    disbursement_memo_id: "",
    escrow_amount: "",
    colender_name: "",
    escrow_bank_name: "",
    escrow_bank_account: "",
    payment_type: "",
  });

  const user = employeeService.userValue;

  useEffect(() => {
    if (isEdit) {
      if (type === "kaabilToEscrow") {
        setValues(escrow_details.kaabilToEscrow || {});
      } else if (type === "escrowToCustomer") {
        setValues(escrow_details.escrowToCustomer || {});
      }
    } else {
      setValues({
        kaabil_account_number: account_data.kaabil_account_number,
        kaabil_bank_name: account_data.kaabil_bank_name,
        disbursed_account_number: account_data.disbursed_account_number,
        account_holder_name: account_data.account_holder_name,
        disbursed_amount: account_data.disbursed_amount,
        disbursed_date: account_data.disbursed_date ? account_data.disbursed_date.split('T')[0] : "",
        disbursed_utr_number: "",
        disbursement_memo_id: account_data.disbursement_memo_id,
        escrow_amount: account_data.escrow_amount,
        colender_name: account_data.colender_name,
        escrow_bank_name: account_data.escrow_bank_name,
        escrow_bank_account: account_data.escrow_bank_account,
        payment_type: account_data.payment_type,
      });
    }
  }, [escrow_details, account_data, isEdit]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let totalAmount = 0;

  const calculate = () =>{
    

    if(customer && customer.disbursement && customer.disbursement.disbursement_details && customer.disbursement.disbursement_details.length>0){
      customer.disbursement.disbursement_details.map((e)=>{
        totalAmount = Number(e.disbursed_payment_amount) + Number(totalAmount)
        console.log(e.disbursed_amount)
      })
    }
    if(customer && customer.escrow_details && customer.escrow_details.length>0){
      customer.escrow_details.map((e)=>{
        if(e.escrowToCustomer  ){

          totalAmount = Number(e.escrowToCustomer.disbursed_amount) + Number(totalAmount)
        }
      })
    }
  }


  const updateEscrowDetails = (e) => {
    setSpinner(true);
    e.preventDefault();

    // Update escrow details based on type
    const updatedEscrowDetails = [...customer.escrow_details];

    if (type === "kaabilToEscrow") {
      updatedEscrowDetails[idx].kaabilToEscrow = { ...values, payment_status: "paid" };
    } else if (type === "escrowToCustomer" && isEdit) {
      updatedEscrowDetails[idx].escrowToCustomer = { ...values, payment_status: "paid" };
    } else if (type === "escrowToCustomer" && !isEdit) {
      updatedEscrowDetails[idx].escrowToCustomer = { ...values, payment_status: "Pending" };
    }
    let deduction=customer.loan.disbursement_deduction;
    if(!deduction)
    {
         deduction=0
    } 
    calculate()
    const updateObject = {
      loan :{...customer.loan, disbursed_amount: (totalAmount+deduction),disbursed_payment_amount :totalAmount},
      escrow_details: updatedEscrowDetails,
    };

    loanService
      .updateDisbursement(customer._id, updateObject)
      .then(() => {
        alert("Details Added");
        window.location.reload();
        setSpinner(false);
      })
      .catch((err) => {
        console.error(err);
        setSpinner(false);
      });
  };

  const style = {
    modal: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "80%",
      maxWidth: "600px",
      backgroundColor: "#fff",
      border: "2px solid #000",
      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
      padding: "32px",
      borderRadius: "8px",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    },
    row: {
      display: "flex",
      justifyContent: "space-between",
      gap: "16px",
    },
    label: {
      marginBottom: "8px",
      fontWeight: "bold",
      fontSize: "14px",
      color: "#333",
    },
    input: {
      padding: "8px 12px",
      fontSize: "14px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      width: "100%",
    },
    button: {
      padding: "10px 20px",
      fontSize: "16px",
      fontWeight: "bold",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      marginTop: "16px",
      alignSelf: "flex-end",
    },
  };

  console.log('values are', values);

  return (
    <div>
      <Button onClick={handleOpen} variant="contained" color="primary">
        {button}
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style.modal}>
          <Typography variant="h6">
            <form style={style.form} onSubmit={updateEscrowDetails}>
              <div style={style.row}>
                <label style={style.label}>Kaabil Account Number</label>
                <input
                  style={style.input}
                  type="text"
                  name="kaabil_account_number"
                  required
                  value={values.kaabil_account_number}
                  onChange={handleChange}
                />
              </div>
              <div style={style.row}>
                <div>
                  <label style={style.label}>Kaabil Bank Name</label>
                  <input
                    style={style.input}
                    type="text"
                    name="kaabil_bank_name"
                    required
                    value={values.kaabil_bank_name}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label style={style.label}>Account Holder Name</label>
                  <input
                    style={style.input}
                    type="text"
                    name="account_holder_name"
                    value={values.account_holder_name}
                    disabled={isEdit}
                    required
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div style={style.row}>
                <div>
                  <label style={style.label}>Disburse Amount</label>
                  <input
                    style={style.input}
                    type="number"
                    name="disbursed_amount"
                    required
                    disabled={isEdit}
                    value={values.disbursed_amount}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label style={style.label}>Disburse Date</label>
                  <input
                    style={style.input}
                    type="date"
                    name="disbursed_date"
                    required
                    value={values.disbursed_date ? values.disbursed_date.split('T')[0] : ""}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div style={style.row}>
                <div>
                  <label style={style.label}>Escrow Amount</label>
                  <input
                    style={style.input}
                    type="text"
                    name="escrow_amount"
                    required
                    disabled={isEdit}
                    value={values.escrow_amount}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label style={style.label}>Co-lender Name</label>
                  <input
                    style={style.input}
                    type="text"
                    name="colender_name"
                    required={isEdit}
                    value={values.colender_name}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div style={style.row}>
                <div>
                  <label style={style.label}>Customer Bank Account</label>
                  <input
                    style={style.input}
                    type="text"
                    name="disbursed_account_number"
                    required
                    disabled={isEdit}
                    value={values.disbursed_account_number}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label style={style.label}>UTR Number</label>
                  <input
                    style={style.input}
                    type="text"
                    name="disbursed_utr_number"
                    required={isEdit}
                    value={values.disbursed_utr_number}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <button  disabled={spinner} style={style.button} type="submit">
              {  spinner &&  <span className="spinner-border spinner-border-sm"></span>}{isEdit ? "Update" : "Submit"}
              </button>
            </form>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default AddEscrowDetails;