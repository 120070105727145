import React, { useState, useEffect } from "react";
import { loanService } from "../../_services";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box, TextField, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { Chart } from "react-google-charts";
import { uploadFileToBucket } from "../../_helpers/upload";
import { v4 as uuidv4 } from 'uuid';
import { bulkUploadService } from '../../_services/bulkUpload.services';
import success from "../../assets/success.png";
import BulkUploadCollection from "../../assets/calling_case_assignment_sample.csv";
import { useHistory } from 'react-router-dom';

const AgentTracking = ({}) => {
  const history = useHistory();
  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [agentsDetails, setAgentsDetails] = useState([]);
  const [callingAnalyticsData, setCallingAnalyticsData] = useState([]);
  const [WeeklyCallingAnalyticsData, setWeeklyCallingAnalyticsData] = useState([]);
  const [analytics, setAnalytics] = useState(null);
  const [chartData, setChartData] = useState([
    ["Day", "Calls Made", "Successful Calls", "Call Duration"],
  ]);
  

  useEffect(() => {
    if (
      WeeklyCallingAnalyticsData.length > 0 &&
      WeeklyCallingAnalyticsData[0].display_data &&
      Array.isArray(WeeklyCallingAnalyticsData[0].display_data)
    ) {
      const data = WeeklyCallingAnalyticsData[0].display_data.map(item => [
        item.date || "N/A", // Default to "N/A" if date is missing
        item.total_make_call || 0, // Calls Made
        item.total_success_call || 0, // Successful Calls
        convertDurationToSeconds(item.total_call_duration || "0H:0M:0Sec"), // Call Duration in seconds
      ]);
      // Update the chart data
      setChartData(prevData => [...prevData.slice(0, 1), ...data]); // Keep headers, append new data
    }
  }, [WeeklyCallingAnalyticsData]);
  
  
  const convertDurationToSeconds = (duration) => {
    const parts = duration.match(/(\d+)H|(\d+)M|(\d+)Sec/g) || [];
    const [hours = 0, minutes = 0, seconds = 0] = parts.map(part =>
      parseInt(part.replace(/[^\d]/g, ""), 10) || 0
    );
    return hours+":"+ minutes +":"+ seconds;
  };
  

  const [selectedFile, setSelectedFile] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const random_uid = uuidv4();
  const bucket = process.env.REACT_APP_s3sblBucket;

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    let val = selectedFile.name.split('.').pop().toLowerCase();

    if (val === 'csv') {
      const link = await uploadFileToBucket(bucket, 'application/csv', `${bucket}/callingteamcaseassignment/${random_uid}.csv`, selectedFile);
      
      if (link) {
        bulkUploadService.callingTeamCaseAssignment({ link })
          .then((res) => {
            if (res && res.status === "success") {
              setIsSuccess(true);
              setShowMessage(res.message);
              setSelectedFile(null);
            } else {
              setShowMessage(res.message);
            }
          })
          .catch((err) => console.log("Error:", err));
      }
    } else {
      setShowError(true);
      setTimeout(() => setShowError(false), 3000);
    }
  };

  useEffect(() => {
    const CallingAgentsList = async () => {
      try {
        const response = await loanService.getCallingAgents();
        const agentsData = Array.isArray(response) ? response : [];
        setAgentsDetails(agentsData);
      } catch (error) {
        alert('An error occurred while fetching the data.');
      }
    };
    CallingAgentsList();

    const getCallingAnalyticsData = async () => {
      try {
        const analyticsResponse = await loanService.getCallingAnalyticsData();
        const analyticsData = Array.isArray(analyticsResponse) ? analyticsResponse : [];
        setCallingAnalyticsData(analyticsData);
      } catch (error) {
        alert('An error occurred while fetching the data.');
      }
    };
    getCallingAnalyticsData();

    const getWeeklyCallingAnalyticsData = async () => {
      try {
        const analyticsResponse = await loanService.getWeeklyCallingAnalyticsData();
        const analyticsData = Array.isArray(analyticsResponse) ? analyticsResponse : [];
        setWeeklyCallingAnalyticsData(analyticsData);
      } catch (error) {
        alert('An error occurred while fetching the data.');
      }
    };
    getWeeklyCallingAnalyticsData();

  }, []);

  
console.log("WeeklyCallingAnalyticsData", WeeklyCallingAnalyticsData);
  useEffect(() => {
    // Extract and parse display_data if available
    if (callingAnalyticsData && callingAnalyticsData.length > 0) {
      const data = callingAnalyticsData[0];  // Assuming we have at least one item
      const parsedDisplayData = JSON.parse(data.display_data);

      // Set the parsed data into state
      setAnalytics(parsedDisplayData);
    }
  }, [callingAnalyticsData])

  // Handle View Details for each agent
  const handleViewDetails = (agent) => {
    history.push({
      pathname: `/home/callingteamdashboard`,
      state: { agent } // Pass the 'agent' object to the new page
    });
  };

  
  console.log("callingAnalyticsData", callingAnalyticsData);
  return (
    <>
      <div style={{ position: 'relative' }}>
        {/* Button Aligned Top Left */}
        <Button 
          onClick={() => setOpenModal(true)} 
          color="primary" 
          variant="contained" 
          style={{ 
            position: 'absolute', 
            top: '10px', 
            right: '10px',  // Aligns the button to the top-right corner
            zIndex: 1000 
          }}
        >
          Upload CSV File
        </Button>

        {/* Modal for File Upload */}
        <Dialog open={openModal} onClose={() => setOpenModal(false)} maxWidth="sm" fullWidth>
          <DialogTitle style={{ backgroundColor: '#1976d2', color: 'white'}}>Upload CSV File</DialogTitle>
          <DialogContent>
            <Box style={{ textAlign: 'center', padding: '20px' }}>
              {showError && <Typography color="error">Please select a CSV file only.</Typography>}
              {isSuccess && (
                <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <img src={success} alt="Success" style={{ width: '30px', marginRight: '10px' }} />
                  <Typography color="success">{showMessage}</Typography>
                </Box>
              )}

              {/* File Upload Section */}
              <div style={{ border: '2px dashed #1976d2', padding: '20px', marginBottom: '20px', borderRadius: '10px' }}>
                <input
                  type="file"
                  accept=".csv"
                  style={{ width: '80%', padding: '10px' }}
                  onChange={handleFileChange}
                />
              </div>
              <Button
                color="primary"
                variant="contained"
                onClick={handleFileUpload}
                disabled={!selectedFile}
                style={{ marginBottom: '10px' }}
              >
                Upload
              </Button>
              <div>
                <a href={BulkUploadCollection} download style={{ display: 'block', textAlign: 'center', color: 'red', textDecoration: 'none' }}>
                  Download Sample File
                </a>
              </div>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenModal(false)} color="secondary">Cancel</Button>
          </DialogActions>
        </Dialog>

        {/* Agent Tracking Data Section */}
        <Box p={3} bgcolor="#f9f9f9">
      <Typography variant="h5" fontWeight="bold" mb={3}>Agent Tracking</Typography>

          {/* Summary Cards */}
          <Box display="flex" justifyContent="space-around" mt={4}>
            {analytics ? [
              { label: "Total Agents", value: analytics.total_agents_count },
              { label: "Total Made Calls", value: analytics.total_make_calls },
              { label: "Total Success Call", value: analytics.total_success_calls },
              { label: "Total Assigned Call", value: analytics.total_assign_cases },
              { label: "Total Call Duration", value: analytics.total_calls_duration },
            ].map((card, index) => (
              <Card key={index} style={{ minWidth: "18%", margin: "0 10px", backgroundColor: "#ffddc7" }}>
                <CardContent>
                  <Typography variant="h6">{card.label}</Typography>
                  <Typography variant="h5" fontWeight="bold">{card.value}</Typography>
                </CardContent>
              </Card>
            )) : (
              <Typography variant="h6" color="textSecondary">Loading...</Typography>
            )}
          </Box>
 

          {/* Agents Table */}
          <Box style={{marginTop: "10px"}}mt={4}>
            <Typography variant="h5" mb={2}>Agents Details</Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead style={{ backgroundColor: "#1976d2" }}>
                  <TableRow>
                    {["Agent Name", "Emp ID", "Branch ID", "Contact", "Manager Emp ID", "Actions"].map((header, index) => (
                      <TableCell key={index} style={{ color: "white", fontWeight: "bold" }}>
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {agentsDetails.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={6} align="center">No agents available</TableCell>
                    </TableRow>
                  ) : (
                    agentsDetails.map((agent) => (
                      <TableRow key={agent._id}>
                        <TableCell>{`${agent.first_name} ${agent.middle_name} ${agent.last_name}`.trim()}</TableCell>
                        <TableCell>{agent.emp_id}</TableCell>
                        <TableCell>{agent.branch_id}</TableCell>
                        <TableCell>{agent.contact}</TableCell>
                        <TableCell>{agent.manager_emp_id}</TableCell>
                        <TableCell>
                          <Button variant="outlined" onClick={() => handleViewDetails(agent)}>View Details</Button>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box style={{marginTop: "10px"}} mb={2}>
            <Typography variant="h5" mb={3}>Weekly Calling Analytics</Typography>
                {/* <TextField
                  label="Filter by Date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                /> */}
              </Box>
              <Chart
                chartType="Bar"
                data={chartData}
                width="100%"
                height="300px"
                options={{
                  hAxis: { title: "Days" },
                  vAxis: { title: "Calls Made" },
                  colors: ["#1976d2", "#ff7043", "#66bb6a"],
                  series: { 0: { lineDashStyle: [4, 4] } },
                }}
              />
            </Box>
        
      </div>
    </>
  );
};

export default AgentTracking;
