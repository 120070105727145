import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { receiptService } from "../../_services/receipt.service";
import { Print } from "@material-ui/icons";
import { Modal } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import datehelper from "../../_helpers/datehelper";
import { Input } from "antd";
const { Search } = Input;
export const Receipts = (props) => {
    const { path } = props.match;
    const [receipts, setReceipts] = useState([])
    const [open, setOpen] = useState(false)
    const [value, setValue]=useState('')
    const [receiptData, setReceiptData] = useState()

    const handleOpen = (receipt) => {
        setOpen(true)
        setReceiptData(receipt)
    }
    const handleCLose = () => {
        setOpen(false)
        setReceiptData()
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        height: 300,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        //overflow: 'scroll',
        //paddingBottom: '30px',
    };
    
    const handleClick =(e)=>{
        e.preventDefault()
        props.history.push(`/home/printreceipt`, {receipt:{...receiptData,value}})
    }


    const handleSearch = (search) => {
            receiptService.getAllByAccount({'loan_account':search}).then((p)=> {
             console.log(p);
             setReceipts(p);
            })
            .catch((err)=> {
              console.log(err);
            })
    }

    const openQRPayments = ()=> {
        props.history.push(`/home/virtualaccounts/payments`, {})
    }


    const openCashPayments = ()=> {
        props.history.push(`/home/payments`, {})
    }
        
    return (
        <div>
            <Modal
                open={open}
                onClose={handleCLose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <form onSubmit={(e)=>handleClick(e)} >
                            <label style={{
                                display: 'flex',
                                flexDirection: 'column',
                                margin: '20px'
                            }} > For
                                <select style={{
                                    width: '200px',
                                    height: '30px',
                                    borderRadius: '5px',
                                    border: '1px solid grey'
                                }} onChange={(e)=>setValue(e.target.value)} required>
                                    <option value=""></option>
                                    <option value="EMI">EMI</option>
                                    <option value="Loan Charges">Loan Charges</option>
                                    <option value="Login Fee">Login Fee</option>
                                </select>
                            </label>
                            <button  style={{ color: "white", margin: '20px', backgroundColor: '#0066cc', borderRadius: "5px", padding: "5px", border: 'none', width: '150px' }}  >Submit</button>
                        </form>
                    </Typography>
                </Box>
            </Modal>
            <h1>Payment Receipts</h1> 
            <br/>
            <Search
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="Loan Account"
                enterButton
                style={{
                    position: "sticky",
                    top: "0",
                    left: "0",
                    width: "250px",
                    marginTop: "2vh",
                    marginRight: "20px",
                }}
            />
                <button style={{  marginLeft: "30px", border: "none", color: "white", backgroundColor: "green", borderRadius: "5px", padding: "6px", textStyle: "none" }} onClick={()=> openQRPayments()} >Generate QR Receipt</button>
                <button style={{  marginLeft: "30px", border: "none", color: "white", backgroundColor: "red", borderRadius: "5px", padding: "6px", textStyle: "none" }} onClick={()=> openCashPayments()} >Generate CASH Receipt</button>
    
            <hr />
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th style={{ width: "20%" }}>Loan Number</th>
                        <th style={{ width: "15%" }}>Date</th>
                        <th style={{ width: "15%" }}>Customer Name</th>
                        <th style={{ width: "15%" }}>Receipt Number</th>
                        <th style={{ width: "15%" }}>Amount</th>
                        <th style={{ width: "10%" }}>Mode</th>
                        <th style={{ width: "10%" }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        receipts && receipts.map(receipt =>
                            <React.Fragment key={receipt._id}>
                                <tr>
                                    <td>{receipt.loan_no}</td>
                                    <td>{datehelper.displayDate(new Date(receipt.date))}</td>
                                    <td> {receipt.cust_name}</td>
                                    <td> {receipt.receipt_no}</td>
                                    <td> {receipt.payment}</td>
                                    <td> {receipt.mode}</td>
                                    <td> <button variant="outlined" aria-label="increase" onClick={() => handleOpen(receipt)} >
                                        <Print fontSize="small" />
                                    </button></td>
                                </tr>
                            </React.Fragment>)
                    }
                </tbody>
            </table>
            {/* {
                console.log(receiptData)
            } */}
        </div>
    )

}
