import { Box, Button } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Table, Th, Thead, Td, Tr, Tbody } from 'react-super-responsive-table'
import { CreateDepositSlipModal } from './CreateDepositSlipModal'
import { collectionService, employeeService } from '../../../_services'
import datehelper from '../../../_helpers/datehelper'
import { history } from '../../../_helpers'
import { DepositConfirmModal } from './DepositConfirmModal'
import urlhelper from '../../../_helpers/urlhelper'
import roleChecker from '../../../_helpers/rolechecker'
import VerifyDepositModal from './VerifyDepositModal'
import { CircularProgress } from '@material-ui/core'
export const DepositSlipDashboard = () => {
  const user=employeeService.userValue
  const [depositSlip, setDepositSlip] = useState([])
  const [employeeList, setEmployeeList] = useState({})
  const getDepositSlip = async () => {
    await collectionService.getDepositSlip()
      .then((res) => {
        setDepositSlip(res)
      })
      .catch((err) => console.log(err))
  }
  async function getAllEmployees() {
    await employeeService.getAll()
      .then((res) => {
        let list = {};
        res.map(e => {
          list[e.emp_id] = e.first_name + ' ' + e.last_name
        })
        setEmployeeList(list)
      })
  }
  useEffect(() => {
    getDepositSlip();
    getAllEmployees();
  }, [])

  console.log("tes slip", depositSlip)
  return (
    <div>
      <div style={{ margin: "100px" }}>
        <div>
          <h4 style={{ display: 'flex', float: 'left', color: 'black', fontWeight: 'bold', marginBottom: '40px' }}>Dashboard</h4>
          <CreateDepositSlipModal />
        </div>
        <Table>
          <Thead>
            <Tr style={{fontSize:"18px"}}>
              <Th>Sr.No</Th>
              <Th>Created Date</Th>
              <Th>Amount</Th>
              <Th>Created By</Th>
              <Th>Status</Th>
              <Th colSpan="2" style={{ textAlign: "center" }}>Action</Th>
              <Th>View</Th>
              <Th>Verify By</Th>
            </Tr>
          </Thead>
          <Tbody>
            {depositSlip.length > 0 ? depositSlip.filter(slip =>
              (slip.branch_id === user.branch_id) || (user.branch_id === "HO001")).map((x, idx) => <Tr key={idx} style={{fontSize:"18px"}}>
                <Td>{idx + 1}</Td>
                <Td>{datehelper.displayDate(new Date(x.createdAt))}</Td>
                <Td>{x.total_payment}</Td>
                <Td>{employeeList[x.created_by]}</Td>
                {x.branch_name? <Td style={{color:'green',fontSize:"18px"}}>PAID</Td>:<Td style={{fontSize:"18px",color:"red"}}>PENDING</Td>}
                <Td style={{ textAlign: "center" }}>
                  <Button onClick={() => (history.push("generatedepositslip", x))}
                    style={{ width: '90px', height: "27px", background: "blue", marginTop: '10px', color: "white" }}>View</Button>
                </Td>
                {roleChecker.isBMLevelAccess(user.role) && (!x.branch_name)?<Td style={{ textAlign: "center" }}>
                  <DepositConfirmModal data={x} /></Td>:<Td></Td>}
                {/* {roleChecker.hasAccountsAccess(user.role)&&x.status !=="Verified"?<Td>
                  <VerifyDepositModal depositSlip={x}/>
                  </Td>:<Td></Td>} */}
                <Td>{x.slip_photo && <Button onClick={() => window.open(`/home/pdfviewer?pdf_url=` + urlhelper.encodeToBase64(x.slip_photo) + "&download=1","_blank")}
                  style={{ color: "blue" }}
                >
                  Bank Proof </Button>}</Td>
                  <Td>{x.status ==="Verified"?employeeList[x.verify_by]:(x.branch_name && (roleChecker.hasAccountsAccess(user.role))&&<VerifyDepositModal depositSlip={x}/>)}</Td>
              </Tr>) :
              <Tr>
                <Td colSpan={9} style={{ textAlign: 'center', fontSize: "20px" }}>
                  {/* Data Not Found */} 
                  {<div style={{ display: 'flex', justifyContent: 'space-around' }}><CircularProgress /></div>}
                </Td>
              </Tr>
            }
          </Tbody>
        </Table>
      </div>
    </div>
  )
}

