import { fetchWrapper } from "../_helpers";

const apiUrl = process.env.REACT_APP_API_URL;
const baseUrl = `${apiUrl}/scandocumentacc`;

export const scanDocumentaccService = {
  upload,
  getDocuments,
  searchData,
  getDocumentCounts, // Added the new function to fetch counts
};

function upload(payload) {
  return fetchWrapper.post(`${baseUrl}/upload`, payload);
}

function getDocuments() {
  return fetchWrapper.get(`${baseUrl}/documents`);
}

function searchData(searchString) {
  return fetchWrapper.post(`${baseUrl}/searchData`, { searchString });
}

// New function to get document counts
function getDocumentCounts() {
  return fetchWrapper.get(`${baseUrl}/documentCounts`);
}
