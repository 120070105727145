import React from "react";
import { useState, useEffect } from "react";
import roleChecker from "../../_helpers/rolechecker";
import { employeeService, misService } from "../../_services";
import { BarChart, PieChart, Pie, Cell, Bar, CartesianGrid, XAxis, YAxis, Legend, Tooltip, LabelList, ResponsiveContainer, Label } from "recharts";
import { reportsService } from "../../_services/reports.service";
import Product from "../../_helpers/product";
import { loanService } from "../../_services";
import { Button } from "@material-ui/core";
import { history } from "../../_helpers";
import { Paper } from "@material-ui/core";
import { makerCheckerServices } from "../../_services/makerchecker.service";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import CourtCaseServices from "../../_services/courtcase.service";
import datehelper from "../../_helpers/datehelper";
import { ledgerService } from "../../_services/ledger.service";
import TableModal from "./TableModal";

function SBLDashboard(){
  const user = employeeService.userValue;
  const [assignedFiles, setAssignedFiles] = useState({})
  const [chartData, setChartData] = useState({})
  const [loginChartType, setLoginChartType] = useState('login_weekly_count')
  const [sanctionChartType, setSanctionChartType] = useState('sanction_letter_weekly_count')
  const [disburseChartType, setDisburseChartType] = useState('disburse_weekly_amount')
  const [colenderChartType, setColenderChartType] = React.useState('colender_weekly_count')
  const [bucketingChartType, setBucketingChartType] = useState('current_month_bucket_plot');
  const [loading,setLoading]=useState(true);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen3, setIsModalOpen3] = useState(false);
 const [notRegisteredCount, setNotRegisteredCount] = useState("");
  const [balanceInsufficientCount, setBalanceInsufficientCount] = useState("");
  const [discontinuedCount, setDiscontinuedCount] = useState("")

  let colors = ['#3C2A21', '#6D67E4', '#5D8AA8', '#46C2CB', '#800000']
  let colors_2 = ['#58508D	', ' #AADEA7	', '#64C2A6', ' #2D87BB']
  let stages = ['Credit', 'Sanction', 'Operation', 'Disbursal', 'Suspense']
  let branchId='';
  let branchIds='';



  const LoginHandleClick = (event) => {
    console.log('Button was clicked!');
    setLoginChartType(event)
    console.log(event)
  };
    const SanctionHandleClick = (event) => {
      console.log('Button was clicked!');
      setSanctionChartType(event)
      console.log(event)
  
  
    };
    const DisburseHandleClick = (event) => {
      console.log('Button was clicked!');
      setDisburseChartType(event)
      console.log(event)
  
  
    };
  
    const ColenderHandleClick = (event) => {
      console.log('Button was clicked!');
      setColenderChartType(event)
      // console.log(event)
  
  
    };
    const percentageLabelFormatter = (value) => {
      return value + '%';
    };
    const BucketingHandleClick = (event) => {
      console.log('Button was clicked!');
      setBucketingChartType(event)
      console.log(event)
  
  
    };  
    const MyFiles = () => {
      loanService.getAssignedFiles()
        .then(res => setAssignedFiles(res))
        .catch(err => console.log(err))
  
    };
    
    
    useEffect(() => {
      loadCharts()
      MyFiles()
  }, [])
  async function loadCharts() {
    setLoading(true);
    if (roleChecker.isHOLevelAccess(user.role) || roleChecker.isBMLevelAccess(user.role) || roleChecker.isASMLevelAccess(user.role) || roleChecker.isCCMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isBCMLevelAccess(user.role) || roleChecker.isCSMLevelAccess(user.role) || roleChecker.isRSMLevelAccess(user.role) || roleChecker.isZSMLevelAccess(user.role)) {
      if(roleChecker.isZSMLevelAccess(user.role)){
        console.log('User role ---->>> ', user.role, user.branch_id)
        branchId = 'zone-' + user.branch_id
      }
      else if (roleChecker.isRSMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role)) {
        console.log('User role ---->>> ', user.role, user.branch_id)
        branchId = 'region-' + user.branch_id
      }
      else if (roleChecker.isAreaLevelAccess(user.role)) {
        console.log('User role ---->>> ', user.role, user.branch_id)
        branchId = 'area-' + user.branch_id
      }
      else if (roleChecker.isCSMLevelAccess(user.role) || roleChecker.isCCMLevelAccess(user.role)) {
        console.log('User role ---->>> ', user.role, user.branch_id)
        branchId = 'cluster-' + user.branch_id
      }
      else if (roleChecker.isZSMLevelAccess(user.role) ) {
        console.log('User role ---->>> ', user.role, user.branch_id)
        branchId = 'cluster-' + user.branch_id
      }
      else if (roleChecker.isBMLevelAccess(user.role) ) {
        console.log('User role ---->>> ', user.role, user.branch_id)
        branchId = 'branch-' + user.branch_id
      }
      else {
        branchId = user.branch_id
         console.log('User role ---->>> ', user.role, user.branch_id, branchId)
      }

      reportsService.getChartsData({ 'chart_key': "all", 'branch_key': branchId }).
        then((chartListObj) => {
          chartListObj.map((chartDataObj) => {
            console.log('BKey ---->>>', chartDataObj.branch_key, branchId)

            if (chartDataObj.branch_key === branchId) {
              let dataArray = JSON.parse(chartDataObj.display_data);

              let data = [];
              Object.keys(dataArray).map((dataKey) => {
                let dataItem = dataArray[dataKey];

                console.log('dataitem-----------', dataItem)


                if (chartDataObj.key == 'Loan_status_pie_chart' || chartDataObj.key == 'nachs_pie_chart' || chartDataObj.key == 'gold_colender_loans_pie') {
                  data.push({
                    'name': dataItem['name'],
                    'value': dataItem['value'],

                  })
                }
                else if (chartDataObj.key == 'login_weekly_count' || chartDataObj.key == 'sanction_letter_weekly_count' || chartDataObj.key == 'disburse_weekly_count' || chartDataObj.key == 'colender_weekly_count') {
                  console.log('inside login count data')
                  data.push({
                    'display_x': dataItem['display_x'],
                    'Current Month': dataItem['curr_month'],
                    'Previous Month': dataItem['prev_month'],

                  })
                }
                else if (chartDataObj.key == 'current_month_bucket_plot' || chartDataObj.key == 'previous_month_bucket_plot') {
                  data.push({
                    'display_x': dataItem['bucket'],
                    'Collected': dataItem['completed'],
                    'Total': dataItem['total']
                  })
                }
                else if (chartDataObj.key == 'gold_amount_monthly') {
                  data.push({
                    'display_x': dataItem['display_x'],
                    'amount': dataItem['amount'],

                  })
                }
                else if (chartDataObj.key == 'bucket_x_monthly') {
                  data.push({
                    'display_x': dataItem['display_x'],
                    'outstanding_amount': dataItem['outstanding_amount'],
                    'collected_amount': dataItem['collected_amount'],

                  })
                }
                else if (chartDataObj.key == 'disburse_daily_amount' || chartDataObj.key == 'sanction_letter_daily_amount' || chartDataObj.key == "disburse_month_amount"
                || chartDataObj.key == 'colender_daily_amount') {
                  data.push({
                    'display_x': dataItem['display_x'],
                    'display_y': dataItem['display_y'],
                    'amount': dataItem['amount'],
                    'count': dataItem['count'],
                  }
                  )
                }
                else if (chartDataObj.key == 'sanction_vs_colending_data') {
                  data.push({
                    'display_x': dataItem['display_x'],
                    'Sanction Count': dataItem['sanc_count'],
                    "Colender's Sanction Count": dataItem['col_count'],

                  })
                }
                else {
                  data.push({
                    'name': dataItem['display_x'],
                    'value': dataItem['display_y'],
                    'count': dataItem['count']
                  })
                }

              })
              // console.log('chartdata -->> ',dataItem)
              console.log('createing chartdataobj key')

              chartData[chartDataObj.key] = data;
            }
          })
          console.log('the value of sbl chartdata is',chartData);
          setChartData(chartData);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        })
    }
  }
  useEffect(() => {
    loadCharts()
    getNotRegisteredenachcount()
    getBalanceInsufficientcount()
    getDiscontinuedcount()
  }, [user])
  
   console.log('hii this is a testing for successfully build')
   console.log('I am testing again')
  const getNotRegisteredenachcount = () => {
    if (roleChecker.isASMLevelAccess(user.role) ||  ["ACM","ACCM","RSM","RCM","RCCM","ZCCM"].includes(user.role) || roleChecker.isZSMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isCollectionManager(user.role)||["BM"].includes(user.role)|| ["BCM"].includes(user.role)) {
      if (roleChecker.isASMLevelAccess(user.role)|| ["ACM","ACCM"].includes(user.role)) {
        branchIds =  {area_id : user.branch_id}
       }
       else if (roleChecker.isZSMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role)|| ["ZCCM"].includes(user.role)) {
        branchIds =  { zone_id: user.branch_id}
       } 
       else if (["RSM","RCM","RCCM"].includes(user.role)) {
        branchIds = { region_id: user.branch_id}
       }else if (["BM"].includes(user.role)|| ["BCM"].includes(user.role)) {
        branchIds =  { branch_id: user.branch_id}
      } 
       else {
          console.log('User role ---->>> ')
       }
    
      reportsService
      .getNotregisterdatacount({ role:user.role, enach_status:"Not Registered",...branchIds})
        .then((response) => {
          setNotRegisteredCount(response);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if ((["CEO","CFO","Admin","HOCL","PIVG"].includes(user.role)||roleChecker.isCollectionManager(user.role) )){
      reportsService
        .getNotregisterdatacount({enach_status: "Not Registered"})
        .then((response) => {
          console.log(response.totalcount,"response")
          setNotRegisteredCount(response);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    else {
      console.log("data not found")
    }
  };
  const getBalanceInsufficientcount = () => {
    if (roleChecker.isASMLevelAccess(user.role) ||  ["ACM","ACCM","RSM","RCM","RCCM","ZCCM"].includes(user.role) || roleChecker.isZSMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isCollectionManager(user.role)||["BM"].includes(user.role)|| ["BCM"].includes(user.role)) {
      if (roleChecker.isASMLevelAccess(user.role)|| ["ACM","ACCM"].includes(user.role)) {
        branchIds =  {area_id : user.branch_id}
       }
       else if (roleChecker.isZSMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role)|| ["ZCCM"].includes(user.role)) {
        branchIds =  { zone_id: user.branch_id}
       } 
       else if (["RSM","RCM","RCCM"].includes(user.role)) {
        branchIds = { region_id: user.branch_id}
       }else if (["BM"].includes(user.role)|| ["BCM"].includes(user.role)) {
        branchIds =  { branch_id: user.branch_id}
      } 
       else {
          console.log('User role ---->>> ')
       }
    
      reportsService
      .getNotregisterdatacount({ role:user.role, enach_status:"Balance Insufficient",...branchIds})
        .then((response) => {
          setBalanceInsufficientCount(response);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if ((["CEO","CFO","Admin","HOCL","PIVG"].includes(user.role)||roleChecker.isCollectionManager(user.role) )){
      reportsService
        .getNotregisterdatacount({enach_status: "Balance Insufficient"})
        .then((response) => {
          console.log(response,"response")
          setBalanceInsufficientCount(response);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    else {
      console.log("data not found")
    }
  };
  const getDiscontinuedcount = () => {
    if (roleChecker.isASMLevelAccess(user.role) ||  ["ACM","ACCM","RSM","RCM","RCCM","ZCCM"].includes(user.role) || roleChecker.isZSMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isCollectionManager(user.role)||["BM"].includes(user.role)|| ["BCM"].includes(user.role)) {
      if (roleChecker.isASMLevelAccess(user.role)|| ["ACM","ACCM"].includes(user.role)) {
        branchIds =  {area_id : user.branch_id}
       }
       else if (roleChecker.isZSMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role)|| ["ZCCM"].includes(user.role)) {
        branchIds =  { zone_id: user.branch_id}
       } 
       else if (["RSM","RCM","RCCM"].includes(user.role)) {
        branchIds = { region_id: user.branch_id}
       }else if (roleChecker.isBMLevelAccess(user.role)  || roleChecker.isBCMLevelAccess(user.role)) {
        branchIds =  { branch_id: user.branch_id}
      } 
       else {
          console.log('User role ---->>> ')
       }
    
      reportsService
      .getdiscontinuedcount({ role:user.role, discontinued:true,...branchIds})
        .then((response) => {
          console.log(response,"responseddddddd")
          setDiscontinuedCount(response);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if ((["CEO","CFO","Admin","HOCL","PIVG"].includes(user.role)||roleChecker.isCollectionManager(user.role) )){
      reportsService
        .getdiscontinuedcount({ discontinued:true})
        .then((response) => {
          console.log(response,"responseddddddd")
          setDiscontinuedCount(response);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    else {
      console.log("data not found")
    }
  };
        return  <>
               {assignedFiles && assignedFiles.total_files && assignedFiles.total_files > 0  ?
            <Paper style={{ width: '610px', borderRadius: '10px', margin: '10px', padding: '20px' }}>
              <h3>Assigned Loan Files</h3>
              <hr style={{ width: '100%' }} />
              <div style={{ display: 'flex' }}>
                {
                  stages.map((stage, idx) => {
                    if (assignedFiles[stage] && assignedFiles[stage] > 0) {
                      return <div
                        style={{ width: '100px', height: '100px', backgroundColor: colors[idx], borderRadius: '10px', color: 'white', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around' }}>
                        <b>{stage}</b>
                        {assignedFiles[stage]}
                        <Button size="small" style={{ color: 'white', marginLeft: "25px" }} onClick={() => history.push('/home/sblloans/mytaskslist')}>...More</Button>
                      </div>
                    }
                  })
                }
              </div>
            </Paper> :
            null}
             {(roleChecker.isCFOAccess(user.role)||roleChecker.isCollectionManager(user.role) ||roleChecker.isASMLevelAccess(user.role) ||  ["ACM","ACCM","RSM","RCM","RCCM","ZCCM","HOCL","PIVG"].includes(user.role) || roleChecker.isZSMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role)||roleChecker.isBMLevelAccess(user.role) || roleChecker.isBCMLevelAccess(user.role)) &&(
                        <Paper style={{ width: '600px', borderRadius: '10px', margin: '10px', padding: '20px' }}>
                  <h3>ENACH Analytics</h3>
                  <hr style={{ width: '100%' }} />
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div style={{ width: '160px', height: '150px', backgroundColor: '#e3f2fd', borderRadius: '10px', color: 'white', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '10px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', }} >
                        <b style={{color:"#1565c0"}}>Not Registered</b> 
                        <h2>{notRegisteredCount && notRegisteredCount.totalcount}</h2>
                        <Button
                          size="small"
                          style={{ color: '#1565c0',textTransform: 'none' }}
                          onClick={() => history.push('/home/sblloans/NotRegistered')}
                        >
                          View Details
                        </Button>
                      </div>
                      <div style={{ width: '160px', height: '150px',backgroundColor: '#FFDBBB',  borderRadius: '10px', color: 'white', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '10px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', }} >
                        <b style={{color:"#ff9913"}}>Balance Insufficient</b> 
                        <h2 >{balanceInsufficientCount && balanceInsufficientCount.totalcount}</h2> 
                        <Button
                          size="small"
                          style={{ color:"#ff9913", textTransform: 'none' }}
                          onClick={() => {
                            history.push(`/home/sblloans/BalanceInsufficient/${false}`); // Redirect to Balance Insufficient page
                          }}
                        >
                          View Details
                        </Button>
                      </div>
                      <div style={{ width: '160px', height: '150px', backgroundColor: '#ffebee', borderRadius: '10px', color: 'white', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '10px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', }} >
                        <b style={{color:"#d32f2f"}}>Discontinued</b> 
                        <h2 >{discontinuedCount && discontinuedCount.totalcount}</h2> 
                        <Button
                          size="small"
                          style={{ color:"#d32f2f", textTransform: 'none' }}
                          
                          onClick={() => {
                            history.push(`/home/sblloans/BalanceInsufficient/${true}`); // Redirect to Balance Insufficient page
                          }}
                        >
                          View Details
                        </Button>
                      </div>
                  </div>
                </Paper>
                 )} 
          {(roleChecker.isHOLevelAccess(user.role) || roleChecker.isBMLevelAccess(user.role) || roleChecker.isASMLevelAccess(user.role) || roleChecker.isCCMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isBCMLevelAccess(user.role) || roleChecker.isCSMLevelAccess(user.role) || roleChecker.isRSMLevelAccess(user.role) || roleChecker.isZSMLevelAccess(user.role) || roleChecker.isPIVGAccess(user.role))   && (
         <div>
            <hr style={{ width: '100%' }} />
            <h2>Analytics</h2><br />
          {loading? <p>Loading</p>:<>
            <div class="row">
              <div class="col-md-6">
                {(roleChecker.isGMLevelAccess(user.role) || roleChecker.isVPCLevelAccess(user.role) || roleChecker.isBMLevelAccess(user.role) || roleChecker.isAreaLevelAccess(user.role) || roleChecker.isCCMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isBCMLevelAccess(user.role) || roleChecker.isCSMLevelAccess(user.role) || roleChecker.isRSMLevelAccess(user.role) || roleChecker.isZSMLevelAccess(user.role) || roleChecker.isHOSalesApproverAccess(user.role) || roleChecker.isZSMLevelAccess(user.role) || roleChecker.isPIVGAccess(user.role)) && chartData && chartData.login_month_count && (
                  <>{ console.log('the value of sbl chartdata is',chartData)}
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <b>Login Count </b>
                      {(roleChecker.isGMLevelAccess(user.role) || roleChecker.isVPCLevelAccess(user.role) || roleChecker.isPIVGAccess(user.role) || roleChecker.isZSMLevelAccess(user.role)) && (
                          <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          style={{ marginLeft: "20px", marginBottom: "10px" }}
                          value={loginChartType}
                          onChange={(e) => LoginHandleClick(e.target.value)}
                          >
                          <MenuItem value={'login_weekly_count'}>Weekly</MenuItem>
                          <MenuItem value={'login_month_count'}>Monthly</MenuItem>
                          <MenuItem value={'login_daily_count'}>Daily</MenuItem>

                          </Select>
                      )}
                      {(roleChecker.isGMLevelAccess(user.role) || roleChecker.isVPCLevelAccess(user.role) || roleChecker.isPIVGAccess(user.role) || roleChecker.isZSMLevelAccess(user.role) ) && (

                            <button 
                            style={{
                              marginLeft: "210px",
                              marginBottom: '10px',
                              padding: "5px 10px",
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                              background: "#f0f0f0"
                            }}
                            onClick={()=>setIsModalOpen1(true) }

                            >
                            View Tables
                            </button>
                      )}

                    </div>
                   
                    {loginChartType === 'login_month_count' ?

                      <ResponsiveContainer width="90%" height={250}>
                        <BarChart data={chartData['login_month_count']}>
                        
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis type="number" domain={[0, 'dataMax']} />
                          <Tooltip />
                          <Legend />
                          <Bar isAnimationActive={false} dataKey="value" fill="#40e0d0"  onClick={()=>setIsModalOpen1(true)} barSize={30}> 
                            <LabelList dataKey="value"  position="inside" fill="#000000" style={{ fontSize: '10px' }}  />
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>

                      :
                      (
                        loginChartType === 'login_daily_count'? 
                          <BarChart width={490} height={250} data={chartData['login_daily_count']}>
                            <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey="name" />
                              <YAxis type="number" domain={[0, 'dataMax']} />
                              <Tooltip />
                              <Legend />
                              <Bar isAnimationActive={false} dataKey="count" fill="#40e0d0" >
                                  <LabelList dataKey="count"  position="inside" fill="#000000" style={{ fontSize: '10px' }}  barSize={35}/>
                              </Bar>
                          </BarChart>
                        :
                        <BarChart width={490} height={250} data={chartData['login_weekly_count']}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="display_x" />
                        <YAxis type="number" domain={[0, 'dataMax']} />
                        <Tooltip />
                        <Legend />
                        <Bar isAnimationActive={false} dataKey="Previous Month" fill="#FA803A" >
                          <LabelList dataKey="Previous Month" position="inside" fill="#000000" style={{ fontSize: '10px' }} barSize={35} />
                        </Bar>
                        <Bar isAnimationActive={false} dataKey="Current Month" fill="#40e0d0" >
                          <LabelList dataKey="Current Month"  position="inside" fill="#000000" style={{ fontSize: '10px' }}  barSize={35}/>
                        </Bar>

                      </BarChart>                      )


                    }

                    {console.log(branchId, "here check")}
                    {(roleChecker.isGMLevelAccess(user.role) || roleChecker.isVPCLevelAccess(user.role) || roleChecker.isPIVGAccess(user.role) || roleChecker.isZSMLevelAccess(user.role) || roleChecker.isBMLevelAccess(user.role)) && (
                      <TableModal isModalOpen={isModalOpen1} setIsModalOpen={setIsModalOpen1} chartType={"login_month_count"} />
                    )}

                   

    
                  </>
                )}
              </div>



              <div class="col-md-6">
                {(roleChecker.isHOCreditTeamAccess(user.role) || roleChecker.isHOSalesApproverAccess(user.role) || roleChecker.isGMLevelAccess(user.role) || roleChecker.isVPCLevelAccess(user.role) || roleChecker.isBMLevelAccess(user.role) || roleChecker.isAreaLevelAccess(user.role) || roleChecker.isCCMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isBCMLevelAccess(user.role) || roleChecker.isCSMLevelAccess(user.role) || roleChecker.isRSMLevelAccess(user.role) || roleChecker.isZSMLevelAccess(user.role) || roleChecker.isPIVGAccess(user.role) || roleChecker.isBMLevelAccess(user.role)) && chartData && chartData.sanction_letter_month_amount && (
                  <>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <b>Sanction Letter Count</b>
                      {(roleChecker.isGMLevelAccess(user.role) || roleChecker.isVPCLevelAccess(user.role) || roleChecker.isPIVGAccess(user.role) || roleChecker.isZSMLevelAccess(user.role)) && (
                          <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          style={{ marginLeft: "20px", marginBottom: "10px" }}
                          value={sanctionChartType}
                          onChange={(e) => SanctionHandleClick(e.target.value)}
                          >
                          <MenuItem value={'sanction_letter_weekly_count'}>Weekly</MenuItem>
                          <MenuItem value={'sanction_letter_month_amount'}>Monthly</MenuItem>
                          <MenuItem value={'sanction_letter_daily_amount'}>Daily</MenuItem>
                          </Select>
                      )}
                      {(roleChecker.isGMLevelAccess(user.role)|| roleChecker.isVPCLevelAccess(user.role) || roleChecker.isPIVGAccess(user.role) || roleChecker.isZSMLevelAccess(user.role)) && (
                        <button 
                          style={{
                            marginLeft: "140px",
                            marginBottom: '10px',
                            padding: "5px 10px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            background: "#f0f0f0"
                          }}
                          onClick={()=>setIsModalOpen2(true) }
                          
                        >
                          View Tables

                        </button>
                        )}

  
                    </div>

                    {sanctionChartType === 'sanction_letter_month_amount' ?
                      <ResponsiveContainer width="90%" height={250}>
                        <BarChart data={chartData["sanction_letter_month_amount"]}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis type="number" domain={[0, 'dataMax']} />
                          <Tooltip />
                          <Legend />
                          <Bar isAnimationActive={false} dataKey="value" fill="#40e0d0" onClick={()=>setIsModalOpen2(true)} barSize={30}>
                            <LabelList dataKey="value" position="inside" fill="#000000" style={{ fontSize: '10px' }} />


                          </Bar>
                          <Bar isAnimationActive={false} dataKey="count" fill="#8884d8" onClick={()=>setIsModalOpen2(true)} barSize={30}>
                            <LabelList dataKey="count" position="inside" fill="#000000" style={{ fontSize: '10px' }}/>
                          </Bar>
                        </BarChart>

                      </ResponsiveContainer>
                      :
                      (sanctionChartType === 'sanction_letter_daily_amount' ?
                        <BarChart width={490} height={250} data={chartData['sanction_letter_daily_amount']}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="display_x" />
                        <YAxis type="number" domain={[0, 'dataMax']} />
                        <Tooltip />
                        <Legend />
                        <Bar isAnimationActive={false} dataKey="amount" fill="#40e0d0" onClick={() => setIsModalOpen3(true)} barSize={30}>
                            <LabelList dataKey="amount" position="inside" fill="#000000" style={{ fontSize: '10px' }} />
                          </Bar>
                          <Bar isAnimationActive={false} dataKey="count" fill="#8884d8" onClick={() => setIsModalOpen3(true)} barSize={30}>
                            <LabelList dataKey="count" position="inside" fill="#000000" style={{ fontSize: '10px' }} />
                          </Bar>
                        </BarChart>
                        :
                        <BarChart width={490} height={250} data={chartData['sanction_letter_weekly_count']}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="display_x" />
                        <YAxis type="number" domain={[0, 'dataMax']} />
                        <Tooltip />
                        <Legend />
                        <Bar isAnimationActive={false} dataKey="Previous Month" fill="#FA803A" barSize={35}>
                          <LabelList dataKey="Previous Month" position="inside" fill="#000000" style={{ fontSize: '10px' }}/>
                        </Bar>
                        <Bar isAnimationActive={false} dataKey="Current Month" fill="#40e0d0" barSize={35}>
                          <LabelList dataKey="Current Month" position="inside" fill="#000000" style={{ fontSize: '10px' }} />
                        </Bar>

                      </BarChart>
                      )

                    }
                    {(roleChecker.isGMLevelAccess(user.role) || roleChecker.isVPCLevelAccess(user.role) || roleChecker.isPIVGAccess(user.role) || roleChecker.isZSMLevelAccess(user.role) || roleChecker.isBMLevelAccess(user.role)) && (
                      <TableModal isModalOpen={isModalOpen2} setIsModalOpen={setIsModalOpen2} chartType={"sanction_letter_month_amount"}/>      
                        )}
                  </>
                )}
              </div>
            </div>
            <br />
            <div class="row">


           



            <div class="col-md-6">

{(roleChecker.isColendingAccess(user.role) || roleChecker.isGMLevelAccess(user.role) || roleChecker.isVPCLevelAccess(user.role)) && chartData && chartData.colender_month_amount && (
  <>
    <div style={{ display: "flex", flexDirection: "row" }}>
      <b>Colender Disbursement Count</b>
      {(roleChecker.isGMLevelAccess(user.role)|| roleChecker.isVPCLevelAccess(user.role)) && (
      
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        style={{ marginLeft: "20px", marginBottom: "10px" }}
        value={colenderChartType}
        onChange={(e) => ColenderHandleClick(e.target.value)}
      >
        <MenuItem value={'colender_weekly_count'}>Weekly</MenuItem>
        <MenuItem value={'colender_month_amount'}>Monthly</MenuItem>
        <MenuItem value={'colender_daily_amount'}>Daily</MenuItem>
      </Select>
      )}
    </div>

    {colenderChartType === 'colender_month_amount' ?
      <ResponsiveContainer width="90%" height={250}>
        <BarChart data={chartData["colender_month_amount"]}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis type="number" domain={[0, 'dataMax']} />
          <Tooltip />
          <Legend />
          <Bar isAnimationActive={false} dataKey="value" fill="#40e0d0" barSize={30}>
            <LabelList dataKey="value" position="inside" fill="#000000" style={{ fontSize: '10px' }}/>


          </Bar>
          <Bar isAnimationActive={false} dataKey="count" fill="#8884d8" barSize={30}>
            <LabelList dataKey="count" position="inside" fill="#000000" style={{ fontSize: '10px' }} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      : (colenderChartType === 'colender_daily_amount' ?
        <BarChart width={490} height={250}  data={chartData["colender_daily_amount"]}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="display_x" />
          <YAxis type="number" domain={[0, 'dataMax']} />
          <Tooltip />
          <Legend />
          <Bar isAnimationActive={false} dataKey="amount" fill="#40e0d0" barSize={30}>
            <LabelList dataKey="amount" position="inside" fill="#000000" style={{ fontSize: '15px' }}/>
          </Bar>
          <Bar isAnimationActive={false} dataKey="count" fill="#8884d8" barSize={30}>
            <LabelList dataKey="count" position="inside" fill="#000000" style={{ fontSize: '15px' }} />
          </Bar>
        </BarChart>
      :
      <BarChart width={490} height={250} data={chartData['colender_weekly_count']}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="display_x" />
        <YAxis type="number" domain={[0, 'dataMax']} />
        <Tooltip />
        <Legend />
        <Bar isAnimationActive={false} dataKey="Previous Month" fill="#FA803A" barSize={35}>
          <LabelList dataKey="Previous Month" position="inside" fill="#000000" style={{ fontSize: '10px' }}/>
        </Bar>
        <Bar isAnimationActive={false} dataKey="Current Month" fill="#40e0d0" barSize={35}>
          <LabelList dataKey="Current Month" position="inside" fill="#000000" style={{ fontSize: '10px' }} />
        </Bar>

      </BarChart>
      )
    }
  </>
)}
</div>

              <div class="col-md-6">
                {(roleChecker.isHOOpsTeamAccess(user.role) || roleChecker.isHOSalesApproverAccess(user.role)  || roleChecker.isGMLevelAccess(user.role) || roleChecker.isVPCLevelAccess(user.role) || roleChecker.isBMLevelAccess(user.role) || roleChecker.isAreaLevelAccess(user.role) || roleChecker.isCCMLevelAccess(user.role) || roleChecker.isZCMLevelAccess(user.role) || roleChecker.isBCMLevelAccess(user.role) || roleChecker.isCSMLevelAccess(user.role) || roleChecker.isRSMLevelAccess(user.role)|| roleChecker.isZSMLevelAccess(user.role) || roleChecker.isPIVGAccess(user.role) || roleChecker.isBMLevelAccess(user.role)) && chartData && chartData.disburse_month_amount && (
                  <>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <b>Disbursement Count</b>
                      {(roleChecker.isGMLevelAccess(user.role)|| roleChecker.isVPCLevelAccess(user.role) || roleChecker.isPIVGAccess(user.role) || roleChecker.isZSMLevelAccess(user.role)) && (
                      
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        style={{ marginLeft: "20px", marginBottom: "10px" }}
                        value={disburseChartType}
                        onChange={(e) => DisburseHandleClick(e.target.value)}
                      >
                        <MenuItem value={'disburse_weekly_count'}>Weekly</MenuItem>
                        <MenuItem value={'disburse_month_amount'}>Monthly</MenuItem>
                        <MenuItem value={'disburse_daily_amount'}>Daily</MenuItem>

                      </Select>
                      )}
                      {(roleChecker.isGMLevelAccess(user.role)|| roleChecker.isVPCLevelAccess(user.role) || roleChecker.isPIVGAccess(user.role) || roleChecker.isZSMLevelAccess(user.role)) && (

                      <button 
                        style={{
                          marginLeft: "150px",
                          marginBottom: '10px',
                          padding: "5px 10px",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          background: "#f0f0f0"
                        }}
                        onClick={()=>setIsModalOpen3(true) }
                        
                      >
                        View Tables

                      </button>
                      )}
                    </div>

                    {disburseChartType === 'disburse_month_amount' ?
                      <ResponsiveContainer width="90%" height={250}>
                      <BarChart data={chartData["disburse_month_amount"]}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="display_x" />
                          <YAxis type="number" domain={[0, 'dataMax']} />
                          <Tooltip />
                          <Legend />
                          <Bar isAnimationActive={false} dataKey="count" fill="#40e0d0" onClick={() => setIsModalOpen3(true)} barSize={30}>
                            <LabelList dataKey="count" position="inside" fill="#000000" style={{ fontSize: '10px' }} />
                          </Bar>
                          <Bar isAnimationActive={false} dataKey="amount" fill="#8884d8" onClick={() => setIsModalOpen3(true)} barSize={30}>
                            <LabelList dataKey="amount" position="inside" fill="#000000" style={{ fontSize: '10px' }} />
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                      : ( disburseChartType === 'disburse_daily_amount'?
                        <BarChart width={490} height={250} data={chartData['disburse_daily_amount']}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="display_x" />
                        <YAxis type="number" domain={[0, 'dataMax']} />
                        <Tooltip />
                        <Legend />
                        <Bar isAnimationActive={false} dataKey="amount" fill="#40e0d0" onClick={() => setIsModalOpen3(true)} barSize={30}>
                            <LabelList dataKey="amount" position="inside" fill="#000000" style={{ fontSize: '10px' }} />
                          </Bar>
                          <Bar isAnimationActive={false} dataKey="count" fill="#8884d8" onClick={() => setIsModalOpen3(true)} barSize={30}>
                            <LabelList dataKey="count" position="inside" fill="#000000" style={{ fontSize: '10px' }} />
                          </Bar>
                        </BarChart>
                      : <BarChart width={490} height={250} data={chartData['disburse_weekly_count']}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="display_x" />
                        <YAxis type="number" domain={[0, 'dataMax']} />
                        <Tooltip />
                        <Legend />
                        <Bar isAnimationActive={false} dataKey="Previous Month" fill="#FA803A" barSize={35}>
                          <LabelList dataKey="Previous Month" position="inside" fill="#000000" style={{ fontSize: '10px' }} />
                        </Bar>
                        <Bar isAnimationActive={false} dataKey="Current Month" fill="#40e0d0" barSize={35}>
                          <LabelList dataKey="Current Month" position="inside" fill="#000000" style={{ fontSize: '10px' }} />
                        </Bar>

                      </BarChart>
                      )
                    }

                  {(roleChecker.isGMLevelAccess(user.role)|| roleChecker.isVPCLevelAccess(user.role) || roleChecker.isZSMLevelAccess(user.role) || roleChecker.isBMLevelAccess(user.role)) && (
                    <TableModal isModalOpen={isModalOpen3} setIsModalOpen={setIsModalOpen3} chartType={"disburse_month_amount"}/>
                    
                    )}


                  </>
                )}
              </div>
             
              {/* <div class="col-md-6">
                {roleChecker.isCFOAccess(user.role)&& chartData && chartData.sanction_vs_colending_data && (
                  <>
                    <b>Colender's Sanctions </b>
                    <ResponsiveContainer width="90%" height={250}>
                      <BarChart data={chartData["sanction_vs_colending_data"]} style={{ fontSize: '0.75rem' }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="display_x" />
                        <YAxis type="number" domain={[0, 'dataMax']} />
                        <Tooltip />
                        <Legend />
                        <Bar isAnimationActive={false} dataKey="Sanction Count" fill="#40e0d0" >
                          <LabelList dataKey="Sanction Count" position="inside" fill="#000000" style={{ fontSize: '10px' }} barSize={35} />
                        </Bar>
                        <Bar isAnimationActive={false} dataKey="Colender's Sanction Count" fill="#8884d8" >
                          <LabelList dataKey="Colender's Sanction Count"  position="inside" fill="#000000" style={{ fontSize: '10px' }}  barSize={35}/>
                        </Bar>

                      </BarChart>
                    </ResponsiveContainer>
                  </>
                )}
              </div> */}
              {/* <div class="col-md-6">
                {(roleChecker.isCFOAccess(user.role) || roleChecker.isHOCollectionTeamAccess(user.role)) && chartData && chartData.enach_month_amount && (
                  <>

                    <b>Monthly ENACH Collection Success (%)</b>
                    {roleChecker.isCFOAccess(user.role) && (
                        <ResponsiveContainer width="90%" height={250}>
                        <BarChart data={chartData["enach_month_amount"]} style={{ fontSize: '0.75rem' }}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis type="number" domain={[0, 'dataMax']} />
                          <Tooltip />
                          <Legend />
                          <Bar isAnimationActive={false} dataKey="value" fill="#40e0d0"  barSize={35} label={{ position: 'top', formatter: percentageLabelFormatter }} >


                          </Bar>
                          <Bar isAnimationActive={false} dataKey="count" fill="#8884d8"  barSize={35}>
                            <LabelList dataKey="count" position="inside"  fill="#000000" style={{ fontSize: '10px' }} />
                          </Bar>
                        </BarChart>
                        </ResponsiveContainer>
                    )}
                    {roleChecker.isHOCollectionTeamAccess(user.role) && (
                        <ResponsiveContainer width="90%" height={250}>
                        <BarChart data={chartData["enach_month_amount"]} style={{ fontSize: '0.75rem' }}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis type="number" domain={[0, 'dataMax']} />
                          <Tooltip />
                          <Legend />
                          <Bar isAnimationActive={false} dataKey="value" fill="#40e0d0" label={{ position: 'top', formatter: percentageLabelFormatter }} >
                          </Bar>
                        </BarChart>
                        </ResponsiveContainer>
                    )}

                  </>
                )}
              </div> */}
            </div>
            <br />

            <div class="row">
            
              {/* <div class="col-md-6">
                {roleChecker.isCFOAccess(user.role)&& chartData && chartData.collection_payment_month_data && (
                  <>

                    <b>SBL Cash Inflow </b>
                    <ResponsiveContainer width="90%" height={250}>
                      <BarChart data={chartData["collection_payment_month_data"]} style={{ fontSize: '0.75rem' }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis type="number" domain={[0, 'dataMax']} />
                        <Tooltip />
                        <Legend />
                        <Bar isAnimationActive={false} dataKey="value" fill="#40e0d0" barSize={30}>
                          <LabelList dataKey="value" position="inside" fill="#000000" style={{ fontSize: '10px' }}/>


                        </Bar>

                      </BarChart>
                    </ResponsiveContainer>

                  </>
                )}
              </div> */}
              {/* <div class="col-md-6">
                {(roleChecker.isCFOAccess(user.role)) && chartData && chartData.nachs_pie_chart && (
                  <>
                    <b>Collection NACH Status</b>
                    <ResponsiveContainer width="90%" height={250}>
                      <PieChart width={400} height={400}>
                        <Pie data={chartData['nachs_pie_chart']}
                          dataKey="value"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                          label={(data) => `${data.value} ${data.name}`}


                          outerRadius={80}
                          fill="#8884d8">
                          {chartData['nachs_pie_chart']
                            .map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={colors_2[index % colors.length]} />
                            ))}
                        </Pie>
                        <Legend />
                      </PieChart>
                    </ResponsiveContainer>
                  </>
                )}

              </div> */}
              <div class="col-md-6">
                {(roleChecker.isCFOAccess(user.role)) && chartData && chartData.Loan_status_pie_chart && (
                  <>
                    <b>SBL Loan Under Process</b>
                    <ResponsiveContainer width="90%" height={250}>
                      <PieChart width={400} height={400}>
                        <Pie data={chartData['Loan_status_pie_chart']}
                          dataKey="value"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                          label={(data) => `${data.value} ${data.name}`}


                          outerRadius={80}
                          fill="#8884d8">
                          {chartData['Loan_status_pie_chart']
                            .map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                            ))}
                        </Pie>
                        <Legend />
                      </PieChart>
                    </ResponsiveContainer>
                  </>
                )}

              </div>


            </div>
</>}
     <br/><br/>
          </div>
        )}

        </>
}

export default SBLDashboard;