import { fetchWrapper } from "../_helpers";
const apiUrl =process.env.REACT_APP_API_URL;
const baseUrl =`${apiUrl}/makerchecker`

export const makerCheckerServices ={
    getRequests,
    newRequest,
    deleteRequest,
    getRequestsByEmpid,
    approveRequest,
    checkAlreadyRequested
}

function getRequests(params){
    return fetchWrapper.post(`${baseUrl}/getrequests`, params)
}

function newRequest(params){
    return fetchWrapper.post(`${baseUrl}/newrequest`, params)
}

function deleteRequest(id){
    return fetchWrapper.delete(`${baseUrl}/deleterequest/${id}`)
}

function getRequestsByEmpid(params){
    return fetchWrapper.post(`${baseUrl}/getrequestsbyempid`, params)
}

function approveRequest(id,params){
    return fetchWrapper.put(`${baseUrl}/approverequest/${id}`, params)
}
function checkAlreadyRequested(params) {
    console.log('params::::::',params)
    return fetchWrapper.post(`${baseUrl}/checkalreadyrequested`,params)
  
  }