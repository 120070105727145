import React, { useState, useEffect,useRef  } from 'react';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
 import { Button } from '@material-ui/core';
import { uploadFileToBucket } from "../_helpers/upload";
import { v4 as uuidv4 } from 'uuid';
import {bulkUploadService} from '../_services/bulkUpload.services'
import BulkUploadCollection from "../assets/rpsbulkupload.csv"
import success from "../assets/success.png"
import swal from 'sweetalert';
const RpsBulkUploade = () => {
   const [selectedFile, setSelectedFile] = useState(null);
   const [showField,setShowField]=useState(false);
   const [showError,setShowError] = useState(false);
   const [showMessage,setShowMessage] = useState();
const [isSuccess,setIsSuccess]=useState(false)
const fileInputRef = useRef(null);
const random_uid= uuidv4();
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const bucket = process.env.REACT_APP_s3sblBucket;

  const handleFileUpload = async( ) => {
     

     let val = selectedFile.name.split('.').pop().toLowerCase();
   console.log("random_uid",random_uid)
   var link;
   swal({
    title: "Please wait",
    text: "File uploading...",
    icon: "info",
    buttons: false,
    closeOnClickOutside: false,
    closeOnEsc: false,
  });
    if(val==='csv'){
        link = await uploadFileToBucket(bucket, 'application/csv', `${bucket}/rpsbulkupload/${random_uid}.csv`, selectedFile);
      console.log('link is',link)
    }else{
        setShowError(true)
        setTimeout(() => {
            setShowError(false)
          }, 3000);
       
    }
    console.log("var link ",link)
    if(link){
      console.log("link",link)

      bulkUploadService.rpsUploadingDataSheet({link })
    .then((res)=>{
      console.log(res,"resresresresresresres")
      // if(res && res.status=="sucess"  ){
        // if (res.failed_loans.length > 0) {
          // Create data in the desired format
          // const failedLoansData = res.failed_loans.map(loan => ({ "Failed Loan": loan }));
          // const ws = XLSX.utils.json_to_sheet(failedLoansData);
          // const wb = XLSX.utils.book_new();
          // XLSX.utils.book_append_sheet(wb, ws, "Failed Loans");
          // XLSX.writeFile(wb, 'failed_loans.xlsx');
      //  }
        // swal({
        //   title: res.message,
          // text: `${res.failed_loans.length> 0 ? 'loans detected. Please check the downloaded file for details.':""}`,
        //   icon: "success",
        //   confirmButtonText: 'Ok'
        // });

        // setIsSuccess(true);
        // setTimeout(() => {
        //     setIsSuccess(false)
        //   }, 3000);
        // setSelectedFile(null);
        // fileInputRef.current.value = '';
      // }
      // else{
      //   swal({
      //     title: "Error",
      //     text: res.message,
      //     icon: "error",
      //     confirmButtonText: 'Ok'
      //   });
      // } 
      if(res && res.status=="success"){
        swal({
            title: res.message,
            text: "Note: You can download the RPS failed loans data by navigating to the Reports section under the Accounting category. ",
            icon: "success",
            confirmButtonText: 'Ok'
          });
        setSelectedFile("")
      } else if(res && res.status=="failed"){
        swal({
            title: res.message,
            text: "Please check the data format and file extension.",
            icon: "error",
            confirmButtonText: 'Ok'
          });
        setSelectedFile("")
      }
      else{
        setShowMessage(res.message);
      } 
      console.log("reponse",res)
    })
    .catch((err)=>console.log("error",err))

     }
  }
   
  return (
    <div>
      <Button onClick={()=>setShowField(!showField)} color='primary' variant='contained' style={{marginBottom:'1rem'}}>Upload CSV File</Button>
    <div >
    {showError && <h3 style={{color:"red"}}>please select CSV File Only</h3>}
    {/* {isSuccess && <h3 style={{color:"green"}}>{showMessage}</h3>} */}
    { showField && <div className='offset col-md-6 m-auto' style={{display:'flex',flexDirection:'column',justifyContent:'center',gap:'1rem'}}>
    <div style={{display:'flex',width:'100%',height:'10rem',border:'2px solid #410202',alignItems:'center',justifyContent:'center',borderRadius:'0.5rem',borderStyle:'dashed'}}>

          <input type="file" accept=".csv, .xlsx"  className="form-control" style={{width:'80%',padding:'4px'}} onChange={handleFileChange}  ref={fileInputRef} />
    </div>
      <Button color='primary' variant='contained' onClick={handleFileUpload} disabled={!selectedFile}>
        Upload
      </Button>
      <div >
      <a href="https://sbl-credit.s3.ap-south-1.amazonaws.com/sbl-credit/bulkupload/b7ce76cd-3dd0-49a6-92b8-6f53df8129a6.csv" download style={{ display: "flex", justifyContent: "right", textDecoration: "none", textStyling: "none", color: "red" }} >Download Sample File </a>
      </div>
      </div>
      }
    </div>
    {/* <div >
      
    {isSuccess && <h3 style={{display:'flex',width:'50%',height:'7rem',border:'2px solid rgb(165, 237, 165)',alignItems:'center',justifyContent:'center',borderRadius:'0.5rem',color:'green',marginLeft:'25%'}}>
    <img style={{display:'flex',width:'50px',height:'5rem'}} src={success}/>
    {showMessage}</h3>}
    </div> */}
    </div>
  );
};

export  {RpsBulkUploade};




 
