import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Fab from "@material-ui/core/Fab";
import SaveIcon from "@material-ui/icons/Save";
import { Button } from "@material-ui/core";
import { loanService } from "../../../../_services/loan.service";
import { receiptService } from "../../../../_services/receipt.service";
import { employeeService } from "../../../../_services";
import FormElement from "../../../uielements/FormElement";

const useStyles = makeStyles((theme) => ({
    modal: {
        width: "75%",
        overflow: "scroll",
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    table: {
        minWidth: 650,
    },
}));

export default function PaymentFeeModal(props) {
    const customer = props.customer;
    console.log("customer",customer)
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const user = employeeService.userValue;
    const [selectedFeeType, setSelectedFeeType] = React.useState(null);
    const [employeeList, setEmployeeList] = React.useState({})

    const initialValues = {
        application_fees: {
            payment_date: "",
            payment_mode: "Online",
            payment_comment: "",
            payment_amount: "",
        },
        emd_fees: {
            payment_date: "",
            payment_mode: "Online",
            payment_comment: "",
            payment_amount: "",
        },
        receipt_generate: {
            loan_no: customer.application_id,
            date: "",
            mode: "Online",
            comment: "",
            amount: "",
        },
    };

    const loginFeesvalidationSchema = Yup.object().shape({
        application_fees: Yup.object().shape({
            payment_date: Yup.date().required("This field is required"),
            payment_amount: Yup.number().required("This field is required"),
            payment_comment: Yup.string()
                .required("This field is required")
                .matches(/^\d{12}$/, "Should have 12 digits"),
        }),
    });


    const emdFeesValidationSchema = Yup.object().shape(
        {
            emd_fees: Yup.object().shape({
                payment_date: Yup.date().required("This field is required"),
                payment_amount: Yup.number().required("This field is required"),

                payment_comment: Yup.string()
                    .required("This field is required")
                    .matches(/^\d{12}$/, "Should have 12 digits"),
            }),
        })

    const receiptGenerationValidationSchema = Yup.object().shape(
        {
            receipt_generate: Yup.object().shape({
                date: Yup.date().required("This field is required"),
                amount: Yup.number().required("This field is required"),

                comment: Yup.string()
                    .required("This field is required")
                    .matches(/^\d{12}$/, "Should have 12 digits"),
            }),
        })
    const handleOpen = async () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    async function onSubmitLoginFees(fields, { setSubmitting }) {
        console.log("onSubmitLoginFees", fields)
        if (fields.application_fees.payment_amount === 1200 || fields.application_fees.payment_amount === 2500) {

            let newApplicant = { 'application_fees': {} }
            newApplicant['application_fees']['payment_date'] = fields['application_fees']['payment_date'];
            newApplicant['application_fees']['payment_mode'] = fields['application_fees']['payment_mode'];
            newApplicant['application_fees']['payment_amount'] = fields['application_fees']['payment_amount'];
            newApplicant['application_fees']['payment_comment'] = 'UTR ' + fields['application_fees']['payment_comment'];
            newApplicant['application_fees']['payment_received_employee_id'] = user.emp_id;


            console.log(newApplicant);
            await loanService.paymentFees(customer._id, newApplicant)
              .then((ns) => {
                console.log("Applicant added");
                window.alert("Details added");
                window.location.reload();
              })
              .catch((err) => {
                console.log(err);
                window.alert("error adding details");
              })
            setSubmitting(false);

        } else {
            alert('Amount Should be equal to 1200 or 2500')
        }
    }

    async function onSubmitEmdFees(fields, { setSubmitting }) {
        console.log("onSubmitEmdFees", fields)
        if (fields.emd_fees.payment_amount === 3800) {

            let newApplicant = { 'emd_fees': {} }
            newApplicant['emd_fees']['payment_date'] = fields['emd_fees']['payment_date'];
            newApplicant['emd_fees']['payment_mode'] = fields['emd_fees']['payment_mode'];
            newApplicant['emd_fees']['payment_amount'] = fields['emd_fees']['payment_amount'];
            newApplicant['emd_fees']['payment_comment'] = 'UTR ' + fields['emd_fees']['payment_comment'];
            newApplicant['emd_fees']['payment_received_employee_id'] = user.emp_id;


            console.log("onSubmitEmdFees", newApplicant);
            await loanService.emdPaymentFees(customer._id, newApplicant)
              .then((ns) => {
                console.log("Applicant added");
                window.alert("Details added");
                window.location.reload();
              })
              .catch((err) => {
                console.log(err);
                window.alert("error adding details");
              })
            setSubmitting(false);

        } else {
            alert('Amount Should be equal to 3800')
        }
    }

    async function onSubmitReceiptGenerate(fields, { setSubmitting }) {
        console.log("onSubmitReceiptGenerate", fields)
        const date = new Date(fields['receipt_generate']['date']);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const date_str = `${day}${month}${year}`;
        // const random_int = Math.floor(Math.random() * (1000 - 100 + 1)) + 100;
        let random_int = date.getMilliseconds();

        const receipt_no = date_str + fields['receipt_generate']['loan_no'] + random_int;
        console.log(date_str, random_int, receipt_no)
        let newApplicant = {}
        newApplicant['loan_no'] = fields['receipt_generate']['loan_no']
        newApplicant['date'] = fields['receipt_generate']['date'];
        newApplicant['mode'] = fields['receipt_generate']['mode'];
        newApplicant['payment'] = fields['receipt_generate']['amount'];
        newApplicant['comment'] = 'UTR ' + fields['receipt_generate']['comment'];
        newApplicant['emp_id'] = user.emp_id;
        newApplicant['cust_name'] = customer['applicant']['general_information']['name'];
        newApplicant['is_discarded'] = false;
        newApplicant['receipt_no'] = receipt_no;
        newApplicant['emp_name'] = employeeList[user.emp_id]


        console.log(newApplicant);
        await receiptService.receiptGeneratepaymentFees(customer._id, newApplicant)
          .then((ns) => {
            console.log("Applicant added");
            window.alert("Details added");
            window.location.reload();
          })
          .catch((err) => {
            console.log(err);
            window.alert("error adding details");
          })
        setSubmitting(false);


    }




    const handleFeeTypeChange = (event) => {
        setSelectedFeeType(event.target.value);
    };


    async function getAllEmployees() {
        await employeeService.getAll()
            .then((res) => {
                let list = {};
                res.map(e => {
                    list[e.emp_id] = e.first_name + ' ' + e.last_name
                })
                setEmployeeList(list)
            })
    }

    useEffect(() => {

        getAllEmployees()
    }, [])


    return (
        <div style={{ "margin": "10px" }}>
            <br />
            <Button
                onClick={handleOpen}
                variant="contained"
                color="primary"
            > Add Payment fee
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>

                        <div className="row justify-content-around">
                            <label>Select Fee Type:</label>
                            <select
                                onChange={handleFeeTypeChange}
                                value={selectedFeeType || ""}
                            >
                                <option value="" disabled>Select Fee Type</option>
                                <option value="login_fees">Login Fees</option>
                                <option value="emd_fees">IMD Fees</option>
                                <option value="receipt_generate">Receipt Generate</option>
                            </select>
                        </div>
                        {customer && customer.application_fees && customer.application_fees.payment_amount==2500 &&  selectedFeeType === "login_fees" && <h2>Login fees 2500 aldready exists</h2>}
                        {customer && customer.application_fees && customer.application_fees.payment_amount!=2500 &&  selectedFeeType === "login_fees" && <Formik

                            validationSchema={loginFeesvalidationSchema}
                            initialValues={initialValues}
                            onSubmit={onSubmitLoginFees}
                            enableReinitialize={true}
                            id="transition-modal-description"
                        >
                            {({ errors, touched, isSubmitting, setFieldValue, values }) => {
                                return (
                                    <Form>
                                        <h2 id="transition-modal-title">Login Fees  </h2>

                                        <div className="form-row">
                                            <div className="form-group col">
                                                <FormElement
                                                    type="number"
                                                    name="application_fees.payment_amount"
                                                    value={values.application_fees.payment_amount}
                                                    label="Amount"
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            </div>
                                            <div className="form-group col">
                                                <FormElement
                                                    type="text"
                                                    name="application_fees.payment_mode"
                                                    disabled
                                                    label="Payment Mode"
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            </div>
                                            <div className="form-group col">
                                                <FormElement
                                                    type="text"
                                                    name="application_fees.payment_comment"

                                                    label="Payment UTR"
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col">
                                                <FormElement
                                                    type="date"
                                                    onKeyDown={(e) => e.preventDefault()}
                                                    name="application_fees.payment_date"
                                                    label="Payment Date"
                                                    errors={errors}
                                                    touched={touched}
                                                />

                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row justify-content-around">
                                            <Fab
                                                type="submit"
                                                disabled={isSubmitting}
                                                color="primary"
                                                aria-label="save"
                                            >
                                                {isSubmitting && (
                                                    <span className="spinner-border spinner-border-sm mr-1"></span>
                                                )}
                                                <SaveIcon />
                                            </Fab>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>}
                        {selectedFeeType === "emd_fees" && <Formik

                            validationSchema={emdFeesValidationSchema}
                            initialValues={initialValues}
                            onSubmit={onSubmitEmdFees}
                            enableReinitialize={true}
                            id="transition-modal-description"
                        >
                            {({ errors, touched, isSubmitting, setFieldValue, values }) => {
                                return (

                                    <Form>
                                        <h2 id="transition-modal-title">IMD Fees  </h2>

                                        <div className="form-row">
                                            <div className="form-group col">
                                                <FormElement
                                                    type="number"
                                                    name="emd_fees.payment_amount"
                                                    value={values.application_fees.payment_amount}
                                                    label="Amount"
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            </div>
                                            <div className="form-group col">
                                                <FormElement
                                                    type="text"
                                                    name="emd_fees.payment_mode"
                                                    disabled
                                                    label="Payment Mode"
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            </div>
                                            <div className="form-group col">
                                                <FormElement
                                                    type="text"
                                                    name="emd_fees.payment_comment"

                                                    label="Payment UTR"
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col">
                                                <FormElement
                                                    type="date"
                                                    onKeyDown={(e) => e.preventDefault()}
                                                    name="emd_fees.payment_date"
                                                    label="Payment Date"
                                                    errors={errors}
                                                    touched={touched}
                                                />

                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row justify-content-around">
                                            <Fab
                                                type="submit"
                                                disabled={isSubmitting}
                                                color="primary"
                                                aria-label="save"
                                            >
                                                {isSubmitting && (
                                                    <span className="spinner-border spinner-border-sm mr-1"></span>
                                                )}
                                                <SaveIcon />
                                            </Fab>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>}
                        {selectedFeeType === "receipt_generate" && <Formik

                            validationSchema={receiptGenerationValidationSchema}
                            initialValues={initialValues}
                            onSubmit={onSubmitReceiptGenerate}
                            enableReinitialize={true}
                            id="transition-modal-description"
                        >
                            {({ errors, touched, isSubmitting, setFieldValue, values }) => {
                                return (

                                    <Form>
                                        <h2 id="transition-modal-title">Receipt Generate  </h2>

                                        <div className="form-row">
                                            <div className="form-group col">
                                                <FormElement
                                                    type="string"
                                                    name="receipt_generate.loan_no"
                                                    value={values.amount}
                                                    disabled
                                                    label="Loan No"
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            </div>
                                            <div className="form-group col">
                                                <FormElement
                                                    type="number"
                                                    name="receipt_generate.amount"
                                                    value={values.amount}
                                                    label="Amount"
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            </div>
                                            </div>
                                            <div className="form-row">
                                            <div className="form-group col">
                                                <FormElement
                                                    type="text"
                                                    name="receipt_generate.mode"
                                                    disabled
                                                    label="Payment Mode"
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            </div>
                                            <div className="form-group col">
                                                <FormElement
                                                    type="text"
                                                    name="receipt_generate.comment"

                                                    label="UTR"
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col">
                                                <FormElement
                                                    type="date"
                                                    onKeyDown={(e) => e.preventDefault()}
                                                    name="receipt_generate.date"
                                                    label="Date"
                                                    errors={errors}
                                                    touched={touched}
                                                />

                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row justify-content-around">
                                            <Fab
                                                type="submit"
                                                disabled={isSubmitting}
                                                color="primary"
                                                aria-label="save"
                                            >
                                                {isSubmitting && (
                                                    <span className="spinner-border spinner-border-sm mr-1"></span>
                                                )}
                                                <SaveIcon />
                                            </Fab>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>}
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}