import React, { useEffect, useState } from 'react'
import { useReactToPrint } from "react-to-print";
import { loanService } from '../../../_services';
import datehelper from "../../../_helpers/datehelper";
import capitalfinance from "../../../assets/capitalfinance.png";
import logo from "../../../assets/kaabil.png"

const LoanAgreement = (props) => {

    const data = props.data
    const branches = props.branches

    console.log(props)

    // const LoanAgreementRef = React.useRef();


    // const handleSubmissionPrint = useReactToPrint({
    //     content: () => LoanAgreementRef.current,
    //     pageStyle: "print",
    // });

    const one = ["", "One ", "Two ", "Three ", "Four ", "Five ", "Six ", "Seven ", "Eight ", "Nine ", "Ten ", "Eleven ", "Twelve ", "Thirteen ", "Fourteen ", "Fifteen ",
        "Sixteen ", "Seventeen ", "Eighteen ", "Nineteen "];

    var ten = ["", "", "Twenty ", "Thirty ", "Forty ", "Fifty ", "Sixty ", "Seventy ", "Eighty ", "Ninety "];

    function numToWords(n, s) {
        let str = "";
        // if n is more than 19, divide it
        if (n > 19) {
            str += ten[parseInt(n / 10)] + one[n % 10];
        }
        else {
            str += one[n];
        }
        if (n != 0) {
            str += s;
        }

        return str;
    }

    function convertToWords(n) {
        // stores word representation of given number n
        let out = "";

        // handles digits at ten millions and hundred
        // millions places (if any)
        out += numToWords(parseInt(n / 10000000),
            "Crore ");

        // handles digits at hundred thousands and one
        // millions places (if any)
        out += numToWords(parseInt((n / 100000) % 100),
            "Lakh ");

        // handles digits at thousands and tens thousands
        // places (if any)
        out += numToWords(parseInt((n / 1000) % 100),
            "Thousand ");

        // handles digit at hundreds places (if any)
        out += numToWords(parseInt((n / 100) % 10),
            "Hundred ");

        if (n > 100 && n % 100 > 0) {
            out += "and ";
        }

        // handles digits at ones and tens places (if any)
        out += numToWords(parseInt(n % 100), "");

        return out;
    }

    const headerDiv = () => (
        <div>
          <img src={logo} style={{ height: "100px" }} alt="KFPL Logo" />
          
            <img
              src={capitalfinance}
              style={{
                height: "100px",
                position: "absolute",
                top: "2",
                right: "0",
                marginRight: "3rem",
              }}
              alt="Capital Finance Logo"
            />
          
          
        </div>
      );

    return (
        <>
            {/* <div style={{ textAlign: "center" }}>
                <button onClick={handleSubmissionPrint}>Print Letter</button>
            </div> */}

            <div
                style={{
                    width: "290mm",
                    height: "1250mm",
                    backgroundColor: "white",
                    padding: 40,
                    position: "relative",
                }}
            >{headerDiv()}<br/><br/>
                <div style={{ textAlign: "center", fontSize: "20px" }}>
                    <span style={{ textAlign: "center" }}><b>SCHEDULE</b></span> <br />
                    <span style={{ textAlign: "center" }}><b>DETAILS OF THE LOAN</b></span><br />
                    <span style={{ textAlign: "center" }}><b>(Forming Part of Loan Agreement)</b></span>
                </div>
                <br />
                <div style={{ textAlign: "justify" }}>
                    <table style={{ width: "90%", marginLeft: "5%", marginTop: "20px", fontSize: "20px" }}>
                        <tr style={{ border: "1px solid" }}>
                            <td colspan="2" style={{ textAlign: "center", border: "1px solid" }}>Details</td>
                        </tr>
                        <tr style={{ border: "1px solid" }}>
                            <td style={{ border: "1px solid", width: "30%" }}>Date Of Execution</td>
                            <td style={{ border: "1px solid" }}><b>{datehelper.displayDate(new Date(data.loan?.agreement_stamp_date))} </b></td>
                        </tr>
                        <tr style={{ border: "1px solid" }}>
                            <td style={{ border: "1px solid", width: "30%" }}>Place of the Execution</td>
                            <td style={{ border: "1px solid" }}>{branches.branch_city}</td>
                        </tr>
                        <tr style={{ border: "1px solid" }}>
                            <td style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "start"
                            }}>
                                Details of Borrowers
                            </td>
                            <td style={{ border: "1px solid" }}>
                                <tr>Name: {data && data.applicant && data.applicant.general_information && data.applicant.general_information.name}</tr>
                                <tr>Address: {data && data.applicant && data.applicant.general_information && data.applicant.general_information.address}</tr>
                                <tr>PAN: {data && data.applicant && data.applicant.general_information && data.applicant.general_information.pan_no}</tr><br />
                                {data && data.co_applicants && data.co_applicants.length > 0 && data.co_applicants.map((e, idx) => {
                                    return <>
                                        <tr>Name: {e.general_information.name}</tr>
                                        <tr>Address: {e.general_information.address}</tr>
                                        <tr>PAN: {e.general_information.pan_no}</tr><br />
                                    </>
                                })}
                            </td>
                        </tr>
                        <tr style={{ border: "1px solid" }}>
                            <td style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "start"
                            }}>
                                Details of Guarantors
                            </td>
                            <td style={{ border: "1px solid" }}>
                                {data && data.guarantors && data.guarantors.length > 0 && data.guarantors.map((e, idx) => {
                                    return <>
                                        <tr>Name: {e.general_information.name}</tr>
                                        <tr>Address: {e.general_information.address}</tr>
                                        <tr>PAN: {e.general_information.pan_no}</tr><br />
                                    </>
                                })}
                            </td>
                        </tr>
                        <tr style={{ border: "1px solid" }}>
                            <td style={{ border: "1px solid" }}>
                                Details of Security Provider
                            </td>
                            <td>
                                <tr>
                                    Name:- {data && data.property_details && data.property_details.owner}
                                </tr>
                                <tr>
                                    Address:- {data && data.property_details && data.property_details.address}
                                </tr>
                            </td>
                        </tr>

                        <tr style={{ border: "1px solid" }}>
                            <td style={{ border: "1px solid" }}>
                                Purpose of the loan
                            </td>
                            <td>Business Use</td>
                        </tr>
                        <tr style={{ border: "1px solid" }}>
                            <td style={{ border: "1px solid" }}>
                                Amount of Loan(Amount in figures & words)
                            </td>
                            <td>Rs.{data && data.loan && data.loan.sanction_amount}/- &nbsp;(Rupees {convertToWords(data && data.loan && data.loan.sanction_amount)}only)</td>
                        </tr>

                        <tr style={{ border: "1px solid" }}>
                            <td style={{ border: "1px solid" }}>
                                Proportion of share of Lenders in the Loan
                            </td>
                            <td>
                                <tr>Lender1  10%</tr>
                                <tr>Lender2  90%</tr>
                            </td>
                        </tr>

                        <tr style={{ border: "1px solid" }}>
                            <td style={{ border: "1px solid" }}>
                                Sanction Letter
                            </td>
                            <td>Dated <b>{datehelper.displayDate(new Date(data.loan?.agreement_stamp_date))} </b> , issued by the Lenders</td>
                        </tr>

                        <tr style={{ border: "1px solid" }}>
                            <td style={{ border: "1px solid" }}>
                                Details of the Security
                            </td>
                            <td>
                                {data && data.property_details && data.property_details.address}
                                {data && data.property_details && data.property_details.area}sq.yards
                            </td>
                        </tr>
                        <tr style={{ border: "1px solid" }}>
                            <td style={{ border: "1px solid" }}>
                                Security Cover
                            </td>
                            <td>__________ times of the Outstanding Amounts</td>
                        </tr>


                        <tr style={{ border: "1px solid" }}>
                            <td style={{ border: "1px solid" }}>
                                Tenor of the Loan
                            </td>
                            <td>{data && data.loan && data.loan.tenure} months</td>
                        </tr>
                        <tr style={{ border: "1px solid" }}>
                            <td style={{ border: "1px solid" }}>
                                Applicable Interest Rate
                            </td>
                            <td>
                                Adjustable Interest Rate / Fixed Interest Rate at {data && data.loan && data.loan.roi}% p.a., subject to
                                changes from time to time
                            </td>
                        </tr>

                        <tr style={{ border: "1px solid" }}>
                            <td style={{ border: "1px solid" }}>
                                Additional Interest
                            </td>
                            <td>___________%p.a.</td>
                        </tr>
               
                        <tr style={{ border: "1px solid" }}>
                            <td style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "start"
                            }}>
                                Repayment Details
                                (Repayment Schedule)
                            </td>
                            <td style={{ border: "1px solid" }}>
                                1) Repayment frequency: monthly / quarterly / half yearly / yearly.<br />
                                2) Mode of Repayment: Installments / structured repayment / bullet repayment<br />
                                3) Pre- Instalment Interest : Yes / No (In case of Yes, Pre- Instalment Interest
                                shall be payable until the date on which Instalment/EMIs shall be commenced.)<br />
                                4) Instalment / EMI Amount: Rs {data && data.loan && data.loan.emi}<br />
                                5) Number of Instalments / EMIs: {data && data.loan && data.loan.tenure}<br />
                                6) Instalment / EMI commencement date: _____________________________<br />
                                7) Due Date(s) of Instalments / EMIs: ___________________ every month<br />
                            </td>
                        </tr>

                        <tr style={{ border: "1px solid" }}>
                            <td style={{ border: "1px solid" }}>
                                Details of the bank account for Repayment
                            </td>
                            <td>
                                Bank account of the Lenders, as may be specified the Lenders from time to time.
                            </td>
                        </tr>

                        <tr style={{ border: "1px solid" }}>
                            <td style={{ border: "1px solid" }}>
                                Application Fees
                            </td>
                            <td>
                                Rs._____________________
                            </td>
                        </tr>
                        <tr style={{ border: "1px solid" }}>
                            <td style={{ border: "1px solid" }}>
                                Processing Fees
                            </td>
                            <td>
                                Rs._____________________
                            </td>
                        </tr>
                        <tr style={{ border: "1px solid" }}>
                            <td style={{ border: "1px solid" }}>
                                CERSAI Charges
                            </td>
                            <td>
                                Rs._____________________
                            </td>
                        </tr>

                        <tr style={{ border: "1px solid" }}>
                            <td style={{ border: "1px solid" }}>
                                Details of Receivables
                            </td>
                            <td>
                                __________________________________________<br />
                                __________________________________________<br />
                                __________________________________________
                            </td>
                        </tr>

                        <tr style={{ border: "1px solid" }}>
                            <td style={{ border: "1px solid" }}>
                                Payment Charges
                            </td>
                            <td>
                                __________% of the amount being prepaid.
                            </td>
                        </tr>
                    </table><br /><br />
                
                    <span>IN WITNESS WHEREOF the Parties hereto have hereunto set and subscribed their respective hands on the day
                        and year as mentioned herein above.
                    </span><br /><br />
                    <span>
                        For Lender1:

                        SIGNED AND DELIVERED by the within named<br />
                        <b>"Lender1", KAABIL FINANCE PRIVATE LIMITED,</b><br />
                        by the hand of __________________________________________<br />
                        its duly authorized signatory.
                    </span><br /><br />

                    <span>
                        For Lender2:

                        SIGNED AND DELIVERED by the within named<br />
                        <b>"Lender1", CAPITAL INDIA FINANCE LIMITED,</b><br />
                        by the hand of __________________________________________<br />
                        its duly authorized signatory.
                    </span><br /><br />

                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span style={{ width: "300px" }}>Name of the Borrower/s</span>
                        <span style={{ width: "300px" }}>Signature/ Thumb Impression</span>
                    </div><br />
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span span style={{ width: "300px" }}>{data && data.applicant && data.applicant.general_information && data.applicant.general_information.name}</span><br />
                        <span span style={{ width: "200px" }}>________________________</span><br /><br />
                    </div>
                    {data && data.co_applicants && data.co_applicants.length > 0 && data.co_applicants.map((e, idx) => {
                        return <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span span style={{ width: "300px" }}>{e.general_information.name}</span><br />
                            <span span style={{ width: "200px" }}>________________________</span><br /><br />
                        </div>
                    })}
                </div>
            </div>
        </>
    )
}

export default LoanAgreement