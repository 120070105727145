
import React, { useState } from "react"
import { Modal} from "@material-ui/core"
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import { customerService } from "../../../../_services"
import { loanService } from '../../../../_services';
const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    
    paper: {
        backgroundColor: theme.palette.background.paper,
        // border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: "500px",
        maxHeight: "90vh", // Set maximum height
        overflowY: "auto"
    },
}));

export const PanOtpSendModal = (props) => {
    console.log("props is role", props)
    const [spinner, setSpinner] = React.useState(false);

    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const customer = props.customer
    
    const [verificationSuccessMessage, setVerificationSuccessMessage] = useState("");
    const [verificationFailedMessage, setVerificationFailedMessage] = useState("");
   
    const handleClose = () => {
        setOpen(false);
    };

   

    const pan_no = customer?.verification_details?.pan_no;
    const name = `${customer?.general_details?.customer_firstname} ${customer?.general_details?.customer_middlename ? customer.general_details.customer_middlename + ' '  : ''}${customer?.general_details?.customer_lastname ? customer.general_details.customer_lastname : ''}`;


    const kycVerification = (params) => {
        console.log(params, "pdddddddddrams");
        loanService.kycVerification({ status: params, application_id: props.application_id, _id: customer._id, customer_id: customer.customer_id, role: props.role })
            .then((resp) => {
                console.log(resp, "gggggggggggggggggg");
                alert("Pan Card Verified Success");
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const verifyPanCard = async () => {
        setOpen(true);
        var info = {
                    name: name,
                    pan_no: pan_no,
                    application_id: props.application_id,
                    customer_id: customer.customer_id,
                };
        setSpinner(true);
    
        loanService.fetchPanIdentity(info)
          .then(res => {
            if (res && res.status == "success") {
                const messageResopnse = res.message || "Pan Card Verified Success";
                setVerificationSuccessMessage(messageResopnse)
               kycVerification(true); // Call kycVerification with true if response.ok is true
            } else {
                console.log("Verification Failed, Check Pan No.")
                let messageResopnse = res.message || "Verification Failed, Check Pan No.";
                if(res.data && res.data.upstreamName){
                    messageResopnse = messageResopnse + " " +" upstreamName " + res.data.upstreamName;
                }
                 setVerificationFailedMessage(messageResopnse);
            }
            console.log('response of pan card ', res)
            setSpinner(false);
    
          })
          .catch(err => {
            console.log('err of pan is', err); setSpinner(false);
          })
      }
    return (
        <div>
  {customer?.verification_details?.karza_pan_verified ? 
             <span title="Verified">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-patch-check" viewBox="0 0 16 16" style={{color:"green",paddingLeft:"3px",cursor:"pointer"}}>
             <path fillRule="evenodd" d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0"/>
             <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622-.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911z"/>
         </svg>
          </span>
            :
            <span onClick={() => verifyPanCard()} title="Verify">  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" className="bi bi-patch-plus" viewBox="0 0 16 16" style={{ transform: 'rotate(45deg)',paddingLeft:"3px",cursor:"pointer"}}>
            <path fillRule="evenodd" d="M8 5.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V10a.5.5 0 0 1-1 0V8.5H6a.5.5 0 0 1 0-1h1.5V6a.5.5 0 0 1 .5-.5"/>
            <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622-.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911z"/>
        </svg>
        </span>

            }


            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <button type="button" onClick={handleClose} style={{ float: "right", backgroundColor: "black", color: 'white' }}>X</button>
                        <h5 id="transition-modal-title" style={{ textAlign: "center", fontWeight: "bold", color: "blue" }}>Pan Card Verification</h5>
                        <div className="row justify-content-center" style={{ 'margin-top': '20px' }}>
                            <span style={{ color: 'green',fontSize:"20px" }}><b>{verificationSuccessMessage}</b></span>
                            <span style={{ color: 'red',fontSize:"20px"}}><b>{verificationFailedMessage}</b></span>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    )
}
