import { fetchWrapper } from "../_helpers";

const apiUrl = process.env.REACT_APP_API_URL;
const baseUrl = `${apiUrl}/safecustudies`;

export const safeCustodyService = {
  getAllRequests,
  getallfiles, // Added createFile function
  createFile,
  requestFile,
  getAvailableFilesCount,
  searchDataController,
  updateOtpAndStatus,
  searchByMonthYear,
};

function getallfiles() {
  return fetchWrapper.get(`${baseUrl}/getallfiles`);
}

function createFile(fileData) {
  console.log(fileData,"file data")
  return fetchWrapper.post(`${baseUrl}/createfile`, fileData); // Send the file data to the backend
}

function requestFile(fileData) {
  return fetchWrapper.post(`${baseUrl}/requestfile`, fileData); // Send the file data to the backend
}

function getAvailableFilesCount() {
  return fetchWrapper.get(`${baseUrl}/getallavailablefiles`); // Correct endpoint
}

function getAllRequests(fileData) {
  return fetchWrapper.get(`${baseUrl}/getallrequests`, fileData); // Send the file data to the backend
}

function searchDataController(fileData) {
  return fetchWrapper.post(`${baseUrl}/searchdatacontroller`, fileData); // Send the file data to the backend
}

function updateOtpAndStatus(fileData) {
  return fetchWrapper.post(`${baseUrl}/updateOtpAndStatus`, fileData); // Send the file data to the backend
}
// Added searchByMonthYear function
function searchByMonthYear(payload) {
  return fetchWrapper.post(`${baseUrl}/searchbymonthyear`, payload);  // Make sure the backend handles this route
}