import React from "react";
import { Route, Switch } from "react-router-dom";
import Termloans from "./TermLoans";
import { ReconcilationList } from "./ReconcilationList";
import { LedgerDetails } from "./ledger/LedgerDetails";
import {TermLoanForm} from "./TermLoanForm";
import FundUsages from "./FundUsages";
import InitiatedTermLoan from "./InitiatedTermLoan";

// import SBLSanction from "./SanctionLetter/SBLSanction";

function AccountingIndex({ match }) {
  const { path } = match;

  return (
    <Switch> 
      <Route path={`${path}/reconcilationlist`} component={ReconcilationList} /> 
      <Route path={`${path}/termloans`} component={Termloans} />        
      <Route path={`${path}/initiatedtermloan`} component={InitiatedTermLoan} />       
      <Route path={`${path}/ledgerdetails`} component={LedgerDetails} />        
      <Route path={`${path}/termloanform`} component={TermLoanForm}/>
      <Route path={`${path}/edit/:id`} component={TermLoanForm}/>
      <Route path={`${path}/fundusages`} component={FundUsages}/>
    </Switch>
  );
}

export { AccountingIndex };
