export const Role = {
  Admin: "Admin",
  Sales: "Sales",
  Collections:"Collections",
  HO: "HO",
  HOA:"HOA", //HO Approver Role
  GM: "GM", //General Management
  CEO:"CEO",
  BM: "BM",
  BCM:"BCM",
  RSM:'RSM',
  ASM:"ASM",
  ACM:"ACM",
  RCM:"RCM",
  CSM:"CSM",
  CCM:"CCM",
  ZM:"ZM",
  ZCM:"ZCM",
  CC:"CC", //customer care
  AM:'AM', //account maker
  AC:'AC', //account checker
  LG:'LG', //legal
  CLM:'CLM', //collection manager
  IT:'IT', 
  HOGM:"HOGM", //HO Gold maker
  HOGC:"HOGC", //HO Gold checker
  GLDR:"GLDR", //Gold Rider
  HR:"HR", 
  CFO:"CFO",
  CP:"CP", //Channel Partner
  SLG:"SLG", // Sale Lead generator
  FH:"FH", //Finance Head
  COL:"COL", //Colending Maker
  COLA:"COLA", // Colending Approver
  COLM:"COLM", // Colending Manager
  HOC:"HOC", // HO Credit Maker
  HOCA:"HOCA", //HO Credit Approver
  HOO:"HOO", //HO Ops
  HOOA:"HOOA", //HO Ops Approver
  HOCL:"HOCL", //HO Collection
  HOCLA:"HOCLA", //HO Collection Approver
  HOSA:"HOSA", //HO Sales Approver
  BOP:"BOP", // Branch Operation Maker
  VPC:"VPC",  // VP - Corporate Strategy
  ZSM:"ZSM",
  PIVG:"PIVG", // Performance Information & Value Management Group
  KTR:"KTR",
  ZCCM:"ZCCM",
  RCCM:"RCCM",
  ACCM:"ACCM",
  CCCM:"CCCM",
  BCCM:"BCCM",
  EAZSM:"EAZSM" , //  Executive Assistance for ZSM  
  VENDOR:"VENDOR",
  AUDITOR:"AUDITOR",
  ABM:"ABM",
  IR:"IR", 
  DOCA:"DOCA",
};
