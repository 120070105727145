import React from 'react'
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import { Button, ServerStyleSheets } from "@material-ui/core";
import { useFormik } from 'formik';
import TextField from '@material-ui/core/TextField';
import { misService } from '../../_services';
import { useEffect } from 'react';
import {reconcile} from "../../_services/reconcile.service"
import { loanService } from '../../_services';
import roleChecker from '../../_helpers/rolechecker';
import { employeeService } from '../../_services';


const Reconciliation = (props) => {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [cust, setCust] = React.useState({});
  const [sblCust, setSblCust] = React.useState({})
  const user = employeeService.userValue
  

  // const handleCharges=(fieldKey, fieldValue)=>{
  //   charges[fieldKey]=fieldValue;
  //   setCharges(charges)
  // }

  const [values, setValues] = React.useState({
    loan_number: "",
    department: ""
  })
 
   const handleChange = (e) => {
    setValues({...values, [e.target.name]: e.target.value})
    // console.log(values)
   }

   const handleSubmit = async () => {
    await misService.getLoanByAccount(values.loan_number)
     .then((data) => {
       setCust(data[0])
       console.log(data[0])
     })
     .catch((err)=> {
       console.log(err, "Error")
     })
   }

     

   const reconcile_date = props.txn_date; 
   const reconcile_amt = parseInt(props.txn_amt);
   const reconcile_mode = props.payment_mode;
   const payment_ref = props.payment_ref;
   const reference_id = props.reference_id;
   const loan_type =  values.department;
   const payment_type = "collection";
   const loan_account = values.loan_number;
   const [emi, setEmi]= React.useState(0);
   const[other_charges, setOther_charges]=React.useState(0);
   const[principle_amt, setPrinciple_amt]=React.useState(0);
   const receipt_id = props?.receipt_id

   console.log('receipt no ',receipt_id)

  //  const [charges, setCharges]=React.useState({});

   const addData = async (e) => {
    e.preventDefault();
    let total= Number(emi)+Number(other_charges)+Number(principle_amt)
    // console.log(total)
     
    // if(total==reconcile_amt){
      // const temp = {reconcile_date, reconcile_amt, reconcile_mode, reference_id, payment_ref, loan_type, payment_type, loan_account, emi:emi, other_charges:other_charges, principle_amt:principle_amt}
      const temp = {reconcile_date, reconcile_amt, reconcile_mode, reference_id, payment_ref, loan_type, payment_type, loan_account,receipt_id}
      console.log(temp);
      await reconcile.reconcileData(temp)
      .then((data) => {
       alert("Data Added Successfully")
       handleClose()
       window.location.reload()
      })
      .catch((err)=> {
        alert("Something went Wrong", err)
      })
    // }else{
    //   window.alert("Sum of EMI, Principle and Other charges must be equal to Total Amount")
    // }
    //  console.log(loan_account, temp)
   }

   const handleSbl = async () =>{
    await loanService.getBySearch({...values, application_id: values.loan_number})
    .then((x)=>{
      setSblCust(x[0])
      console.log(x[0])
    })
    .catch((err)=>{
      console.log(err)
    })
   }

   const addSbl = async (e) => {
    e.preventDefault();
    // let total= Number(emi)+Number(other_charges)+Number(principle_amt)

    // if(total==reconcile_amt){
      const temp = {reconcile_date, reconcile_amt, reconcile_mode, reference_id, payment_ref, loan_type, payment_type, loan_account,receipt_id}
      console.log(temp);
      if(values.loan_number === ""){
        alert("Please fill all the fields")
      }else{
        await reconcile.reconcileSblData(temp)
        .then((data) => {
         alert("Data Added Successfully")
         handleClose()
         window.location.reload()
        })
        .catch((err)=> {
          alert("Something went Wrong")
          console.log(err)
        })
      }
    // }else{
    //   window.alert("Sum of EMI, Principle and Other charges must be equal to Total Amount")
    // }
   }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    //overflow: 'scroll',
    //paddingBottom: '30px',
  };

  return (
    <> 
      <Button style={{ color: "white", backgroundColor: "#A020F0", borderRadius: "5px", padding: "5px", textStyle: "none" }} onClick={handleOpen}>Reconciliation</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <Typography id="modal-modal-title" variant="h6" component="h2">

            <h2>Reconcile Payment</h2><br />

            <form >
            <div className='my-3' >
            <select className="form-select form-select-sm" style={{width: "700px"}}  name='department' aria-label=".form-select-sm example"  onChange={handleChange} required>
              <option selected>Select the Department</option>
              <option >Gold</option>
              <option >SBL</option>
            </select> 
            </div>
              <div className='my-3'>
              <input type="text" className="form-control"  placeholder='Enter the Loan Account Number'  name='loan_number'  onChange={handleChange} required/>
              </div>
              {values.department === "Gold" ?  <div>
                <button className='btn btn-primary' type='button'  onClick={handleSubmit} >Search</button><br/><br/>
              </div> : <div> 
              <button className='btn btn-primary' type='button'  onClick={handleSbl} >Search</button><br/><br/></div>}
      
            </form>
           {values.department === "SBL" && (
            sblCust && sblCust.applicant && sblCust.applicant.general_information ?
              <div>
                Amount Received : {reconcile_amt} <br/>
                <p>{sblCust.applicant.general_information.name}</p>
               <form style={{display:'flex', flexWrap:'wrap'}} onSubmit={(e)=>addSbl(e)} >
               {/* <label>Interest Amount: <input style={{width:'100px', height:"25px", borderRadius:"5px", marginRight:'25px'}} value={emi} type="number" name='emi' onChange={(e)=>setEmi(e.target.value)} required/> </label>
               <label>Principal Amount: <input style={{width:'100px', height:"25px", borderRadius:"5px", marginRight:'25px'}} value={principle_amt}  name="principle_amt" type="number"  onChange={(e)=>setPrinciple_amt(e.target.value)} required/> </label>
               <label>Other Charges: <input style={{width:'100px', height:"25px", borderRadius:"5px", marginRight:'25px'}} value={other_charges}  name='other_charges' type="number" onChange={(e)=>setOther_charges(e.target.value)} required/> </label> */}
             <button className='btn btn-danger' >Reconcile</button>
             </form> 
             </div> : <span style={{color: "red"}}>***No Data Found***</span>
            )}
            {values.department === "Gold" &&(
              cust&&cust.name ? <div>
                Amount Received : {reconcile_amt} <br />
                <p>{cust.name}</p>
                <form style={{display:'flex', flexWrap:'wrap'}} onSubmit={(e)=>addData(e)} >
                  {/* <label>Interest Amount: <input style={{width:'100px', height:"25px", borderRadius:"5px", marginRight:'25px'}} value={emi} type="number" name='emi' onChange={(e)=>setEmi(e.target.value)} required/> </label>
                  <label>Principal Amount: <input style={{width:'100px', height:"25px", borderRadius:"5px", marginRight:'25px'}} value={principle_amt}  name="principle_amt" type="number"  onChange={(e)=>setPrinciple_amt(e.target.value)} required/> </label>
                  <label>Other Charges: <input style={{width:'100px', height:"25px", borderRadius:"5px", marginRight:'25px'}} value={other_charges}  name='other_charges' type="number" onChange={(e)=>setOther_charges(e.target.value)} required/> </label> */}
                  {((cust.loan_status === "Locked"&&!roleChecker.hasAccountsAccess(user.role)) || ((cust.loan_status === "Closed" || cust.loan_status === "closed")&&!roleChecker.hasAccountsAccess(user.role))) ? <span>***Loan is Closed***</span> : <button className='btn btn-danger' >Reconcile</button> }
                </form>           
              </div>: <span style={{color: "red"}}>***No Data Found***</span>
            )}

          </Typography>

        </Box>
      </Modal>

    </>
  )
}

export default Reconciliation
