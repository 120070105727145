import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Backdrop,
  Fade,
  Fab,
  TextField,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import LoadingOverlay from "react-loading-overlay";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { employeeService, loanService } from "../../../_services";
import { makerCheckerServices } from "../../../_services/makerchecker.service";
import { OperationSubStages } from "../../../constants/operationsubstages";
import { SanctionSubStages } from "../../../constants/sanctionsubstages";
import { CreditSubStages } from "../../../constants/creditsubstages";
import { ApprovalRequestFilter } from "../../MakerCheckerFramework/ApprovalRequestFilter";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 6, 3),
    width: "600px",
  },
}));

const RevertStageModal = ({ cust }) => {
  const classes = useStyles();
  const [warningMessage, setWarningMessage] = useState("Reverting to any stage will  require additional approvals.");
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fEmployeeList, setFEmployeeList] = useState([]);
  const [alreadyRequestedMessage, setAlreadyRequestedMessage] = useState("");
  const user = employeeService.userValue;
  const stage = cust.stage;
  const [subStages,setSubStages] = useState([])
  const sanctionStages=[SanctionSubStages.SanctionLetterPending,SanctionSubStages.SanctionLetterVerification, SanctionSubStages.SanctionLetterSentToBranch,SanctionSubStages.SanctionLetterIMDPending,SanctionSubStages.AddColender]; 
  const creditStages=[CreditSubStages.CreditFileAssigned, CreditSubStages.ApplicationFormSigningPending,CreditSubStages.CAMPending,CreditSubStages.ChequePending,CreditSubStages.DocumentCollectionPending,CreditSubStages.ENachPending,CreditSubStages.FCUReportPending,CreditSubStages.FIReportPending,CreditSubStages.FileBeingSentToHO,CreditSubStages.GuarantorAdditionPending,CreditSubStages.HOApprovalPending,CreditSubStages.IMDandPropertyPaperPending, CreditSubStages.LegalReportPending,CreditSubStages.ValuationReportPending];
const opsSubStages=[OperationSubStages.FileReceived,OperationSubStages.FileChecked,OperationSubStages.PendencySentToBranch,OperationSubStages.PendencyReceivedFromBranch,OperationSubStages.NonBranchOtherPendency,OperationSubStages.PendencyClear,OperationSubStages.SentToOpsTeam,OperationSubStages.ColendingPending, OperationSubStages.PropertyPapersAtHO];

  let previousStages = [];
  let approvalAccess = "isAccountChecker";

  if (stage === "Operation") {
    previousStages = ["Sanction", "Credit"];
    approvalAccess = "isHOOpsApproverAccess";
  } else if (stage === "Disbursal") {
    previousStages = ["Sanction", "Credit", "Operation"];
    approvalAccess = "isAccountChecker";
  }

  const initialValues = {
    current_stage: stage,
    requested_stage: "",
    requested_sub_stage:"",
    next_stage_emp_id: "",
    comments: "",
  };

  const validationSchema = Yup.object({
    requested_stage: Yup.string().required("Please select a previous stage."),
    requested_sub_stage: Yup.string().required("Please select a sub-stage."),

    next_stage_emp_id: Yup.string().required("Please select an employee."),
    comments: Yup.string().required("Comments are required."),
  });

  const handleChange = (field, value, setFieldValue) => {
    if (field === "next_stage_emp_id") {
      setFieldValue(field, parseInt(value));
    } else {
      setFieldValue(field, value);
    }
    if(field=="requested_stage"){
      switch (value) {
        case "Operation":
          setSubStages(opsSubStages);
          break;
        case "Sanction":
          setSubStages(sanctionStages);
          break;
        case "Credit":
          setSubStages(creditStages);
          break;
        default:
          setSubStages([]);
      }
    }
   
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (values, { setSubmitting }) => {
    console.log("Form Submitted:", values);
    values["requested_by"] = user.emp_id;
    values["loan_number"] = cust.application_id;

    setSubmitting(false);
    await ApprovalRequestFilter.RequestFilter({
      service: "loanService",
      api: "revertStageRequest",
      id: values.loan_number,
      task: "Revert Stage Request",
      updated_fields: [
        "loan_number",
        "requested_by",
        "requested_stage",
        "requested_sub_stage",
        "current_stage",
        "next_stage_emp_id",
        "comments",
      ],
      sensitive_fields: [],
      new_values: values,
      old_values: {},
      identification_key: "Loan Number",
      identification_value: values.loan_number,
      emp_id: user.emp_id,
      approval_needed: true,
      approved: false,
      approval_access: approvalAccess,
      product: "sbl",
    })
      .then((x) => {
        console.log(x);
        alert("Request Sent to Concerned Authority");
        window.location.reload();
      })
      .catch((err) => {
        window.alert(err);
        console.log(err);
      });

    handleClose();
  };

  useEffect(() => {
    if (open) {
      employeeService
        .getAll()
        .then((el) => {
          setFEmployeeList(el);
        })
        .catch(() => {
          console.log("Unable to get employee list");
        });

      makerCheckerServices
        .checkAlreadyRequested({
          id: cust.application_id,
          api: "revertStageRequest",
          service: "loanService",
        })
        .then((res) => {
          if (res && !res.status) {
            setAlreadyRequestedMessage(res.message);
          }
        })
        .catch((err) => console.log("Error checking request:", err));
    }
  }, [open]);

  return (
    <div>
      <Button onClick={handleOpen} variant="outlined" color="primary">
        Revert Stage
      </Button>
      <Modal
        aria-labelledby="revert-modal-title"
        aria-describedby="revert-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <LoadingOverlay
            active={isLoading}
            spinner
            text="Loading Previous Stages..."
          >
            <div className={classes.paper}>
              {!isLoading && (
                <>
                  <h2 id="revert-modal-title">Revert Loan Stage</h2>

                  {alreadyRequestedMessage ? (
                    <p>{alreadyRequestedMessage}</p>
                  ) : (
                    <Formik
                      validationSchema={validationSchema}
                      initialValues={initialValues}
                      onSubmit={onSubmit}
                      enableReinitialize
                      id="revert-modal-description"
                    >
                      {({
                        errors,
                        touched,
                        isSubmitting,
                        setFieldValue,
                        values,
                      }) => (
                        <Form>
                          <div  >
                            <div className="form-group col">
                              <p>Current Stage:</p>
                              <Field
                                name="current_stage"
                                type="text"
                                className="form-control"
                                disabled
                                value={values.current_stage}
                              />
                            </div>
                          </div>

                          <div  >
                            <div className="form-group col">
                              <p>Select Previous Stage:</p>
                              <Field
                                as="select"
                                className={`form-control ${
                                  errors.requested_stage &&
                                  touched.requested_stage
                                    ? "is-invalid"
                                    : ""
                                }`}
                                name="requested_stage"
                                onChange={(e) =>
                                  handleChange(
                                    "requested_stage",
                                    e.target.value,
                                    setFieldValue
                                  )
                                }
                              >
                                <option value="" disabled>
                                  Requested Stage
                                </option>
                                {previousStages.map((stage, idx) => (
                                  <option value={stage} key={idx}>
                                    {stage}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                name="requested_stage"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
<div className="form-row">
    
                {   subStages && subStages.length >0   && (
                      <div className="form-group col">
                      <p>Select Next Step:</p>
                      <Field
                        as="select"
                        className="form-control" 
                        name="requested_sub_stage"
                        label="Next Stage Step" 
                      >
                        <option value="" disabled>Select Sub stages</option> 
                        {subStages && subStages.map((substage,idx)=> (
                          <option value={substage} key={idx}>{substage}</option>
                        ))}
                      </Field>
                      </div>
                )}
                </div>
                          <div  >
                            <div className="form-group col">
                              <label>Select Next Stage Owner</label>
                              <Autocomplete
                                options={fEmployeeList}
                                getOptionLabel={(option) =>
                                  `${option.first_name} ${option.last_name} - ${option.role} - ${option.emp_id}`
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Search Employee"
                                    variant="outlined"
                                    error={
                                      errors.next_stage_emp_id &&
                                      touched.next_stage_emp_id
                                    }
                                    helperText={
                                      errors.next_stage_emp_id &&
                                      touched.next_stage_emp_id &&
                                      errors.next_stage_emp_id
                                    }
                                  />
                                )}
                                onChange={(e, value) => {
                                  const empId = value ? value.emp_id : "";
                                  handleChange(
                                    "next_stage_emp_id",
                                    empId,
                                    setFieldValue
                                  );
                                }}
                              />
                            </div>
                          </div>

                          <div  >
                            <div className="form-group col">
                              <label>Comments</label>
                              <Field
                                name="comments"
                                type="text"
                                className="form-control"
                              />
                              <ErrorMessage
                                name="comments"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>

                          <div className="row justify-content-around">
                            <p style={{ color: "red" }}>{warningMessage}</p>
                          </div>

                          <hr />

                          <div className="row justify-content-around">
                            <Fab
                              type="submit"
                              disabled={isSubmitting}
                              color="primary"
                              aria-label="save"
                            >
                              {isSubmitting && (
                                <span className="spinner-border spinner-border-sm mr-1"></span>
                              )}
                              <SaveIcon />
                            </Fab>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  )}
                </>
              )}
            </div>
          </LoadingOverlay>
        </Fade>
      </Modal>
    </div>
  );
};

export default RevertStageModal;
