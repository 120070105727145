import React from "react";

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Collapse
} from "@material-ui/core";
import { ExpandMore,ExpandLess,AccountBalanceWallet,MonetizationOn} from "@material-ui/icons";
import roleChecker from "../../_helpers/rolechecker";

export default function AccountingMenuList(props) {
  const [open, setOpen] = React.useState(false);
  const { path } = props.match;
  const user =props.user;

  const handleClick = () => {
    setOpen((prev) => !prev);
  };
  return (
    <React.Fragment>
    <ListItem button onClick={handleClick}>
    <ListItemIcon>
         <MonetizationOn></MonetizationOn>
    </ListItemIcon>
        <ListItemText primary="Accounting" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" style={{paddingLeft:"50px"}}>
        <ListItem
            hidden={!roleChecker.hasAccountsAccess(user.role)}
            button
            key="prl"
            component="a"
            href={`${path}/accounting/reconcilationlist`}
          >
            <ListItemText primary="Reconcilation" />
          </ListItem>
        <ListItem
            hidden={!roleChecker.hasAccountsAccess(user.role)}
            button
            key="avap"
            component="a"
            href={`${path}/virtualaccounts/payments`}
          >
            <ListItemText primary="QRCode Payments" />
          </ListItem>

          <ListItem
            hidden={!roleChecker.hasAccountsAccess(user.role)}
            button
            key="acvap"
            component="a"
            href={`${path}/payments`}
          >
            <ListItemText primary="CASH Payments" />
          </ListItem>
        <ListItem
            hidden={!roleChecker.hasAccountsAccess(user.role)}
            button
            key="avar"
            component="a"
            href={`${path}/paymentreceipts`}
          >
            <ListItemText primary="Payment Receipts" />
          </ListItem>
          <ListItem
            hidden={!(roleChecker.hasAccountsAccess(user.role) && !["GM"].includes(user.role))  } 
            button
            key="prl"
            component="a"
            href={`${path}/cashmanagement/bankledgerlist`}
          >
            <ListItemText primary="Bank Ledgers" />
          </ListItem>
        <ListItem
              hidden={!roleChecker.hasAccountsAccess(user.role)}
              button
              key="afees"
              component="a"
              href={`${path}/sblloans/applicationfeeslist`}
            >
              <ListItemText primary="Application Fees" />
           </ListItem>

           {/* <ListItem
            hidden={!roleChecker.hasAccountsAccess(user.role)}
            button
            key="prl"
            component="a"
            href={`${path}/accounting/termloans`}
          >
            <ListItemText primary="Term Loans" />
          </ListItem> */}
           <ListItem
            hidden={!roleChecker.hasAccountsAccess(user.role)}
            button
            key="prl"
            component="a"
            href={`${path}/accounting/ledgerdetails`}
          >
            <ListItemText primary="Ledgers" />
          </ListItem>
          <ListItem
            hidden={!roleChecker.hasAccountsAccess(user.role)}
            button
            key="prl"
            component="a"
            href={`${path}/accounting/fundusages`}
          >
            <ListItemText primary="Fund Use" />
          </ListItem>
        </List>
      </Collapse>
      </React.Fragment>
  )
}
