import React, { useEffect, useState } from 'react';
import { legalServices } from '../../../_services/legal_cases.services';
import LegalCasesPersonalInfo from "../../Legal/LegalCases/LegalCasesPersonalInfo";
import { CircularProgress, Box, Typography } from '@material-ui/core';

function LegalCasesOnLoan({ cust }) {
    const [legalInfo, setLegalInfo] = useState(null); // Ensure `useState` is initialized with `null`
    const [spinner, setSpinner] = useState(true);

    // Fetch legal cases data
    async function fetchData() {
        try {
            setSpinner(true);
            const legalData = await legalServices.getLegalCasesOfLoan({
                application_id: cust.application_id,
            });
            console.log('Response is:', legalData);
            setLegalInfo(legalData[0]); // Set the first legal case if available
        } catch (err) {
            console.error('Error fetching legal cases:', err);
        } finally {
            setSpinner(false);
        }
    }

    useEffect(() => {
        if (cust?.application_id) {
            fetchData();
        }
    }, [cust]); // Dependency array ensures the effect runs when `cust` changes

    return (
        <div>
            <Typography variant="h5" gutterBottom>
                Legal Cases Personal Info
            </Typography>

            {spinner ? (
                <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '200px' }}>
                    <CircularProgress />
                </Box>
            ) : legalInfo ? (
                <LegalCasesPersonalInfo data={legalInfo} />
            ) : (
                <p>No legal information available</p>
            )}
        </div>
    );
}

export default LegalCasesOnLoan;
