import React from 'react'
import { Route, Switch } from 'react-router-dom';
import LegalCasesList from './LegalCasesList';
import LegalCasesPersonalInfo from './LegalCasesPersonalInfo';
import LegalPersonalCase from './LegalPersonalCase';
const LegalCasesIndex = ({match}) => {
  const {path}=match;
  return (
    <div>
      <Switch>
        <Route  path={`${path}/legalcaseslist`} component={LegalCasesList}/>
        <Route path={`${path}/personalinfo`} component={LegalPersonalCase}/>
      </Switch>
    </div>
  )
}

export default LegalCasesIndex
