// // src/_services/scanDocumentService.js
// import { fetchWrapper } from "../_helpers";

// const apiUrl = process.env.REACT_APP_API_URL;
// const baseUrl = `${apiUrl}/scandocument`;

// export const scanDocumentService = {
//     upload,
//     getDocuments
// };

// function upload(payload) {
//   return fetchWrapper.post(`${baseUrl}/upload`, payload);
// }

// function getDocuments() {
//   return fetchWrapper.get(`${baseUrl}/documents`);
// }


// src/_services/scanDocumentService.js
// import { fetchWrapper } from "../_helpers";

// const apiUrl = process.env.REACT_APP_API_URL;
// const baseUrl = `${apiUrl}/scandocument`;

// export const scanDocumentService = {
//     upload,
//     getDocuments
// };

// function upload(payload) {
//   return fetchWrapper.post(`${baseUrl}/upload`, payload);
// }

// function getDocuments() {
//   return fetchWrapper.get(`${baseUrl}/documents`);
// }



// import { fetchWrapper } from "../_helpers";

// const apiUrl = process.env.REACT_APP_API_URL;
// const baseUrl = `${apiUrl}/scandocument`;

// export const scanDocumentService = {
//     upload,
//     getDocuments,
//     searchData
// };

// function upload(payload) {
//   return fetchWrapper.post(`${baseUrl}/upload`, payload);
// }

// function getDocuments() {
//   return fetchWrapper.get(`${baseUrl}/documents`);
// }

// function searchData(file_no) {
//   return fetchWrapper.post(`${baseUrl}/searchData`, { file_no });
// }



import { fetchWrapper } from "../_helpers";

const apiUrl = process.env.REACT_APP_API_URL;
const baseUrl = `${apiUrl}/scandocument`;

export const scanDocumentService = {
    upload,
    getDocuments,
    searchData,
    searchLoan,
    getDocumentCounts
};

function upload(payload) {
  return fetchWrapper.post(`${baseUrl}/upload`, payload);
}

function getDocuments() {
  return fetchWrapper.get(`${baseUrl}/documents`);
}

function searchData(file_no) {
  return fetchWrapper.post(`${baseUrl}/searchData`, { file_no });
}

// frontend service code (scanDocumentService.js)
// frontend: scanDocumentService.js
function searchLoan(data) {
  console.log('Request data:', data); // Log the request data
  return fetchWrapper.post(`${baseUrl}/searchCustomer`, data);
}


// New function to get document counts
function getDocumentCounts() {
  return fetchWrapper.get(`${baseUrl}/documentCounts`);
}