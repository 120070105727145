
import React, { useEffect, useRef } from "react"
import { useState } from "react"
import { Modal, Box, Typography, Button } from "@material-ui/core"
import { loanService } from "../../../../_services"
import { employeeService } from "../../../../_services"
import { ledgerService } from "../../../../_services/ledger.service"
import { customerService } from "../../../../_services"
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';


export const MobileOtpVerificationModel = (props) => {
    console.log("props is role",props)
    const [customerData,setCustomerData]=useState()
    const [open, setOpen] = useState(false)
    const [otp, setOtp] = useState({ otp1: '', otp2: '', otp3: '', otp4: '' })
    const [otpResponse, setOtpResponse] = useState('')
    const formRef=useRef()
    const user = employeeService.userValue
   const [isVerified,setIsVerified]=useState(false);
    const emp_contact = props.customer && props.customer.general_details && props.customer.general_details.customer_mobile_num
    // console.log("emp_contact",emp_contact)
    const customer = props.customer
    const customer_id=customer.customer_id
    // console.log("customer_id",customer_id)

async function findCustomer(){

    const customer_val= await customerService.searchCustomer({customer_id:customer_id}).then((res)=>{

        // console.log("customer_val",res )
        setIsVerified(res[0].general_details.mobile_no_verified);
        setCustomerData(res[0]);
        // console.log("is_verified",isVerified)
    })
    .catch((err)=>console.log("err",err))
}
console.log(isVerified,"isVerifiedisVerifiedisVerifiedisVerified")

    const handleOpen = () => {
        setOpen(true)
         sendOtp();
    }

    const handleClose = () => {
        setOtp({ otp1: '', otp2: '', otp3: '', otp4: '' })
        setOpen(false)
    }

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "680px",
        height: "480px",
        bgcolor: "background.paper",
        border: "2px solid #000",
        // overflow: "scroll",
        boxShadow: 24,
        p: 4,
    };

    const inputFocus = (elmnt) => {
        if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
            const next = elmnt.target.tabIndex - 2;
            if (next > -1) {
                elmnt.target.form.elements[next].focus()
            }
        }
        else {
            console.log("next");
            const next = elmnt.target.tabIndex;
            if (next < 4) {
                elmnt.target.form.elements[next].focus()
            }
        }
    }

    const handleChange = (e) => {
        let item = { ...otp }
        item[e.target.name] = e.target.value
        setOtp(item)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let input_otp = otp.otp1 + otp.otp2 + otp.otp3 + otp.otp4
     
        
        if (input_otp.toString() === otpResponse.toString()) {
            // console.log('matches',props.id,props.role)
   
            customerService.verifyCustomerMobileNumber(customerData._id,{application_id:props.application_id,role:props.role}).then(res=>{
                // console.log("response is ",res)
                // console.log("hey otp is write")
                window.location.reload();
            }).catch(err=>{
                console.log("err is",err);
            })
               
        }
         else {
            window.alert('Incorrect Otp')
            setOtp({ otp1: '', otp2: '', otp3: '', otp4: '' })
        }
    }

    // function autoSubmit() {
    //     if (otp.otp1 !== '' && otp.otp2 !== '' && otp.otp3 !== '' && otp.otp4 !== '') {
    //         formRef.current.click()
    //     }
    // }

    // useEffect(()=>{
    //     autoSubmit()
    // })

    const resendOtp = () => {
        setOtp({ otp1: '', otp2: '', otp3: '', otp4: '' })
        sendOtp()
    }

    const sendOtp = () => {
        let random_num = Math.floor(1000 + Math.random() * 9000);
        // console.log("test otp", random_num)
        loanService.sendOtp({ contact: emp_contact, otp: random_num })
            .then((data) => {
                setOtpResponse(data.otp)
                // setSentOtp(true)
                // console.log("true response",data)
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        findCustomer();
      }, [isVerified])
    // console.log("customerData",customerData)
    return (
        <div>
            {/* {isVerified ? <button style={{ backgroundColor: "green", height: "30px", width: "80px" ,color: "white"}}
                variant="outlined" disabled>Verified <VerifiedUserIcon/> </button>: <button onClick={handleOpen}
                style={{ backgroundColor: "blue", height: "30px", width: "80px",color: "white" }}>
                Verify
            </button>} */}
            {isVerified ? <span title="Verified">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-patch-check" viewBox="0 0 16 16" style={{color:"green",paddingLeft:"3px",cursor:"pointer"}}>
             <path fillRule="evenodd" d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0"/>
             <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622-.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911z"/>
         </svg> </span>:
            <span onClick={handleOpen}   title="Verify">  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-patch-plus" viewBox="0 0 16 16" style={{ transform: 'rotate(45deg)' ,color:"red",paddingLeft:"3px",cursor:"pointer"}}>
            <path fillRule="evenodd" d="M8 5.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V10a.5.5 0 0 1-1 0V8.5H6a.5.5 0 0 1 0-1h1.5V6a.5.5 0 0 1 .5-.5"/>
            <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622-.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911z"/>
        </svg></span>}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={style}>
                    <Box style={{ fontSize: 'medium' }}>
                        <div
                            style={{
                                border: '1px solid Black',
                                width: '580px',
                                height: '380px',
                                margin: 'auto',
                                marginTop: '10px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                paddingTop: '20px'
                            }}>
                            <h3><u>Verify OTP</u></h3>
                            <div
                                style={{
                                    border: '1px solid Black',
                                    width: '500px',
                                    height: '250px',
                                    marginTop: '5px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-around'
                                }}>
                                <h5>OTP has sent to the mobile number: xxxxxx{emp_contact && emp_contact.toString().slice(-4) && <>{emp_contact.toString().slice(-4)}</>}</h5>
                                <h5>Enter the OTP below</h5>
                                <form
                                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                                    id='form'
                                   >
                                    <div
                                        style={{
                                            width: '250px',
                                            display: 'flex',
                                            marginBottom: '20px',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <input
                                            style={{ border: '1px solid Black', width: '40px', height: '45px', borderRadius: '5px', textAlign: 'center' }}
                                            autoComplete='off'
                                            name='otp1'
                                            tabIndex='1'
                                            maxLength='1'
                                            type="text"
                                            value={otp.otp1}
                                            onKeyUp={e => inputFocus(e)}
                                            onChange={e => handleChange(e)}
                                            required />
                                        <input
                                            style={{ border: '1px solid Black', width: '40px', height: '45px', borderRadius: '5px', textAlign: 'center' }}
                                            autoComplete='off'
                                            name="otp2"
                                            tabIndex='2'
                                            maxLength='1'
                                            type="text"
                                            value={otp.otp2}
                                            onKeyUp={e => inputFocus(e)}
                                            onChange={e => handleChange(e)}
                                            required />
                                        <input
                                            style={{ border: '1px solid Black', width: '40px', height: '45px', borderRadius: '5px', textAlign: 'center' }}
                                            autoComplete='off'
                                            name='otp3'
                                            tabIndex='3'
                                            maxLength='1'
                                            type="text"
                                            value={otp.otp3}
                                            onKeyUp={e => inputFocus(e)}
                                            onChange={e => handleChange(e)}
                                            required />
                                        <input
                                            style={{ border: '1px solid Black', width: '40px', height: '45px', borderRadius: '5px', textAlign: 'center' }}
                                            autoComplete='off'
                                            name='otp4'
                                            tabIndex='4'
                                            maxLength='1'
                                            type="text"
                                            value={otp.otp4}
                                            onKeyUp={e => inputFocus(e)}
                                            onChange={e => handleChange(e)}
                                            required />
                                    </div>
                                    <div>
                                        <Button
                                            color="primary"
                                            variant="outlined"
                                            id="form_button"
                                            ref={formRef}
                                            type="button"
                                            onClick={handleSubmit}
                                        >
                                            Verify
                                        </Button>
                                        <Button
                                            color="secondary"
                                            variant="outlined"
                                            style={{ marginLeft: '10px' }}
                                            type="button"
                                            onClick={resendOtp}
                                        >
                                            Resend OTP
                                        </Button>
                                    </div>
                                </form>
                            </div>
                            {/* {console.log(otp)} */}
                        </div>
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}
