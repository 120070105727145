import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import logo from '../../assets/kaabil.png'
import datehelper from "../../_helpers/datehelper";
import InvoiceQRCode from './InvoiceQRCode';

export const PrintReceipt = (props) => {
    const [datea, setDatea] = useState()
    const [timea, setTimea] = useState()
    const [amount, setAmount] = useState()
    const [cgst, setCgst] = useState(0)
    const [sgst, setSgst] = useState(0)
    const receipt = props.location.state.receipt
    const receiptRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => receiptRef.current
    })

    useEffect(() => {
        function getDate() {
            let dates = new Date(receipt.date)
            // {console.log(dates)}
            let months=["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT",
            "NOV", "DEC"]
            let xx = dates.getDate();
            let yy = dates.getMonth();
            let zz = dates.getFullYear();
            let date = `${xx}` + ` ` + `${months[yy]}` + ` ` + `${zz}`;
            let h = dates.getHours();
            let m = dates.getMinutes();
            let zone = "AM"
            if(h>=12){
                zone = "PM"
            if(h > 12){
                h = Number(h)-12
            }
        }
            let time = `${h}:${m} ${zone}`
            setDatea(date)
            setTimea(time)
        }
        function getAmount() {
            if (receipt.value === 'EMI') {
                setAmount(receipt.payment)
                // console.log('emi')
            }
            else {
                let pay = receipt.payment
                let gs = Math.round(pay * .09 / 1.18);
                let amt = pay - 2 * gs;
                setAmount(amt);
                setCgst(gs)
                setSgst(gs)
            }
        }
        getDate()
        getAmount()
    },
        [])

    return (
        <div>
            <button onClick={handlePrint} >Print Receipt</button>
            <div ref={receiptRef}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '1500px',
                    width: '794px',
                    lineHeight: '1.6',
                    margin: 'auto',
                    fontSize: '30px'
                }} >
                    <img src={logo} style={{ width: '100%' }} alt="Logo" />
                    <br />
                    <div style={{display:'flex',justifyContent:'space-around'}}>

                    <div>
                        <div >
                            Invoice No. :  {receipt.invoice_no ? receipt.invoice_no: receipt.receipt_no} <br />
                            Loan Number :  {receipt.loan_no}  <br />
                            Name :  {receipt.cust_name}  <br />
                            Date: {datehelper.displayDateTime(new Date(receipt.date))} <br />
                            ................................................................<br />
                            Item : {receipt.value}    <br />
                            Mode :  {receipt.mode}      <br />
                            {(receipt.value === 'Loan Charges' || receipt.value==="Login Fee" )  ?
                                <div>
                                    Amount :  Rs.{amount}/- <br />
                                    CGST Charges (9%):  Rs.{cgst}/-  <br />
                                    SGST Charges (9%):  Rs.{sgst}/-  <br />
                                </div>
                                : null}
                            Total Amount :  Rs.{receipt.payment}/- <br />
                            ................................................................ <br />
                        </div>
                        <div>
                            Kaabil Finance Pvt. Ltd. <br />
                            Kaabil Towers, Plot No. 1, Scheme No. 1 <br/>
                            Income Tax Colony, Model Town, Jagatpura <br/>
                            Jaipur Rajasthan 302017 <br /> <br />
                            Thank You
                        </div>
                    </div>
                    <div>
                    <InvoiceQRCode invoiceNumber={receipt.invoice_no ? receipt.invoice_no: receipt.receipt_no} amount={receipt.payment} />
                    </div>
                    </div>
                </div>
            </div>
        </div>

    )
}