import { fetchWrapper } from "../_helpers";

const apiUrl = process.env.REACT_APP_API_URL;

const baseUrl = `${apiUrl}/loan_collection`;

export const collectionService = {
  getCollectionDetailsbyLoan,
  processReceipt,
  pdfReceipt,
  uploadCsv,
  fetchPayments,
  fetchPaymentsByEmpId,
  create,
  deletePayment,
  deleteCollectionList,
  getAll,
  delete: _delete,
  branchCollectionSummary,
  getBranchTransactions,
  saveDepositSlip,
  getDepositSlip,
  confirmSlip,
  countSlip,
  verifyDepositAmount,
  kpAssignment,
  totalLoandsAssignedToKp,
  viewAssignedLoansToKps,
  getAllCallOFLoans,
};

function uploadCsv(data) {
  return fetch(`${baseUrl}/importdata`, {
    method: "POST",
    body: data,
    headers:fetchWrapper.authHeader(`${baseUrl}/importdata`),
  });
}

function processReceipt(params) {
  return fetchWrapper.post(`${apiUrl}/receipts`, params);
}

function pdfReceipt(params) {
  return fetchWrapper.post(`${baseUrl}/receipt`, params);
}

function getAll() {
  return fetchWrapper.get(baseUrl);
}

function fetchPayments() {
  return fetchWrapper.get(`${apiUrl}/receipts`);
}


function fetchPaymentsByEmpId() {
  return fetchWrapper.post(`${apiUrl}/receipts/empid/`,{});
}


function deletePayment(id) {
  return fetchWrapper.delete(`${apiUrl}/receipts/${id}`);
}

// prefixed with underscore because 'delete' is a reserved word in javascript
function _delete(id) {
  return fetchWrapper.delete(`${baseUrl}/${id}`);
}
function deleteCollectionList() {
  return fetchWrapper.post(`${baseUrl}/deletecollectionlist`,{});
}
function getCollectionDetailsbyLoan(loan_no) {
  return fetchWrapper.get(`${baseUrl}/loan/${loan_no}`);
}
function create(params) {
  return fetchWrapper.post(`${baseUrl}/addcollection`, params);
}


async function branchCollectionSummary(params) {
  return fetchWrapper.post(`${baseUrl}/branchcollectionsummary`, params);
}
function getBranchTransactions(params){
  return fetchWrapper.post(`${baseUrl}/getbranchtransactions`,params)
}
function saveDepositSlip(params){
  return fetchWrapper.post(`${baseUrl}/savedepositslip`,params)
}
function getDepositSlip(){
  return fetchWrapper.get(`${baseUrl}/sbl/getdepositslip`)
}
function confirmSlip(id,params){
  return fetchWrapper.put(`${baseUrl}/confirmslip/${id}`,params)
}
function countSlip(){
  return fetchWrapper.get(`${baseUrl}/sbl/countslip`)
}
function verifyDepositAmount(id){
  return fetchWrapper.put(`${baseUrl}/sbl/verifyDepositAmount/${id}`)
}
function kpAssignment(params){
  return fetchWrapper.post(`${baseUrl}/kp_assignment`,params) 
}
function totalLoandsAssignedToKp(params){
  return fetchWrapper.post(`${baseUrl}/totalloansassignedtokp`,params) 
}
function viewAssignedLoansToKps(params){
  return fetchWrapper.post(`${baseUrl}/viewassignedloans`,params) 
}
function getAllCallOFLoans(params){
  return fetchWrapper.post(`${apiUrl}/customercare/getcallresofloan`,params) 
}