import React, { useState, useEffect } from 'react';
import {
  Warning as AlertCircle,
  Description as FileText,
  Visibility as Eye,
  GetApp as Download,
  CloudUpload as Upload,
  CheckCircle as CheckCircle2,
  FileCopy as Copy
} from '@material-ui/icons';
import { scanDocumentService } from '../../../_services/scanDocumentService';
import { uploadFileToBucket } from '../../../_helpers/upload';
import swal from 'sweetalert';

const DocumentUploadSystem = () => {
  const [documents, setDocuments] = useState([]);
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [loanNumber, setLoanNumber] = useState('');
  const [isLoanVerified, setIsLoanVerified] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [comment, setComment] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [totalDocumentsCount, setTotalDocumentsCount] = useState(0);
  const [todayDocumentsCount, setTodayDocumentsCount] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const user = JSON.parse(localStorage.getItem('user'));
  const bucket = process.env.REACT_APP_s3docsBucket;
  const Role = user?.role;
  console.log("Role",Role)
  const handleSearch = async () => {
    if (searchQuery.trim() === '') {
      swal({
        title: 'Input Required',
        text: 'Please enter a loan number.',
        icon: 'warning',
        button: 'OK',
      });
      return;
    }
  
    try {
      const result = await scanDocumentService.searchData(searchQuery);
  
      if (!result.error) {
        if (result.data && result.data.length > 0) {
          // Loan number found
          setDocuments(result.data);
          swal({
            title: 'Loan Verified',
            text: 'Loan number searched successfully.',
            content: {
              element: 'div',
              attributes: {
                innerHTML: `
                  <div style="
                    padding: 16px; 
                    background-color: #f9f9f9; 
                    border: 1px solid #ddd; 
                    border-radius: 8px; 
                    max-height: 300px; 
                    overflow-y: auto;
                    font-family: Arial, sans-serif;
                  ">
                    ${result.data
                      .map(
                        (doc) => `
                      <div style="
                        margin-bottom: 16px; 
                        padding: 12px; 
                        border: 1px solid #ccc; 
                        border-radius: 4px; 
                        background-color: #ffffff;
                      ">
                        <p style="margin: 0; font-weight: bold; color: #333;">Document No:</p>
                        <p style="margin: 0; color: #555;">${doc.file_no}</p>
                      </div>
                    `
                      )
                      .join('')}
                  </div>
                `,
              },
            },
            buttons: {
              confirm: {
                text: 'Close',
                value: true,
                visible: true,
                className: 'btn btn-primary',
                closeModal: true,
              },
            },
          });
          
        } else {
          // Loan number not found
          swal({
            title: 'Loan Not Found',
            text: 'The entered loan number is incorrect or does not exist.',
            icon: 'error',
            button: 'OK',
          });
        }
      } else {
        swal({
          title: 'Error',
          text: 'An error occurred while searching for the loan number.',
          icon: 'error',
          button: 'OK',
        });
        console.error('Error searching documents:', result.message);
      }
    } catch (error) {
      swal({
        title: 'Error',
        text: 'An unexpected error occurred. Please try again later.',
        icon: 'error',
        button: 'OK',
      });
      console.error('Error searching documents:', error.message);
    }
  };
  
  

  const modalStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: isUploadDialogOpen ? 'flex' : 'none',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000
    },
    content: {
      backgroundColor: 'white',
      padding: '24px',
      borderRadius: '8px',
      width: '90%',
      maxWidth: '500px',
      position: 'relative'
    }
  };

  const fetchDocumentCounts = async () => {
    try {
      const response = await scanDocumentService.getDocumentCounts(); // This new API gets the counts
      if (response.data) {
        setTotalDocumentsCount(response.data.totalDocuments); // Set total documents
        setTodayDocumentsCount(response.data.todayDocuments); // Set today's documents
      }
    } catch (error) {
      console.error('Error fetching document counts:', error);
    }
  };
 
  useEffect(() => {
   fetchDocumentCounts(); // Fetch document counts when component mounts
 }, []);
 
 // Fetch all documents and count logic
 const fetchDocuments = async () => {
   try {
     const response = await scanDocumentService.getAllDocuments(); // Assuming this service gets all documents
     if (response.data) {
       setDocuments(response.data);
     }
   } catch (error) {
     console.error('Error fetching documents:', error);
   }
 };
 
 // Call fetchDocuments when component mounts
 useEffect(() => {
   fetchDocuments();
 }, []);
  const handleVerifyLoan = async () => {
    if (loanNumber.trim() === '') {
      swal({
        title: 'Input Required',
        text: 'Please enter a loan number.',
        icon: 'warning',
        button: 'OK'
      });
      return;
    }
  
    try {
      const file_no = loanNumber.trim();
      console.log("Sending file_no:", file_no);
  
      const response = await scanDocumentService.searchLoan({ file_no });
  
      if (response.error) {
        swal({
          title: 'Error',
          text: response.message || 'Loan number is incorrect.',
          icon: 'error',
          button: 'OK'
        });
        setIsLoanVerified(false);
        return;
      }
  
      if (response.data) {
        setIsLoanVerified(true);
        setShowSuccess(true);
        setTimeout(() => setShowSuccess(false), 3000);
        swal({
          title: 'Loan Verified',
          text: 'Loan number verified successfully.',
          icon: 'success',
          button: 'OK'
        });
      } else {
        setIsLoanVerified(false);
        swal({
          title: 'Verification Failed',
          text: 'Loan number not found.',
          icon: 'error',
          button: 'OK'
        });
      }
    } catch (error) {
      console.error('Error verifying loan number:', error);
      swal({
        title: 'Error',
        text: 'Loan number is incorrect.',
        icon: 'error',
        button: 'OK'
      });
    }
  };
  
  console.log("loan number",loanNumber)

  const handleFileSelect = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files); // No size check
  };

  const handleUpload = async () => {
    if (selectedFiles.length > 0 && isLoanVerified) {
      setSpinner(true);
      setIsUploading(true); // Disable upload button
      const documentUrls = [];
  
      for (const file of selectedFiles) {
        const fileExtension = file.name.split('.').pop().toLowerCase();
        let num1 = Math.floor(Math.random() * 1000);
        let num2 = Math.floor(Math.random() * 100);
        const filePath = `${bucket}/loandata/scandocuments/${num1}.${fileExtension}`;
  
        try {
          const link = await uploadFileToBucket(bucket, file.type, filePath, file);
          console.log('File uploaded successfully:', link);
          documentUrls.push(link);
        } catch (error) {
          console.error('Error uploading document:', error);
          alert('Failed to upload document. Please try again.');
        }
      }
  
      try {
        const payload = {
          file_no: loanNumber,
          comment: comment,
          documentUrl: documentUrls
        };
  
        const result = await scanDocumentService.upload(payload);
  
        if (result.error) {
          alert('Failed to upload some documents. Please try again.');
          console.error('Error uploading documents:', result.message);
        } else {
          setDocuments([...documents, result.data]);
          setIsUploadDialogOpen(false);
          setLoanNumber('');
          setIsLoanVerified(false);
          setSelectedFiles([]);
          setComment('');
          alert('Documents uploaded successfully!');
        }
      } catch (error) {
        console.error('Error uploading documents:', error);
        alert('Failed to upload documents. Please try again.');
      } finally {
        setSpinner(false);
      }
    }
  };

  const handleView = (urls) => {
    let currentIndex = 0;
  
    const showSlide = (index) => {
      swal({
        title: `Document ${index + 1} of ${urls.length}`,
        content: {
          element: 'div',
          attributes: {
            innerHTML: `
              <iframe src="${urls[index]}" width="100%" height="100%" style="border: none;"></iframe>
            `,
            style: 'width: 100%; height: calc(80vh - 120px); margin: 0 auto;',
          },
        },
        buttons: {
          copyUrl: {
            text: 'Copy URL',
            value: 'copyUrl',
            visible: true,
          },
          download: {
            text: 'Download',
            value: 'download',
            visible: true,
          },
          previous: {
            text: 'Previous',
            value: 'previous',
            visible: index > 0,
          },
          next: {
            text: 'Next',
            value: 'next',
            visible: index < urls.length - 1,
          },
          close: {
            text: 'Close',
            value: 'close',
          },
        },
        className: 'custom-swal',
        buttonsStyling: false,
      }).then((value) => {
        switch (value) {
          case 'copyUrl':
            navigator.clipboard.writeText(urls[index]);
            swal({
              title: 'Copied',
              text: 'The URL has been copied to your clipboard.',
              icon: 'success',
              timer: 2000,
              buttons: false,
            });
            showSlide(index);
            break;
          case 'download':
            // Open download URL in a new tab
            const newTab = window.open(urls[index], '_blank');
            if (newTab) newTab.focus(); // Ensure the tab gets focus
            showSlide(index); // Keep the modal open
            break;
          case 'previous':
            currentIndex -= 1;
            showSlide(currentIndex);
            break;
          case 'next':
            currentIndex += 1;
            showSlide(currentIndex);
            break;
          default:
            break;
        }
      });
    };
  
    const style = document.createElement('style');
    style.textContent = `
      .custom-swal {
        width: 50vw !important;
        margin: 0 auto;
        padding: 0 !important;
      }
      
      .custom-swal .swal-content {
        margin: 0;
        padding: 0;
        height: calc(80vh - 120px);
      }
      
      .custom-swal .swal-title {
        margin: 10px 0;
        padding: 0;
        font-size: 20px;
      }
      
      .custom-swal .swal-footer {
        margin: 15px 0;
        padding: 15px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 10px;
      }
      
      .custom-swal .swal-button {
        font-size: 14px;
        padding: 10px 20px;
        margin: 0;
        min-width: 100px;
        font-weight: 500;
      }
      
      @media (max-width: 768px) {
        .custom-swal {
          width: 90vw !important;
        }
        
        .custom-swal .swal-button {
          padding: 8px 16px;
          min-width: 80px;
        }
      }
    `;
    document.head.appendChild(style);
  
    showSlide(currentIndex);
  };
  
  
  
  const handleDownload = (urls) => {
    const downloadLinks = urls.map(url => `<a href="${url}" download style="display: block; margin-bottom: 10px;">${url.split('/').pop()}</a>`).join('');
    swal({
      title: 'Download Documents',
      content: {
        element: 'div',
        attributes: {
          innerHTML: downloadLinks,
          style: 'text-align: left;'
        }
      },
      buttons: {
        confirm: {
          text: 'Close',
          value: true,
          visible: true,
          className: 'btn btn-primary',
          closeModal: true
        }
      }
    });
  };

  const handleCopy = (urls) => {
    const copyLinks = urls.map(url => `<button onclick="navigator.clipboard.writeText('${url}')" style="display: block; margin-bottom: 10px;">Copy ${url.split('/').pop()}</button>`).join('');
    swal({
      title: 'Copy Document Links',
      content: {
        element: 'div',
        attributes: {
          innerHTML: copyLinks,
          style: 'text-align: left;'
        }
      },
      buttons: {
        confirm: {
          text: 'Close',
          value: true,
          visible: true,
          className: 'btn btn-primary',
          closeModal: true
        }
      }
    });
  };

  return (
    <div style={{ padding: '24px', maxWidth: '1000px', margin: '0 auto' }}>
                      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '24px'
      }}>
        {/* Card for Total Documents Count */}
        <div style={{
          backgroundColor: '#d4f7d4', // Light green
          borderRadius: '8px',
          padding: '24px',
          width: '48%',
          textAlign: 'center',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
        }}>
          <h3>Total Documents</h3>
          <p style={{ fontSize: '36px', fontWeight: '600' }}>{totalDocumentsCount}</p>
        </div>

        {/* Card for Today's Documents Count */}
        <div style={{
          backgroundColor: '#e0f7fa', // Light blue
          borderRadius: '8px',
          padding: '24px',
          width: '48%',
          textAlign: 'center',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
        }}>
          <h3>Today's Documents</h3>
          <p style={{ fontSize: '36px', fontWeight: '600' }}>{todayDocumentsCount}</p>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '24px' }}>
        <div>
          <h1 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '8px' }}>Scan Documents</h1>
          <p style={{ color: '#666' }}>Manage and access your scanned documents</p>
        </div>

        {(["DOCA", "Admin"].includes(user.role) || [201131, 22058].includes(user.emp_id)) && (
          <button
            onClick={() => setIsUploadDialogOpen(true)}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              padding: '10px 20px',
              backgroundColor: '#2563eb',
              color: 'white',
              border: 'none',
              borderRadius: '6px',
              cursor: 'pointer',
            }}
          >
            <Upload style={{ fontSize: 20 }} /> Upload New Document
          </button>
        )}
      </div>

      <div style={{ display: 'flex', gap: '8px', marginBottom: '24px' }}>
        <input
          type="search"
          placeholder="Search documents by file number..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{
            flex: 1,
            padding: '12px',
            border: '1px solid #ddd',
            borderRadius: '6px'
          }}
        />
        <button
          onClick={handleSearch}
          style={{
            padding: '12px 20px',
            backgroundColor: '#2563eb',
            color: 'white',
            border: 'none',
            borderRadius: '6px',
            cursor: 'pointer'
          }}
        >
          Search
        </button>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        {documents.map((doc) => (
          <div
            key={doc._id}
            style={{
              padding: '16px',
              border: '1px solid #ddd',
              borderRadius: '8px',
              backgroundColor: 'white'
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                <FileText style={{ color: '#2563eb' }} />
                <div>
                  <h3 style={{ fontWeight: '600', marginBottom: '4px' }}>{doc.title}</h3>
                  <p style={{ fontSize: '14px', color: '#666' }}>Document No: {doc.file_no}</p>
                  <p style={{ fontSize: '14px', color: '#666' }}>{doc.description}</p>
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                <span
                  style={{
                    padding: '4px 12px',
                    borderRadius: '9999px',
                    fontSize: '14px',
                    backgroundColor: doc.status === 'Verified' ? '#dcfce7' : '#fef9c3',
                    color: doc.status === 'Verified' ? '#166534' : '#854d0e'
                  }}
                >
                  {doc.status}
                </span>
                <button
                  onClick={() => handleView(doc.documentUrl)}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 16px',
                    border: '1px solid #ddd',
                    borderRadius: '6px',
                    backgroundColor: 'white',
                    cursor: 'pointer'
                  }}
                >
                  <Eye style={{ marginRight: '8px', fontSize: 20 }} /> View
                </button>
                {/* <button
                  onClick={() => handleDownload(doc.documentUrl)}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 16px',
                    border: '1px solid #ddd',
                    borderRadius: '6px',
                    backgroundColor: 'white',
                    cursor: 'pointer'
                  }}
                >
                  <Download style={{ marginRight: '8px', fontSize: 20 }} /> Download
                </button> */}
                {/* <button
                  onClick={() => handleCopy(doc.documentUrl)}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 16px',
                    border: '1px solid #ddd',
                    borderRadius: '6px',
                    backgroundColor: 'white',
                    cursor: 'pointer'
                  }}
                >
                  <Copy style={{ marginRight: '8px', fontSize: 20 }} /> Copy Link
                </button> */}
              </div>
            </div>
          </div>
        ))}
      </div>

      {isUploadDialogOpen && (
        <div style={modalStyles.overlay}>
          <div style={modalStyles.content}>
            <div style={{ marginBottom: '24px' }}>
              <h2 style={{ fontSize: '20px', fontWeight: 'bold' }}>Safe Custody Document Upload</h2>
            </div>

            <div style={{ marginBottom: '20px' }}>
              <label style={{ display: 'block', marginBottom: '8px', fontWeight: '500' }}>
                Enter Loan Number
              </label>
              <div style={{ display: 'flex', gap: '8px' }}>
                <input
                  type="text"
                  placeholder="Enter loan number"
                  value={loanNumber}
                  onChange={(e) => setLoanNumber(e.target.value)}
                  style={{
                    flex: 1,
                    padding: '8px 12px',
                    border: '1px solid #ddd',
                    borderRadius: '6px'
                  }}
                />
                <button
                  onClick={handleVerifyLoan}
                  style={{
                    padding: '8px 16px',
                    backgroundColor: '#2563eb',
                    color: 'white',
                    border: 'none',
                    borderRadius: '6px',
                  }}
                >
                  Verify
                </button>
              </div>
            </div>

            {showSuccess && (
              <div
                style={{
                  padding: '12px',
                  backgroundColor: '#dcfce7',
                  borderRadius: '6px',
                  marginBottom: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px'
                }}
              >
                <CheckCircle2 style={{ color: '#166534', fontSize: 20 }} />
                <span style={{ color: '#166534' }}>Loan number verified successfully</span>
              </div>
            )}

            {isLoanVerified && (
              <>
                <div style={{ marginBottom: '20px' }}>
                  <label style={{ display: 'block', marginBottom: '8px', fontWeight: '500' }}>
                    Upload Documents
                  </label>
                  <div
                    style={{
                      border: '2px dashed #ddd',
                      borderRadius: '8px',
                      padding: '24px',
                      textAlign: 'center'
                    }}
                  >
                    <input
                      type="file"
                      onChange={handleFileSelect}
                      accept=".pdf"
                      multiple
                      style={{ display: 'none' }}
                      id="file-upload"
                    />
                    <label htmlFor="file-upload" style={{ cursor: 'pointer' }}>
                      <Upload style={{ fontSize: 48, color: '#666', margin: '0 auto' }} />
                      <p style={{ marginTop: '8px', color: '#666' }}>
                        Click to upload or drag and drop
                      </p>
                      <p style={{ fontSize: '12px', color: '#666' }}>
                        PDF
                      </p>
                    </label>
                  </div>
                  {selectedFiles.length > 0 && (
                    <p style={{ marginTop: '8px', fontSize: '14px', color: '#666' }}>
                      Selected: {selectedFiles.map(file => file.name).join(', ')}
                    </p>
                  )}
                </div>

                <div style={{ marginBottom: '20px' }}>
                  <label style={{ display: 'block', marginBottom: '8px', fontWeight: '500' }}>
                    Add Comment
                  </label>
                  <textarea
                    placeholder="Add a comment about these documents"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    style={{
                      width: '100%',
                      padding: '8px 12px',
                      border: '1px solid #ddd',
                      borderRadius: '6px',
                      minHeight: '80px'
                    }}
                  />
                </div>

                <button
                  onClick={handleUpload}
                  // disabled={selectedFiles.length === 0}
                  disabled={isUploading}
                  style={{
                    width: '100%',
                    padding: '12px',
                    backgroundColor: selectedFiles.length > 0 ? '#2563eb' : '#ccc',
                    color: 'white',
                    border: 'none',
                    borderRadius: '6px',
                    cursor: 'pointer',
                    opacity: isUploading ? 0.7 : 1,
                    // cursor: selectedFiles.length > 0 ? 'pointer' : 'not-allowed'
                  }}
                >
                  Upload Documents
                </button>
              </>
            )}

            <button
              onClick={() => setIsUploadDialogOpen(false)}
              style={{
                position: 'absolute',
                top: '16px',
                right: '16px',
                background: 'none',
                border: 'none',
                fontSize: '20px',
                cursor: 'pointer'
              }}
            >
              ×
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentUploadSystem;