import React, { useEffect, useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Typography,
  CircularProgress,
  Box,
} from '@material-ui/core';
import datehelper from '../../../_helpers/datehelper';
import { virtualAccountService } from '../../../_services/virtualaccount.service';

function NachPayments({ cust }) {
  const [paymentData, setPaymentData] = useState([]);
  const [spinner, setSpinner] = useState(true);

  const fetchData = async () => {
    try {
      setSpinner(true);
      const response = await virtualAccountService.nachPaymentsDetailsUsingLoanReferenceNo({
        application_id: cust.application_id,
      });
      console.log('response of nach is', response);
      
      setPaymentData(response || []);
    } catch (err) {
      console.error('Error fetching payment data:', err);
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    if (cust?.application_id) {
      fetchData();
    }
  }, [cust]);

 

  return (
    <div>
      <Typography
        variant="h5"
        gutterBottom
        style={{ marginBottom: '20px', color: '#2949B9' }}
      >
        Nach Payments
      </Typography>
      <TableContainer component={Paper} style={{ maxHeight: '500px' }}>
        <Table  >
          <TableHead style={{ backgroundColor: '#525155' }}>
            <TableRow  >
              <TableCell  style={{textAlign:'center' , color:'white'}}>#</TableCell>
              <TableCell  style={{textAlign:'center' , color:'white'}}>Date</TableCell>
               <TableCell  style={{textAlign:'center' , color:'white'}}>Status</TableCell>
              <TableCell  style={{textAlign:'center' , color:'white'}}>Payment</TableCell>
               <TableCell  style={{textAlign:'center' , color:'white'}}>Payment Reference</TableCell>
              <TableCell  style={{textAlign:'center' , color:'white'}}>Ref Number</TableCell>
              <TableCell  style={{textAlign:'center' , color:'white'}}>Comment</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {spinner ? (
              <TableRow>
                <TableCell colSpan={9} style={{ textAlign: 'center', padding: '20px' }}>
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress />
                  </Box>
                </TableCell>
              </TableRow>
            ) : paymentData.length > 0 ? (
              paymentData.map((nach, index) => {
                const nachStatus =
                  nach.collection_status === 'Success'
                    ? 'green'
                    : nach.collection_status === 'Failed'
                    ? 'red'
                    : 'yellow';
                return (
                  <TableRow key={index}>
                    <TableCell style={{textAlign:'center'}}>{index + 1}</TableCell>
                    <TableCell style={{textAlign:'center'}}>
                      {nach.collection_date
                        ? datehelper.displayDate(new Date(nach.collection_date))
                        : 'N/A'}
                    </TableCell>
                     <TableCell style={{ background: nachStatus }}>
                      {nach.collection_status  }
                    </TableCell>
                    <TableCell style={{textAlign:'center'}}>₹{nach.amount  }</TableCell>
                     <TableCell style={{textAlign:'center'}}>{nach.payment_reference }</TableCell>
                    <TableCell style={{textAlign:'center'}}>{nach.reference_code  }</TableCell>
                    <TableCell style={{textAlign:'center'}}>{nach.collection_comment  }</TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={9} style={{ textAlign: 'center' }}>
                  No Nach payments available.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default NachPayments;
