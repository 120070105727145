import { ErrorMessage, Field, Form, Formik } from 'formik'
import React from 'react'
import * as Yup from "yup";
import { ArrowForward } from "@material-ui/icons";
import { termLoans } from "../../_services/termloans.service";
import { history } from '../../_helpers';
import { ApprovalRequestFilter } from '../MakerCheckerFramework/ApprovalRequestFilter';
import { employeeService } from '../../_services';
export const TermLoanForm = (props) => {
    let user = employeeService.userValue
    let loanData = props.location.state
    console.log("test loan data", loanData)
    let id = loanData && loanData._id
    const loan_number = loanData && loanData.loan_number
    const formikRef = React.useRef();
    const isAdd = !id;
    const [data, setData] = React.useState();
    console.log("Test data", data)
    let initialValues = {
        name_of_lender: loanData && loanData.name_of_lender || "",
        loan_type: "",
        roi: loanData && loanData.roi || "",
        lender_type:"",
        fd_amount:"",
        fd_interest:"",
        maturity_date:"",
        repayment_mode:"",
        processing_fees: "",
        sanction_date: loanData && loanData.sanction_date || "",
        availment_date: "",
        tenure: "",
        sanctioned_amt: loanData && loanData.sanction_amount || "",
        amt_availed: "",
        loan_name: loanData && loanData.loan_name || "",
        emi_day: "",
        payment_collection: {
            mode: '',
            bank: ''
        },
        loan_number: '',
        pos: '',
        security: '',
        charge_created: '',
        charge_id: '',
        remark: loanData && loanData.status || '',
        pg_or_cg: '',
        fldg: '',
    };
    let validationSchema = Yup.object().shape({
        name_of_lender: Yup.string().required("Choose Name of Lender"),
        loan_type: Yup.string().required("This is Required"),
        roi: Yup.number().required('Roi is Required'),
        processing_fees: Yup.number().required('Processing fees is Required'),
        // lender_type: Yup.string().required("This is Required"),
        // fd_amount: Yup.number().required('FD Amount is Required'),
        // fd_interest: Yup.number().required('FD Interest is Required'),
        // maturity_date: Yup.date().required('Maturity Date is Required'),
        // repayment_mode: Yup.string().required('Repayment Mode is Required'),

        sanction_date: Yup.date().required("This is Required"),
        availment_date: Yup.date().required("This is Required"),
        tenure: Yup.number().required('Tenure is required'),
        sanctioned_amt: Yup.string().required("This is Required"),
        amt_availed: Yup.string().required("This is Required"),
        loan_name: Yup.string().required("This is Required"),
        emi_day: Yup.number().required("Emi is Required"),
        loan_number: Yup.string().required("This is Required"),
        pos: Yup.number().required("This is Required"),
        security: Yup.string().required("This is Required"),
        charge_created: Yup.string().required("This is Required"),
        charge_id: Yup.string(),
        remark: Yup.string().required("This is Required"),
        pg_or_cg: Yup.string().required("This is Required"),
        // fldg: Yup.number().min(1, 'Fldg must be at least 1').max(100, 'Fldg cannot be more than 100').required('Fldg is Required'),
        payment_collection: Yup.object().shape({
            mode: Yup.string().required('Payment mode is required'),
            bank: Yup.string().required('Payment mode is required')
        }),
    })
    const createLoan = async (values, setSubmitting) => {
        await ApprovalRequestFilter.RequestFilter({
            service: 'termLoans',
            api: 'create',
            id: values.loan_number,
            task: 'Create Term Loan',
            updated_fields:['name_of_lender', 'loan_type', 'roi', 'processing_fees', 'sanction_date', 'availment_date', 'tenure', 'sanctioned_amt', 'amt_availed', 'loan_name', 'emi_day', 'loan_number', 'payment_collection.mode','payment_collection.bank','pos', 'security', 'charge_created', 'charge_id', 'remark', 'pg_or_cg', 'fldg', 'repayment_mode', 'lender_type', 'fd_amount', 'fd_interest', 'maturity_date'],
            sensitive_fields: [],
            new_values: values,
            old_values: {},
            identification_key: 'Loan Number',
            identification_value: values.loan_number,
            emp_id: user.emp_id,
            approval_needed: true,
            approved: false,
            approval_access: 'isAccountChecker',
            product: 'accounting'
        })
            .then((x) => {
                console.log(x)
                alert("Request Sent to concerned Authority")
                history.push("/home/accounting/termloans")
                // window.location.reload()
            })
            .catch((err) => {
                window.alert(err)
                console.log(err)
            })
    }

    const updateloans = async (id, editedFields) => {
    function getKeys(obj, parentKey = '') {
        let keys = [];
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            let currentKey = parentKey ? `${parentKey}.${key}` : key;
            if (typeof obj[key] === 'object') {
              keys = keys.concat(getKeys(obj[key], currentKey));
            } else {
              keys.push(currentKey);
            }
          }
        }
        return keys;
      }
      const edit_fields = getKeys(editedFields)
      console.log("Test obj filed",edit_fields)
        await ApprovalRequestFilter.RequestFilter({
            service: 'termLoans',
            api: 'updateLoan',
            id: id,
            task: 'update term loans',
            updated_fields:edit_fields,
            sensitive_fields: [],
            new_values:editedFields,
            old_values:loanData,
            identification_key: 'Loan Number',
            identification_value: loan_number,
            emp_id: user.emp_id,
            approval_needed: true,
            approved: false,
            approval_access: 'isAccountChecker',
            product: 'accounting'
        })
            .then((x) => {
                console.log("test x", x)
                alert("Request Sent to concerned Authority")
                history.push("/home/accounting/termloans")
            })
            .catch((err) => {
                window.alert(err)
                console.log(err)
            })
    }
    React.useEffect(() => {
        termLoans
            .getAll()
            .then((x) => {
                console.log(x);
                setData(x);
            })
            .catch((err) => {
                console.log(err);
            });

        if (!isAdd) {
            termLoans.getLoanById(id).then((loan) => {
                // setLoan(loan)
                console.log("Test loan", loan)
                const formattedDate = new Date(loan.sanction_date).toISOString().slice(0, 10);
                const formattedDate_availment_date = new Date(loan.availment_date).toISOString().slice(0, 10);
                let maturityDate;
                if(loan.maturity_date){
                maturityDate = new Date(loan.maturity_date).toISOString().slice(0, 10);
                }
                formikRef.current.setFieldValue('sanction_date', formattedDate);
                formikRef.current.setFieldValue('availment_date', formattedDate_availment_date);
                formikRef.current.setFieldValue('payment_collection.bank', loan.payment_collection.bank);
                formikRef.current.setFieldValue('payment_collection.mode', loan.payment_collection.mode);
                formikRef.current.setFieldValue('maturity_date', maturityDate);
                const fields = [
                    "name_of_lender",
                    "loan_type",
                    "roi",
                    "processing_fees",
                    // "sanction_date",
                    // "availment_date",
                    "tenure",
                    "sanctioned_amt",
                    "amt_availed",
                    "loan_name",
                    "emi_day",
                    "loan_number",
                    "pos",
                    "security",
                    "charge_created",
                    "charge_id",
                    "remark",
                    "pg_or_cg",
                    "fldg",
                    "fd_amount",
                    "fd_interest",
                    // "maturity_date",
                    "repayment_mode",
                    "lender_type"

                ];
                fields.forEach((field) => {
                    if (formikRef.current) {
                        formikRef.current.setFieldValue(field, loan[field], false);
                    }
                });
            })
        }
    }, [id, isAdd, formikRef]);

    // const handleSubmit = async (fields, setSubmitting) => {
    //     console.log("Test value", fields)
    //     await termLoans
    //         .create(fields)
    //         .then((data) => {
    //             alert("loan added successfully");
    //             history.push("/home/accounting/termloans")
    //         })
    //         .catch((err) => {
    //             setSubmitting(false);
    //             console.log(err);
    //         });
    // };
    // function updateloans(id, fields, setSubmitting) {
    //     termLoans.updateLoan(id, fields)
    //         .then((res) => {
    //             alert("Loan Upated successfully")
    //             history.push("/home/accounting/termloans")
    //         })
    //         .catch((error) => {
    //             alert("error", error)
    //             setSubmitting(false);
    //         });
    // }

    // const editedFields = {};
    // Object.keys(fields).forEach(key => {
    //     if (fields[key] !== initialValues[key]) {
    //       editedFields[key] = fields[key];
    //     }
    //   });
    function onSubmit(values, { setStatus, setSubmitting }) {
        setStatus();
        if (isAdd) {
            createLoan(values, setSubmitting)
        } else {
            const editedFields = {};
            Object.keys(values).forEach(key => {
            if (values[key] !== loanData[key]) {
              editedFields[key] = values[key];
            }
          })
            updateloans(id,editedFields, setSubmitting);
        }
    }
    return (
        <div>
            <Formik
                innerRef={formikRef}
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ errors, touched, isSubmitting, setFieldValue, values }) => {
                    return (
                        <Form style={{ marginTop: '30px' }}>
                            <h2>Term Loan {<ArrowForward />} {isAdd ? "Add Loan" : "Edit Loan"}</h2>
                            <div style={{ background: '#D5DEFC', borderRadius: '5px' }}>
                                <br />
                                <br />
                                <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                    <div>
                                        <label>Name of Lender</label> <br />
                                        <Field
                                            name='name_of_lender'
                                            as='select'
                                            style={{ width: "250px", height: "30px" }}
                                            className={
                                                "form-control" +
                                                (errors.name_of_lender && touched.name_of_lender ? " is-invalid" : "")
                                            }
                                        >
                                            <option value=''>Choose Name of Lender</option>
                                            <option value='Capital India Finance Limited'>Capital India Finance Limited</option>
                                            <option value='Red Fort Capital'>Red Fort Capital </option>
                                            <option value='Dhara Motor Finance Ltd'>Dhara Motor Finance Ltd</option>
                                            <option value='Mentor Finmart Pvt Ltd'>Mentor Finmart Pvt Ltd</option>
                                            <option value='Bhala Finance Private Limited'>Bhala Finance Private Limited</option>
                                            <option value='Nice Finance & Leasing Private Limited'>Nice Finance & Leasing Private Limited</option>
                                            <option value='Samunnati Agro Solutions Pvt Ltd'>Samunnati Agro Solutions Pvt Ltd</option>
                                            <option value='Kamal Auto Finance'>Kamal Auto Finance</option>
                                            <option value='Usha Finance'>Usha Finance</option>
                                            <option value='AU Small Finance Bank'>AU Small Finance Bank</option>
                                            <option value='DCB Bank Ltd'>DCB Bank Ltd</option>
                                            <option value='Eclear Leasing Private Limited'>Eclear Leasing Private Limited</option>
                                            <option value='Nice Finance & Leasing Private Limited'>Nice Finance & Leasing Private Limited</option>
                                            <option value='State Bank of India'>State Bank of India</option>
                                            <option value='Greenwings Innovative Finance Pvt Ltd'>Greenwings Innovative Finance Pvt Ltd</option>
                                            <option value='Rar Fincare limited'>Rar Fincare limited</option>
                                            <option value='Muthoot Microfin Finance'>Muthoot Microfin Finance</option>
                                            <option value='Ambit Finvest Private Limited'>Ambit Finvest Private Limited</option>
                                            <option value='Cholamanadalm Investment and Finance Company LTD'>Cholamanadalm Investment and Finance Company LTD</option>
                                            <option value='Greenwings Innovative'>Greenwings Innovative</option>
                                            <option value='Hinduja Ley land'>Hinduja Ley land</option>
                                            <option value='ICICI Bank'>ICICI Bank</option>
                                            <option value='IKF Finance '>IKF Finance </option>
                                            <option value='Incred Financial Services Limited '>Incred Financial Services Limited </option>
                                            <option value='Muthoot Microfin Limited '>Muthoot Microfin Limited </option>
                                            <option value='Northern Arc Capital Limited '>Northern Arc Capital Limited </option>
                                            <option value='Oxyzo Financial Services Pvt Ltd'>Oxyzo Financial Services Pvt Ltd</option>
                                            <option value='Profectus Capital'>Profectus Capital</option>
                                            <option value='Shriram Finance '>Shriram Finance </option>
                                            <option value='SMC finance'>SMC finance</option>
                                            <option value='UC Inclusive Credit Private Limited'>UC Inclusive Credit Private Limited</option>
                                            <option value='Western Capital Advisors Limited'>Western Capital Advisors Limited</option>
                                            <option value='IBL Finance Ltd'>IBL Finance Ltd</option>
                                            <option value='Mas Financial Services'> Mas Financial Services</option>
                                            <option value='Real Touch Finance Limited'>Real Touch Finance Limited</option>
                                            <option value='Blacksoil'>Blacksoil</option>
                                            <option value='RevX Capital'>RevX Capital</option>

                                        </Field>
                                        <ErrorMessage
                                            name="name_of_lender"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", }}>
                                        <label>Name of Loan</label>
                                        <Field style={{ width: "250px", height: "30px" }} name="loan_name"
                                            className={
                                                "form-control" +
                                                (errors.loan_name && touched.loan_name ? " is-invalid" : "")
                                            } />
                                        <ErrorMessage
                                            name="loan_name"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", }}>
                                        <label>Loan Number</label>
                                        <Field style={{ width: "250px", height: "30px" }} name="loan_number" className={
                                            "form-control" +
                                            (errors.loan_number && touched.loan_number ? " is-invalid" : "")
                                        } />
                                        <ErrorMessage
                                            name="loan_number"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", }}>
                                        <label>Loan Type</label>
                                        <Field style={{ width: "250px", height: "30px" }} name="loan_type"
                                            as='select' className={
                                                "form-control" +
                                                (errors.loan_type && touched.loan_type ? " is-invalid" : "")
                                            } >
                                            <option value=''>Choose loan type</option>
                                            <option value='Term Loan'>Term Loan</option>
                                            <option value='NCD'>NCD</option>
                                            <option value='Car Loan'>Car Loan</option>
                                        </Field>
                                        <ErrorMessage
                                            name="loan_type"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                  
                                </div>
                                <br />
                                <br />
                                <div style={{ display: "flex", justifyContent: "space-around", }}>
                                <div style={{ display: "flex", flexDirection: "column", }}>
                                        <label>Lender Type</label>
                                        <Field style={{ width: "250px", height: "30px" }} name="lender_type"
                                            as='select' className={
                                                "form-control" +
                                                (errors.lender_type && touched.lender_type ? " is-invalid" : "")
                                            } >
                                            <option value=''>Choose lender type</option>
                                            <option value='Bank'>Bank</option>
                                            <option value='NBFC'>NBFC</option>
                                         </Field>
                                        <ErrorMessage
                                            name="lender_type"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", }}>
                                        <label>FD Amount </label>
                                        <Field style={{ width: "250px", height: "30px" }} name="fd_amount" type="number" className={
                                            "form-control" +
                                            (errors.fd_amount && touched.fd_amount ? " is-invalid" : "")
                                        } />
                                        <ErrorMessage
                                            name="fd_amount"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", }}>
                                        <label>FD Interest in %</label>
                                        <Field style={{ width: "250px", height: "30px" }} name="fd_interest" type="number" className={
                                            "form-control" +
                                            (errors.fd_interest && touched.fd_interest ? " is-invalid" : "")
                                        } />
                                        <ErrorMessage
                                            name="fd_interest"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", }}>
                                        <label>Maturity Date</label>
                                        <Field style={{ width: "250px", height: "30px" }} name="maturity_date" onKeyDown={(e) => e.preventDefault()} type="date" className={
                                            "form-control" +
                                            (errors.maturity_date && touched.maturity_date ? " is-invalid" : "")
                                        } />
                                        <ErrorMessage
                                            name="maturity_date"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                        </div>
                                         


                                </div>
                                <br />
                                <br />
                                <div style={{ display: "flex", justifyContent: "space-around", }}>
                                    <div style={{ display: "flex", flexDirection: "column", }}>
                                        <label>Date of Sanction</label>
                                        <Field style={{ width: "250px", height: "30px" }} name="sanction_date" onKeyDown={(e) => e.preventDefault()} type="date" className={
                                            "form-control" +
                                            (errors.sanction_date && touched.sanction_date ? " is-invalid" : "")
                                        } />
                                        <ErrorMessage
                                            name="sanction_date"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", }}>
                                        <label>Date of Availment</label>
                                        <Field style={{ width: "250px", height: "30px" }} name="availment_date" onKeyDown={(e) => e.preventDefault()} type="date" className={
                                            "form-control" +
                                            (errors.availment_date && touched.availment_date ? " is-invalid" : "")
                                        } />
                                        <ErrorMessage
                                            name="availment_date"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", }}>
                                        <label> Tenure(Months)</label>
                                        <Field type="number"
                                            style={{ width: "250px", height: "30px" }} name="tenure" className={
                                                "form-control" +
                                                (errors.tenure && touched.tenure ? " is-invalid" : "")
                                            } />
                                        <ErrorMessage
                                            name="tenure"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", }}>
                                        <label>Amount Sanctioned</label>
                                        <Field style={{ width: "250px", height: "30px" }} name="sanctioned_amt" type="number" className={
                                            "form-control" +
                                            (errors.sanctioned_amt && touched.sanctioned_amt ? " is-invalid" : "")
                                        } />
                                        <ErrorMessage
                                            name="sanctioned_amt"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                </div>
                                <br />
                                <br />
                                <div style={{ display: "flex", justifyContent: "space-around", }}>
                                    <div style={{ display: "flex", flexDirection: "column", }}>
                                        <label> Amount Availed</label>
                                        <Field style={{ width: "250px", height: "30px" }} name="amt_availed" type="number" className={
                                            "form-control" +
                                            (errors.amt_availed && touched.amt_availed ? " is-invalid" : "")
                                        } />
                                        <ErrorMessage
                                            name="amt_availed"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>

                                    <div style={{ display: "flex", flexDirection: "column", }}>
                                        <label> ROI %</label>
                                        <Field style={{ width: "250px", height: "30px" }} name="roi" type='number' className={
                                            "form-control" +
                                            (errors.roi && touched.roi ? " is-invalid" : "")
                                        } />
                                        <ErrorMessage
                                            name="roi"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", }}>
                                        <label>EMI Date(1 to 31)</label>
                                        <Field type="number"
                                            style={{ width: "250px", height: "30px" }} name="emi_day" className={
                                                "form-control" +
                                                (errors.emi_day && touched.emi_day ? " is-invalid" : "")
                                            } />
                                        <ErrorMessage
                                            name="emi_day"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", }}>
                                        <label>Status</label>
                                        <Field style={{ width: "250px", height: "30px", color: "green" }} type="text" value="Active" disabled />
                                    </div>
                                </div>
                                <br />
                                <br />
                                <div style={{ display: "flex", justifyContent: "space-around", }}>
                                    <div style={{ display: "flex", flexDirection: "column", }}>
                                        <label> Payment Mode</label>
                                        <Field name='payment_collection.mode'
                                            as='select'
                                            className={
                                                "form-control" +
                                                (errors.payment_collection?.mode && touched.payment_collection?.mode ? " is-invalid" : "")
                                            }
                                            style={{ width: "250px", height: "30px" }}>
                                            <option value="">Choose Payment Mode</option>
                                            <option value="Nach">Nach</option>
                                            <option value="Manual">Manual</option>
                                        </Field>
                                        <ErrorMessage
                                            name="payment_collection.mode"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>

                                    <div style={{ display: "flex", flexDirection: "column", }}>
                                        <label> Payment Bank</label>

                                        <Field name="payment_collection.bank" style={{ width: "250px", height: "30px" }} className={
                                            "form-control" +
                                            (errors.payment_collection?.bank && touched.payment_collection?.bank ? " is-invalid" : "")
                                        } />
                                        <ErrorMessage
                                            name="payment_collection.bank"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", }}>
                                        <label>POS</label>
                                        <Field type="number"
                                            style={{ width: "250px", height: "30px" }} name="pos" className={
                                                "form-control" +
                                                (errors.pos && touched.pos ? " is-invalid" : "")
                                            } />
                                        <ErrorMessage
                                            name="pos"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", }}>
                                        <label>Security</label>
                                        <Field name="security" style={{ width: "250px", height: "30px" }} className={
                                            "form-control" +
                                            (errors.security && touched.security ? " is-invalid" : "")
                                        } />
                                        <ErrorMessage
                                            name="security"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                </div>
                                <br />
                                <br />
                                <div style={{ display: "flex", justifyContent: "space-around", }}>
                                    <div style={{ display: "flex", flexDirection: "column", }}>
                                        <label> PG/CG</label>
                                        <Field style={{ width: "250px", height: "30px" }} name="pg_or_cg" as='select'
                                            className={
                                                "form-control" +
                                                (errors.pg_or_cg && touched.pg_or_cg ? " is-invalid" : "")
                                            }
                                        >
                                            <option value="">Choose</option>
                                            <option value="PG">PG</option>
                                            <option value="CG">CG</option>
                                            <option value="Both">Both</option>
                                        </Field>
                                        <ErrorMessage
                                            name="pg_or_cg"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", }}>
                                        <label>FLDG  %</label>
                                        <Field type="number" style={{ width: "250px", height: "30px" }} name="fldg" className={
                                            "form-control" +
                                            (errors.fldg && touched.fldg ? " is-invalid" : "")
                                        } />
                                        <ErrorMessage
                                            name="fldg"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", }}>
                                        <label>Processing Fees  %</label>
                                        <Field type="number"
                                            style={{ width: "250px", height: "30px" }} name="processing_fees" className={
                                                "form-control" +
                                                (errors.processing_fees && touched.processing_fees ? " is-invalid" : "")
                                            } />
                                        <ErrorMessage
                                            name="processing_fees"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <label> Charge Created</label>
                                        <Field style={{ width: "250px", height: "30px" }} name='charge_created' as="select"
                                            className={
                                                "form-control" +
                                                (errors.charge_created && touched.charge_created ? " is-invalid" : "")
                                            }>
                                            <option value="">Choose</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </Field>
                                        <ErrorMessage
                                            name="charge_created"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                </div>
                                <br />
                                <br />
                                <div style={{ display: "flex", justifyContent: "space-around", }}>
                               <div style={{ display: "flex", flexDirection: "column" }}>
  <label>Repayment Mode</label>
  <Field
    as="select"
    style={{ width: "250px", height: "30px" }}
    name="repayment_mode"
    className={
      "form-control" +
      (errors.repayment_mode && touched.repayment_mode ? " is-invalid" : "")
    }
  >
    <option value="">Choose Repayment Mode</option>
    <option value="Monthly">Monthly</option>
    <option value="Quarterly">Quarterly</option>
    <option value="Yearly">Yearly</option>
  </Field>
  <ErrorMessage
    name="repayment_mode"
    component="div"
    className="invalid-feedback"
  />
</div>

                                    {values.charge_created == "Yes" && <div style={{ display: "flex", flexDirection: "column", }}>
                                        <label> Charge Id</label>
                                        <Field style={{ width: "250px", height: "30px" }} type="string" name="charge_id" className={
                                            "form-control" +
                                            (errors.charge_id && touched.charge_id ? " is-invalid" : "")
                                        } />
                                        <ErrorMessage
                                            name="charge_id"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>}
                                    <div style={{ display: "flex", flexDirection: "column", }}>
                                        <label>  Remarks</label>
                                        <Field style={{ width: "250px", height: "50px" }} type="string" name="remark" className={
                                            "form-control" +
                                            (errors.remark && touched.remark ? " is-invalid" : "")
                                        } />
                                        <ErrorMessage
                                            name="remark"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                </div>
                                <br />
                                <div>
                                    <button
                                        type='submit'
                                        disabled={isSubmitting}
                                        className="btn btn-primary"
                                        style={{ marginTop: "30px", marginLeft: "45%", width: '110px', marginBottom: '50px' }}
                                    >{isSubmitting && (
                                        <span className="spinner-border spinner-border-sm mr-1"></span>
                                    )}
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </Form>)
                }}
            </Formik>
        </div>
    )
}
