import React from "react";
import { useEffect } from "react";
import { employeeService } from "../_services";
import Footer from "./uielements/Footer";
import Logo from "./uielements/Logo";
import "../css/intro.css"
import Product from "../_helpers/product"
import productbyrole from "../_helpers/productbyrole";
import colenderimage from "../assets/images/colender.jpeg"
const Intro = ({ history }) => {
    console.log('history', history)
    const user = employeeService.userValue;
    console.log('user role  is' )
    if (!employeeService.userValue) {
        history.push("/employee/login");
    }
    const ArrayofProducts=productbyrole(user.role);
    console.log('ArrayofProducts',ArrayofProducts);
    useEffect(() => {
        // redirect to home if already logged in
        console.log('user role  is',user)
        if (!employeeService.userValue) {
            history.push("/employee/login");
        }
        if(ArrayofProducts.length===1){
            console.log('hiiiii '     )
            if(ArrayofProducts.includes('goldloans')){
                handleProductClick(Product.GoldLoan);
            }
            else if(ArrayofProducts.includes('sbl')){
                handleProductClick(Product.SBL);
            }
            else if(ArrayofProducts.includes('accounting')){
                handleProductClick(Product.Accounting);
            }
            else if(ArrayofProducts.includes('collection')){
                handleProductClick(Product.Collection);
            }
            else if(ArrayofProducts.includes('HR')){
                handleProductClick(Product.HR);
            }
            else if(ArrayofProducts.includes('customercare')){
                handleProductClick(Product.CustomerCare);
            }
            else if(ArrayofProducts.includes('legal')){
                handleProductClick(Product.Legal);
            }
            else if(ArrayofProducts.includes('vendorpanel')){
                handleProductClick(Product.VendorPanel);
            }
            else if(ArrayofProducts.includes('colending')){
                handleProductClickonColender(Product.CoLending);
            }
        }
    }, [user]);
    function handleProductClick(productType) {
        console.log("setting product type to ",productType);
        employeeService.setProduct(productType)
        history.push("/home")
    }
    function handleProductClickonColender(productType) {
        employeeService.setProduct(productType)
        history.push("/home/colending/dashboard")
    }
    

    return (
        <div className="container">
            <Logo />
            <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'flex-start',
                alignItems: 'center',
                alignContent: 'center'
            }}>
                {user && productbyrole(user.role).includes('sbl') && <div className="col-md-4 icon_holder">
                    <a onClick={() => handleProductClick(Product.SBL)} >
                        <div className="row">
                            <img alt="LOS Portal" className="image_icon_holder" src="https://kaabil-docs-uat.s3.ap-south-1.amazonaws.com/008119239/kyc_images/sbl.jpeg" />
                        </div>
                        <div className="row icon_holder">
                            <span className="link_header">LOS</span>
                        </div>
                    </a>
                </div>}

                { user && productbyrole(user.role).includes('colending') && <div className="col-md-4 icon_holder">
                <a onClick={() => handleProductClickonColender(Product.CoLending)}>
                    <div className="row">
                        <img alt="CoLending Portal" className="image_icon_holder" src="https://kaabil-docs-uat.s3.ap-south-1.amazonaws.com/008119813/kyc_images/colender.jpeg" />
                    </div>
                    <div className="row icon_holder">
                        <span className="link_header">CoLending</span>
                    </div>
                </a>
            </div>}
            {user && productbyrole(user.role).includes('collection') && <div className="col-md-4 icon_holder">
                    <a onClick={() => handleProductClick(Product.Collection)} >
                        <div className="row">
                            <img alt="Collection" className="image_icon_holder" src="https://kaabil-docs-uat.s3.ap-south-1.amazonaws.com/008119239/kyc_images/collection_logo.jpeg" />
                        </div>
                        <div className="row icon_holder">
                            <span className="link_header">Collection</span>
                        </div>
                    </a>
                </div>}

                {user && productbyrole(user.role).includes('accounting') && <div className="col-md-4 icon_holder">
                    <a onClick={() => handleProductClick(Product.Accounting)} >
                        <div className="row">
                            <img alt="Accounting" className="image_icon_holder" src="https://kaabil-docs-uat.s3.ap-south-1.amazonaws.com/008119239/kyc_images/accounting.jpeg" />
                        </div>
                        <div className="row icon_holder">
                            <span className="link_header">Accounting</span>
                        </div>
                    </a>
                </div>}
                


             

                {user && productbyrole(user.role).includes('customercare') && <div className="col-md-4 icon_holder">
                    <a onClick={() => handleProductClick(Product.CustomerCare)}>
                        <div className="row">
                            <img alt="Customer Care" className="image_icon_holder" src="https://kaabil-docs-uat.s3.ap-south-1.amazonaws.com/008119239/kyc_images/call-center.jpeg" />
                        </div>
                        <div className="row icon_holder">
                            <span className="link_header">Customer Care</span>
                        </div>
                    </a>
                </div>}
                {user && productbyrole(user.role).includes('legal') && <div className="col-md-4 icon_holder">
                    <a onClick={() => handleProductClick(Product.Legal)}>
                        <div className="row">
                            <img alt="Legal Portal" className="image_icon_holder" src="https://kaabil-docs-uat.s3.ap-south-1.amazonaws.com/008119813/kyc_images/legal.jpeg" />
                        </div>
                        <div className="row icon_holder">
                            <span className="link_header">Legal</span>
                        </div>
                    </a>
                </div>}
                {user && productbyrole(user.role).includes('HR') && <div className="col-md-4 icon_holder">
                    <a onClick={() => handleProductClick(Product.HR)}>
                        <div className="row">
                            <img alt="HR Portal" className="image_icon_holder" src="https://kaabil-docs-uat.s3.ap-south-1.amazonaws.com/008119813/kyc_images/hr.jpeg" />
                        </div>
                        <div className="row icon_holder">
                            <span className="link_header">HR</span>
                        </div>
                    </a>
                </div>}
                {user && productbyrole(user.role).includes('vendorpanel') && <div className="col-md-4 icon_holder">
                    <a onClick={() => handleProductClick(Product.VendorPanel)}>
                        <div className="row">
                            <img alt="Vendor Portal" className="image_icon_holder" src="https://kaabil-docs-uat.s3.ap-south-1.amazonaws.com/008119813/kyc_images/6963167.jpg" />
                        </div>
                        <div className="row icon_holder">
                            <span className="link_header">Vendor Portal</span>
                        </div>
                    </a>
                </div>}
                
           
            </div>
            <Footer />
        </div>
    )
}

export default Intro;