import React ,{useState}from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import { Button, TextField ,MenuItem, FormControl, InputLabel, Select } from "@material-ui/core";
import { initiatedtermloans } from "../../_services/initiatedtermloans.service";
import datehelper from "../../_helpers/datehelper";
import { Add } from "@material-ui/icons";
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router-dom';
const ActionButton = ({ variant, children, onClick }) => {
  const baseButtonStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '6px 12px',
    fontSize: '14px',
    fontWeight: 500,
    border: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.2s',
    margin: '0 4px'
  };
  const getVariantStyles = () => {
    switch (variant) {
      case 'disbursed':
        return {
          ...baseButtonStyle,
          backgroundColor: '#F8F9FA',
          color: '#1E88E5',
          borderRadius: '20px'
        };
      case 'sanctioned':
        return {
          ...baseButtonStyle,
          backgroundColor: '#F8F9FA',
          color: '#43A047',
          borderRadius: '20px'
        };
        case 'Rejected':
        return {
          ...baseButtonStyle,
          backgroundColor: '#F8F9FA',
          color: '#EF5350',
          borderRadius: '20px'
        };
        case 'Initiated':
        return {
          ...baseButtonStyle,
          backgroundColor: '#F8F9FA',
          color: '#301934',
          borderRadius: '20px'
        };
      case 'sanction':
        return {
          ...baseButtonStyle,
          backgroundColor: '#1E1E1E',
          color: 'white',
          borderRadius: '4px',
          ':hover': {
            backgroundColor: '#333333'
          }
        };
      case 'reject':
        return {
          ...baseButtonStyle,
          backgroundColor: '#EF5350',
          color: 'white',
          borderRadius: '4px',
          ':hover': {
            backgroundColor: '#E53935'
          }
        };
      case 'disburse':
        return {
          ...baseButtonStyle,
          backgroundColor: '#1E1E1E',
          color: 'white',
          borderRadius: '4px',
          ':hover': {
            backgroundColor: '#333333'
          }
        };
      default:
        return baseButtonStyle;
    }
  };

  // SVG icons as components
  const icons = {
    disbursed: (
      <svg style={{ width: '16px', height: '16px', marginRight: '6px' }} viewBox="0 0 24 24" fill="currentColor">
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/>
      </svg>
    ),
    sanctioned:(
      <svg style={{ width: '16px', height: '16px', marginRight: '6px' }} viewBox="0 0 24 24" fill="currentColor">
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/>
      </svg>
    ),
    Rejected:(
      <svg style={{ width: '16px', height: '16px', marginRight: '6px' }} viewBox="0 0 24 24" fill="currentColor">
        <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"/>
      </svg>
    ),
    Initiated:(
<svg style={{ width: '16px', height: '16px', marginRight: '6px' }} viewBox="0 0 24 24" fill="currentColor">
<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/>
      </svg>
    ),
    sanction: (
      <svg style={{ width: '16px', height: '16px', marginRight: '6px' }} viewBox="0 0 24 24" fill="currentColor">
        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"/>
      </svg>
    ),
    reject: (
      <svg style={{ width: '16px', height: '16px', marginRight: '6px' }} viewBox="0 0 24 24" fill="currentColor">
        <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"/>
      </svg>
    ),
    disburse: (
      <svg style={{ width: '16px', height: '16px', marginRight: '6px' }} viewBox="0 0 24 24" fill="currentColor">
        <path d="M11.8 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4z"/>
      </svg>
    )
  };

  return (
    <button 
      style={getVariantStyles()}
      onClick={onClick}
    >
      {icons[variant]}
      {children}
    </button>
  );
};
const InitiatedTermLoan = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [data, setData] = React.useState();
  const history = useHistory();
  const [isEditMode, setIsEditMode] = useState(false);
  const [formValues, setFormValues] = React.useState({
    name_of_lender: "",
    loan_name: "",
    roi: "",
    initiated_date: "",
    initiated_amount: "",
    sanction_date: "",
    sanction_amount: "",
    status: "Initiated",
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    zIndex:10000,
    overflow: "scroll",
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const lenderOptions = [
    "Capital India Finance Limited",
    "Red Fort Capital",
    "Dhara Motor Finance Ltd",
    "Mentor Finmart Pvt Ltd",
    "Bhala Finance Private Limited",
    "Nice Finance & Leasing Private Limited",
    "Samunnati Agro Solutions Pvt Ltd",
    "Kamal Auto Finance",
    "Usha Finance",
    "AU Small Finance Bank",
    "DCB Bank Ltd",
    "Eclear Leasing Private Limited",
    "State Bank of India",
    "Greenwings Innovative Finance Pvt Ltd",
    "Rar Fincare limited",
    "Muthoot Microfin Finance",
    "Ambit Finvest Private Limited",
    "Cholamanadalm Investment and Finance Company LTD",
    "Greenwings Innovative",
    "Hinduja Ley land",
    "ICICI Bank",
    "IKF Finance",
    "Incred Financial Services Limited",
    "Muthoot Microfin Limited",
    "Northern Arc Capital Limited",
    "Oxyzo Financial Services Pvt Ltd",
    "Profectus Capital",
    "Shriram Finance",
    "SMC finance",
    "UC Inclusive Credit Private Limited",
    "Western Capital Advisors Limited",
    "IBL Finance Ltd",
    "Mas Financial Services",
    "Real Touch Finance Limited",
    "Blacksoil",
    "RevX Capital",
  ];
  const handleFormSubmit = async () => {
    try {
      const updatedFormValues = {
        ...formValues,
        status: "Initiated", // Explicitly set the status
      };
      await initiatedtermloans.create(updatedFormValues); // Pass new data
      alert("Form submitted successfully!");
      setOpen(false);
      getData(); // Refresh data
      setFormValues('')
    } catch (err) {
      console.error(err);
      alert("Error submitting the form. Please try again.");
    }
  };
  const handleGetId = async (id) => {
    try {
      const response = await initiatedtermloans.getLoanById(id);
  
      // Convert date fields to YYYY-MM-DD
      const formattedData = {
        ...response,
        initiated_date: response.initiated_date
          ? new Date(response.initiated_date).toISOString().split("T")[0]
          : "",
        sanction_date: response.sanction_date
          ? new Date(response.sanction_date).toISOString().split("T")[0]
          : "",
      };
  
      setFormValues(formattedData); // Prepopulate form fields with formatted data
      setIsEditMode(true);
      setOpen(true); // Open modal
    } catch (err) {
      console.error(err);
      alert("Failed to fetch loan details.");
    }
  };
  
  const handleClose = () => {
    setOpen(false);
    setIsEditMode(false);
    setFormValues({
      name_of_lender: "",
      loan_name: "",
      roi: "",
      initiated_date: "",
      initiated_amount: "",
      sanction_date: "",
      sanction_amount: "",
      status: "",
    });
  };
  const handleFormUpdate = async () => {
    try {
      await initiatedtermloans.update(formValues); // Pass updated data
      alert("Form updated successfully!");
      setOpen(false);
      setIsEditMode(false);
      getData(); // Refresh data
      setFormValues('')
    } catch (err) {
      console.error(err);
      alert("Error updating the form. Please try again.");
    }
  };
  const getData = async () => {
    try {
      const response = await initiatedtermloans.getAll();
      setData(response);
    } catch (err) {
      console.error(err);
    }
  };

  React.useEffect(() => {
    getData();
  }, []);



  const [isModalOpen, setIsModalOpen] = React.useState(false);
const [selectedRecord, setSelectedRecord] = React.useState(null);
const [sanctionFormValues, setSanctionFormValues] = React.useState({
  sanction_date: "",
  sanction_amount: "",
});
const handleSanctionClick = (record) => {
  if (window.confirm("Are you sure you want to proceed with this sanction?")) {
    console.log(record, "recordrecordrecord");
    setSelectedRecord(record);
    setSanctionFormValues({
      sanction_date: "",
      sanction_amount: "",
    });
    setIsModalOpen(true);
    setFormValues('')
  } else {
    console.log("Action cancelled by user.");
  }
};

const handleDisburseClick = async (record,status) => {
  if (window.confirm(`Are you sure you want to proceed with this ${status}?`)) {
    try {
      // Prepare the updated data
      const updatedData = {
        ...record,
        status: status, // Set status to 'Sanctioned'
      };
      await initiatedtermloans.update(updatedData); 
      alert("Disbursed data submitted successfully!");
      handleIsModalClose(); // Close the modal
      getData(); // Refresh the table data
      if (status === "Disbursed") {
          // Function to format date to MM-DD-YYYY

        const { _id, ...dataWithoutId } = updatedData;
        const formattedData = {
          ...dataWithoutId,
          initiated_date: dataWithoutId.initiated_date
            ? new Date(dataWithoutId.initiated_date).toISOString().split("T")[0]
            : "",
          sanction_date: dataWithoutId.sanction_date
            ? new Date(dataWithoutId.sanction_date).toISOString().split("T")[0]
            : "",
        };
        history.push({
          pathname: '/home/accounting/termloanform',
          state: { ...formattedData }, // Pass the data without _id
        });
      }
      setFormValues('')
    } catch (error) {
      console.error("Error updating sanction data:", error);
      alert("Failed to update sanction data. Please try again.");
    }  
  }
  else {
    console.log("Action cancelled by user.");
  }
};
const handleIsModalClose = () => {
  setIsModalOpen(false);
}

const handleFormUpdated = async () => {
  try {
    // Prepare the updated data
    const updatedData = {
      ...selectedRecord,
      ...sanctionFormValues,
      status: "Sanctioned", // Set status to 'Sanctioned'
    };
console.log(updatedData,"updatedDataupdatedDataupdatedData")
    // Update the record in your database
    await initiatedtermloans.update(updatedData); // Replace `recordId` with the ID of the record being updated.

    // Close the modal and refresh the data
    alert("Sanction data submitted successfully!");
    handleIsModalClose(); // Close the modal
    getData(); // Refresh the table data
  } catch (error) {
    console.error("Error updating sanction data:", error);
    alert("Failed to update sanction data. Please try again.");
  }
};

return (
    <>
      <div
        style={{
          position: "fixed",
          width: "87%",
          backgroundColor: "#fff",
          padding: "25px",
          overflow: "hidden",
          marginLeft: "-10px",
          marginTop: "-10px",
        }}
      >
        <h1 style={{ display: "flex", float: "left" }}>Initiated Term Loan</h1>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "28px",
          }}
        >
          <Button
            style={{
              borderRadius: "5px",
              backgroundColor: "blue",
              color: "white",
              marginLeft: "20px",
              width: "250px",
            }}
            onClick={handleOpen}
          >
            <Add />
            &nbsp;Add Initiated Term Loan
          </Button>
        </div>
      </div>
      <div
        style={{
          marginTop: "80px",
          overflowX: "auto",
          padding: "20px",
        }}
      >
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            textAlign: "left",
          }}
        >
          <thead style={{backgroundColor:"#2c88d9", color:"#fff"}}>
            <tr>
              <th style={{ borderBottom: "1px solid #ccc", padding: "10px",textAlign:"center" }}>
                Lender Name
              </th>
              <th style={{ borderBottom: "1px solid #ccc", padding: "10px",textAlign:"center" }}>
                Loan Name
              </th>
              <th style={{ borderBottom: "1px solid #ccc", padding: "10px",textAlign:"center" }}>
                ROI(%)
              </th>
              <th style={{ borderBottom: "1px solid #ccc", padding: "10px" ,textAlign:"center"}}>
                Initiated Date
              </th>
              <th style={{ borderBottom: "1px solid #ccc", padding: "10px" ,textAlign:"center"}}>
                Initiated Amount
              </th>
              <th style={{ borderBottom: "1px solid #ccc", padding: "10px",textAlign:"center" }}>
                Sanctioned Date
              </th>
              <th style={{ borderBottom: "1px solid #ccc", padding: "10px",textAlign:"center" }}>
                Sanctioned Amount
              </th>
              <th style={{ borderBottom: "1px solid #ccc", padding: "10px" ,textAlign:"center"}}>
                Status
              </th>
              <th style={{ borderBottom: "1px solid #ccc", padding: "10px",textAlign:"center" }}>
                Action
              </th>
              <th style={{ borderBottom: "1px solid #ccc", padding: "10px",textAlign:"center" }}>
                Edit
              </th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((e, idx) => (
                <tr key={idx}>
                  <td style={{ padding: "10px", borderBottom: "1px solid #eee",textAlign:"center" }}>
                    {e.name_of_lender}
                  </td>
                  <td style={{ padding: "10px", borderBottom: "1px solid #eee",textAlign:"center" }}>
                    {e.loan_name}
                  </td>
                  <td style={{ padding: "10px", borderBottom: "1px solid #eee",textAlign:"center" }}>
                    {e.roi}%
                  </td>
                  <td style={{ padding: "10px", borderBottom: "1px solid #eee",textAlign:"center" }}>
                  {datehelper.displayDate(new Date(e.initiated_date))}
                  </td>
                  <td style={{ padding: "10px", borderBottom: "1px solid #eee",textAlign:"center" }}>
                  {e.initiated_amount}
                  </td>
                  <td style={{ padding: "10px", borderBottom: "1px solid #eee",textAlign:"center" }}>
                  {e.sanction_date === null?"":datehelper.displayDate(new Date( e.sanction_date))}
                  </td>
                  <td style={{ padding: "10px", borderBottom: "1px solid #eee",textAlign:"center" }}>
                    {e.sanction_amount}
                  </td>
                  <td style={{ padding: "10px", borderBottom: "1px solid #eee",textAlign:"center" }}>
                  {e.status === "Sanctioned" ? (<ActionButton variant="sanctioned">Sanctioned</ActionButton>):e.status === "Initiated" ? (<ActionButton variant="Initiated">Initiated</ActionButton>) : e.status === "Disbursed" ? (<ActionButton variant="disbursed">Disbursed</ActionButton> ) : e.status === "Rejected" ? (<ActionButton variant="Rejected">Rejected</ActionButton>) : (e.status)}
                  </td>
                  <td style={{ padding: "10px", borderBottom: "1px solid #eee",textAlign:"center" }}>
                    
                    {e.status === "Sanctioned" ? ( <div style={{display:'flex'}}><ActionButton variant="disburse" onClick={() => handleDisburseClick(e,"Disbursed")}>Disburse</ActionButton><ActionButton variant="reject"onClick={() => handleDisburseClick(e,"Rejected")}>Reject</ActionButton></div>)
                    :e.status === "Initiated" ? (<div style={{display:'flex'}}><ActionButton variant="sanction"  onClick={() => handleSanctionClick(e)}>Sanction</ActionButton><ActionButton variant="reject">Reject</ActionButton></div>)
                     : e.status === "Disbursed" ? "" : e.status === "Rejected" ? "" : ""}
                  </td>
                  <td style={{ padding: "10px", borderBottom: "1px solid #eee",textAlign:"center" }}><EditIcon onClick={()=>handleGetId(e._id)} style={{color:"blue",cursor:"pointer"}} title="Edit"/></td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Typography variant="h6" component="h2" gutterBottom>
            {isEditMode ? "Edit Term Loan" : "Add New Term Loan"}
          </Typography>
          <form noValidate autoComplete="off">
            <FormControl fullWidth required style={{ marginBottom: "15px" }}>
              <InputLabel>Name of Lender</InputLabel>
              <Select
                name="name_of_lender"
                value={formValues.name_of_lender}
                onChange={handleInputChange}
                label="Name of Lender"
              >
                <MenuItem value="">
                  <em>Choose Name of Lender</em>
                </MenuItem>
                {lenderOptions.map((lender, index) => (
                  <MenuItem key={index} value={lender}>
                    {lender}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label="Loan Name"
              name="loan_name"
              value={formValues.loan_name}
              onChange={handleInputChange}
              required
              style={{ marginBottom: "15px" }}
            />
            <TextField
              fullWidth
              label="ROI (%)"
              name="roi"
              value={formValues.roi}
              onChange={handleInputChange}
              type="number"
              required
              style={{ marginBottom: "15px" }}
            />
            <TextField
              fullWidth
              label="Initiated Date"
              name="initiated_date"
              value={formValues.initiated_date}
              onChange={handleInputChange}
              type="date"
              onKeyDown={(e) => e.preventDefault()}
              InputLabelProps={{ shrink: true }}
              required
              style={{ marginBottom: "15px" }}
            />
            <TextField
              fullWidth
              label="Initiated Amount"
              name="initiated_amount"
              value={formValues.initiated_amount}
              onChange={handleInputChange}
              type="number"
              required
              style={{ marginBottom: "15px" }}
            />
        {(formValues.status === "Sanctioned"||formValues.status === "Disbursed") && (
  <>   <TextField
              fullWidth
              label="Sanction Date"
              name="sanction_date"
              value={formValues.sanction_date}
              onChange={handleInputChange}
              type="date"
              onKeyDown={(e) => e.preventDefault()}
              InputLabelProps={{ shrink: true }}
              required
              style={{ marginBottom: "15px" }}
            />
            <TextField
              fullWidth
              label="Sanction Amount"
              name="sanction_amount"
              value={formValues.sanction_amount}
              onChange={handleInputChange}
              type="number"
              required
              style={{ marginBottom: "15px" }}
            /></>
          )}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={isEditMode ? handleFormUpdate : handleFormSubmit}
              >
                {isEditMode ? "Update" : "Submit"}
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleClose}
                style={{ marginLeft: "10px" }}
              >
                Cancel
              </Button>
            </div>
          </form>
        </Box>
      </Modal>


      <Modal open={isModalOpen} onClose={handleIsModalClose}>
  <Box sx={style}>
    <Typography variant="h6" component="h2" gutterBottom>
      Add Sanction Data
    </Typography>
    <form noValidate autoComplete="off">
      {/* Sanction Date Field */}
      <InputLabel htmlFor="sanction-date" style={{ marginTop: "10px" }}>
        Sanction Date
      </InputLabel>
      <TextField
        id="sanction-date"
        type="date"
        onKeyDown={(e) => e.preventDefault()}
        value={sanctionFormValues.sanction_date}
        onChange={(e) =>
          setSanctionFormValues({
            ...sanctionFormValues,
            sanction_date: e.target.value,
          })
        }
        required
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
      />

      {/* Sanction Amount Field */}
      <InputLabel htmlFor="sanction-amount" style={{ marginTop: "20px" }}>
        Sanction Amount
      </InputLabel>
      <TextField
        id="sanction-amount"
        type="number"
        value={sanctionFormValues.sanction_amount}
        onChange={(e) =>
          setSanctionFormValues({
            ...sanctionFormValues,
            sanction_amount: e.target.value,
          })
        }
        required
        fullWidth
      />

      {/* Action Buttons */}
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleFormUpdated}
        >
          Submit
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleIsModalClose}
          style={{ marginLeft: "10px" }}
        >
          Cancel
        </Button>
      </div>
    </form>
  </Box>
</Modal>

    </>
  );
};
export default InitiatedTermLoan;

